<!-- <ion-header *ngIf="event">
    <ion-toolbar class="ion-color" [style.background]="event.styling.menuColor"
        [style.color]="event.styling.menuTextColor">
        <ion-buttons slot="start">
            <ion-button slot="icon-only" (click)="close()">
                <ion-icon src="assets/icon/Close.svg"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            <p>{{"feed_news.default_title" | translate}}</p>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content style="--background:  var(--bg-color-content);"> -->
<div *ngIf="event && module && post && user" class="comment-main-container">
	<ion-card class="custom-card m-t-5 p-b-10">
		<ion-card-header class="header-post-item">
			<ion-item>
				<ion-icon
					style="cursor: pointer"
					[style.color]="event.styling.menuColor"
					slot="start"
					name="arrow-undo"
					(click)="close()"
				></ion-icon>
				<!-- image of profile user -->
				<ion-avatar slot="start" [style.background]="event.styling.menuColor">
					<img [src]="post.user.photoUrl" *ngIf="post.user.photoUrl" />
					<div class="profile-picture" *ngIf="!post.user.photoUrl && post?.user.name">
						<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
					</div>
				</ion-avatar>

				<ion-label class="ion-text-wrap">
					<h2 *ngIf="post.user.name" class="name-post" [style.color]="event.styling.titleColor">
						{{ post?.user.name }}
					</h2>
					<p class="post-date">{{ getDate(post.creationDate) }}</p>
				</ion-label>
			</ion-item>
		</ion-card-header>
		<ion-card-content class="post-card-content">
			<!-- Post text description -->
			<p
				class="post-description"
				[style.color]="event.styling.contentTextColor"
				[innerHTML]="post.description | safeHtml"
			></p>
			<!-- Post image: in openImage() pass the feed image url to use zoom -->
			<img class="post-img" [src]="post.imageUrl" (click)="openImage(post.imageUrl)" />

			<div class="divider"></div>
			<!-- Post likes -->
			<ion-grid *ngIf="post">
				<ion-row class="align-footer-post">
					<ion-col size="12">
						<app-feed-news-react [post]="post" [event]="event" [eventUser]="eventUser">
						</app-feed-news-react>
						<!-- <div class="normal-text like-container" (click)="addOrRemoveLike()">
                            <ion-icon [src]="verifiesLikedPost() ? 'assets/icon/Heart2.svg' : 'assets/icon/Heart.svg'"
                                class="icon-like" [style.color]="event.styling.btnBgColor">
                            </ion-icon>
                            <span [style.color]="event.styling.btnBgColor" class="nbr-likes-cmts"> {{ likes.length }}
                            </span>
                        </div>
                        <div class="align-texts-comment">
                            <ion-icon src="assets/icon/Message.svg" class="icon-not-like"
                                [style.color]="event.styling.btnBgColor"></ion-icon>
                            <span class="nbr-likes-cmts" [style.color]="event.styling.btnBgColor"> {{ comments.length
                                }} </span>
                        </div> -->
					</ion-col>
				</ion-row>
			</ion-grid>
			<div class="divider" *ngIf="comments.length > 0"></div>

			<!-- Write ur comment -->
			<div class="write-cmt" *ngIf="module.options.comments">
				<ion-avatar slot="start">
					<div class="profile-picture" *ngIf="!eventUser.photoUrl">
						<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
					</div>
					<ion-img *ngIf="eventUser.photoUrl" [src]="eventUser.photoUrl"> </ion-img>
				</ion-avatar>
				<ion-label>
					<textarea
						autocapitalize="sentences"
						rows="1"
						#chatInput
						placeholder="{{ 'feed_news.comments_plc' | translate }}"
						[(ngModel)]="textArea"
						style="min-height: 50px"
						[style.color]="event.styling.contentTextColor"
						[style.border-color]="event.styling.contentTextColor"
						(keyup)="checkOverFlow($event)"
						id="chatInput"
					></textarea>
					<button
						(click)="createComment()"
						[style.background]="event.styling.btnBgColor"
						[style.color]="event.styling.btnTextColor"
					>
						<ion-icon src="assets/icon/Send.svg"></ion-icon>
					</button>
				</ion-label>
			</div>

			<!-- Comments -->
			<ion-item *ngFor="let comment of comments" class="comment" lines="none">
				<ion-avatar slot="start">
					<div class="profile-picture" *ngIf="!comment.user.photoUrl">
						<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
					</div>
					<ion-img *ngIf="comment.user.photoUrl" [src]="comment.user.photoUrl"></ion-img>
				</ion-avatar>
				<ion-label class="ion-text-wrap">
					<ion-text>
						<h2>{{ comment.user.name }}</h2>
					</ion-text>
					<p [style.color]="event.styling.contentTextColor">{{ comment.comment }}</p>
					<div class="bottom-comment">
						<span>{{ getDate(comment.creationDate) }}</span>
						<span
							class="btn-delete-cmt"
							*ngIf="(post.user && post.user.uid === userId) || comment.user.uid === userId"
							(click)="deleteComment(comment)"
							>{{ "buttons.delete" | translate }}</span
						>
					</div>
				</ion-label>
			</ion-item>
		</ion-card-content>
	</ion-card>
	<br />
</div>
<!-- </ion-content> -->
