import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage, deleteObject, getDownloadURL, ref, uploadBytes, uploadString } from "@angular/fire/storage";
import { Camera, CameraResultType } from "@capacitor/camera";
import { Storage as AngularStorage } from "@ionic/storage-angular";
import { TranslateService } from "@ngx-translate/core";
import { Observable, firstValueFrom, from, lastValueFrom, of } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ISearchFilter } from "../interfaces/search.interfaces";
import { Filesystem, Directory, Encoding, ReadFileResult } from "@capacitor/filesystem";
import * as CryptoJS from "crypto-js";
import { MatSnackBar } from "@angular/material/snack-bar";

export interface IFilesUploadMetadata {
	uploadProgress$: Observable<number>;
	downloadUrl$: Observable<string>;
}

@Injectable()
export class StorageService {
	MAX_PICTURE_SIZE = 5 * 1024 * 1024; // 5MB
	private _storage: AngularStorage | null = null;
	baseFiltersQuery: ISearchFilter = {
		equalityFields: [],
		inequalityFields: [],
		includeTextFields: [],
		includeOrTextFields: [],
		isIncludedInFields: [],
		superiorFields: [],
		superiorOrEqualFields: [],
		inferiorFields: [],
		inferiorOrEqualFields: [],
		anyTextFields: [],
		arrayContainsAnyFields: [],
		arrayContainsAllFields: [],
		arrayContainsBlocAndOrFields: [],
		arrayNotContainsFields: [],
		elemMatchArrayFields: [],
		page: 1,
		itemsPerPage: 20,
		sortBy: []
	};

	constructor(
		private http: HttpClient,
		private fStorage: Storage,
		private storage: AngularStorage,
		private STranslate: TranslateService,
		private snackbar: MatSnackBar
	) {}

	async init() {
		const storage = await this.storage.create();
		this._storage = storage;
	}

	public get(key: string) {
		return from(this._storage?.get(key));
	}

	public set(key: string, value: any) {
		this._storage?.set(key, value);
	}

	/**
	 * Take a pictue
	 */
	getPicture() {
		return Camera.getPhoto({
			quality: 90,
			allowEditing: true,
			resultType: CameraResultType.Base64,
			promptLabelCancel: this.STranslate.instant("buttons.cancel"),
			promptLabelPhoto: this.STranslate.instant("buttons.from_photos"),
			promptLabelPicture: this.STranslate.instant("buttons.take_photo")
		});
	}

	/**
	 * Take a pictue in Uri format
	 */
	getPictureUri() {
		return Camera.getPhoto({
			quality: 90,
			allowEditing: true,
			resultType: CameraResultType.Base64,
			promptLabelCancel: this.STranslate.instant("buttons.cancel"),
			promptLabelPhoto: this.STranslate.instant("buttons.from_photos"),
			promptLabelPicture: this.STranslate.instant("buttons.take_photo")
		});
	}

	/**
	 * Upload base64 image
	 * @param base64
	 * @param type
	 * @param path
	 * @returns
	 */
	uploadBase64(base64: string, type: string, path: string) {
		return firstValueFrom(
			from(
				uploadString(ref(this.fStorage, path), base64, "base64", {
					contentType: type
				})
			).pipe(switchMap((_) => getDownloadURL(ref(this.fStorage, path))))
		);
	}

	/**
	 * Upload a file
	 * @param file
	 * @param path
	 */
	uploadFile(file: File | Blob, path: string, type: string) {
		return firstValueFrom(
			from(
				uploadBytes(ref(this.fStorage, path), file, {
					contentType: type
				})
			).pipe(switchMap((_) => getDownloadURL(ref(this.fStorage, path))))
		);
	}

	/**
	 * Delete a document on storage
	 * @param path
	 */
	deleteDocumentOnStorage(path: string) {
		return deleteObject(ref(this.fStorage, path));
	}

	/**
	 * blobToBase64
	 * @param blob
	 * @returns
	 */
	async getBlobFromPhotoUri(webPath: string) {
		return fetch(webPath).then((r) => r.blob());
	}

	blobFromBase64String(data: string) {
		const base64String = data.replace(/^data:image\/jpeg;base64,/, "")?.trim();
		const byteCharacters = atob(base64String);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		return new Blob([new Uint8Array(byteNumbers)], { type: "image/jpeg" });
	}

	/**
	 * getImageFileFromUrl
	 * @description get image from url and return it as Blob
	 */
	getImageFileFromUrl(url: string) {
		return lastValueFrom(
			this.http
				.request("GET", url, {
					responseType: "blob"
				})
				.pipe(
					switchMap((data: Blob) => {
						return data ? of(data) : of(null);
					})
				)
		);
	}

	checkIfFileExists(filePath: string): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			from(getDownloadURL(ref(this.fStorage, filePath))).subscribe({
				next: () => {
					resolve(true);
				},
				error: () => {
					resolve(false);
				}
			});
		});
	}

	fromFileToBase64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	getSearchDatas(
		eventId: string,
		moduleId: string,
		filters: ISearchFilter,
		focusedData?: { fieldData: string; fieldName: string; isDate: boolean }
	) {
		return this.http.post("https://storage-getsearchdatasmodule" + environment.platform.apiV2BaseUrl, {
			eventId: eventId,
			moduleId: moduleId,
			filters: filters,
			focusedData: focusedData
		});
	}

	//
	//
	// FILE SYSTEM PART
	//
	//

	writeFile = (path: string, data: string, directory: Directory, encoding: Encoding, recursive: boolean) => {
		return Filesystem.writeFile({
			path: path,
			data: data,
			directory: directory,
			encoding: encoding,
			recursive: recursive
		});
	};

	readFile = (path: string, directory: Directory, encoding: Encoding) => {
		return Filesystem.readFile({
			path: path,
			directory: directory,
			encoding: encoding
		});
	};

	deleteFile = (path: string, directory: Directory) => {
		return Filesystem.deleteFile({
			path: path,
			directory: directory
		});
	};

	createDirectory = (path: string, directory: Directory, recursive: boolean) => {
		return Filesystem.mkdir({
			path: path,
			directory: directory,
			recursive: recursive
		});
	};

	deleteDirectory = (path: string, directory: Directory, recursive: boolean) => {
		return Filesystem.rmdir({
			path: path,
			directory: directory,
			recursive: recursive
		});
	};

	statOfFile(path: string, directory: Directory) {
		return Filesystem.stat({
			path: path,
			directory: directory
		});
	}

	checkFileExist(
		path: string,
		directory: Directory,
		encoding: Encoding
	): Observable<{ status: "OK" | "NO-FILE" | "ERROR" | "NO-KEY"; datas: ReadFileResult }> {
		return from(this.readFile(path, directory, encoding)).pipe(
			take(1),
			switchMap((fileResult) => {
				return of({
					status: "OK" as const,
					datas: fileResult
				});
			}),
			catchError((error) => {
				if (
					error.message.toLowerCase().includes("no such file") ||
					error.message.toLowerCase().includes("not exist")
				) {
					return of({
						status: "NO-FILE" as const,
						datas: error
					});
				} else {
					return of({
						status: "ERROR" as const,
						datas: error
					});
				}
			})
		);
	}

	getDatasDbFromFile(
		path: string,
		directory: Directory,
		_encrypted: boolean
	): Observable<{ status: "OK" | "ERROR" | "NO-FILE"; datas: any }> {
		return from(
			new Promise((resolve) => {
				this.readFile(path, directory, Encoding.UTF8)
					.then((fileResult) => {
						const jsonDatas = JSON.parse(
							this.decryptUsingAES256(environment.platform.encryptKey, fileResult.data as string)
						);
						resolve({
							status: "OK",
							datas: jsonDatas
						});
					})
					.catch((error) => {
						if (error.message.includes("File does not exist")) {
							resolve({
								status: "NO-FILE",
								datas: error
							});
						} else {
							resolve({
								status: "ERROR",
								datas: error
							});
						}
					});
			})
		).pipe(
			switchMap((result: { status: "OK" | "ERROR" | "NO-FILE"; datas: any }) => {
				return of(result);
			})
		);
	}

	encryptUsingAES256(key: string, datas: any) {
		const _key = CryptoJS.enc.Utf8.parse(key);
		const _iv = CryptoJS.enc.Utf8.parse(key);
		const encrypted = CryptoJS.AES.encrypt(JSON.stringify(datas), _key, {
			keySize: 16,
			iv: _iv,
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		return encrypted.toString();
	}

	decryptUsingAES256(key: string, datas: string) {
		const _key = CryptoJS.enc.Utf8.parse(key);
		const _iv = CryptoJS.enc.Utf8.parse(key);

		return CryptoJS.AES.decrypt(datas, _key, {
			keySize: 16,
			iv: _iv,
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		}).toString(CryptoJS.enc.Utf8);
	}

	/**
	 * checkBase64ImgSizeLimit
	 * @param {string} base64File
	 * @return {*}  {boolean}
	 * @memberof ProfileGeneralInfoComponent
	 */
	checkBase64ImgSizeLimit(base64File: string): boolean {
		const size = Math.round((base64File.length * 0.75) / (1024 * 1024));

		if (size > this.MAX_PICTURE_SIZE / (1024 * 1024)) {
			this.snackbar.open(
				this.STranslate.instant("register.validator-error-msg.file.file_size", {
					limit: this.MAX_PICTURE_SIZE / (1024 * 1024)
				}),
				"",
				{
					duration: 5000,
					panelClass: "error-snackbar"
				}
			);
			return true;
		}

		return false;
	}
}
