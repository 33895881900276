<div
	*ngIf="event && eventUser"
	style="margin: 20px 0"
	[class.f-col-c-c]="disposition === 'column'"
	[class.f-row-c-fs]="disposition === 'row'"
	[style.height]="height"
>
	<ion-label
		style="height: auto !important; text-align: center"
		[style.margin-bottom]="disposition === 'column' ? '20px' : '0px'"
		[style.color]="event.styling.contentTextColor"
	>
		{{
			eventUser.updatedSettings.timezoneType === "local"
				? ("events.switch_timezone_event" | translate) + " (" + event.timezone + ")"
				: ("events.switch_timezone_user" | translate) + getCurrentTimezone()
		}}
	</ion-label>
	<ion-toggle
		[style.margin-left]="disposition === 'row' ? '20px' : '0'"
		[disabled]="updating"
		[checked]="timezoneType"
		mode="ios"
		[style.--background-checked]="event.styling.btnBgColor"
		[style.--handle-background-checked]="event.styling.btnTextColor"
		[style.--background]="event.styling.btnBgColor"
		[style.--handle-background]="event.styling.btnTextColor"
		(ionChange)="changeTimezoneType($event)"
	>
	</ion-toggle>
</div>
