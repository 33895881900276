import { Injectable } from "@angular/core";
import { Firestore, limit, orderBy, runTransaction, where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Observable, Subscription, combineLatest, firstValueFrom, forkJoin, of, from } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { GetAllChats, GetAllMessages } from "../actions/chats.actions";
import { InitSpecificEventDatasPart, SetChatsBadge } from "../actions/utility.actions";
import { IEvent, IEventUser, IMessage, IModule } from "../interfaces";
import { IChat } from "../interfaces/chats.interfaces";
import { getCurrentEventUser, getCurrentUserId } from "../selectors/auth.selectors";
import { checkSameEvent, getChatOfTwo, getChats, getMessages } from "../selectors/chats.selectors";
import { getCurrentEvent } from "../selectors/events.selectors";
import { selectRouteNestedParams, selectUrl } from "../selectors/router.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import { LastAccessService } from "./last-access.service";
import { NotificationsService } from "./notifications.service";

@Injectable({
	providedIn: "root"
})
export class ChatsService {
	messageAlertSub: Subscription;
	mainChatsSub: Subscription;
	chatsSub: Subscription;
	chatsNotifsSub: Subscription;
	messagesSub: Subscription;

	lastMessageAlert: IMessage = null;
	initDate: string = "";
	idsOfSubscribedChats: string[] = [];
	savedEvent: IEvent;
	savedUser: IEventUser;

	constructor(
		private firestore: Firestore,
		private SFirestore: FirestoreService,
		private SLastAccess: LastAccessService,
		private SNotifs: NotificationsService,
		private store: Store,
		private STranslate: TranslateService
	) {}

	/**
	 * Unsubscribe chats
	 */
	unsubscribeChats() {
		[this.messagesSub, this.mainChatsSub, this.chatsSub, this.messageAlertSub, this.chatsNotifsSub].forEach(
			(sub) => {
				if (sub) sub.unsubscribe();
			}
		);
		this.savedUser = null;
		this.savedEvent = null;
		this.idsOfSubscribedChats = [];
	}

	/**
	 * Init chats badge notifs
	 */
	initChatsBadge() {
		if (this.chatsNotifsSub && !this.chatsNotifsSub.closed) {
			this.chatsNotifsSub.unsubscribe();
		}

		this.chatsNotifsSub = this.store.select(getCurrentEvent).subscribe((event) => {
			if (event && !_.isEqual(event, this.savedEvent)) {
				this.savedEvent = event;
				this.initDate = DateTime.local().toISO();
				if (this.messageAlertSub && !this.messageAlertSub.closed) {
					this.messageAlertSub.unsubscribe();
				}
				this.messageAlertSub = combineLatest([
					this.SLastAccess.getChatsLastAccess(event.uid),
					this.store.select(getChats),
					this.store.select(getMessages),
					this.store.select(getCurrentUserId)
				]).subscribe({
					next: async (results) => {
						const lastAccess = results[0];
						const chats = results[1];
						let messages = results[2];
						const userId = results[3];
						const chatsNotAccessed = chats.filter(
							(chat) => userId && chat.lastMessageId && !chat.lastAccess[userId]
						);
						if (userId && (lastAccess.length > 0 || chatsNotAccessed.length > 0)) {
							const results = await firstValueFrom(
								combineLatest([
									this.store.select(selectUrl),
									this.store.select(selectRouteNestedParams)
								]).pipe(take(1))
							);
							let chatsBadge = 0;
							messages = messages.filter(
								(message) =>
									message.sender.uid !== userId &&
									chats.find((chat) => chat.uid === message.chatId) &&
									chats.find((chat) => chat.uid === message.chatId).type === 0
							);
							messages.forEach((message) => {
								const checkChat =
									!results[0].includes("chat/") ||
									(results[0].includes("chat/") && results[1].chatId !== message.chatId)
										? true
										: false;
								if (lastAccess.length > 0 && message.sendAt > lastAccess[0].lastAccess && checkChat) {
									chatsBadge++;
								}
							});

							chatsBadge += chatsNotAccessed.length;
							const otherMess = messages.filter((mess) => {
								const chat = chats.find((chat) => chat.uid === mess.chatId);
								const checkChat =
									!results[0].includes("chat/") ||
									(results[0].includes("chat/") && chat && results[1].chatId !== chat.uid)
										? true
										: false;
								if (
									checkChat &&
									chat &&
									chat.allowNotifs &&
									mess.sender.uid !== userId &&
									mess.sendAt >= this.initDate &&
									((lastAccess.length > 0 && mess.sendAt > lastAccess[0].lastAccess) ||
										(chat && chat.type === 0 && !chat.lastAccess[userId]))
								) {
									return true;
								} else {
									return false;
								}
							});
							if (
								event &&
								event.settings.allowChatPopup &&
								otherMess.length > 0 &&
								(!this.lastMessageAlert ||
									otherMess[otherMess.length - 1].uid !== this.lastMessageAlert.uid)
							) {
								this.lastMessageAlert = otherMess[otherMess.length - 1];
								this.SNotifs.presentNotifToast(
									this.lastMessageAlert.sender.name,
									this.lastMessageAlert.content,
									// eslint-disable-next-line max-len
									`/event/${this.lastMessageAlert.eventId}/chats/${this.lastMessageAlert.moduleId}/chat/${this.lastMessageAlert.chatId}`,
									this.lastMessageAlert.uid
								).then(() => {
									const els = document.querySelectorAll("ion-toast");
									els.forEach((item) => {
										if (item.id !== this.lastMessageAlert.uid) {
											item.style.top = "180px";
											setTimeout(() => item.remove(), 400);
										}
									});
								});
							}
							this.store.dispatch(SetChatsBadge({ payload: chatsBadge }));
						}
					}
				});
			}
		});
	}

	/**
	 * Get chats on store
	 * @param eventId
	 */
	getChatsOnStore(eventId: string) {
		if (eventId) {
			this.mainChatsSub = combineLatest([
				this.store.select(checkSameEvent(eventId)).pipe(take(1)),
				this.store.select(getCurrentEventUser)
			]).subscribe((results) => {
				const sameEvent = results[0];
				const eventUser = results[1];

				if (
					sameEvent &&
					this.chatsSub &&
					!this.chatsSub.closed &&
					results[1] &&
					this.savedUser.uid === results[1].uid
				) {
					return;
				} else {
					this.chatsSub?.unsubscribe();
				}

				this.savedUser = results[1];

				if (!eventUser) {
					this.store
						.select(getInitSpecificEventDatasPart("initChats"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(InitSpecificEventDatasPart({ part: "initChats", payload: true }));
							}
						});
					// });
				} else {
					const obsArrayChats: Observable<IChat[]>[] = [];
					// Push groups chats not linked to groups
					obsArrayChats.push(
						this.SFirestore.collectionGroupValueChangesDocuments("chats", [
							where("eventId", "==", eventId),
							where("type", "==", 1),
							where("options.groupsLink.linked", "==", false),
							where("options.specificMembersLink.linked", "==", false),
							where("disabled", "==", false)
						])
					);
					// Push groups chats linked to specific
					obsArrayChats.push(
						this.SFirestore.collectionGroupValueChangesDocuments("chats", [
							where("eventId", "==", eventId),
							where("type", "==", 1),
							where("options.groupsLink.linked", "==", false),
							where("options.specificMembersLink.linked", "==", true),
							where("options.specificMembersLink.specifics", "array-contains", eventUser?.uid),
							where("disabled", "==", false)
						])
					);

					// Push groups chats linked to event user groups
					const chunkedGroups = _.chunk(eventUser.groups, 10);
					chunkedGroups.forEach((chunk) => {
						obsArrayChats.push(
							this.SFirestore.collectionGroupValueChangesDocuments("chats", [
								where("eventId", "==", eventId),
								where("type", "==", 1),
								where("options.groupsLink.linked", "==", true),
								where(
									"options.groupsLink.groups",
									"array-contains-any",
									chunk.length > 0 ? chunk : ["none"]
								),
								where("disabled", "==", false)
							])
						);
					});

					// Push chats one to one
					obsArrayChats.push(
						this.SFirestore.collectionGroupValueChangesDocuments("chats", [
							where("eventId", "==", eventId),
							where("type", "==", 0),
							where("members", "array-contains", eventUser?.uid),
							where("disabled", "==", false)
						]).pipe(
							switchMap((chats) => {
								if (chats.length === 0) {
									return of([]);
								}
								const obsArray: Observable<IEventUser>[] = [];
								chats.forEach((chat) => {
									if (chat.type === 0) {
										const interlocutorId = chat.members.filter((uid) => uid !== eventUser?.uid)[0];
										if (interlocutorId) {
											obsArray.push(
												from(
													this.SFirestore.getDocumentsCollectionGroup("event-users", [
														where("eventId", "==", eventId),
														where("uid", "==", interlocutorId)
													])
												).pipe(
													map((docs) => docs.docs.map((doc) => doc.data())),
													switchMap((users) => {
														return users.length > 0 ? of(users[0]) : of(null);
													})
												)
											);
										}
									}
								});
								return forkJoin(obsArray).pipe(
									switchMap((interlocutors) => {
										interlocutors.forEach((interlocutor) => {
											if (interlocutor) {
												const chat = chats.find(
													(chat) => chat.type === 0 && chat.members.includes(interlocutor.uid)
												);
												chat.interlocutor = interlocutor;
											}
										});
										return of(chats);
									})
								);
							})
						)
					);

					this.chatsSub = combineLatest(obsArrayChats).subscribe((resultsChats) => {
						let concatedChats: IChat[] = [];
						resultsChats.forEach((resultChat) => {
							concatedChats = concatedChats.concat(resultChat);
						});
						this.store.dispatch(
							GetAllChats({
								payload: concatedChats && concatedChats.length > 0 ? concatedChats : [],
								eventId: eventId
							})
						);
						const chatsSorted = _.cloneDeep(concatedChats).sort((a, b) =>
							a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
						);
						// if (
						// 	!_.isEqual(
						// 		this.idsOfSubscribedChats,
						// 		chatsSorted.map((chat) => chat.uid)
						// 	) &&
						// 	eventUser
						// ) {
						this.idsOfSubscribedChats = chatsSorted.map((chat) => chat.uid);
						this.getMessagesOfChatsOnStore(eventId, chatsSorted);
						// }

						this.store
							.select(getInitSpecificEventDatasPart("initChats"))
							.pipe(take(1))
							.subscribe((init) => {
								if (!init) {
									this.store.dispatch(
										InitSpecificEventDatasPart({ part: "initChats", payload: true })
									);
								}
							});
					});
				}
			});
		}
	}

	/**
	 * Get mesages of chats on store
	 * @param eventId
	 */
	getMessagesOfChatsOnStore(eventId: string, chats: IChat[]) {
		if (this.messagesSub && !this.messagesSub.closed) {
			this.messagesSub.unsubscribe();
		}

		const obsArray: Observable<IMessage[]>[] = [];
		chats.forEach((chat) => {
			obsArray.push(this.getMessagesOfSpecificChat(chat.eventId, chat.moduleId, chat.uid, 1));
		});

		this.messagesSub = combineLatest(obsArray).subscribe({
			next: (resultsMessages) => {
				const messages = _.flatten(resultsMessages);
				this.store.dispatch(GetAllMessages({ payload: messages ? messages : [], eventId: eventId }));
			}
		});
	}

	/**
	 * Get messages of chat
	 * @param eventId
	 * @param moduleId
	 * @param chatId
	 * @returns
	 */
	getMessagesOfSpecificChat(eventId: string, moduleId: string, chatId: string, limitMessages: number) {
		return this.SFirestore.collectionGroupValueChangesDocuments("chat-messages", [
			where("eventId", "==", eventId),
			where("moduleId", "==", moduleId),
			where("chatId", "==", chatId),
			orderBy("sendAt", "desc"),
			limit(limitMessages)
		]);
	}

	/**
	 * Get or create chat
	 * @param event
	 * @param eventUserId
	 * @param interlocutorId
	 * @returns
	 */
	async getOrCreateChat(event: IEvent, module: IModule, eventUserId: string, interlocutorId: string) {
		const chats = await firstValueFrom(
			this.store
				.select(getChatOfTwo({ myId: eventUserId, userId: interlocutorId, eventId: event.uid }))
				.pipe(take(1))
		);

		if (chats.length === 0) {
			// Chat don't exist so create it
			const chat: IChat = {
				uid: this.SFirestore.createId(`events/${event.uid}/modules/${module.uid}/chats`),
				allowNotifs: true,
				creationDate: DateTime.local().toISO(),
				createdFrom: "app",
				disabled: false,
				eventId: event.uid,
				lastAccess: "",
				lastMessageId: "",
				lastMessageUser: null,
				lastUpdated: "",
				members: [eventUserId, interlocutorId],
				moduleId: module.uid,
				options: {
					allowVisio: event.settings.allowVisioForTwo
				},
				type: 0,
				visibility: true
			};
			await this.createChat(event.uid, module.uid, chat);
			return chat;
		} else {
			return chats[0];
		}
	}

	/**
	 * Create a chat
	 * @param eventId
	 * @param moduleId
	 * @param chat
	 * @returns
	 */
	createChat(eventId: string, moduleId: string, chat: IChat) {
		const id = !chat.uid ? this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/chats`) : chat.uid;
		chat.uid = id;

		return this.SFirestore.setDocument(`events/${eventId}/modules/${moduleId}/chats/${chat.uid}`, chat);
	}

	/**
	 * Update a chat
	 * @param eventId
	 * @param moduleId
	 * @param chat
	 * @returns
	 */
	updateChat(eventId: string, moduleId: string, chatId: string, datas: any) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/chats/${chatId}`,
			_.omit(datas, ["eventId", "moduleId", "uid", "createdFrom", "type", "creationDate"])
		);
	}

	/**
	 * Update full chat
	 * @param eventId
	 * @param moduleId
	 * @param chat
	 * @returns
	 */
	updateFullChat(eventId: string, moduleId: string, chat: IChat) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/chats/${chat.uid}`,
			_.omit(chat, ["eventId", "moduleId", "uid", "createdFrom", "type", "creationDate"])
		);
	}

	/**
	 * Delete a chat
	 * @param chat
	 * @returns
	 */
	deleteChat(chat: IChat) {
		return this.SFirestore.deleteDocument(`events/${chat.eventId}/modules/${chat.moduleId}/chats/${chat.uid}`);
	}

	/**
	 * Create a message
	 * @param eventId
	 * @param moduleId
	 * @param chatId
	 * @param message
	 * @returns
	 */
	createMessage(eventId: string, moduleId: string, chatId: string, message: IMessage) {
		message.uid = message.uid
			? message.uid
			: this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/chats/${chatId}/chat-messages`);
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/chats/${chatId}/chat-messages/${message.uid}`,
			message
		);
	}

	/**
	 * Create visio message
	 * @param event
	 * @param chatId
	 * @param userId
	 */
	createVisioMessage(event: IEvent, moduleId: string, chat: IChat, message: IMessage) {
		return this.createMessage(event.uid, moduleId, chat.uid, message);
	}

	/**
	 * Update muted for chats groups
	 * @param eventId
	 * @param moduleId
	 * @param chatId
	 * @param userId
	 * @returns
	 */
	updateMuted(eventId: string, moduleId: string, chatId: string, userId: string) {
		const chatRef = this.SFirestore.docRef(`events/${eventId}/modules/${moduleId}/chats/${chatId}`);

		return runTransaction(this.firestore, async (transaction) => {
			const chatDoc = await transaction.get(chatRef);
			const muted = chatDoc.data().options.muted || [];

			return transaction.update(chatRef, {
				"options.muted": muted.includes(userId) ? muted.filter((u) => u !== userId) : [...muted, userId]
			});
		});
	}

	/**
	 * Check if is admin of chat
	 * @param chat
	 * @param eventUser
	 * @returns
	 */
	checkAdminOfChat(chat: IChat, eventUser: IEventUser) {
		if (
			chat &&
			eventUser &&
			chat.options &&
			chat.options.groupCreator &&
			chat.options.groupCreator.uid === eventUser.uid &&
			chat.options.specificMembersLink &&
			chat.options.specificMembersLink.specifics.includes(eventUser.uid)
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Check if is member of chat
	 * @param chat
	 * @param eventUser
	 * @returns
	 */
	checkMemberOfChat(chat: IChat, eventUser: IEventUser) {
		if (
			eventUser &&
			chat &&
			chat.type === 1 &&
			chat.options &&
			chat.options.groupCreator &&
			chat.options.groupCreator.uid !== eventUser.uid &&
			chat.options.specificMembersLink &&
			chat.options.specificMembersLink.specifics.includes(eventUser.uid)
		) {
			return true;
		} else {
			return false;
		}
	}
}
