import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Observable, of } from "rxjs";
import { skipWhile, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { GetAllEvents } from "../actions/events.actions";
import { TypeLogin } from "../enums/type-login";
import { getCurrentUser } from "../selectors/auth.selectors";
import { getContainersState } from "../selectors/containers.selectors";
import { getAllEvents } from "../selectors/events.selectors";
import { getInitContainers, getInitEvents, getInitUser } from "../selectors/utility.selectors";
import { EventsService } from "../services";

@Injectable()
export class ShortcodeGuard {
	constructor(
		private store: Store,
		private snackbar: MatSnackBar,
		private navCtrl: NavController,
		private SEvents: EventsService,
		private STranslate: TranslateService
	) {}
	canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
		return combineLatest([
			this.store.select(getInitUser).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitEvents).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitContainers).pipe(
				skipWhile((init) => init === false),
				take(1)
			)
		]).pipe(
			take(1),
			switchMap(() =>
				combineLatest([
					this.store.select(getCurrentUser),
					this.store.select(getAllEvents),
					this.SEvents.getEventByShortcode(route.params.shortCodeId),
					this.store.select(getContainersState)
				]).pipe(take(1))
			),
			switchMap((results) => {
				const user = results[0];
				const events = results[1];
				const event = results[2];
				const containersState = results[3];
				const container =
					!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
						? containersState.defaultContainer
						: containersState.currentContainer;

				if (event) {
					// If event exist
					if (
						(container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM &&
							!container.loginSettings.showShortCodeBtn) ||
						(container.loginSettings.type === TypeLogin.WITHOUT_EMAIL_CONFIRM &&
							!container.loginSettings.showShortCodeBtn)
					) {
						/**
						 * If event don't have authorized shortcode access for login type event redirect
						 * to login or events list if user logged
						 */
						this.snackbar.open(this.STranslate.instant("snackbar.not_authorized_event"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
						this.navCtrl.navigateRoot(
							user
								? "/events-list/" +
										(container.loginSettings.showAccessPublicEventsBtn ? "all" : "my-events")
								: "/login"
						);
						return of(false);
					} else if (event.settings.visibility) {
						this.store.dispatch(GetAllEvents({ payload: [event] }));
						// If event is public go to event
						this.navCtrl.navigateRoot("/event/" + event.uid);
						return of(false);
					} else {
						// If event is private
						if (user && events.find((evt) => evt.uid === event.uid)) {
							// If user exist and event found in user events list go to event
							this.navCtrl.navigateRoot("/event/" + event.uid);
							return of(false);
						} else {
							// If user not found redirect to login
							this.snackbar.open(this.STranslate.instant("snackbar.authentification_required"), "", {
								duration: 3000,
								panelClass: "error-snackbar"
							});
							this.navCtrl.navigateRoot("/login/" + event.uid);
							return of(false);
						}
					}
				} else {
					// If not event found for this shortcode
					this.snackbar.open(this.STranslate.instant("snackbar.not_authorized_event"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					this.navCtrl.navigateRoot(
						user
							? "/events-list/" +
									(container.loginSettings.showAccessPublicEventsBtn ? "all" : "my-events")
							: "/login"
					);
					return of(false);
				}
			})
		);
	}
}
