<ion-content padding>
	<div class="align-content">
		<ion-grid>
			<!-- <ion-row *ngIf="event">
        <ion-col>
          <div class="img">
            <img class="banner" [src]="event.logo.url" alt="banner"
              *ngIf="event.logo.url !== '' && event.logo.url !== undefined && event.logo.url !== null">
          </div>
        </ion-col>
        <br>
      </ion-row> -->
			<ion-row>
				<ion-col>
					<div class="align-loader-custom">
						<div class="loader-splash"></div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</ion-content>
