import { AnimationController, Animation } from "@ionic/angular";

export const enterAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
	const animationCtrl = new AnimationController();

	if (opts.direction === "back") {
		const enteringAnim = animationCtrl
			.create()
			.addElement(opts.enteringEl)
			.duration(200)
			.iterations(1)
			.easing("ease-out")
			.fromTo("transform", "translateX(-200px)", "translateX(0px)")
			.fromTo("opacity", "0.0", "1");

		const leavingAnim = animationCtrl
			.create()
			.addElement(opts.leavingEl)
			.duration(250)
			.iterations(1)
			.easing("ease-out")
			.fromTo("transform", "translateX(0px)", "translateX(200px)")
			.fromTo("opacity", "1", "0.0");

		return animationCtrl.create().addAnimation([enteringAnim, leavingAnim]);
	} else {
		const enteringAnim = animationCtrl
			.create()
			.addElement(opts.enteringEl)
			.duration(200)
			.iterations(1)
			.easing("linear")
			.fromTo("transform", "translateX(200px)", "translateX(0px)")
			.fromTo("opacity", "0.0", "1");

		const leavingAnim = animationCtrl
			.create()
			.addElement(opts.leavingEl)
			.duration(250)
			.iterations(1)
			.easing("linear")
			.fromTo("transform", "translateX(0px)", "translateX(-200px)")
			.fromTo("opacity", "1", "0.0");

		return animationCtrl.create().addAnimation([enteringAnim, leavingAnim]);
	}
};
