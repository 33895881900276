@for (computedCustomField of computedCustomFields; track computedCustomField) {
<div [ngClass]="module.type === typeModule.SCHEDULE ? 'container-schedule' : 'container-profile'">
	<!-- Text field -->
	@if ( (computedCustomField.baseSettings.type === typeCustomFields.EMAIL || computedCustomField.baseSettings.type ===
	typeCustomFields.URL) && ((module.type === typeModule.SCHEDULE &&
	!computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.text && computedCustomField.fieldDatas.field.text !== '' ) {
	<div class="simpletext-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- visibility chip -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			@if (computedCustomField.baseSettings.type === typeCustomFields.EMAIL) {
			<a href="mailto:{{ computedCustomField.fieldDatas.field.text }}">
				{{ computedCustomField.fieldDatas.field.text }}
			</a>
			} @if (computedCustomField.baseSettings.type === typeCustomFields.URL) {
			<a href="{{ computedCustomField.fieldDatas.field.text }}" target="_blank">
				{{ computedCustomField.fieldDatas.field.text }}
			</a>
			}
		</div>
		<!-- <div #itemDiv [style.color]="event.styling.contentTextColor" class="description"
        style="white-space: pre-line;"
        [innerHTML]="item.textValue[userLanguage] | safeHtml">
      </div>    -->
	</div>
	}
	<!-- Multi language Text & Select field -->
	@if ( (computedCustomField.baseSettings.type === typeCustomFields.TEXT || computedCustomField.baseSettings.type ===
	typeCustomFields.SELECT) && ((module.type === typeModule.SCHEDULE &&
	!computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.multiLanguageText &&
	computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !== '' ) {
	<div class="multi-laguage-text-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] }}</span>
		</div>
	</div>
	}
	<!-- Muli-Text field -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.MULTI_TEXT && ((module.type === typeModule.SCHEDULE
	&& !computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage] &&
	computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage].length > 0 ) {
	<div class="multi-text-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			@if ( computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== undefined ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			@for ( data of computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage]; track data; let
			i = $index) {
			<span>
				@if (i < computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage].length - 1) {
				<span>{{ data }},&nbsp;</span>
				} @if ( i === computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage].length - 1 )
				{
				<span>
					{{ data }}
				</span>
				}
			</span>
			}
		</div>
	</div>
	}
	<!-- Multi-Select field -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.MULTI_SELECT && ((module.type ===
	typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE
	|| module.type === typeModule.SPEAKER || module.type === typeModule.SHEETS) &&
	((!computedCustomField.moduleSettings.canBeTag && !computedCustomField.moduleSettings.hiding.profil && isProfilMode)
	|| (!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.multiLanguageSelectArray &&
	computedCustomField.fieldDatas.field.multiLanguageSelectArray.length > 0 &&
	checkMultiSelectDatas(computedCustomField.fieldDatas.field.multiLanguageSelectArray, currentLanguage) ) {
	<div class="multi-select-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			@for (data of computedCustomField.fieldDatas.field.multiLanguageSelectArray; track data; let i = $index) {
			<span>
				@if ( i < computedCustomField.fieldDatas.field.multiLanguageSelectArray.length - 1 &&
				data[currentLanguage]?.trim() !== '' ) {
				<span>{{ data[currentLanguage]?.trim() }},&nbsp;</span>
				} @if ( i === computedCustomField.fieldDatas.field.multiLanguageSelectArray.length - 1 &&
				data[currentLanguage]?.trim() !== '' ) {
				<span>
					{{ data[currentLanguage] }}
				</span>
				}
			</span>
			}
		</div>
	</div>
	}
	<!-- Numeric field -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.NUMERIC && ((module.type === typeModule.SCHEDULE &&
	!computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.numeric && computedCustomField.fieldDatas.field.numeric !== null ) {
	<div class="numeric-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ computedCustomField.fieldDatas.field.numeric }}</span>
		</div>
	</div>
	}
	<!-- Date field -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.DATE && ((module.type === typeModule.SCHEDULE &&
	!computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.date.fullDateISO && computedCustomField.fieldDatas.field.date.fullDateISO !==
	null ) {
	<div class="simpletext-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ getPartOfDate(computedCustomField.fieldDatas.field.date.fullDateISO, "full") }}</span>
		</div>
	</div>
	}
	<!-- Address field -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.ADDRESS && ((module.type === typeModule.SCHEDULE &&
	!computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.address.fullAddress !== '' ) {
	<div class="simpletext-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ computedCustomField.fieldDatas.field.address.fullAddress }}</span>
		</div>
	</div>
	}
	<!-- Checkbox field -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.CHECKBOX && ((module.type === typeModule.SCHEDULE
	&& !computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.checkbox ) {
	<div class="simpletext-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ computedCustomField.fieldDatas.field.checkbox }}</span>
		</div>
	</div>
	}
	<!-- Phone number field -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.PHONE && ((module.type === typeModule.SCHEDULE &&
	!computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.phoneNumber &&
	computedCustomField.fieldDatas.field.phoneNumber.internationalNumber &&
	computedCustomField.fieldDatas.field.phoneNumber.internationalNumber !== '' ) {
	<div class="phonenumber-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<a href="tel:{{ computedCustomField.fieldDatas.field.phoneNumber.internationalNumber }}">{{
				computedCustomField.fieldDatas.field.phoneNumber.internationalNumber
			}}</a>
		</div>
	</div>
	}
	<!-- Country field -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.COUNTRY && ((module.type === typeModule.SCHEDULE &&
	!computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.country && computedCustomField.fieldDatas.field.country.name !== '' ) {
	<div class="simpletext-field">
		<div class="item-name" [style.color]="event.styling.titleColor">
			@if ( computedCustomField.baseSettings.icon.iconFamily !== undefined &&
			computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
			computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon' ) {
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons"> </span>
			} @if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon">{{
				computedCustomField.baseSettings.icon.icon
			}}</i>
			}
			<!-- show custom picto img -->
			@if ( computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
			computedCustomField.baseSettings.icon.icon !== null ) {
			<img
				class="menu-icon"
				style="width: 22px; height: 22px"
				[src]="computedCustomField.baseSettings.icon.icon"
			/>
			}
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
			<!-- Visibility state -->
			@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
			<span class="visibility-state">
				@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
				} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
				computedCustomField.baseSettings.uid ) ) {
				<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
				}
			</span>
			}
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			@if (computedCustomField.baseSettings.type === typeCustomFields.COUNTRY) {
			<span>
				{{ computedCustomField.fieldDatas.field.country.name }}
			</span>
			}
		</div>
	</div>
	}
	<!-- HTML - Tiny text fields -->
	<!-- Only display if the Tiny / Rich texts fields are filled  -->
	@if ( computedCustomField.baseSettings.type === typeCustomFields.HMTL && ((module.type === typeModule.SCHEDULE &&
	!computedCustomField.moduleSettings.hiding.general) || ((module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER || module.type === typeModule.SHEETS) && ((!computedCustomField.moduleSettings.canBeTag &&
	!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
	computedCustomField.fieldDatas.field.multiLanguageText &&
	computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !== '' ) {
	<ion-card class="html-card">
		<div class="custom-card">
			<div class="block-content">
				<span class="custom-field-name-block">
					@if ( computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== '' ) {
					<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon custom-field-icon">
						{{ computedCustomField.baseSettings.icon.icon }}
					</i>
					} @if ( computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== undefined ) {
					<span
						class="b3-icons {{
							computedCustomField.baseSettings.icon.iconFamily
						}} b3app-icons custom-field-icon"
					>
					</span>
					}
					<ion-label class="custom-field-name">
						{{ computedCustomField.baseSettings.name[currentLanguage] }}
					</ion-label>
				</span>
				<div
					class="html-card-content"
					[innerHTML]="computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] | safeHtml"
				></div>
			</div>
		</div>
	</ion-card>
	}
	<!-- user document -->
	@if ( ((!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)) &&
	computedCustomField.baseSettings.type === typeCustomFields.FILE && computedCustomField.fieldDatas.field.file &&
	computedCustomField.fieldDatas.field.file.url && computedCustomField.fieldDatas.field.file.url !== '' ) {
	<div class="custom-card">
		<ng-container>
			<div style="display: flex">
				<h6
					class="custom-field-name-block"
					[style.font-family]="event.styling.font"
					[style.color]="event.styling.titleColor"
				>
					<ion-icon class="custom-field-icon" src="assets/icon/Clip.svg"></ion-icon>
					<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
				</h6>
				<!-- Visibility state -->
				@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
				<span class="visibility-state">
					@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
					computedCustomField.baseSettings.uid ) ) {
					<ion-chip color="success" style="position: relative; top: 18px !important">{{
						"labels.visible" | translate
					}}</ion-chip>
					} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
					computedCustomField.baseSettings.uid ) ) {
					<ion-chip color="danger" style="position: relative; top: 18px !important">{{
						"labels.hidden" | translate
					}}</ion-chip>
					}
				</span>
				}
			</div>
			<ion-list>
				<ion-item
					id="toto"
					lines="none"
					class="align-flex clickable-doc"
					(click)="openDocument(computedCustomField.fieldDatas.field.file, 'user')"
				>
					<ion-thumbnail class="margin-padding-0" slot="start">
						<img
							src="assets/icon/Files/{{
							typeFormatFiles.includes( computedCustomField.fieldDatas.field.file.type?.split('/')?.[1]) ? computedCustomField.fieldDatas.field.file.type.split('/')[1] : 'generic-file'
							}}_ico.svg"
						/>
					</ion-thumbnail>
					<ion-label class="margin-padding-0" [style.color]="event.styling.titleColor">
						<h4 class="margin-padding-0" [style.font-family]="event.styling.font">
							{{ computedCustomField.fieldDatas.field.file.name }}
						</h4>
						<p class="margin-padding-0" [style.font-family]="event.styling.font">
							{{
								SDocument.checkType(
									computedCustomField.fieldDatas.field.file &&
										computedCustomField.fieldDatas.field.file.type &&
										computedCustomField.fieldDatas.field.file.type.toString().split("/")[1]
								)
							}}
						</p>
					</ion-label>
					<ion-icon
						slot="end"
						[style.color]="event.styling.btnBgColor"
						style="cursor: pointer"
						name="copy-outline"
						(click)="copyToClipboard(computedCustomField.fieldDatas.field.file); $event.stopPropagation()"
					></ion-icon>
				</ion-item>
			</ion-list>
		</ng-container>
	</div>
	}
	<!-- user Image -->
	@if ( ((!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
	(!SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
	computedCustomField.baseSettings.uid ) && !computedCustomField.moduleSettings.hiding.list && !isProfilMode)) &&
	computedCustomField.baseSettings.type === typeCustomFields.IMAGE && computedCustomField.fieldDatas.field.image &&
	computedCustomField.fieldDatas.field.image.url && computedCustomField.fieldDatas.field.image.url !== '' ) {
	<div class="custom-card">
		<ng-container>
			<div style="display: flex">
				<h6
					class="custom-field-name-block"
					[style.font-family]="event.styling.font"
					[style.color]="event.styling.titleColor"
				>
					<ion-icon class="custom-field-icon" src="assets/icon/Clip.svg"></ion-icon>
					<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
				</h6>
				<!-- Visibility state -->
				@if (module.options.enableUserFieldsHideAbility && isProfilMode) {
				<span class="visibility-state">
					@if ( !SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
					computedCustomField.baseSettings.uid ) ) {
					<ion-chip color="success" style="position: relative; top: 18px !important">{{
						"labels.visible" | translate
					}}</ion-chip>
					} @if ( SCustomFields.isUserOrModuleFieldsVisibilityHidden( module, modulesCustomsFields, data,
					computedCustomField.baseSettings.uid ) ) {
					<ion-chip color="danger" style="position: relative; top: 18px !important">{{
						"labels.hidden" | translate
					}}</ion-chip>
					}
				</span>
				}
			</div>
			<ion-list>
				<ion-item
					lines="none"
					class="align-flex clickable-doc"
					(click)="openDocument(computedCustomField.fieldDatas.field.image, 'user')"
				>
					<ion-thumbnail class="margin-padding-0" slot="start">
						<img src="assets/images/picture-2.svg" />
					</ion-thumbnail>
					<ion-label class="margin-padding-0" [style.color]="event.styling.titleColor">
						<h4 class="margin-padding-0" [style.font-family]="event.styling.font">
							{{ computedCustomField.fieldDatas.field.image.name }}
						</h4>
						<p class="margin-padding-0" [style.font-family]="event.styling.font">
							{{
								SDocument.checkType(
									computedCustomField.fieldDatas.field.image.format &&
										computedCustomField.fieldDatas.field.image.format.toString()?.split("/")[1]
								)
							}}
						</p>
					</ion-label>
				</ion-item>
			</ion-list>
		</ng-container>
	</div>
	}
	<!-- Attendees -->
	@if (showRelatedDatas(computedCustomField, typeModule.ATTENDEE)) {
	<div class="custom-card">
		@if (computedModule.length >= 1) {
		<h6 class="custom-field-name-block" [style.color]="event.styling.titleColor">
			<ion-icon class="custom-field-icon" src="assets/icon/User3.svg"></ion-icon>
			<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
		</h6>
		@for ( attendee of returnCustomModuleItemsEventUsers( computedCustomField.fieldDatas.field.module.items,
		typeModule.ATTENDEE ); track attendee; let i = $index) {
		<ion-list>
			<ion-item lines="none" class="align-flex clickable-doc" (click)="openUserProfil(attendee)">
				@if (attendee?.photoUrl && attendee?.photoUrl !== '') {
				<ion-avatar
					[ngClass]="
						attendee?.photoUrl || attendee?.photoUrl !== ''
							? 'event-user-avatar-img'
							: 'event-user-avatar-blank'
					"
					class="margin-padding-0"
					slot="start"
				>
					<img
						[ngClass]="{
							'event-user-avatar-img-blank':
								!attendee?.photoUrl || attendee?.photoUrl === 'event-user-blank-img'
						}"
						[src]="attendee?.photoUrl"
					/>
				</ion-avatar>
				}
				<ion-label class="margin-padding-0">
					<h4
						class="margin-padding-0 custom-fields-event-users-module-item-title"
						[style.color]="event.styling.titleColor"
					>
						{{ attendee.name }}
					</h4>
					<div class="custom-fields-event-user-tag-bloc">
						@for (customField of getEventUserTags(attendee); track customField) {
						<div class="margin-padding-0">
							@if ( (customField.field.text && customField.field.text?.trim() !== '') ||
							(customField.field.multiLanguageText &&
							customField.field.multiLanguageText[currentLanguage]?.trim() !== '') ) {
							<h5
								class="custom-fields-event-users-module-item-tags"
								[style.color]="event.styling.contentTextColor"
							>
								{{
									customField.field.text?.trim() ||
										customField.field.multiLanguageText[currentLanguage]?.trim()
								}}
							</h5>
							}
						</div>
						}
					</div>
				</ion-label>
			</ion-item>
		</ion-list>
		} }
	</div>
	}
	<!-- SPEAKERS || SHEETS -->
	@if (showRelatedDatas(computedCustomField, typeModule.SPEAKER) || showRelatedDatas(computedCustomField,
	typeModule.SHEETS)) {
	<div class="custom-card">
		@if (computedModule.length >= 1) {
		<h6 class="custom-field-name-block" [style.color]="event.styling.titleColor">
			<ion-icon [style.fontSize]="'1.3em'" class="custom-field-icon" src="assets/icon/User.svg"> </ion-icon>
			<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
		</h6>
		@for ( data of returnCustomModuleItems( computedCustomField.fieldDatas.field.module?.items,
		computedCustomField.baseSettings.customFieldModuleType); track data; let i = $index) {
		<ion-list>
			<ion-item
				lines="none"
				class="align-flex clickable-doc"
				(click)="openUserProfil(data)"
				[ngStyle]="
					!data.photoUrl || data.photoUrl === ''
						? { 'padding-left': '0px', left: '-10px' }
						: { 'padding-left': '0px', left: '-10px' }
				"
			>
				@if (data?.photoUrl && data?.photoUrl !== '') {
				<ion-avatar
					class="event-user-avatar-blank"
					class="margin-padding-0"
					slot="start"
					style="color: rgb(133, 133, 133)"
				>
					@if (!data?.photoUrl || data?.photoUrl === '') {
					<img
						[ngClass]="{
							'event-user-avatar-img-blank': !data?.photoUrl || data?.photoUrl === 'event-user-blank-img'
						}"
						src="assets/icon/User2.svg"
					/>
					} @if (data?.photoUrl && data?.photoUrl !== '') {
					<img [src]="data?.photoUrl" />
					}
				</ion-avatar>
				}
				<ion-label
					class="margin-padding-0"
					style="margin: 0 !important; padding: 0 !important"
					[ngStyle]="
						!data.photoUrl || data.photoUrl === ''
							? { position: 'relative', left: '0px' }
							: { position: 'relative', left: '8px' }
					"
				>
					<h4
						[style.color]="event.styling.titleColor"
						class="margin-padding-0 custom-fields-event-users-module-item-title"
					>
						{{ data.name }}
					</h4>
					<div class="custom-fields-event-user-tag-bloc">
						@for (customField of getEventUserTags(data); track customField) {
						<div class="margin-padding-0">
							@if ( (customField.field.text && customField.field.text?.trim() !== '') ||
							(customField.field.multiLanguageText &&
							customField.field.multiLanguageText[currentLanguage]?.trim() !== '') ) {
							<h5
								class="custom-fields-event-users-module-item-tags"
								[style.color]="event.styling.contentTextColor"
							>
								{{
									customField.field.text?.trim() ||
										customField.field.multiLanguageText[currentLanguage]?.trim()
								}}
							</h5>
							}
						</div>
						}
					</div>
				</ion-label>
			</ion-item>
		</ion-list>
		} }
	</div>
	}
	<!-- Schedule module sessions for Schedule module -->
	@if ( module.type === typeModule.SCHEDULE && computedCustomField.baseSettings.type === typeCustomFields.MODULE &&
	showRelatedDatas(computedCustomField, typeModule.SCHEDULE) && computedModule.length >= 1 &&
	getSessionsOfCustomField(computedCustomField.baseSettings.uid)?.length > 0 ) {
	<div class="container-profile speakers-sessions" style="margin-left: 0px !important">
		<h6 class="speakers-sessions-name-block" [style.color]="event.styling.titleColor">
			<ion-icon class="speakers-sessions-icon" src="assets/icon/Calendar.svg"></ion-icon>
			<span class="speakers-sessions-title">{{ "labels.sessions" | translate }}</span>
		</h6>
		<div class="viewHour" [ngClass]="isMobile ? 'relative-top-12' : ''" [style.padding]="'0'">
			@for ( date of SSchedules.getPaginatedDates( sessions,
			getSessionsOfCustomField(computedCustomField.baseSettings.uid) ) ; track date) {
			<ion-list style="padding: 0">
				<ion-list-header>
					<ion-label [style.color]="event.styling.titleColor" class="header-date">
						{{ SUtility.getPartOfDate(event, myEventUser, date, "full") | titlecase }}
					</ion-label>
				</ion-list-header>
				@for ( session of getSessionsOfCustomField(computedCustomField.baseSettings.uid); track session; let i =
				$index) {
				<div
					id="{{ session.uid }}"
					[hidden]="
						!getSessionsOfCustomField(computedCustomField.baseSettings.uid) ||
						getSessionsOfCustomField(computedCustomField.baseSettings.uid).length === 0
					"
					class="session-container"
				>
					@if (SSchedules.checkSessionDate(session, date)) {
					<div
						class="session-item"
						[style.background]="
							SSchedules.getFirstTrackForSession(session, tracks)
								? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
									? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor
									: SSchedules.getFirstTrackForSession(session, tracks).textColor
								: event.styling.titleColor
						"
						[style.margin-top]="i === 0 && 0"
						(click)="openSession(session)"
						[style.width]="
							SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload
								? 'calc(100% - 0px)'
								: '100%'
						"
					>
						<div
							class="label-bg"
							[style.border]="
								session.tracks.length === 0 ? '1px solid ' + event.styling.titleColor + '33' : 'none'
							"
						>
							<div
								[style.background]="
									SSchedules.getFirstTrackForSession(session, tracks)
										? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
											? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor + '1A'
											: SSchedules.getFirstTrackForSession(session, tracks).textColor + '1A'
										: '#fff'
								"
								class="label"
							>
								<div>
									@for (trackId of session.tracks; track trackId) {
									<small
										[hidden]="
											!SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
												.baseFields?.track?.canBeTag
										"
										class="track-name"
										[style.color]="
											SSchedules.getSpecificTrack(trackId, tracks)
												? SSchedules.getSpecificTrack(trackId, tracks).textColor
												: ''
										"
										[style.background]="
											SSchedules.getSpecificTrack(trackId, tracks)
												? SSchedules.getSpecificTrack(trackId, tracks).backgroundColor
												: ''
										"
									>
										{{
											SSchedules.getSpecificTrack(trackId, tracks) &&
												SSchedules.getSpecificTrack(trackId, tracks).name[
													data &&
													SSchedules.getSpecificTrack(trackId, tracks).name[currentLanguage]
														? currentLanguage
														: currentLanguage
												]
										}}
									</small>
									}
								</div>
								<div [style.color]="event.styling.titleColor" class="session-name">
									{{
										session.name[
											data && session.name[currentLanguage] ? currentLanguage : currentLanguage
										]
									}}
								</div>
								<div [style.color]="event.styling.contentTextColor" class="session-time">
									@if (session.startDate) {
									<span>
										{{
											SUtility.getPartOfDate(event, myEventUser, session.startDate, "time")
												| uppercase
										}}
									</span>
									}
									<!-- {{ getPartOfDate(session.startDate, "time") }} -->
									- @if (session.endDate) {
									<span>
										{{
											SUtility.getPartOfDate(event, myEventUser, session.endDate, "time")
												| uppercase
										}}
									</span>
									} @if ( checkSessionDate(session, date) && module.options.allowSessionsDownload ) {
									<ion-icon
										style="float: right; position: relative; right: 10px; width: 25px; height: 25px"
										fill="{{ event.styling.menuColor }}"
										(click)="openDownloadModal(session, $event)"
										class="material-icons ics-download"
										src="assets/images/calendar_colloqium.svg"
										alt="download"
									></ion-icon>
									}
								</div>
								@if ( SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
								.baseFields?.description?.canBeTag ) {
								<div
									style="white-space: pre-line"
									[innerHTML]="
										session.description[
											data && session.description[currentLanguage]
												? currentLanguage
												: currentLanguage
										] | safeHtml
									"
									class="session-description"
								></div>
								}
								<br />
								<div>
									@for (locationId of session.locations; track locationId) {
									<small
										[hidden]="
											!SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
												.baseFields?.location?.canBeTag
										"
										style="display: inline-flex; align-items: center; margin-right: 5px"
										[style.color]="event.styling.btnBgColor"
									>
										<ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
										<span>{{ SSchedules.getSpecificLocation(locationId, locations)?.name }}</span>
									</small>
									}
								</div>
							</div>
						</div>
					</div>
					}
				</div>
				}
			</ion-list>
			}
		</div>
	</div>
	}
	<!-- Schedule module session for eventUsers module -->
	@if ( (module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
	computedCustomField.baseSettings.type === typeCustomFields.MODULE && showRelatedDatas(computedCustomField,
	typeModule.SCHEDULE) && getSessionsOfCustomField(computedCustomField.baseSettings.uid)?.length > 0 ) {
	<div class="container-profile speakers-sessions" style="margin-left: 0px !important">
		<h6 class="speakers-sessions-name-block" [style.color]="event.styling.titleColor">
			<ion-icon class="speakers-sessions-icon" src="assets/icon/Calendar.svg"></ion-icon>
			<span class="speakers-sessions-title">{{ "labels.sessions" | translate }}</span>
		</h6>
		<div class="viewHour" [ngClass]="isMobile ? 'relative-top-12' : ''" [style.padding]="'0'">
			<div class="download-agenda-container"></div>
			@for ( date of SSchedules.getPaginatedDates( sessions,
			getSessionsOfCustomField(computedCustomField.baseSettings.uid) ) ; track date) {
			<ion-list style="padding: 0">
				<ion-list-header>
					<ion-label [style.color]="event.styling.titleColor" class="header-date">
						{{ SUtility.getPartOfDate(event, myEventUser, date, "full") | titlecase }}
					</ion-label>
				</ion-list-header>
				@for ( session of getSessionsOfCustomField(computedCustomField.baseSettings.uid); track session; let i =
				$index) {
				<div
					id="{{ session.uid }}"
					[hidden]="
						!getSessionsOfCustomField(computedCustomField.baseSettings.uid) ||
						getSessionsOfCustomField(computedCustomField.baseSettings.uid).length === 0
					"
					class="session-container"
				>
					@if (SSchedules.checkSessionDate(session, date)) {
					<div
						class="session-item"
						[style.background]="
							SSchedules.getFirstTrackForSession(session, tracks)
								? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
									? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor
									: SSchedules.getFirstTrackForSession(session, tracks).textColor
								: event.styling.titleColor
						"
						[style.margin-top]="i === 0 && 0"
						(click)="openSession(session)"
						[style.width]="
							SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload
								? 'calc(100% - 0px)'
								: '100%'
						"
					>
						<div
							class="label-bg"
							[style.border]="
								session.tracks.length === 0 ? '1px solid ' + event.styling.titleColor + '33' : 'none'
							"
						>
							<div
								[style.background]="
									SSchedules.getFirstTrackForSession(session, tracks)
										? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
											? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor + '1A'
											: SSchedules.getFirstTrackForSession(session, tracks).textColor + '1A'
										: '#fff'
								"
								class="label"
							>
								<div>
									@for (trackId of session.tracks; track trackId) {
									<small
										[hidden]="
											!SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
												.baseFields?.track?.canBeTag
										"
										class="track-name"
										[style.color]="
											SSchedules.getSpecificTrack(trackId, tracks)
												? SSchedules.getSpecificTrack(trackId, tracks).textColor
												: ''
										"
										[style.background]="
											SSchedules.getSpecificTrack(trackId, tracks)
												? SSchedules.getSpecificTrack(trackId, tracks).backgroundColor
												: ''
										"
									>
										{{
											SSchedules.getSpecificTrack(trackId, tracks) &&
												SSchedules.getSpecificTrack(trackId, tracks).name[
													data &&
													SSchedules.getSpecificTrack(trackId, tracks).name[currentLanguage]
														? currentLanguage
														: currentLanguage
												]
										}}
									</small>
									}
								</div>
								<div [style.color]="event.styling.titleColor" class="session-name">
									{{
										session.name[
											data && session.name[currentLanguage] ? currentLanguage : currentLanguage
										]
									}}
								</div>
								<div [style.color]="event.styling.contentTextColor" class="session-time">
									@if (session.startDate) {
									<span>
										{{
											SUtility.getPartOfDate(event, myEventUser, session.startDate, "time")
												| uppercase
										}}
									</span>
									}
									<!-- {{ getPartOfDate(session.startDate, "time") }} -->
									- @if (session.endDate) {
									<span>
										{{
											SUtility.getPartOfDate(event, myEventUser, session.endDate, "time")
												| uppercase
										}}
									</span>
									} @if ( checkSessionDate(session, date) && module.options.allowSessionsDownload ) {
									<ion-icon
										style="float: right; position: relative; right: 10px; width: 25px; height: 25px"
										fill="{{ event.styling.menuColor }}"
										(click)="openDownloadModal(session, $event)"
										class="material-icons ics-download"
										src="assets/images/calendar_colloqium.svg"
										alt="download"
									></ion-icon>
									}
								</div>
								@if ( SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
								?.baseFields?.description?.canBeTag ) {
								<div
									style="white-space: pre-line"
									[innerHTML]="
										session.description[
											data && session.description[currentLanguage]
												? currentLanguage
												: currentLanguage
										] | safeHtml
									"
									class="session-description"
								></div>
								}
								<br />
								<div>
									@for (locationId of session.locations; track locationId) {
									<small
										[hidden]="
											!SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
												?.baseFields?.location?.canBeTag
										"
										style="display: inline-flex; align-items: center; margin-right: 5px"
										[style.color]="event.styling.btnBgColor"
									>
										<ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
										<span>{{ SSchedules.getSpecificLocation(locationId, locations)?.name }}</span>
									</small>
									}
									<br />
									<!-- Agenda name -->
									<small
										style="display: inline-flex; align-items: center; margin-right: 5px"
										[style.color]="event.styling.btnBgColor"
									>
										<ion-icon src="assets/icon/Calendar.svg"></ion-icon>&nbsp;
										<span>{{
											SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.name[
												data && data["updatedSettings"]
													? data["updatedSettings"].language
													: event.language
											]
										}}</span>
									</small>
								</div>
							</div>
						</div>
					</div>
					}
				</div>
				}
			</ion-list>
			}
		</div>
	</div>
	}
	<!-- Tracks -->
	@if (showRelatedDatas(computedCustomField, typeModule.TRACKS)) {
	<div class="custom-card">
		<div class="track-custom-field-name-block">
			<img class="custom-field-icon-img" src="assets/icon/Filter.svg" />
			<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
		</div>
		@for ( track of returnCustomModuleItems( computedCustomField.fieldDatas.field.module.items, typeModule.TRACKS );
		track track; let i = $index) {
		<div class="track-bloc-content">
			<span class="custom-field-track-name-block" [style.background-color]="track.backgroundColor">
				<img class="custom-field-icon-img doc-avatar" src="assets/images/filter-list.png" />
				<h4 class="margin-padding-0 custom-fields-track-module-item-title">
					{{ track.name[currentLanguage] }}
				</h4>
				<p class="margin-padding-0"></p>
			</span>
		</div>
		}
	</div>
	}
	<!-- Documents module -->
	@if (showRelatedDatas(computedCustomField, typeModule.DOCUMENT)) {
	<div class="custom-card">
		@if (computedModule.length >= 1) {
		<h6 class="custom-field-name-block" [style.color]="event.styling.titleColor">
			<ion-icon class="custom-field-icon" src="assets/icon/Clip.svg"></ion-icon>
			<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
		</h6>
		@for ( document of returnCustomModuleItems( computedCustomField.fieldDatas.field.module.items,
		typeModule.DOCUMENT ); track document; let i = $index) {
		<ion-list>
			<ion-item lines="none" class="align-flex clickable-doc" (click)="openDocument(document, 'module')">
				<ion-thumbnail class="margin-padding-0" slot="start">
					<img src="assets/icon/Files/{{ document.icon | splitFirst : ['.'] }}.svg" />
				</ion-thumbnail>
				<ion-label class="margin-padding-0" [style.color]="event.styling.titleColor">
					<h4 class="margin-padding-0 custom-fields-document-module-item-title">
						{{ document.name[currentLanguage] }}
					</h4>
					<p class="margin-padding-0 custom-fields-document-module-item-type">
						{{ SDocument.checkType(document.type) }}
					</p>
				</ion-label>
				<ion-icon
					slot="end"
					[style.color]="event.styling.btnBgColor"
					style="cursor: pointer"
					name="copy-outline"
					(click)="copyToClipboard(document); $event.stopPropagation()"
				></ion-icon>
			</ion-item>
		</ion-list>
		} }
	</div>
	}
</div>
}

<!-- Base Schedule module sessions -->
@if (dataSessions && dataSessions.length > 0) {
<div class="container-profile speakers-sessions">
	<h6 class="speakers-sessions-name-block" [style.color]="event.styling.titleColor">
		<ion-icon class="speakers-sessions-icon" src="assets/icon/Calendar.svg"></ion-icon>
		<span class="speakers-sessions-title">{{ "labels.sessions" | translate }}</span>
	</h6>
	@if (!module.options.activateTracksFiltering) {
	<div class="viewHour" [ngClass]="isMobile ? 'relative-top-12' : ''" [style.padding]="'0'">
		<div class="download-agenda-container">
			@if (module.options.allowSessionsDownload) {
			<ion-button
				[style.color]="event.styling.menuTextColor"
				[style.background]="event.styling.menuColor"
				class="header-ics-download"
				(click)="SSchedules.downloadSchedule(event, module)"
			>
				{{ "schedule.download_schedule" | translate }}
			</ion-button>
			}
		</div>
		@for ( date of SSchedules.getPaginatedDates( sessions, dataSessions ? dataSessions : sessionSchedules ) ; track
		date) {
		<ion-list style="padding: 0">
			<ion-list-header>
				<ion-label [style.color]="event.styling.titleColor" class="header-date">
					{{ SUtility.getPartOfDate(event, myEventUser, date, "full") | titlecase }}
				</ion-label>
			</ion-list-header>
			@for (session of dataSessions ? dataSessions : sessionSchedules; track session; let i = $index) {
			<div id="{{ session.uid }}" class="session-container">
				@if (SSchedules.checkSessionDate(session, date)) {
				<div
					class="session-item"
					[style.background]="
						SSchedules.getFirstTrackForSession(session, tracks)
							? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
								? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor
								: SSchedules.getFirstTrackForSession(session, tracks).textColor
							: event.styling.titleColor
					"
					[style.margin-top]="i === 0 && 0"
					(click)="openSession(session)"
					[style.width]="
						SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload
							? 'calc(100% - 0px)'
							: '100%'
					"
				>
					<div
						class="label-bg"
						[style.border]="
							session.tracks.length === 0 ? '1px solid ' + event.styling.titleColor + '33' : 'none'
						"
					>
						<div
							[style.background]="
								SSchedules.getFirstTrackForSession(session, tracks)
									? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
										? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor + '1A'
										: SSchedules.getFirstTrackForSession(session, tracks).textColor + '1A'
									: '#fff'
							"
							class="label"
						>
							<div>
								@for (trackId of session.tracks; track trackId) {
								<small
									[hidden]="
										!SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
											.baseFields?.track?.canBeTag
									"
									class="track-name"
									[style.color]="
										SSchedules.getSpecificTrack(trackId, tracks)
											? SSchedules.getSpecificTrack(trackId, tracks).textColor
											: ''
									"
									[style.background]="
										SSchedules.getSpecificTrack(trackId, tracks)
											? SSchedules.getSpecificTrack(trackId, tracks).backgroundColor
											: ''
									"
								>
									{{
										SSchedules.getSpecificTrack(trackId, tracks) &&
											SSchedules.getSpecificTrack(trackId, tracks).name[
												data &&
												SSchedules.getSpecificTrack(trackId, tracks).name[currentLanguage]
													? currentLanguage
													: currentLanguage
											]
									}}
								</small>
								}
							</div>
							<div [style.color]="event.styling.titleColor" class="session-name">
								{{
									session.name[
										data && session.name[currentLanguage] ? currentLanguage : currentLanguage
									]
								}}
							</div>
							<div [style.color]="event.styling.contentTextColor" class="session-time">
								@if (session.startDate) {
								<span>
									{{
										SUtility.getPartOfDate(event, myEventUser, session.startDate, "time")
											| uppercase
									}}
								</span>
								} - @if (session.endDate) {
								<span>
									{{
										SUtility.getPartOfDate(event, myEventUser, session.endDate, "time") | uppercase
									}}
								</span>
								} @if (checkSessionDate(session, date) && module.options.allowSessionsDownload) {
								<ion-icon
									style="float: right; position: relative; right: 10px; width: 25px; height: 25px"
									fill="{{ event.styling.menuColor }}"
									(click)="openDownloadModal(session, $event)"
									class="material-icons ics-download"
									src="assets/images/calendar_colloqium.svg"
									alt="download"
								></ion-icon>
								}
							</div>
							@if ( SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
							.baseFields?.description?.canBeTag ) {
							<div
								style="white-space: pre-line"
								[innerHTML]="
									session.description[
										data && session.description[currentLanguage] ? currentLanguage : currentLanguage
									] | safeHtml
								"
								class="session-description"
							></div>
							}
							<br />
							<div>
								@for (locationId of session.locations; track locationId) {
								<small
									[hidden]="
										!SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
											.baseFields?.location?.canBeTag
									"
									style="display: inline-flex; align-items: center; margin-right: 5px"
									[style.color]="event.styling.btnBgColor"
								>
									<ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
									<span>{{ SSchedules.getSpecificLocation(locationId, locations)?.name }}</span>
								</small>
								}
								<br />
								<!-- Agenda name -->
								<small
									style="display: inline-flex; align-items: center; margin-right: 5px"
									[style.color]="event.styling.btnBgColor"
								>
									<ion-icon src="assets/icon/Calendar.svg"></ion-icon>&nbsp;
									<span>{{
										SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.name[
											data && data["updatedSettings"]
												? data["updatedSettings"].language
												: event.language
										]
									}}</span>
								</small>
							</div>
						</div>
					</div>
				</div>
				}
			</div>
			}
		</ion-list>
		}
	</div>
	}
</div>
}
