import { createReducer, on } from "@ngrx/store";
import * as SheetsActions from "../actions/sheets.actions";
import { ISheetsState } from "../interfaces";

export const initialState: ISheetsState = {
	eventId: "",
	sheets: []
};

export const sheetsFeatureKey = "sheets";

export const sheetsReducers = createReducer(
	initialState,
	on(SheetsActions.GetAllSheets, (state, action) => ({
		...state,
		eventId: action.eventId,
		sheets: action.payload
	}))
);
