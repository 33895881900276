import { createReducer, on } from "@ngrx/store";
import * as ContainersActions from "../actions/containers.actions";
import { IContainersState } from "../interfaces/containers.interfaces";

export const initialState: IContainersState = {
	currentContainer: null,
	defaultContainer: null
};

export const containersFeatureKey = "adm";

export const containersReducers = createReducer(
	initialState,
	on(ContainersActions.GetContainers, (state, action) => ({
		...state,
		currentContainer: action.payload.currentContainer,
		defaultContainer: action.payload.defaultContainer
	})),
	on(ContainersActions.GetCurrentContainer, (state, action) => ({
		...state,
		currentContainer: action.payload
	})),
	on(ContainersActions.GetDefaultContainer, (state, action) => ({
		...state,
		defaultContainer: action.payload
	}))
);
