import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	OnDestroy,
	Signal,
	signal,
	WritableSignal,
	OnInit
} from "@angular/core";
import { Browser } from "@capacitor/browser";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, combineLatest, of, timer } from "rxjs";
import { debounceTime, distinctUntilChanged, skipWhile, switchMap, take, takeWhile } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	IDocument,
	IEvent,
	IEventUser,
	IFullCustomField,
	IGroup,
	ILocation,
	IModule,
	ISchedule,
	ITrack
} from "src/app/shared/interfaces";
import {
	ICustomFieldData,
	IFilterOptions,
	IFilteredItemFormat
} from "src/app/shared/interfaces/custom-fields.interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { IScheduleByTrack, IScheduleByTrackDate } from "src/app/shared/interfaces/schedules.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getDocuments } from "src/app/shared/selectors/documents.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getFavoritesFolders,
	getGroupsByOrder,
	getLocations,
	getModulesCustomsFieldsOfModule,
	getTracks
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getModulesByTypes, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectQueryParams, selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	CustomFieldsService,
	DocumentsService,
	EventUsersService,
	SchedulesService,
	UtilityService
} from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";
import { ICustomField, IModuleCustomField } from "../../../../../../shared/interfaces/custom-fields.interfaces";
import { ConfirmIcsDownloadComponent } from "../../modals/confirm-ics-download/confirm-ics-download.component";
import { TimezoneSwitchComponent } from "../../modals/timezone-switch/timezone-switch.component";
import { ISearchFilter } from "src/app/shared/interfaces/search.interfaces";
import { DateTime } from "luxon";
import { getInitSpecificEventDatasPart } from "src/app/shared/selectors/utility.selectors";
import { buildFiltersQuery, filterSearch } from "src/app/shared/helpers-functions/filter-search";
import { IFilters } from "src/app/shared/interfaces/filters.interfaces";
import { TypeUser } from "src/app/shared/enums/type-user";

interface IInitDatasCheck {
	initEvent: boolean;
	initModule: boolean;
	initEventUser: boolean;
	initAttendees: boolean;
	initSpeakers: boolean;
	initFavorites: boolean;
	initTracks: boolean;
	initLocations: boolean;
	initGroups: boolean;
	initDocuments: boolean;
	initSessions: boolean;
	initDates: boolean;
	initCustomFields: boolean;
	initCustomFieldsEventUsers: boolean;
	initFilters: boolean;
}

@Component({
	selector: "app-schedules",
	templateUrl: "./schedules.component.html",
	styleUrls: ["./schedules.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulesComponent implements OnDestroy, OnInit {
	subscriptions: Subscription[] = [];
	eventUserSub: Subscription;
	searchSub: Subscription;
	langSubscription: Subscription;
	queryParamsSub: Subscription;

	typeModule = TypeModule;

	scheduleLoader: WritableSignal<boolean> = signal(false);
	// scheduleLoader: boolean = true;
	datesLoader: boolean = true;
	filtersLoader: boolean = true;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	eventUser: IEventUser;
	computedCustomFields: IFullCustomField[] = [];
	baseCustomFields: ICustomField[] = [];
	modulesCustomFields: IModuleCustomField[] = [];
	customFieldsWithModules: any = {};

	allDatas: WritableSignal<ISchedule[]> = signal([]);
	datasFiltered: WritableSignal<ISchedule[]> = signal([]);
	allFilteredDatasChunked: ISchedule[][] = [];
	mappedFilteredSessionsIndex: any = {};
	datas: WritableSignal<ISchedule[]> = signal([]);

	allDatasByTrackDates: WritableSignal<IScheduleByTrackDate[]> = signal([]);
	datasByTrackDatesFiltered: WritableSignal<IScheduleByTrackDate[]> = signal([]);

	tracks: ITrack[] = [];
	locations: ILocation[] = [];
	documents: IDocument[] = [];
	groups: IGroup[] = [];
	speakers: WritableSignal<IEventUser[]> = signal([]);
	attendees: WritableSignal<IEventUser[]> = signal([]);
	allEventUsers: Signal<IEventUser[]> = computed(() =>
		this.speakers()
			.concat(this.attendees())
			.sort((a, b) => (a.identifier > b.identifier ? 1 : a.identifier < b.identifier ? -1 : 0))
	);
	allDates: string[] = [];

	filters: IFilters = {
		locations: [],
		tracks: [],
		groups: [],
		customFields: [],
		principalKey: ""
	};
	favoriteModule: IModule;

	selectedFilters: IFilteredItemFormat[] = [];
	selectedDatas: IFilterOptions[] = [];

	showFilter = true;
	showSearchBar = true;
	searchValue: string = "";
	searchValueUpdated: Subject<string> = new Subject<string>();
	p: number = 1;
	limit: number = 15;
	isMobile: boolean = false;

	currentLanguage: string = environment.platform.defaultLanguage;

	onPauseSubscription: Subscription;
	onResumeSubscription: Subscription;

	// filtersCollapseState: { uid: string; collapsed: boolean }[] = [];
	icsSessionLoader: { sessionId: string; state: boolean }[] = [];
	scheduleDownloadButtonLoader: boolean = false;

	queryParams: any = {};
	eventUsersFromTags: { eventUsers: IEventUser[]; track: ITrack }[] = [];
	eventUserFavoritesState: any = {};
	favoriteFolder: IFavoriteFolder;

	filtersQuery: ISearchFilter = {
		equalityFields: [],
		inequalityFields: [],
		includeTextFields: [],
		includeOrTextFields: [],
		superiorFields: [],
		superiorOrEqualFields: [],
		inferiorFields: [],
		inferiorOrEqualFields: [],
		anyTextFields: [],
		arrayContainsAnyFields: [],
		arrayContainsAllFields: [],
		arrayContainsBlocAndOrFields: [],
		arrayNotContainsFields: [],
		elemMatchArrayFields: [],
		page: 1,
		itemsPerPage: this.limit,
		sortBy: []
	};

	firstTimeInit: boolean = true;
	firstTimeModuleAccess: boolean = true;
	lastTimeGetDatas: number = 0;

	initDatasChecks: IInitDatasCheck = {
		initEvent: false,
		initModule: false,
		initEventUser: false,
		initAttendees: false,
		initSpeakers: false,
		initFavorites: false,
		initTracks: false,
		initLocations: false,
		initGroups: false,
		initDocuments: false,
		initSessions: false,
		initDates: false,
		initCustomFields: false,
		initCustomFieldsEventUsers: false,
		initFilters: false
	};
	initDatasSubject: BehaviorSubject<IInitDatasCheck> = new BehaviorSubject(this.initDatasChecks);

	navigationSaved: { sessionId: string; moduleId: string }[] = [];

	dateChanged: boolean = false;
	selectedDates: { startDate: string; endDate: string } = null;
	datasForBadgeCalendar: Signal<{ date: string; totalDatas: number }[]> = computed(() => {
		const dates: { date: string; totalDatas: number }[] = [];
		if (!this.datasFiltered()) {
			return dates;
		}
		for (let i = 0; i < this.datasFiltered().length; i++) {
			const session = this.datasFiltered()[i];
			const checkDate = dates.find((date) => {
				let sessionDate = DateTime.fromISO(session.startDate).setLocale(
					this.STranslate.currentLang
						? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
								"-" +
								this.STranslate.currentLang.slice(2, 4).toUpperCase()
						: this.event.language.slice(0, 2).toLowerCase() +
								"-" +
								this.event.language.slice(2, 4).toUpperCase()
				);

				if (
					this.event &&
					this.event.timezoneType === "local" &&
					this.eventUser &&
					this.eventUser.updatedSettings &&
					this.eventUser.updatedSettings.timezoneType === "event"
				) {
					sessionDate = sessionDate.setZone(this.event.timezone);
				}

				let existingDate = DateTime.fromISO(date.date).setLocale(
					this.STranslate.currentLang
						? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
								"-" +
								this.STranslate.currentLang.slice(2, 4).toUpperCase()
						: this.event.language.slice(0, 2).toLowerCase() +
								"-" +
								this.event.language.slice(2, 4).toUpperCase()
				);

				if (
					this.event &&
					this.event.timezoneType === "local" &&
					this.eventUser &&
					this.eventUser.updatedSettings &&
					this.eventUser.updatedSettings.timezoneType === "event"
				) {
					existingDate = existingDate.setZone(this.event.timezone);
				}

				if (
					sessionDate.day === existingDate.day &&
					sessionDate.month === existingDate.month &&
					sessionDate.year === existingDate.year
				) {
					return true;
				} else {
					return false;
				}
			});
			if (checkDate) {
				checkDate.totalDatas++;
			} else {
				let dateToPush = DateTime.fromISO(session.startDate).setLocale(
					this.STranslate.currentLang
						? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
								"-" +
								this.STranslate.currentLang.slice(2, 4).toUpperCase()
						: this.event.language.slice(0, 2).toLowerCase() +
								"-" +
								this.event.language.slice(2, 4).toUpperCase()
				);

				if (
					this.event &&
					this.event.timezoneType === "local" &&
					this.eventUser &&
					this.eventUser.updatedSettings &&
					this.eventUser.updatedSettings.timezoneType === "event"
				) {
					dateToPush = dateToPush.setZone(this.event.timezone);
				}
				dates.push({
					date: dateToPush.toISO(),
					totalDatas: 1
				});
			}
		}
		return dates;
	});

	filtersUuid: string = this.SUtility.generateUuid();

	constructor(
		private SEventUsers: EventUsersService,
		private SAnalytics: AnalyticsService,
		private store: Store,
		public SDocument: DocumentsService,
		private STranslate: TranslateService,
		private SSchedules: SchedulesService,
		private SCustomFields: CustomFieldsService,
		private modalController: ModalController,
		public platform: Platform,
		public SUtility: UtilityService,
		private cd: ChangeDetectorRef
	) {
		platform.ready().then(() => {
			if ((this.platform.is("mobile") && window.innerWidth < 768) || this.platform.is("mobileweb")) {
				this.onResumeSubscription = this.platform.resume.subscribe(() => {
					// Reentering in app
					if (this.checkNeedReloadDatas()) {
						this.firstTimeModuleAccess = true;
						this.unloadDatas();
						this.initDatas();
					}
				});

				this.onPauseSubscription = this.platform.pause.subscribe(() => {
					// App goes in background
					if (this.checkNeedReloadDatas()) {
						this.firstTimeModuleAccess = true;
						this.unloadDatas();
						this.initDatas();
					}
				});
			}
		});

		this.queryParamsSub = this.store.select(selectQueryParams).subscribe((queryParams) => {
			this.queryParams = queryParams;
		});
		this.isMobile = this.platform.is("mobile") || this.platform.is("mobileweb") ? true : false;

		this.currentLanguage = this.STranslate.currentLang;
		this.langSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});

		this.searchSub = this.searchValueUpdated
			.pipe(debounceTime(200), distinctUntilChanged())
			.subscribe((evtValue) => {
				if (evtValue.length >= 1) {
					// const value = this.SUtility.removeAccents(evtValue);
					this.searchValue = evtValue;
					this.resetScrollOfList();
					this.applyFilters();
				} else {
					this.resetFilter();
				}
			});
	}

	ngOnInit() {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) || this.platform.is("mobileweb") ? true : false;
	}

	updateComponent() {
		this.cd.markForCheck();
	}

	checkNeedReloadDatas() {
		let needReloadDatas: boolean = false;
		if (
			this.firstTimeModuleAccess ||
			!this.moduleId ||
			this.lastTimeGetDatas <= this.SSchedules.lastTimeGetSessions()
		) {
			needReloadDatas = true;
		}
		return needReloadDatas;
	}

	ionViewWillEnter() {
		this.store
			.select(getInitSpecificEventDatasPart("initSchedules"))
			.pipe(
				skipWhile((init) => !init),
				take(1),
				switchMap(() => {
					return combineLatest([
						this.store.select(selectUrl),
						this.store.select(selectRouteNestedParams)
					]).pipe(take(1));
				})
			)
			.subscribe((results) => {
				this.eventId = results[1].eventId;
				this.moduleId = results[1].moduleId;

				// Analytics
				this.SAnalytics.moduleAccess(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_SCHEDULE_MODULE);
				this.langSubscription = this.STranslate.onLangChange.subscribe((lang) => {
					if (!this.queryParams || !this.queryParams["lang"]) {
						this.currentLanguage = lang.lang;
					}
				});

				of(EMPTY)
					.pipe(
						take(1),
						switchMap(() => {
							this.scheduleLoader.set(true);
							this.SEventUsers.initSubscriptionOfAllModules(this.eventId);
							return this.store.select(getInitSpecificEventDatasPart("initAttendees")).pipe(
								skipWhile((init) => !init),
								take(1)
							);
						})
					)
					.subscribe(() => {
						if (this.checkNeedReloadDatas()) {
							this.firstTimeModuleAccess = true;
							this.unloadDatas();
							this.initDatas();
						} else {
							if (this.module) {
								this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
							}
							this.getEventUser();
						}
					});
				// setTimeout(() => {}, 1500);
			});
	}

	initDatas() {
		this.datesLoader = true;
		this.filtersLoader = true;
		this.scheduleLoader.set(true);

		this.resetQueryFilters();
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getEventUsers();
		this.getFavoriteModuleAndFolders();
		this.getTracks();
		this.getLocations();
		this.getCustomFields();
		this.getAllCustomFieldsOfEventUsersModules();
		this.getAllDocuments();
		this.getGroups();
		this.getAllSessions();
		this.getFilters();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions
			.concat([
				this.eventUserSub,
				this.searchSub,
				this.langSubscription,
				this.onResumeSubscription,
				this.onPauseSubscription,
				this.queryParamsSub
			])
			.forEach((sub) => sub?.unsubscribe());
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.unloadDatas();
	}

	/**
	 * unloadDatas
	 */
	unloadDatas() {
		this.subscriptions.concat([this.eventUserSub]).forEach((sub) => sub?.unsubscribe());

		this.initDatasChecks = {
			initEvent: false,
			initModule: false,
			initEventUser: false,
			initAttendees: false,
			initSpeakers: false,
			initFavorites: false,
			initTracks: false,
			initLocations: false,
			initGroups: false,
			initDocuments: false,
			initSessions: false,
			initDates: false,
			initCustomFields: false,
			initCustomFieldsEventUsers: false,
			initFilters: false
		};
		this.event = null;
		this.module = null;
		this.eventUser = null;
		this.favoriteFolder = null;
		this.favoriteModule = null;
		this.p = 1;
		this.searchValue = "";
		this.allDatas.set([]);
		this.datas.set([]);
		this.firstTimeModuleAccess = true;
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
				this.initDatasChecks.initEvent = true;
				this.initDatasSubject.next(this.initDatasChecks);
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;

					if (this.module && this.module.options) {
						this.showSearchBar =
							this.module.options.showSearchIcon || this.module.options.showSearchIcon === undefined
								? true
								: false;
					}

					if (this.module && this.module.options.activateTracksFiltering) {
						this.limit = 10;
						this.filtersQuery.itemsPerPage = this.limit;
					}
				}
				this.initDatasChecks.initModule = true;
				this.initDatasSubject.next(this.initDatasChecks);
				this.store.dispatch(GetHeaderTitle({ payload: module.name }));
			})
		);
	}

	/**
	 * getFavoriteModuleAndFolders
	 */
	getFavoriteModuleAndFolders() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getModulesByType(TypeModule.FAVORITES)),
				this.store.select(getFavoritesFolders)
			])
				.pipe(
					skipWhile(() => !this.module || this.module === null),
					take(1)
				)
				.subscribe(([modules, favoriteFolders]) => {
					if (modules && modules.length > 0) {
						this.favoriteModule = modules[0];
					}

					if (favoriteFolders && favoriteFolders.length > 0) {
						this.favoriteFolder = favoriteFolders.filter(
							(folder) => this.module && folder.moduleLinkedId === this.module.uid && !folder.hidden
						)?.[0];
					}

					this.initDatasChecks.initFavorites = true;
					this.initDatasSubject.next(this.initDatasChecks);
				})
		);
	}

	/**
	 * getEventUser
	 */
	getEventUser() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}

		this.eventUserSub = this.store
			.select(getCurrentEventUser)
			.pipe(take(1))
			.subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
					this.buildFavorites();
				}
				this.initDatasChecks.initEventUser = true;
				this.initDatasSubject.next(this.initDatasChecks);
				this.updateComponent();
			});
	}

	getTracks() {
		this.store
			.select(getTracks)
			.pipe(take(1))
			.subscribe((tracks) => {
				this.tracks = tracks;
				this.initDatasChecks.initTracks = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	getLocations() {
		this.store
			.select(getLocations("asc"))
			.pipe(take(1))
			.subscribe((locations) => {
				this.locations = locations;
				this.initDatasChecks.initLocations = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	getCustomFields() {
		combineLatest([
			this.store.select(getBaseCustomFields),
			this.store.select(getModulesCustomsFieldsOfModule(this.moduleId))
		])
			.pipe(take(1))
			.subscribe((results) => {
				const baseCustomFields = results[0];
				const modulesCustomFields = results[1];
				this.computedCustomFields =
					modulesCustomFields.length > 0
						? modulesCustomFields.map((customField) => {
								const baseCustomFieldCorresponding = baseCustomFields.find(
									(custField) => custField.uid === customField.uid
								);
								return {
									baseSettings: baseCustomFieldCorresponding ? baseCustomFieldCorresponding : null,
									moduleSettings: customField,
									fieldDatas: {
										uid: "",
										field: {
											date: {
												day: 0,
												month: 0,
												year: 0,
												value: "",
												fullDateISO: "",
												zone: ""
											},
											file: {
												lastModifiedDate: "",
												name: "",
												type: "",
												url: "",
												size: 0
											},
											image: {
												format: "",
												lastModifiedDate: "",
												name: "",
												url: "",
												size: 0
											},
											module: {
												items: [],
												moduleType: -1
											},
											multiLanguageText: {
												ArAR: "",
												DeDE: "",
												EnUS: "",
												EsES: "",
												FrFR: "",
												PtBR: ""
											},
											multiLanguageSelectArray: [],
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											},
											numeric: 0,
											phoneNumber: {
												countryCode: "",
												dialCode: "",
												e164Number: "",
												internationalNumber: "",
												nationalNumber: "",
												number: ""
											},
											text: ""
										}
									}
								};
						  })
						: [];
				this.initDatasChecks.initCustomFields = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	/**
	 * getAllDocuments
	 */
	getAllDocuments() {
		this.store
			.select(getDocuments)
			.pipe(take(1))
			.subscribe((documents) => {
				this.documents = documents;
				this.initDatasChecks.initDocuments = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.store
			.select(getGroupsByOrder("asc"))
			.pipe(take(1))
			.subscribe((groups) => {
				this.groups = groups;
				this.initDatasChecks.initGroups = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	/**
	 * Get all customfields of event users modules
	 */
	getAllCustomFieldsOfEventUsersModules() {
		this.store
			.select(getModulesByTypes([TypeModule.ATTENDEE, TypeModule.SPEAKER]))
			.pipe(
				take(1),
				switchMap((modules) => {
					const obsArray: Observable<{ moduleId: string; computedCustomFields: IFullCustomField[] }>[] = [];
					modules.forEach((module) => {
						obsArray.push(
							combineLatest([
								this.store.select(getBaseCustomFields),
								this.store.select(getModulesCustomsFieldsOfModule(module.uid))
							]).pipe(
								take(1),
								switchMap((resultsCustom) => {
									const baseCustomFields = resultsCustom[0];
									const modulesCustomFields = resultsCustom[1];
									const computedCustomFields =
										modulesCustomFields.length > 0
											? modulesCustomFields.map((customField) => {
													const baseCustomFieldCorresponding = baseCustomFields.find(
														(custField) => custField.uid === customField.uid
													);
													return {
														baseSettings: baseCustomFieldCorresponding
															? baseCustomFieldCorresponding
															: null,
														moduleSettings: customField,
														fieldDatas: {
															uid: "",
															field: {}
														}
													};
											  })
											: [];
									return of({ moduleId: module.uid, computedCustomFields: computedCustomFields });
								})
							)
						);
					});

					return combineLatest(obsArray);
				})
			)
			.subscribe((customFieldswithModules) => {
				customFieldswithModules.forEach((mod) => {
					this.customFieldsWithModules[mod.moduleId] = mod.computedCustomFields;
				});
				this.initDatasChecks.initCustomFieldsEventUsers = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	getEventUsers() {
		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initEventUser ||
						!initState.initCustomFields ||
						!initState.initCustomFieldsEventUsers
				),
				take(1),
				switchMap(() => {
					return of(this.SEventUsers.eventUsersOfAllModules());
				})
			)
			.subscribe((results) => {
				this.attendees.set(
					results
						? results
								.filter((data) => data.type === TypeUser.ATTENDEE)
								.map((data) => {
									data["tags"] = this.getTagsOfEventUser(data);
									return data;
								})
						: []
				);
				this.speakers.set(
					results
						? results
								.filter((data) => data.type === TypeUser.SPEAKER)
								.map((data) => {
									data["tags"] = this.getTagsOfEventUser(data);
									return data;
								})
						: []
				);

				this.initDatasChecks.initAttendees = true;
				this.initDatasChecks.initSpeakers = true;
				this.initDatasSubject.next(this.initDatasChecks);
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	resetQueryFilters() {
		this.filtersQuery.arrayContainsAnyFields = [];
		this.filtersQuery.equalityFields = [];
		this.filtersQuery.includeTextFields = [];
		this.filtersQuery.includeOrTextFields = [];
		this.filtersQuery.inferiorFields = [];
		this.filtersQuery.inferiorOrEqualFields = [];
		this.filtersQuery.superiorFields = [];
		this.filtersQuery.superiorOrEqualFields = [];
		this.filtersQuery.arrayContainsBlocAndOrFields = [];
		this.filtersQuery.arrayContainsAllFields = [];
		this.filtersQuery.elemMatchArrayFields = [];
		this.filtersQuery.sortBy = [
			{
				fieldKey: "startDate",
				type: "asc"
			}
		];
		this.filtersQuery.page = this.p;
	}

	getAllSessions() {
		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initEventUser ||
						!initState.initAttendees ||
						!initState.initSpeakers ||
						!initState.initCustomFields ||
						!initState.initGroups ||
						!initState.initLocations ||
						!initState.initTracks ||
						!initState.initDocuments ||
						!initState.initFilters
				),
				take(1),
				switchMap(() => {
					return this.SSchedules.getSessionsAndDatesOfModuleWithFilters(
						this.eventId,
						this.module.uid,
						null,
						this.currentLanguage
					).pipe(
						take(1),
						switchMap((results: any) => {
							const count = results.totalItems;
							const datas = results.datas;
							const dates = results.dates;
							if (datas.length === 0) {
								return of({
									totalItems: 0,
									datas: [],
									dates: dates
								});
							}
							return of({
								totalItems: count,
								datas: datas,
								focusedItemId: null,
								dates: dates
							});
						})
					);
				})
			)
			.subscribe({
				next: (res: { totalItems: number; datas: ISchedule[]; focusedItemId: string; dates: string[] }) => {
					this.allDates = res.dates;
					this.datesLoader = false;
					if (this.module && this.module.options && this.module.options.activateTracksFiltering) {
						this.allDatas.set(
							this.mapAdditionnalDatasForSessions(
								res.datas.sort((a, b) => {
									return a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0;
								})
							)
						);
					} else {
						this.allDatas.set(
							res.datas.sort((a, b) => {
								return a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0;
							})
						);
					}
					this.applyFilters();
					this.lastTimeGetDatas = DateTime.local().toMillis();
				}
			});
	}

	filterSessions() {
		this.scheduleLoader.set(true);
		this.calendarDatesChanged();
		this.filtersQuery.page = 0;
		this.filtersQuery.itemsPerPage = 999999;

		const datasFiltered = filterSearch(this.filtersQuery, this.allDatas());
		this.datasFiltered.set(datasFiltered.datas);
		this.allFilteredDatasChunked = _.chunk(this.datasFiltered(), this.limit);

		const computeReachableDatas = this.SUtility.computeReachableDatas(
			this.event,
			this.allDatas(),
			this.datasFiltered(),
			this.computedCustomFields,
			this.filters,
			this.currentLanguage,
			this.groups,
			this.locations,
			this.tracks,
			"firestore"
		);
		this.filters = computeReachableDatas.filters;

		// Map sessions index
		if (this.datasFiltered()) {
			this.datasFiltered().forEach((session, index) => {
				this.mappedFilteredSessionsIndex[session.uid] = index;
			});
		}

		const resultCalculated = this.calculateCurrentSessions();
		let needScrolling: boolean = false;
		if (
			resultCalculated !== null &&
			this.allFilteredDatasChunked.length > 0 &&
			this.allFilteredDatasChunked[resultCalculated.indexOfChunk]
		) {
			if (resultCalculated.indexOfChunk === 0) {
				this.datas.set(
					this.mapAdditionnalDatasForSessions(
						this.allFilteredDatasChunked[resultCalculated.indexOfChunk].concat(
							this.allFilteredDatasChunked[resultCalculated.indexOfChunk + 1]
								? this.allFilteredDatasChunked[resultCalculated.indexOfChunk + 1]
								: []
						)
					)
				);
			} else {
				this.datas.set(
					this.mapAdditionnalDatasForSessions(
						this.allFilteredDatasChunked[resultCalculated.indexOfChunk - 1]
							.concat(
								this.allFilteredDatasChunked[resultCalculated.indexOfChunk]
									? this.allFilteredDatasChunked[resultCalculated.indexOfChunk]
									: []
							)
							.concat(
								this.allFilteredDatasChunked[resultCalculated.indexOfChunk + 1]
									? this.allFilteredDatasChunked[resultCalculated.indexOfChunk + 1]
									: []
							)
					)
				);
			}
			needScrolling = true;
		} else {
			this.datas.set(
				this.mapAdditionnalDatasForSessions(
					this.datasFiltered() ? this.datasFiltered().slice(0, this.limit * 2) : []
				)
			);
		}

		this.initDatasChecks.initSessions = true;
		this.initDatasSubject.next(this.initDatasChecks);
		this.firstTimeInit = false;
		if (needScrolling && resultCalculated && resultCalculated.sessionIdToScroll && !this.dateChanged) {
			this.scrollToSession(resultCalculated.sessionIdToScroll).subscribe({
				complete: () => {
					const element = document.getElementById(resultCalculated.sessionIdToScroll);
					if (element) {
						element.scrollIntoView();
					}
					this.scheduleLoader.set(false);
					this.firstTimeModuleAccess = false;
					this.dateChanged = false;
					this.updateComponent();
				}
			});
		} else if (this.dateChanged) {
			this.resetScrollOfList();
			this.scheduleLoader.set(false);
			this.firstTimeModuleAccess = false;
			this.dateChanged = false;
		} else {
			this.scheduleLoader.set(false);
			this.firstTimeModuleAccess = false;
			this.dateChanged = false;
		}

		this.icsSessionLoader = [];
		this.datas().forEach((session) => {
			this.icsSessionLoader.push({ sessionId: session.uid, state: false });
		});
		this.buildFavorites();
		this.updateComponent();
	}

	filterSessionsByTrack() {
		this.scheduleLoader.set(true);
		this.calendarDatesChanged();
		this.filtersQuery.page = 0;
		this.filtersQuery.itemsPerPage = 999999;

		const datasFiltered = filterSearch(this.filtersQuery, this.allDatas());
		this.datasFiltered.set(datasFiltered.datas);

		const computeReachableDatas = this.SUtility.computeReachableDatas(
			this.event,
			this.allDatas(),
			this.datasFiltered(),
			this.computedCustomFields,
			this.filters,
			this.currentLanguage,
			this.groups,
			this.locations,
			this.tracks,
			"firestore"
		);
		this.filters = computeReachableDatas.filters;

		this.allDatasByTrackDates.set(
			this.SSchedules.buildSessionsByTracks(datasFiltered.datas, this.tracks, this.allDates).map((dateTrack) => {
				const tracks = dateTrack.tracks.map((trackGroup) => {
					return {
						...trackGroup,
						collapsed:
							this.module && this.module.options && this.module.options.trackItemsUnfoldDefault
								? true
								: false
					};
				});
				dateTrack.tracks = tracks;
				return dateTrack;
			})
		);

		const sessionsByTracksToShow: IScheduleByTrackDate[] = [];

		let totalCalcSessionsByTracks: number = 0;
		this.allDatasByTrackDates().forEach((trackDate) => {
			let sessionsForTracks: ISchedule[] = [];
			trackDate.tracks.forEach((sessionsByTrack) => {
				sessionsForTracks = sessionsForTracks.concat(sessionsByTrack.sessions);
			});
			sessionsForTracks = _.uniqBy(sessionsForTracks, "uid");
			if (totalCalcSessionsByTracks < this.limit * 3) {
				sessionsByTracksToShow.push(trackDate);
			}
			totalCalcSessionsByTracks += sessionsForTracks.length;
		});

		this.datasByTrackDatesFiltered.set([]);
		this.datasByTrackDatesFiltered.set(sessionsByTracksToShow);

		this.eventUsersFromTags = this.getEventUsersFromTags(this.datasByTrackDatesFiltered());

		this.initDatasChecks.initSessions = true;
		this.initDatasSubject.next(this.initDatasChecks);
		this.firstTimeInit = false;
		if (this.dateChanged) {
			this.resetScrollOfList();
			this.scheduleLoader.set(false);
			this.firstTimeModuleAccess = false;
			this.dateChanged = false;
		} else {
			this.scheduleLoader.set(false);
			this.firstTimeModuleAccess = false;
			this.dateChanged = false;
		}
		this.updateComponent();
	}

	mapAdditionnalDatasForSessions(sessions: ISchedule[]) {
		return sessions.map((session) => {
			// if (this.module && this.module.options && this.module.options.activateTracksFiltering) {
			// attach 'Tracks' to the session
			session["trackDatas"] = this.tracks
				.filter((track) => session.tracks.includes(track.uid))
				.sort((a, b) => {
					return a.orderInSession < b.orderInSession
						? 1
						: a.orderInSession > b.orderInSession
						? -1
						: a.name < b.name
						? 1
						: a.name < b.name
						? -1
						: 0;
				});
			// }
			// attach 'Locations' field to the session
			session["locationDatas"] = this.locations.filter((location) => session.locations.includes(location.uid));

			// attach 'Tags' to the session
			// session["tags"] = this.getTagsForSession(session, this.attendees().concat(this.speakers()));

			session["progressTime"] = this.checkProgressTime(session);
			return session;
		});
	}

	calculateCurrentSessions() {
		// If first access to module check current date for scrolling to it and reset it
		// For not going anymore on go back page
		const checkNav = this.navigationSaved.find((nav) => nav.moduleId === this.moduleId);

		if (
			(!checkNav || (checkNav && !checkNav.sessionId)) &&
			this.firstTimeModuleAccess &&
			this.module &&
			this.module.options.trackCurrentTime &&
			!this.module.options.activateTracksFiltering
		) {
			let sessionIdToScroll: string = "";
			const currentTime = DateTime.local();
			let hasSessionWithEndDate: boolean = false;
			const sessionsFound = this.datasFiltered().filter((session) => {
				const dateStartSession = DateTime.fromISO(session.startDate);
				const dateEndSession = DateTime.fromISO(session.endDate);

				if (
					(!session.endDate && currentTime <= dateStartSession.plus({ hour: 2 })) ||
					(session.endDate && currentTime < dateEndSession && currentTime >= dateStartSession)
					// currentTime >= dateStartSession && (!session.endDate || currentTime < dateEndSession)
				) {
					if (dateEndSession) {
						hasSessionWithEndDate = true;
					}
					return true;
				} else {
					return false;
				}
			});
			const sessionsBefore = this.datasFiltered().filter((session) => {
				const dateStartSession = DateTime.fromISO(session.startDate);
				const dateEndSession = DateTime.fromISO(session.endDate);
				if ((!session.endDate && dateStartSession <= currentTime) || dateEndSession < currentTime) {
					return true;
				} else {
					return false;
				}
			});
			if (sessionsFound.length > 0) {
				const sessionsWithEndDate = sessionsFound.filter((session) => session.endDate);
				if (hasSessionWithEndDate || sessionsWithEndDate.length === 0) {
					sessionIdToScroll = sessionsFound[0].uid;
				} else {
					const sessionsWithEndDate = sessionsFound.filter((session) => session.endDate);
					sessionIdToScroll = sessionsWithEndDate[0].uid;
				}

				if (sessionIdToScroll) {
					const indexOfSession = this.mappedFilteredSessionsIndex[sessionIdToScroll];
					return {
						indexOfChunk: Math.floor(indexOfSession / this.limit),
						sessionIdToScroll: sessionIdToScroll
					};
				} else {
					return null;
				}
			} else if (sessionsBefore.length > 0) {
				sessionIdToScroll = sessionsBefore[sessionsBefore.length - 1].uid;
				if (sessionIdToScroll) {
					const indexOfSession = this.mappedFilteredSessionsIndex[sessionIdToScroll];
					return {
						indexOfChunk: Math.floor(indexOfSession / this.limit),
						sessionIdToScroll: sessionIdToScroll
					};
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else if (checkNav && checkNav.sessionId && this.firstTimeModuleAccess) {
			const indexOfSession = this.mappedFilteredSessionsIndex[checkNav.sessionId];
			return {
				indexOfChunk: Math.floor(indexOfSession / this.limit),
				sessionIdToScroll: checkNav.sessionId
			};
		} else {
			return null;
		}
	}

	calendarDatesChanged() {
		if (this.selectedDates && this.selectedDates.startDate !== "all" && this.selectedDates.endDate !== "all") {
			if (this.selectedDates.startDate) {
				this.filtersQuery.superiorOrEqualFields.push({
					fieldKey: "startDate",
					compareData: this.selectedDates.startDate
				});
			}

			if (this.selectedDates.endDate) {
				this.filtersQuery.inferiorOrEqualFields.push({
					fieldKey: "startDate",
					compareData: this.selectedDates.endDate
				});
			} else if (this.selectedDates.startDate && !this.selectedDates.endDate) {
				this.filtersQuery.inferiorOrEqualFields.push({
					fieldKey: "startDate",
					compareData: this.selectedDates.startDate
				});
			}
		}
	}

	firstTimeShow: boolean = true;
	onVisibleVirtual(
		virtual: string,
		evt: {
			target: HTMLElement;
			state: "ENTER" | "LEAVE";
			scrollDirection: "UP" | "DOWN";
		}
	) {
		this.scheduleLoader.set(true);
		if (
			evt.state === "ENTER" &&
			virtual === "first" &&
			this.datas().length > 0 &&
			this.datasFiltered().length > 0 &&
			this.datas()[0] !== this.datasFiltered()[0] &&
			!this.firstTimeShow
		) {
			const indexOfCurrentSession = this.mappedFilteredSessionsIndex[this.datas()[0].uid];

			const indexOfPrevChunk = Math.floor(indexOfCurrentSession / this.limit) - 1;

			if (
				this.allFilteredDatasChunked[indexOfPrevChunk] &&
				!this.datas().find((sess) => sess.uid === this.allFilteredDatasChunked[indexOfPrevChunk][0].uid)
			) {
				// Add next chunk to the array
				this.datas.set(
					this.mapAdditionnalDatasForSessions(this.allFilteredDatasChunked[indexOfPrevChunk]).concat(
						this.datas()
					)
				);

				if (this.datas().length > this.limit * 4) {
					// Remove previous chunk of array
					// this.sessions.set(_.flatten(_.chunk(this.datas(), this.limit).slice(0, 4)));
				}
				if (this.datas().length > 0) {
					this.checkSessionLoaded(this.datas()[0].uid).subscribe({
						complete: () => {
							this.scheduleLoader.set(false);
						}
					});
				}
			}
		} else if (
			evt.state === "ENTER" &&
			virtual === "last" &&
			this.datas().length > 0 &&
			this.datasFiltered().length > 0 &&
			this.datas()[this.datas().length - 1] !== this.datasFiltered()[this.datasFiltered().length - 1] &&
			!this.firstTimeShow
		) {
			this.scheduleLoader.set(true);
			const indexOfCurrentSession = this.mappedFilteredSessionsIndex[this.datas()[this.datas().length - 1].uid];

			const indexOfNextChunk = Math.floor(indexOfCurrentSession / this.limit) + 1;

			if (
				this.allFilteredDatasChunked[indexOfNextChunk] &&
				!this.datas().find((sess) => sess.uid === this.allFilteredDatasChunked[indexOfNextChunk][0].uid)
			) {
				// Add next chunk to the array
				this.datas.set(
					this.datas().concat(
						this.mapAdditionnalDatasForSessions(this.allFilteredDatasChunked[indexOfNextChunk])
					)
				);

				if (this.allFilteredDatasChunked[indexOfNextChunk - 2]) {
					// Remove previous chunk of array
					// this.sessions.set(_.flatten(_.chunk(this.datas(), this.limit).reverse().slice(0, 4).reverse()));
				}
				if (this.datas().length > 0) {
					this.checkSessionLoaded(this.datas()[this.datas().length - 1].uid).subscribe({
						complete: () => {
							this.scheduleLoader.set(false);
						}
					});
				}
			}
		} else {
			this.scheduleLoader.set(false);
		}
		this.firstTimeShow = false;
	}

	onVisibleVirtualByTrack(
		virtual: string,
		evt: {
			target: HTMLElement;
			state: "ENTER" | "LEAVE";
			scrollDirection: "UP" | "DOWN";
		}
	) {
		this.scheduleLoader.set(true);

		if (evt.state === "ENTER" && virtual === "last") {
			const getCurrentLastTrack = this.datasByTrackDatesFiltered()[this.datasByTrackDatesFiltered().length - 1];
			const indexOfLastTrack = this.allDatasByTrackDates().indexOf(getCurrentLastTrack);
			if (this.allDatasByTrackDates()[indexOfLastTrack + 1]) {
				this.datasByTrackDatesFiltered.set(
					this.datasByTrackDatesFiltered().concat(this.allDatasByTrackDates()[indexOfLastTrack + 1])
				);
			}

			if (
				this.datasByTrackDatesFiltered().length > 0 &&
				this.datasByTrackDatesFiltered()[this.datasByTrackDatesFiltered().length - 1].tracks.length > 0
			) {
				if (
					this.datasByTrackDatesFiltered()[this.datasByTrackDatesFiltered().length - 1].tracks[
						this.datasByTrackDatesFiltered()[this.datasByTrackDatesFiltered().length - 1].tracks.length - 1
					].track
				) {
					this.checkSessionLoaded(
						this.datasByTrackDatesFiltered()[this.datasByTrackDatesFiltered().length - 1].tracks[
							this.datasByTrackDatesFiltered()[this.datasByTrackDatesFiltered().length - 1].tracks
								.length - 1
						].track.identifier
					).subscribe({
						complete: () => {
							this.scheduleLoader.set(false);
						}
					});
				} else {
					this.scheduleLoader.set(false);
				}
			} else {
				this.scheduleLoader.set(false);
			}
		} else {
			this.scheduleLoader.set(false);
		}
	}

	currentVisibleDateOnList: string = "";
	onVisible(
		session: ISchedule,
		evt: { target: HTMLElement; state: "ENTER" | "LEAVE"; scrollDirection: "UP" | "DOWN" }
	) {
		if (evt.state === "ENTER" && session) {
			this.currentVisibleDateOnList = session.startDate;
		}
	}

	getFilters() {
		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initCustomFields ||
						!initState.initGroups ||
						!initState.initLocations ||
						!initState.initTracks
				),
				take(1),
				switchMap(() => {
					if (
						this.module &&
						this.module.options &&
						(this.module.options.showFilter || this.module.options.showSearchIcon)
					) {
						return this.SCustomFields.getFiltersForModule(
							this.module,
							this.eventUser,
							this.computedCustomFields,
							this.tracks.map((track) => {
								return { name: track.name, uid: track.uid };
							}),
							this.locations.map((group) => {
								return { name: group.name, uid: group.uid };
							}),
							this.groups.map((group) => {
								return { name: group.name, uid: group.uid };
							}),
							this.currentLanguage,
							this.SSchedules.sessionsByModulesState() &&
								this.SSchedules.sessionsByModulesState()[this.moduleId]
								? this.SSchedules.sessionsByModulesState()[this.moduleId]
								: null
						);
					} else {
						return of(null);
					}
				})
			)
			.subscribe({
				next: (filters: IFilters) => {
					if (filters) {
						this.integratePreviousFiltersState(filters);
					}
					// this.filters.customFields = filters.customFields;
					// this.filters.tracks = filters.tracks;
					// this.filters.groups = filters.groups;
					// this.filters.locations = filters.locations;

					this.initDatasChecks.initFilters = true;
					this.initDatasSubject.next(this.initDatasChecks);
					this.filtersLoader = false;
				},
				error: () => {
					this.filtersLoader = false;
				}
			});
	}

	integratePreviousFiltersState(filters: IFilters) {
		const newFilters: IFilters = _.cloneDeep(filters);

		this.filters.locations.forEach((filter) => {
			const checkFilter = newFilters.locations.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.tracks.forEach((filter) => {
			const checkFilter = newFilters.tracks.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.groups.forEach((filter) => {
			const checkFilter = newFilters.groups.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.customFields.forEach((filter) => {
			const checkFilter = newFilters.customFields.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				filter.values.forEach((value) => {
					const checkFilterValue = checkFilter.values.find((val) => val.value === value.value);
					if (checkFilterValue) {
						checkFilterValue.isSelected = value.isSelected;
						checkFilterValue.isReachable = value.isReachable;
					}
				});
			}
		});

		this.filters = newFilters;
	}

	checkDateExist() {
		const date = DateTime.local();
		return this.allDates.some((dateExist) => {
			const date2 = DateTime.fromISO(dateExist);
			return date.hasSame(date2, "year") && date.hasSame(date2, "month") && date.hasSame(date2, "day");
		});
	}

	getCurrentDate() {
		const date = DateTime.local();
		return this.allDates.find((dateExist) => {
			const date2 = DateTime.fromISO(dateExist);
			return date.hasSame(date2, "year") && date.hasSame(date2, "month") && date.hasSame(date2, "day");
		});
	}

	compareDates(dateOne: string, dateTwo: string) {
		return (
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"day"
			) &&
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"year"
			) &&
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"month"
			)
		);
	}

	/**
	 * Filter sessions by date
	 */
	applyFilters() {
		this.resetQueryFilters();
		this.scheduleLoader.set(true);
		// Filter on searchbar term if needed
		this.filtersQuery = this.buildSearchQueryFilters(this.filtersQuery);
		this.filters.customFields.forEach((filter) => {
			filter.values.forEach((value) => {
				if (value.isSelected) {
					const cus = this.computedCustomFields.find(
						(cus) => cus.baseSettings.uid === value.filterId
					).moduleSettings;
					const fieldKey =
						this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
						this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT
							? "multiLanguageText"
							: this.getCustomFieldType(cus.uid) === TypeCustomFields.URL ||
							  this.getCustomFieldType(cus.uid) === TypeCustomFields.EMAIL
							? "text"
							: this.getCustomFieldType(cus.uid) === TypeCustomFields.NUMERIC
							? "numeric"
							: this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT
							? "multiLanguageSelectArray"
							: false;

					const isMultilanguage: boolean =
						this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
						this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT ||
						this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT
							? true
							: false;

					const bloc = this.filtersQuery.arrayContainsBlocAndOrFields.find(
						(bloc) =>
							bloc.fieldKey ===
							"customFields|" +
								fieldKey +
								"|" +
								value.filterId +
								(isMultilanguage ? "|" + this.currentLanguage : "")
					);
					if (!bloc) {
						this.filtersQuery.arrayContainsBlocAndOrFields.push({
							fieldKey:
								"customFields|" +
								fieldKey +
								"|" +
								value.filterId +
								(isMultilanguage ? "|" + this.currentLanguage : ""),
							compareData: [value.isNotSpecified ? "" : value.value]
						});
					} else {
						bloc.compareData.push(value.isNotSpecified ? "" : value.value);
					}
				}
			});
		});

		this.filters.locations.forEach((filter) => {
			if (filter.checked) {
				const bloc = this.filtersQuery.arrayContainsBlocAndOrFields.find(
					(bloc) => bloc.fieldKey === "locations"
				);
				if (!bloc) {
					this.filtersQuery.arrayContainsBlocAndOrFields.push({
						fieldKey: "locations",
						compareData: [filter.uid]
					});
				} else {
					bloc.compareData.push(filter.uid);
				}
			}
		});

		this.filters.groups.forEach((filter) => {
			if (filter.checked) {
				const bloc = this.filtersQuery.arrayContainsBlocAndOrFields.find((bloc) => bloc.fieldKey === "groups");
				if (!bloc) {
					this.filtersQuery.arrayContainsBlocAndOrFields.push({
						fieldKey: "groups",
						compareData: [filter.uid]
					});
				} else {
					bloc.compareData.push(filter.uid);
				}
			}
		});

		this.filters.tracks.forEach((filter) => {
			if (filter.checked) {
				const bloc = this.filtersQuery.arrayContainsBlocAndOrFields.find((bloc) => bloc.fieldKey === "tracks");
				if (!bloc) {
					this.filtersQuery.arrayContainsBlocAndOrFields.push({
						fieldKey: "tracks",
						compareData: [filter.uid]
					});
				} else {
					bloc.compareData.push(filter.uid);
				}
			}
		});

		this.module.options && this.module.options.activateTracksFiltering
			? this.filterSessionsByTrack()
			: this.filterSessions();
	}

	buildSearchQueryFilters(filtersQuery: ISearchFilter) {
		return buildFiltersQuery(
			[this.module],
			filtersQuery,
			this.searchValue,
			this.currentLanguage,
			this.computedCustomFields,
			this.attendees(),
			this.speakers(),
			this.allDatas(),
			this.documents,
			this.groups,
			this.locations,
			this.tracks
		);
	}

	/**
	 * getCustomFieldType
	 * @description return type of custom field based on the uid
	 * @param uid
	 * @return number
	 */
	getCustomFieldType(uid: string): number {
		return this.computedCustomFields.find((computedCustomField) => computedCustomField.baseSettings.uid === uid)
			?.baseSettings.type;
	}

	/**
	 * canShowFilterBloc
	 * @description return true if the filter is at least one custom field that can be filtered
	 * @returns boolean
	 */
	canShowFilterBloc() {
		return (
			(this.filters.locations.length > 0 && this.module.options.baseFields.location.filter) ||
			(this.filters.tracks.length > 0 && this.module.options.baseFields.track.filter) ||
			(this.filters.groups.length > 0 && this.module.options.baseFields.group.filter) ||
			this.filters.customFields.length > 0
		);
	}

	/**
	 * Get part of date
	 * @param date
	 * @param unit
	 * @returns
	 */
	getPartOfDate(date: string, unit: string) {
		return this.SUtility.getPartOfDate(this.event, this.eventUser, date, unit);
	}

	/**
	 * Check session date and given date
	 * @param session
	 * @param date
	 * @returns
	 */
	checkSessionDate(session: ISchedule, date: string) {
		return this.compareDates(session.startDate, date) ? true : false;
	}

	updateNavigationSaved(navigationSaved: { sessionId: string; moduleId: string }[]) {
		this.navigationSaved = navigationSaved;
	}

	checkProgressTime(session: ISchedule) {
		const returnedObject: {
			startSoon: boolean;
			startMinutes: number;
			endSoon: boolean;
			endMinutes: number;
			showProgress: boolean;
			inProgress: boolean;
			progressPercentage: number;
		} = {
			startSoon: false,
			startMinutes: 0,
			endSoon: false,
			endMinutes: 0,
			showProgress: false,
			inProgress: false,
			progressPercentage: 0
		};
		const sessionStart = this.SUtility.configureDateTime(
			this.event,
			this.eventUser,
			DateTime.fromISO(session.startDate)
		);
		const currentDate = this.SUtility.configureDateTime(this.event, this.eventUser, DateTime.local());
		const diffStartCurrent = sessionStart.diff(currentDate, ["minutes", "seconds", "milliseconds"]);
		if (diffStartCurrent.minutes <= 20 && diffStartCurrent.minutes > 0) {
			returnedObject.startSoon = true;
			returnedObject.startMinutes = Math.round(diffStartCurrent.minutes);
			returnedObject.showProgress = true;
		}

		if (session.endDate) {
			const sessionEnd = this.SUtility.configureDateTime(
				this.event,
				this.eventUser,
				DateTime.fromISO(session.endDate)
			);
			const diffEndCurrent = sessionEnd.diff(currentDate, ["minutes", "seconds", "milliseconds"]);
			// Check end soon
			if (diffEndCurrent.minutes <= 20 && diffEndCurrent.minutes > 0) {
				returnedObject.endSoon = true;
				returnedObject.endMinutes = Math.round(diffEndCurrent.minutes);
				returnedObject.showProgress = true;
			}

			// Check in progress
			if (sessionStart <= currentDate && sessionEnd >= currentDate) {
				returnedObject.inProgress = true;
				returnedObject.showProgress = true;
				returnedObject.progressPercentage =
					currentDate.diff(sessionStart, "millisecond").milliseconds /
					sessionEnd.diff(sessionStart, "milliseconds").milliseconds;
			} else {
				if (currentDate > sessionEnd) {
					returnedObject.inProgress = true;
					returnedObject.progressPercentage = 1;
					returnedObject.showProgress = true;
				}
			}
			return returnedObject;
		} else {
			returnedObject.endSoon = false;
			returnedObject.endMinutes = 0;
			returnedObject.showProgress = true;
			returnedObject.inProgress = true;
			returnedObject.progressPercentage = 1;
			return returnedObject;
		}
	}

	/**
	 * Get all custom fields tags for session
	 * @param session
	 */
	getTagsForSession(session: ISchedule, eventUsersFound: IEventUser[]) {
		if (session) {
			let sessionCustomFields: IFullCustomField[] = [];
			const computedCus = this.computedCustomFields
				.filter((custom) => {
					const sessionFieldData = session.customFields.find(
						(sessionFieldData) =>
							custom.baseSettings &&
							custom.moduleSettings &&
							sessionFieldData.uid === custom.baseSettings.uid &&
							custom.moduleSettings.moduleId === this.moduleId
					);

					return custom.baseSettings &&
						custom.moduleSettings &&
						custom.moduleSettings.canBeTag &&
						sessionFieldData &&
						this.SCustomFields.checkValueCustomField(
							custom.baseSettings.type,
							sessionFieldData,
							this.currentLanguage
						)
						? true
						: false;
				})
				.sort((a, b) => {
					if (a.baseSettings && a.moduleSettings && b.baseSettings && b.moduleSettings) {
						return this.getCustomFieldOrder(a.baseSettings.uid, this.moduleId) >
							this.getCustomFieldOrder(b.baseSettings.uid, this.moduleId)
							? 1
							: this.getCustomFieldOrder(a.baseSettings.uid, this.moduleId) <
							  this.getCustomFieldOrder(b.baseSettings.uid, this.moduleId)
							? -1
							: 0;
					}
				});
			sessionCustomFields = _.cloneDeep(computedCus);

			sessionCustomFields.forEach((sessionCustomField) => {
				const sessionFieldData = session.customFields.find(
					(sessionFieldData) => sessionFieldData.uid === sessionCustomField.baseSettings.uid
				);

				if (sessionCustomField.baseSettings.type !== TypeCustomFields.MODULE) {
					if (
						sessionCustomField.baseSettings.type === TypeCustomFields.URL ||
						sessionCustomField.baseSettings.type === TypeCustomFields.EMAIL
					) {
						sessionCustomField.fieldDatas.field.text = sessionFieldData.field.text;
					} else if (
						sessionCustomField.baseSettings.type === TypeCustomFields.TEXT ||
						sessionCustomField.baseSettings.type === TypeCustomFields.SELECT ||
						sessionCustomField.baseSettings.type === TypeCustomFields.HMTL
					) {
						sessionCustomField.fieldDatas.field.multiLanguageText =
							sessionFieldData.field.multiLanguageText;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.MULTI_SELECT) {
						sessionCustomField.fieldDatas.field.multiLanguageSelectArray =
							sessionFieldData.field.multiLanguageSelectArray;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.MULTI_TEXT) {
						sessionCustomField.fieldDatas.field.multiLanguageTextArray =
							sessionFieldData.field.multiLanguageTextArray;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.NUMERIC) {
						sessionCustomField.fieldDatas.field.numeric = sessionFieldData.field.numeric;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.DATE) {
						sessionCustomField.fieldDatas.field.date = sessionFieldData.field.date;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.PHONE) {
						sessionCustomField.fieldDatas.field.phoneNumber = sessionFieldData.field.phoneNumber;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.ADDRESS) {
						sessionCustomField.fieldDatas.field.address = sessionFieldData.field.address;
					}
				} else {
					if (
						sessionCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
						sessionCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER
					) {
						const eventUsers = this.getEventUsersOfSession(session, sessionCustomField, eventUsersFound);
						// eventUsers.forEach((eventUser) => {
						// 	eventUser["tags"] = this.getTagsOfEventUser(eventUser);
						// });
						sessionCustomField.fieldDatas.field.module.itemsDatas = eventUsers;
						sessionCustomField.fieldDatas.field.module.items = eventUsers.map((eventUser) => eventUser.uid);
						sessionCustomField.fieldDatas.field.module.itemsIdentifier = eventUsers.map(
							(eventUser) => eventUser.identifier
						);
						// eslint-disable-next-line no-empty
					} else if (sessionCustomField.baseSettings.customFieldModuleType === TypeModule.SHEETS) {
					} else if (sessionCustomField.baseSettings.customFieldModuleType === TypeModule.DOCUMENT) {
						sessionCustomField.fieldDatas.field.module.itemsDatas = this.getDocumentsOfSession(
							session,
							sessionCustomField
						);
						sessionCustomField.fieldDatas.field.module.items = this.getDocumentsOfSession(
							session,
							sessionCustomField
						)?.map((doc) => doc.uid);
						sessionCustomField.fieldDatas.field.module.itemsIdentifier = this.getDocumentsOfSession(
							session,
							sessionCustomField
						)?.map((doc) => doc.name[this.currentLanguage]);
					}
				}
			});
			return sessionCustomFields;
		} else {
			return [];
		}
	}

	/**
	 * getRepeatedTagsForGroupTrack
	 * @param groupTrack
	 * @returns
	 */
	getRepeatedTagsForGroupTrack(groupTrack: IScheduleByTrack): IFullCustomField[] {
		const repeatedTags: IFullCustomField[] = [];
		const sessions = groupTrack.sessions;
		const allSessionTags = sessions
			.map((session) => this.getTagsForSession(session, this.allEventUsers()))
			.filter((tags) => tags.length > 0);

		for (const session of sessions) {
			const sessionTags = this.getTagsForSession(session, this.allEventUsers());
			const tempSessionTags = sessionTags.filter(
				(tag) =>
					tag.moduleSettings.canBeTag &&
					tag.fieldDatas.field &&
					tag.fieldDatas.field.module &&
					tag.fieldDatas.field.module.itemsDatas &&
					tag.fieldDatas.field.module.itemsDatas.length > 0
			);

			if (tempSessionTags.length > 0) {
				tempSessionTags.forEach((tag) => {
					// only keep tags that are present in all sessions
					if (repeatedTags.length === 0) {
						repeatedTags.push(...tempSessionTags);
					}

					if (
						allSessionTags.every((sessionTag) =>
							sessionTag.every((t) => t.fieldDatas.field.module.items.includes(tag.baseSettings.uid))
						)
					) {
						repeatedTags.splice(repeatedTags.indexOf(tag), 1);
					}
				});
			}
		}

		return repeatedTags;
	}

	/**
	 * getEventUsersFromTags
	 * @param tags
	 * @returns
	 */
	getEventUsersFromTags(datesTracks: IScheduleByTrackDate[]): { eventUsers: IEventUser[]; track: ITrack }[] {
		const datas: { eventUsers: IEventUser[]; track: ITrack }[] = [];
		for (const dateTrack of datesTracks) {
			for (const groupTrack of dateTrack.tracks) {
				const eventUsers: IEventUser[] = [];
				const tags = this.getRepeatedTagsForGroupTrack(groupTrack);
				const sessions = groupTrack.sessions;

				const allSessionTags = sessions
					.map((session) => this.getTagsForSession(session, this.allEventUsers()))
					.filter((tags) => tags.length > 0);

				for (const tag of tags) {
					if (
						tag.baseSettings.type === TypeCustomFields.MODULE &&
						(tag.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
							tag.baseSettings.customFieldModuleType === TypeModule.SPEAKER)
					) {
						for (const item of tag.fieldDatas.field.module.itemsDatas) {
							if (
								allSessionTags.every((sessionTag) =>
									sessionTag.some((t) => t.fieldDatas.field.module.items.includes(item.uid))
								)
							) {
								item["tagName"] =
									tag.baseSettings.name[
										this.eventUser && this.eventUser.updatedSettings.language
											? this.eventUser.updatedSettings.language
											: this.event.language
									];

								eventUsers.push(item as IEventUser);
							}
						}
					}
				}

				datas.push({ eventUsers: eventUsers, track: groupTrack.track });
			}
		}

		return datas;
	}

	/**
	 * getRepeatedTagsByGroupTraks
	 * @param groupTrack
	 * @returns
	 */
	getRepeatedTagsByGroupTraks(groupTrack: IScheduleByTrack): any {
		const eventUsers = this.eventUsersFromTags.find(
			(data) => data.track && groupTrack.track && data.track.uid === groupTrack.track.uid
		);

		if (eventUsers) {
			const groupedEventUsers = _.groupBy(eventUsers.eventUsers, "tagName");

			// remove all from groupTrack.sessions that are in groupedEventUsers
			groupTrack.sessions.forEach((session) => {
				if (session?.["tags"]) {
					session["tags"].forEach((tag: IFullCustomField) => {
						tag.fieldDatas.field.module.itemsDatas = (
							tag.fieldDatas.field.module.itemsDatas as IEventUser[]
						).filter(
							(item) =>
								!groupedEventUsers?.[tag.baseSettings.name[this.currentLanguage]]?.find(
									(i) => i.uid === item.uid
								)
						);
					});
				}
			});

			return Object.keys(groupedEventUsers).map((key) => {
				return groupedEventUsers[key];
			});
		} else {
			return [];
		}
	}

	getEventUsersUidsForSessions(sessions: ISchedule[]) {
		const eventUsersUidsToFind: string[] = [];
		for (let i = 0; i < sessions.length; i++) {
			const session = sessions[i];
			let sessionCustomFields: IFullCustomField[] = [];
			const computedCus = this.computedCustomFields
				.filter((custom) => {
					const sessionFieldData = session.customFields.find(
						(sessionFieldData) =>
							custom.baseSettings &&
							custom.moduleSettings &&
							sessionFieldData.uid === custom.baseSettings.uid &&
							custom.moduleSettings.moduleId === this.moduleId
					);

					return custom.baseSettings &&
						custom.moduleSettings &&
						custom.moduleSettings.canBeTag &&
						sessionFieldData &&
						this.SCustomFields.checkValueCustomField(
							custom.baseSettings.type,
							sessionFieldData,
							this.currentLanguage
						)
						? true
						: false;
				})
				.sort((a, b) => {
					if (a.baseSettings && a.moduleSettings && b.baseSettings && b.moduleSettings) {
						return this.getCustomFieldOrder(a.baseSettings.uid, this.moduleId) >
							this.getCustomFieldOrder(b.baseSettings.uid, this.moduleId)
							? 1
							: this.getCustomFieldOrder(a.baseSettings.uid, this.moduleId) <
							  this.getCustomFieldOrder(b.baseSettings.uid, this.moduleId)
							? -1
							: 0;
					}
				});

			sessionCustomFields = _.cloneDeep(computedCus);
			for (let i = 0; i < sessionCustomFields.length; i++) {
				const sessionCustomField = sessionCustomFields[i];

				if (
					sessionCustomField.baseSettings.type === TypeCustomFields.MODULE &&
					(sessionCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
						sessionCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER)
				) {
					const customFieldsSession = session.customFields
						.slice()
						.filter(
							(cus) =>
								sessionCustomField.baseSettings.uid === cus.uid &&
								sessionCustomField.baseSettings.type === TypeCustomFields.MODULE &&
								(sessionCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
									sessionCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER) &&
								cus.field.module.items.length > 0
						);

					for (const cus of customFieldsSession) {
						for (const itemId of cus.field.module.items) {
							if (!eventUsersUidsToFind.includes(itemId)) {
								eventUsersUidsToFind.push(itemId);
							}
						}
					}
				}
			}
		}

		return eventUsersUidsToFind;
	}

	/**
	 * Get tag value
	 * @param session
	 * @param tag
	 */
	getTagValue(session: ISchedule, tag: IFullCustomField) {
		const fieldData = session.customFields.find((fieldData) => fieldData.uid === tag.baseSettings.uid);

		if (tag.baseSettings.type !== TypeCustomFields.MODULE) {
			return this.SCustomFields.getValueForCustomField(
				tag.baseSettings.type,
				fieldData,
				this.event?.language,
				this.eventUser?.updatedSettings.language
			);
		}
	}

	/**
	 * getEventUsersOfSession
	 * @param session
	 * @param tag
	 */
	getEventUsersOfSession(session: ISchedule, tag: IFullCustomField, eventUsers: IEventUser[]) {
		const itemIds: string[] = [];
		const users: IEventUser[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter(
				(cus) =>
					tag.baseSettings.uid === cus.uid &&
					tag.baseSettings.type === TypeCustomFields.MODULE &&
					(tag.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
						tag.baseSettings.customFieldModuleType === TypeModule.SPEAKER) &&
					cus.field.module.items.length > 0
			);

		for (const cus of customFieldsSession) {
			for (const itemId of cus.field.module.items) {
				if (!itemIds.includes(itemId)) {
					itemIds.push(itemId);
					const user = eventUsers.find((user) => user.uid === itemId);
					user && users.push(user);
				}
			}
		}

		return users;
	}

	/**
	 * getDocumentsOfSession
	 * @param session
	 * @param tag
	 */
	getDocumentsOfSession(session: ISchedule, tag: IFullCustomField) {
		const itemIds: string[] = [];
		const documents: IDocument[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter(
				(cus) =>
					tag.baseSettings.uid === cus.uid &&
					tag.baseSettings.type === TypeCustomFields.MODULE &&
					cus.field.module.items.length > 0
			);

		for (const cus of customFieldsSession) {
			for (const itemId of cus.field.module.items) {
				if (!itemIds.includes(itemId)) {
					itemIds.push(itemId);

					if (tag.baseSettings.customFieldModuleType === TypeModule.DOCUMENT) {
						const document = this.documents.find((document) => document.uid === itemId);
						document && documents.push(document);
					}
				}
			}
		}

		return documents;
	}

	/**
	 * getNumberOfAttendeeModuleOfSession
	 * @param session
	 * @param tag
	 */
	getNumberOfItemModuleOfSession(session: ISchedule) {
		const customFieldIds: string[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter((cus) =>
				this.computedCustomFields.find(
					(computedCustomField) =>
						computedCustomField.moduleSettings.moduleId === this.moduleId &&
						computedCustomField.baseSettings.uid === cus.uid &&
						computedCustomField.baseSettings.type === TypeCustomFields.MODULE &&
						(computedCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
							computedCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER ||
							computedCustomField.baseSettings.customFieldModuleType === TypeModule.DOCUMENT) &&
						cus.field?.module?.items.length > 0
				)
			);

		customFieldsSession.forEach((cus) => {
			const customField = this.computedCustomFields.find(
				(computedCustomField) =>
					computedCustomField.moduleSettings.moduleId === this.moduleId &&
					computedCustomField.baseSettings.uid === cus.uid
			);

			if (customField && !customFieldIds.includes(customField.baseSettings.uid)) {
				customFieldIds.push(customField.baseSettings.uid);
			}
		});

		return customFieldIds.length;
	}

	/**
	 * getTagsOfEventUser
	 * @description returns tags of eventuser
	 * @param eventUser
	 */
	getTagsOfEventUser(eventUser: IEventUser): ICustomFieldData[] {
		const computedCustomFields = this.customFieldsWithModules[eventUser.moduleId];
		return eventUser.customFields && computedCustomFields && computedCustomFields.length > 0
			? eventUser.customFields
					.filter((cus) => {
						return computedCustomFields.some(
							(computedCustomField) =>
								cus &&
								computedCustomField &&
								computedCustomField.baseSettings &&
								computedCustomField.moduleSettings &&
								computedCustomField.baseSettings &&
								computedCustomField.baseSettings.uid === cus.uid &&
								computedCustomField.moduleSettings.moduleId === eventUser.moduleId &&
								computedCustomField.moduleSettings.canBeTag &&
								((cus.field.multiLanguageText &&
									cus.field.multiLanguageText[this.event.language] !== "") ||
									(cus.field.text && cus.field.text !== ""))
						);
					})
					.sort((a, b) => {
						const orderA = computedCustomFields.find(
							(cus) =>
								cus &&
								cus.baseSettings &&
								cus.moduleSettings &&
								cus.baseSettings.uid === a.uid &&
								cus.moduleSettings.moduleId === eventUser.moduleId
						).moduleSettings.order;
						const orderB = computedCustomFields.find(
							(cus) =>
								cus &&
								cus.baseSettings &&
								cus.moduleSettings &&
								cus.baseSettings.uid === b.uid &&
								cus.moduleSettings.moduleId === eventUser.moduleId
						).moduleSettings.order;
						return orderA > orderB ? 1 : orderA < orderB ? -1 : 0;
					})
			: [];
	}

	/**
	 * getCustomFieldOrder
	 */
	getCustomFieldOrder(customId: string, moduleId: string): number {
		return this.computedCustomFields.find(
			(cus) =>
				cus &&
				cus.baseSettings &&
				cus.moduleSettings &&
				cus.baseSettings.uid === customId &&
				cus.moduleSettings.moduleId === moduleId
		).moduleSettings.order;
	}

	async openDownloadScheduleModal(event: any) {
		try {
			event.stopPropagation();
			const modal = await this.modalController.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					sessions: this.datas(),
					language:
						this.eventUser && this.eventUser.updatedSettings.language
							? this.eventUser.updatedSettings.language
							: this.event.language,
					mode: "schedule"
				},
				initialBreakpoint: 1,
				breakpoints: [0, 1],
				mode: "ios",
				canDismiss: true,
				cssClass: "auto-height-modal-css"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}

	// Generate ics file part

	/**
	 * Generate ics file
	 * @param session
	 * @returns
	 */
	generateIcsFile(session: ISchedule) {
		this.setIcsLoaderState(session.uid, true);
		// Implement a loader
		if (session) {
			const currentDate = new Date();
			const offsetHours = currentDate.getTimezoneOffset() / 60;
			this.SSchedules.generateIcsFile(
				this.eventId,
				this.moduleId,
				session.uid,
				this.event.language,
				offsetHours
			).subscribe((res) => {
				if (res && res["message"] === "success") {
					const sessionNameWithExtension = (session.name[this.event.language] as string) + ".ics";

					let sessionName = sessionNameWithExtension.toLowerCase();
					sessionName = sessionName.split(/[ ,]+/).join("_");
					sessionName = sessionName.split(/[ ']+/).join("");
					this.downloadFile(sessionName, res["result"], session.uid);
				}
			});
		}
	}

	/**
	 * Link session date to google calendar
	 * @param session
	 */
	linkEventToGoogleCalendar(session: ISchedule) {
		if (session) {
			const googleLinkPrefix = "https://calendar.google.com/calendar/render?action=TEMPLATE&";
			const currentDate = new Date();
			const offsetHours = currentDate.getTimezoneOffset() / 60;
			this.SSchedules.generateIcsFile(
				this.eventId,
				this.moduleId,
				session.uid,
				this.event.language,
				offsetHours
			).subscribe(async (res) => {
				if (res && res["message"] === "success") {
					const icsData: string = res["result"];
					// formating data for the link
					let splitedData = icsData.split("\n");
					splitedData = splitedData.map((s) => s.replace("\r", ""));
					const arrayTimestampStart = splitedData.filter((s) => s.startsWith("DTSTART"));
					const timestampStart = arrayTimestampStart[0].split(":")[1];
					const arrayTimestampEnd = splitedData.filter((s) => s.startsWith("DTEND"));
					const timestampEnd = arrayTimestampEnd[0].split(":")[1];

					const sessionNameEncoded = session.name[this.event.language]
						? encodeURI(session.name[this.event.language])
						: "";

					const sessionDescriptionEncoded =
						session.description && session.description[this.event.language]
							? encodeURI(session.description[this.event.language])
							: "";

					const datesEncoded = encodeURI(timestampStart + "/" + timestampEnd);

					const link =
						googleLinkPrefix +
						"dates=" +
						datesEncoded +
						"&text=" +
						sessionNameEncoded +
						"&details=" +
						sessionDescriptionEncoded;

					if (this.isMobile) {
						try {
							await Browser.open({
								url: link
							});
						} catch (error) {
							// ~ SchedulesComponent ~ ).subscribe ~ error:", error);
						}
					} else {
						window.open(link, "_blank");
					}
				}
			});
		}
	}

	/**
	 * Search bar
	 * @param ev
	 */
	searchBar(ev) {
		if (ev.target.value.length >= 1) {
			const value = this.SUtility.removeAccents(ev.target.value.toLowerCase());
			this.searchValue = value;
			this.resetScrollOfList();
			this.applyFilters();
		} else {
			this.resetFilter();
		}
	}

	/**
	 * openFilterModal
	 */
	// async openMobileFilterModal() {
	// this.loader = true;
	// const filterModal = await this.modalController.create({
	// 	component: FiltersForListComponent,
	// 	componentProps: {
	// 		event: this.event,
	// 		module: this.module,
	// 		eventUser: this.eventUser,
	// 		filters: this.filters,
	// 		type: "mobile"
	// 	},
	// 	cssClass: "filter-modal-css",
	// 	showBackdrop: true,
	// 	canDismiss: true,
	// 	presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
	// });

	// await filterModal.present();
	// const { data } = await filterModal.onWillDismiss();

	// if (data) {
	// 	this.filters = data.filters;
	// 	this.resetScrollOfList();
	// 	this.applyFilters();
	// }
	// }

	resetScrollOfList() {
		const currentTime = DateTime.local().plus({
			second: 10
		});
		timer(0, 100)
			.pipe(
				takeWhile(() => {
					return !document.getElementById("sessionsList") && DateTime.local() < currentTime;
				})
			)
			.subscribe({
				complete: () => {
					const element = document.getElementById("sessionsList");
					if (element) {
						element.scroll(0, 0);
					}
				}
			});
	}

	scrollToSession(sessionId: string) {
		const timeoutTime = DateTime.local().plus({
			second: 10
		});

		return timer(0, 100).pipe(
			takeWhile(() => {
				return !document.getElementById(sessionId) && DateTime.local() < timeoutTime;
			})
		);
	}

	checkSessionLoaded(sessionId: string) {
		const timeoutTime = DateTime.local().plus({
			second: 10
		});

		return timer(0, 100).pipe(
			takeWhile(() => {
				return !document.getElementById(sessionId) && DateTime.local() < timeoutTime;
			})
		);
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.searchValue = "";
		this.resetScrollOfList();
		this.applyFilters();
	}

	/**
	 * Open timezone switch modal
	 */
	async openMobileTimezoneSwitchModal() {
		// this.loader = true;
		const timezoneSwitchModal = await this.modalController.create({
			component: TimezoneSwitchComponent,
			componentProps: {
				event: this.event
			},
			initialBreakpoint: 1,
			breakpoints: [0, 1],
			cssClass: "auto-height-modal-css",
			showBackdrop: true,
			presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
		});

		await timezoneSwitchModal.present();
	}

	/**
	 * Download schedule sessions ics
	 */
	downloadSchedule(event?: any) {
		// Implement a loader
		event.stopPropagation();
		this.scheduleDownloadButtonLoader = true;
		this.SSchedules.generateSessionsIcsFileFromClient(this.module, this.datas(), this.event.language);
		this.scheduleDownloadButtonLoader = false;
	}

	/**
	 * Download a file
	 * @param filename
	 * @param text
	 */
	async downloadFile(filename: string, text, sessionId?: string) {
		try {
			if (!this.isMobile || this.platform.is("mobileweb")) {
				const element = document.createElement("a");
				element.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(text));
				element.setAttribute("download", filename);
				element.setAttribute("target", "_blank");
				element.style.display = "none";
				element.click();
			} else {
				if (this.platform.is("ios")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: filename,
						data: encodeURIComponent(text),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				} else if (this.platform.is("android")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: `download/${filename}`,
						data: encodeURIComponent(text),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				}
			}
			sessionId && this.setIcsLoaderState(sessionId, false);
			this.scheduleDownloadButtonLoader = false;
		} catch (error) {
			sessionId && this.setIcsLoaderState(sessionId, false);
			this.scheduleDownloadButtonLoader = false;
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.download_error"),
				2000,
				"bottom",
				"danger"
			);
		}
	}

	// /**
	//  * collapse
	//  * @param index
	//  */
	// collapseFilter(filterId: string) {
	// 	this.filtersCollapseState.find((filter) => filter.uid === filterId).collapsed = !this.filtersCollapseState.find(
	// 		(filter) => filter.uid === filterId
	// 	).collapsed;
	// }

	// /**
	//  * getFilterCollapseState
	//  */
	// getFilterCollapseState(filterId: string): boolean {
	// 	return this.filtersCollapseState.find((filter) => filter.uid === filterId)
	// 		? this.filtersCollapseState.find((filter) => filter.uid === filterId).collapsed
	// 		: false;
	// }

	/**
	 * setIcsLoaderState
	 * @param sessionId
	 * @param state
	 */
	setIcsLoaderState(sessionId: string, state: boolean): void {
		const index = this.icsSessionLoader.findIndex((ses) => ses.sessionId === sessionId);
		index !== -1 && (this.icsSessionLoader[index].state = state);
	}

	/**
	 * checkFavorite
	 * @param eventUserId
	 * @returns
	 */
	buildFavorites() {
		if (this.eventUser && this.eventUser.favorites) {
			this.datas().forEach((session) => {
				if (this.eventUser.favorites.includes(session.uid)) {
					this.eventUserFavoritesState = {
						...this.eventUserFavoritesState,
						[session.uid]: true
					};
				} else {
					this.eventUserFavoritesState = {
						...this.eventUserFavoritesState,
						[session.uid]: false
					};
				}
			});
		}
		this.updateComponent();
	}

	getTotalFiltersChecked() {
		let count: number = 0;

		this.filters.locations.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.groups.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.tracks.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.customFields.forEach((filter) => {
			filter.values.forEach((value) => {
				if (value.isSelected) {
					count++;
				}
			});
		});

		return count;
	}
}
