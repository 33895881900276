<div style="display: block">
	<canvas
		*ngIf="loader"
		baseChart
		[data]="barChartData"
		[options]="barChartOptions"
		[type]="barChartType"
		[plugins]="barChartPlugins"
	>
	</canvas>
</div>
