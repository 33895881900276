import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription, firstValueFrom, take } from "rxjs";
import { GetAllAutomations } from "../actions/automations.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { EmailDestinationTypeUsers } from "../enums/email-destination-type-users";
import {
	LeftConditionType,
	OperatorType,
	RightConditionType,
	TriggerType,
	TypeTriggerAction
} from "../enums/type-automation";
import { TypeCustomFields } from "../enums/type-custom-fields";
import { IAutomation, ICustomField, IEvent, IEventUser, ITrigger } from "../interfaces";
import { PathApi } from "../paths/path-api";
import { checkSameEvent } from "../selectors/automations.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { CustomFieldsService } from "./custom-fields.service";
import { FirestoreService } from "./firestore.service";
import { UtilityService } from "./utility.service";
@Injectable({
	providedIn: "root"
})
export class AutomationsService {
	public headers;
	public requestOptions;

	automationSub: Subscription;

	constructor(
		private http: HttpClient,
		private SFirestore: FirestoreService,
		private store: Store,
		private SCustomFields: CustomFieldsService,
		private SUtility: UtilityService
	) {
		this.headers = new HttpHeaders();
		this.headers.append("Accept", "application/json");
		this.headers.append("Content-Type", "application/json");
		this.requestOptions = { headers: this.headers };
	}

	/**
	 * operators functions
	 */
	isEquals = (a: any, b: any) => a === b;
	isNotEquals = (a: any, b: any) => a !== b;
	includes = (a: any, b: any) => a.includes(b);
	notIncludes = (a: any, b: any) => !a.includes(b);
	isEmpty = (a: any) => a === undefined || a === null || a === "";
	isNotEmpty = (a: any) => a !== undefined && a !== null && a !== "";
	isTrue = (a: any) => a === true;
	isFalse = (a: any) => a === false;
	isGreaterThan = (a: any, b: any) => a > b;
	isGreaterThanOrEqual = (a: any, b: any) => a >= b;
	isLessThan = (a: any, b: any) => a < b;
	isLessThanOrEqual = (a: any, b: any) => a <= b;

	operations: { [K in OperatorType]: (a: any, b: any) => boolean } = {
		[OperatorType.EQUAL]: this.isEquals,
		[OperatorType.NOT_EQUAL]: this.isNotEquals,
		[OperatorType.GREATER_THAN_OR_EQUAL]: this.isGreaterThanOrEqual,
		[OperatorType.LESS_THAN_OR_EQUAL]: this.isLessThanOrEqual,
		[OperatorType.GREATER_THAN]: this.isGreaterThan,
		[OperatorType.LESS_THAN]: this.isLessThan,
		[OperatorType.IS_IN]: this.includes,
		[OperatorType.IS_NOT_IN]: this.notIncludes,
		[OperatorType.IS_EMPTY]: this.isEmpty,
		[OperatorType.IS_NOT_EMPTY]: this.isNotEmpty,
		[OperatorType.CONTAINS]: this.includes,
		[OperatorType.NOT_CONTAINS]: this.notIncludes
	};

	unsubscribeAll() {
		[this.automationSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});
	}

	/**
	 * getAllAutomationForEvent
	 * @param eventId
	 * @returns
	 */
	getAllAutomationForEvent(eventId: string) {
		if (eventId) {
			this.store
				.select(checkSameEvent(eventId))
				.pipe(take(1))
				.subscribe((sameEvent) => {
					if (sameEvent && this.automationSub && !this.automationSub.closed) {
						return;
					} else if (!sameEvent && this.automationSub && !this.automationSub.closed) {
						this.automationSub.unsubscribe();
					}

					this.automationSub = this.SFirestore.valueChangesDocuments(
						`/events/${eventId}/automations`,
						[]
					).subscribe((automations: IAutomation[]) => {
						this.store.dispatch(GetAllAutomations({ payload: automations, eventId: eventId }));

						this.store
							.select(getInitSpecificEventDatasPart("initAutomations"))
							.pipe(take(1))
							.subscribe((init) => {
								if (!init) {
									this.store.dispatch(
										InitSpecificEventDatasPart({ part: "initAutomations", payload: true })
									);
								}
							});
					});
				});
		}
	}

	/**
	 * getAutomationById
	 * @param eventId
	 * @param automationId
	 * @returns
	 */
	getAutomationById(eventId: string, automationId: string) {
		if (eventId && automationId) {
			return this.SFirestore.getDocument(`/events/${eventId}/automations/${automationId}`);
		}
	}

	/**
	 * getAutomationTriggersByType
	 * @param automations
	 * @param type
	 * @returns
	 */
	getAutomationTriggersByType(automations: IAutomation[], type: number) {
		return automations
			.filter((automation) => automation.enabled && automation.triggers[0].triggerType === type)
			.map((automation) => {
				return automation.triggers[0];
			});
	}

	/**
	 * checkAutomationTriggerConditions
	 * @param trigger
	 * @param eventUser
	 * @param datas
	 * @returns
	 */
	checkAutomationTriggerConditions(
		trigger: ITrigger,
		eventUser: IEventUser,
		datas: { moduleIds: string[]; groupIds: string[]; customFields: ICustomField[]; event: IEvent }
	): boolean {
		// case there's no conditions
		if (trigger.conditions.length === 0) return true;

		// case there's conditions
		let isConditionsValid = false;

		for (const condition of trigger.conditions) {
			switch (condition.leftConditionType) {
				case LeftConditionType.USER_ENTITY:
					{
						switch (condition.rightConditionType) {
							case RightConditionType.MODULE:
								{
									isConditionsValid = this.operations[condition.operator](
										Array.isArray(condition.value) ? condition.value : [condition.value],
										eventUser.moduleId
									);
								}
								break;

							default:
								return false;
						}
					}
					break;

				case LeftConditionType.USER_FIELDS:
					{
						switch (condition.rightConditionType) {
							case RightConditionType.GROUP:
								{
									for (const groupId of condition.value as string[]) {
										isConditionsValid = this.operations[condition.operator](
											eventUser.groups,
											groupId
										);
										if (!isConditionsValid) return false;
									}
								}
								break;

							// Other user fields cases
							default:
								{
									let eventUserPropertyValue = eventUser[condition.fieldProperty];

									if (eventUserPropertyValue === undefined || eventUserPropertyValue === null) {
										return false;
									}

									if (condition.rightConditionType === RightConditionType.DATE) {
										eventUserPropertyValue = eventUserPropertyValue.split("T")?.[0];
									}

									isConditionsValid = this.operations[condition.operator](
										eventUserPropertyValue,
										condition.value
									);
								}
								break;
						}
					}
					break;

				case LeftConditionType.USER_CUSTOM_FIELDS:
					{
						const customFieldData = eventUser.customFields.find(
							(cus) => cus.uid === condition.fieldProperty
						);
						const customFieldType = datas.customFields.find(
							(cus) => cus.uid === condition.fieldProperty
						).type;

						if (!customFieldData) return false;

						if (customFieldType === TypeCustomFields.MULTI_SELECT) {
							// get filter values
							(condition.value as Array<any>).forEach((data) => {
								const customFieldBase = datas.customFields.find((cus) => cus.uid === data.uid);

								if (!customFieldBase) return false;

								if (
									condition.operator &&
									this.SCustomFields.getValueForCustomField(
										customFieldType,
										customFieldData,
										datas.event.language,
										datas.event.language
									)?.map((cus) => cus[datas.event.language])?.length === 0
								) {
									return true;
								} else {
									isConditionsValid = this.operations[condition.operator](
										this.SCustomFields.getValueForCustomField(
											customFieldType,
											customFieldData,
											datas.event.language,
											datas.event.language
										)?.map((cus) => cus[datas.event.language]),
										datas.customFields
											.map((cus) =>
												cus.uid === data.uid
													? cus.options[data.index]?.[datas.event.language]
													: null
											)
											.filter((cus) => cus !== null)?.[0]
									);
								}
							});
						} else {
							isConditionsValid = this.operations[condition.operator](
								this.SCustomFields.getValueForCustomField(
									customFieldType,
									customFieldData,
									datas.event.language,
									eventUser.updatedSettings && eventUser.updatedSettings.language
								),
								(condition.value as any)?.value
							);
						}
					}
					break;

				default:
					return false;
			}
		}

		return isConditionsValid;
	}

	/**
	 * excecuteAutomationTrigger
	 * @param trigger
	 */
	excecuteAutomationTrigger(
		trigger: ITrigger,
		datas: {
			[key: string]: IEvent | IEventUser | IEventUser[] | any | any[];
		} = {
			event: {},
			user: {},
			eventUser: {},
			eventUsers: []
		}
	) {
		const promiseAll: Promise<any>[] = [];

		switch (trigger.triggerType) {
			case TriggerType.GENERIC_REGISTER_EVENTUSER:
				{
					const eventUser = datas.eventUser as IEventUser;
					const event = datas.event as IEvent;
					// get actions in triggers and excecute them
					for (const action of trigger.actions) {
						switch (action.actionType) {
							case TypeTriggerAction.SEND_MAIL_TO_STAFF:
								{
									action.emailContent.body = this.parseEmailVarDatas(
										action.emailContent.body,
										event,
										eventUser
									);
									const email = {
										eventId: event.uid,
										mailContent: this.parseEmailVarDatas(
											action.emailContent.html[0],
											event,
											eventUser
										),
										mailSubject: action.emailContent.subject,
										mailTo: action.receiverType === 12 ? eventUser.email : null,
										attachement: action.settings?.enableIcsMail
											? action.emailContent?.attachement
											: null,
										destinationType:
											action.receiverType === 12
												? EmailDestinationTypeUsers.SPECIFIC
												: action.receiverType
									};

									// send email to the staff
									promiseAll.push(this.sendEmail(email));
								}
								break;
							case TypeTriggerAction.SEND_MAIL_TO_USER:
								{
									action.emailContent.body = this.parseEmailVarDatas(
										action.emailContent.body,
										event,
										eventUser
									);

									const email = {
										eventId: event.uid,
										mailContent: this.parseEmailVarDatas(
											action.emailContent.html[0],
											event,
											eventUser
										),
										mailSubject: action.emailContent.subject,
										mailTo: action.receiverType === 12 ? eventUser.email : null,
										attachement: action.settings?.enableIcsMail
											? action.emailContent?.attachement
											: null,
										destinationType:
											action.receiverType === 12
												? EmailDestinationTypeUsers.SPECIFIC
												: action.receiverType
									};

									// send email to the event user
									promiseAll.push(this.sendEmail(email));
								}
								break;

							default:
								break;
						}
					}
				}
				break;

			case TriggerType.MODULE_USER_EDITED_PROFIL:
				{
					const eventUser = datas.eventUser as IEventUser;
					const event = datas.event as IEvent;

					// get actions in triggers and excecute them
					for (const action of trigger.actions) {
						switch (action.actionType) {
							case TypeTriggerAction.SEND_MAIL_TO_STAFF:
								{
									action.emailContent.body = this.parseEmailVarDatas(
										action.emailContent.body,
										event,
										eventUser
									);
									const email = {
										eventId: event.uid,
										mailContent: this.parseEmailVarDatas(
											action.emailContent.html[0],
											event,
											eventUser
										),
										mailSubject: action.emailContent.subject,
										mailTo: action.receiverType === 12 ? eventUser.email : null,
										attachement: action.settings?.enableIcsMail
											? action.emailContent?.attachement
											: null,
										destinationType: 7
									};
									// send email to the staff
									promiseAll.push(this.sendEmail(email));
								}
								break;
							case TypeTriggerAction.SEND_MAIL_TO_USER:
								{
									action.emailContent.body = this.parseEmailVarDatas(
										action.emailContent.body,
										event,
										eventUser
									);
									const email = {
										eventId: event.uid,
										mailContent: this.parseEmailVarDatas(
											action.emailContent.html[0],
											event,
											eventUser
										),
										mailSubject: action.emailContent.subject,
										mailTo: action.receiverType === 12 ? eventUser.email : null,
										attachement: action.settings?.enableIcsMail
											? action.emailContent?.attachement
											: null,
										destinationType:
											action.receiverType === 12
												? EmailDestinationTypeUsers.SPECIFIC
												: action.receiverType
									};

									// send email to the event user
									promiseAll.push(this.sendEmail(email));
								}
								break;

							default:
								console.warn("🚀 ~ Action not implemented yet ...");
								break;
						}
					}
				}
				break;
		}

		Promise.all(promiseAll)
			.then(() => {
				// Needed for the moment, will be removed in the future
				console.log("Automations performed successfully !");
			})
			.catch((e) => {
				console.error("Error while performed automations : ", e);
			});
	}

	/**
	 * sendEmail
	 * @param email
	 * @returns
	 */
	sendEmail(email: any) {
		return firstValueFrom(
			this.http.post(
				PathApi.baseUrl + PathApi.sendEmailTo,
				{
					mailContent: email.mailContent,
					mailSubject: email.mailSubject,
					attachement: email.attachement,
					destinationType: email.destinationType,
					mailTo: email.mailTo,
					eventId: email.eventId
				},
				this.requestOptions
			)
		);
	}

	parseEmailVarDatas(emailContent: string, event: IEvent, eventUser: IEventUser): string {
		return emailContent
			?.replaceAll("{{eventUser_name}}", eventUser.name)
			?.replaceAll("{{eventUser_email}}", eventUser.email)
			?.replaceAll("{{event_name}}", event.title)
			?.replaceAll("{{event_start_date}}", this.SUtility.formatDate(event, eventUser, event.startDate, "full"))
			?.replaceAll("{{event_end_date}}", this.SUtility.formatDate(event, eventUser, event.endDate, "full"));
	}
}
