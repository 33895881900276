@if (event && module && quiz && eventUser) {
<div
	class="background"
	[class.full-w]="componentMode"
	[style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'"
>
	@if (quiz && componentMode && showBackBtn) {
	<ion-row>
		<ion-icon class="icons-25" name="arrow-back" [style.color]="event.styling.titleColor" (click)="goBackToList()">
		</ion-icon>
	</ion-row>
	}

	<!-- Progress Bar -->
	@if ((!currentQuestion || !currentQuestion.showGraphic) && !showLastPageResume) {
	<div class="progress-outer">
		<div class="progress-inner" [style.width]="progress + '%'" [style.background]="event.styling.titleColor"></div>
	</div>
	} @if (quiz.activateTimer && !showLastPageResume && (!currentQuestion || !currentQuestion.showGraphic)) {
	<div id="countdown">
		<div id="countdown-number" [style.color]="event.styling.menuColor">{{ counter }}</div>

		<svg>
			<circle r="18" cx="20" cy="20" [style.stroke]="event.styling.menuColor"></circle>
		</svg>
	</div>
	}

	<div [class.full-w]="componentMode">
		<!-- Last page of results resume -->
		@if (showLastPageResume) {
		<div
			style="
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				padding: 20px;
			"
		>
			@if (quiz.showScoreInApp) {
			<div style="flex-direction: column; justify-content: center; align-items: center">
				<span style="font-size: xx-large; font-weight: bold; margin: 20px 0">{{
					"quizs.score-is" | translate
				}}</span>
				<div
					style="
						width: 160px;
						height: 160px;
						border-radius: 100%;
						background-color: #fcd980;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						margin-left: auto;
						margin-right: auto;
					"
				>
					<div
						style="
							width: 140px;
							height: 140px;
							border-radius: 100%;
							background-color: #f9b200;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
						"
					>
						<span style="color: white; font-size: x-large; font-weight: bold"
							>{{ calcPercentageGoodAnswers() }}%</span
						>
					</div>
				</div>
			</div>
			} @else {

			<div
				style="
					width: 160px;
					height: 160px;
					border-radius: 100%;
					background-color: #fcd980;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
				"
			>
				<div
					style="
						width: 140px;
						height: 140px;
						border-radius: 100%;
						background-color: #f9b200;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
					"
				>
					<!-- simply show yes symbol -->
					<ion-icon name="checkmark" style="color: white; font-size: 100px"></ion-icon>

					<!-- <span style="color: white; font-size: x-large; font-weight: bold"
						>{{ calcPercentageGoodAnswers() }}%</span
					> -->
				</div>
			</div>

			} @if (quiz.showScoreInApp) {
			<span style="font-size: medium; margin: 20px 0">
				{{ "quizs.correct-answered-out-of-1" | translate }}
				<span style="color: #f9b200">{{
					getTotalGoodAnswers().length > 0 ? getTotalGoodAnswers().length : 0
				}}</span>
				{{ "quizs.correct-answered-out-of-2" | translate }}
				<span style="color: #f9b200">{{
					countanswerableQuestions(quiz.questions) > 0 ? countanswerableQuestions(quiz.questions) : 0
				}}</span>
			</span>
			} @else {

			<!-- Just text showed -->
			<span style="font-size: medium; margin: 20px 0">
				{{ "quizs.result_send" | translate }}
			</span>

			}

			<div style="width: 100%; height: 2px; background-color: #e5e5e5; margin: 30px 0 40px 0"></div>

			@if (!quizAnsweredInInit) {
			<div
				style="display: flex; align-items: center; justify-content: center"
				[style.flex-direction]="isMobile || componentMode ? 'column' : 'row'"
			>
				@if (quiz && quiz.canRedoQuiz) {
				<button
					style="border-radius: 28px; padding: 10px 20px"
					[style.margin]="isMobile || componentMode ? '10px 0' : '0 10px'"
					[style.background]="'#f9b200'"
					[style.color]="'white'"
					(click)="redoQuiz(false)"
				>
					{{ "quizs.retry" | translate }}
				</button>
				} @if (quiz && quiz.canRedoQuiz) {
				<button
					style="border-radius: 28px; padding: 10px 20px"
					[style.margin]="isMobile || componentMode ? '10px 0' : '0 10px'"
					[style.background]="'#f9b200'"
					[style.color]="'white'"
					(click)="redoQuiz(true)"
				>
					{{ "quizs.retry-later" | translate }}
				</button>
				} @if (getTotalGoodAnswers().length >= quiz.minGoodAnswersToValidate) {
				<button
					style="border-radius: 28px; padding: 10px 20px"
					[style.margin]="isMobile || componentMode ? '10px 0' : '0 10px'"
					[style.background]="'#f9b200'"
					[style.color]="'white'"
					(click)="presentAlertConfirm()"
				>
					{{ "quizs.validate" | translate }}
				</button>
				}
			</div>
			} @if ( !quizAnsweredInInit && quiz && quiz.canRedoQuiz && getTotalGoodAnswers().length >=
			quiz.minGoodAnswersToValidate ) {
			<span style="color: red; margin-top: 20px; font-size: small; font-weight: bold">{{
				"quizs.warning-validation-definitive" | translate
			}}</span>
			}
		</div>
		} @if (!showLastPageResume) {
		<form [formGroup]="questionsForm" class="margin-">
			<swiper-container #mySlider [modules]="swiperModules">
				@for (question of quiz.questions; track question; let i = $index) {
				<swiper-slide class="quiz-slide">
					@if (currentQuestion && currentQuestion.showGraphic) {
					<app-quiz-result
						[event]="event"
						[module]="module"
						[eventUser]="eventUser"
						[quiz]="quiz"
						[question]="currentQuestion"
					>
					</app-quiz-result>
					} @if (!currentQuestion.showGraphic) {
					<div class="question-container" [style.padding]="componentMode && !isMobile && '25px 0 0 0'">
						@if (quiz.showQuestionsNumber) {
						<span class="question-pagination" [style.color]="event.styling.menuColor">
							<span class="actual-page">{{ i + 1 }}</span>
							<span class="total-page">/ {{ quiz.questions.length }}</span>
						</span>
						} @if (question.image && question.image.url) {
						<div class="container-image-question">
							<div
								class="image-question"
								[ngStyle]="{ 'background-image': 'url(' + question.image.url + ')' }"
							>
								<img src="../../../assets/images/1x1px.png" />
							</div>
						</div>
						}

						<div class="ion-text-wrap question-title" [class.question-without-img]="!question.image.url">
							<ion-label
								class="ion-text-wrap"
								style="width: 100%"
								[style.color]="event.styling.titleColor"
							>
								{{ question.name[currentLanguage] }}
							</ion-label>
						</div>
					</div>
					} @if (question.description && question.description[currentLanguage] &&
					!currentQuestion.showGraphic) {
					<div class="infobooth">
						<small>
							<p [innerHTML]="question.description[currentLanguage] | safeHtml"></p>
						</small>
					</div>
					} @if (question.type === 'oneSelect' && currentQuestion && !currentQuestion.showGraphic) {
					<div class="questions-to-user">
						<ion-list>
							<ion-radio-group [formControlName]="question.uid" [name]="question.uid">
								@for (option of question.answers; track option; let ind = $index) {
								<ion-item
									class="justify-text"
									[lines]="ind !== question.answers.length - 1 ? 'full' : 'none'"
									[class.correct-answer]="
										quiz?.showCorrectAnsInApp && checkAnswered(question, option) && option.correct
									"
									[class.incorrect-answer]="
										quiz?.showCorrectAnsInApp && checkAnswered(question, option) && !option.correct
									"
								>
									@if (quiz.addImageToQuestionResponses && option.image && option.image.url) {
									<ion-avatar slot="start" style="width: 72px; height: 72px">
										<ion-img [src]="option.image.url" style="width: 72px; height: 72px"> </ion-img>
									</ion-avatar>
									}
									<ion-label
										class="option-size ion-text-wrap"
										[style.color]="event.styling.contentTextColor"
									>
										{{ option.answer[currentLanguage] }}
									</ion-label>
									<ion-radio
										legacy="true"
										[disabled]="questionsForm.get(question.uid).disabled"
										[value]="option.uid"
									>
										<!-- <ng-container [style.color]="event.styling.contentTextColor"> -->
										<!-- {{ option.answer[currentLanguage] }} -->
										<!-- </ng-container> -->
									</ion-radio>
								</ion-item>
								}
							</ion-radio-group>
						</ion-list>
						<br />
					</div>
					} @if (question.type === 'multipleSelect' && currentQuestion && !currentQuestion.showGraphic) {
					<div class="questions-to-user">
						<ion-list>
							@for (option of question.answers; track option; let ind = $index) {
							<div>
								<ion-item
									class="justify-text"
									[class.correct-answer]="
										quiz?.showCorrectAnsInApp && checkAnswered(question, option) && option.correct
									"
									[class.incorrect-answer]="
										quiz?.showCorrectAnsInApp && checkAnswered(question, option) && !option.correct
									"
									[lines]="ind !== question.answers.length - 1 ? 'full' : 'none'"
								>
									@if (quiz.addImageToQuestionResponses && option.image && option.image.url) {
									<ion-avatar slot="start" style="width: 72px; height: 72px">
										<ion-img [src]="option.image.url" style="width: 72px; height: 72px"> </ion-img>
									</ion-avatar>
									}
									<ion-label
										class="option-size ion-text-wrap"
										[style.color]="event.styling.contentTextColor"
									>
										{{ option.answer[currentLanguage] }}
									</ion-label>
									<ion-checkbox
										legacy="true"
										[checked]="checkCheckbox(question, option)"
										mode="md"
										[disabled]="!checkCanChangeAnswer(question)"
										(ionChange)="updateCheckboxs(question, option, $event)"
									>
									</ion-checkbox>
								</ion-item>
							</div>
							}
						</ion-list>
						<br />
					</div>
					}

					<div class="align-buttons-quiz">
						@if ( (quiz && quiz.showQtsNavigationBtns) || (quiz && quiz.viewAnswered) ||
						currentQuestion.showGraphic ) {
						<button
							class="btn-prev"
							[style.background]="event.styling.btnBgColor"
							[style.color]="event.styling.btnTextColor"
							[disabled]="indexSlide === 1 || quiz.activateTimer"
							[style.opacity]="indexSlide === 1 || quiz.activateTimer ? 0.7 : 1"
							(click)="slidePrevious()"
						>
							<ion-icon src="assets/icon/ArrowLeft.svg"></ion-icon>
							<div class="curve"></div>
						</button>
						} @if (!quiz.viewAnswered || quiz.changeAnswers || !getResultOfQuestion(currentQuestion) ||
						question.type !== 'plainText') {
						<button
							class="btn-validate-quiz btn-validate-primary"
							[style.cursor]="
								submitting ||
								(!isAnswered(question) && !quiz.changeAnswers) ||
								(getResultOfQuestion(question) && !quiz.changeAnswers)
									? 'not-allowed'
									: 'pointer'
							"
							[style.opacity]="
								submitting ||
								(!isAnswered(question) && !quiz.changeAnswers) ||
								(getResultOfQuestion(question) && !quiz.changeAnswers)
									? 0.7
									: 1
							"
							[style.display]="currentQuestion && currentQuestion.showGraphic && 'none'"
							(click)="sendResult(question)"
							[disabled]="
								submitting ||
								(!isAnswered(question) && !quiz.changeAnswers) ||
								(getResultOfQuestion(question) && !quiz.changeAnswers)
							"
						>
							<span>{{ "buttons.answer" | translate }}</span>
							@if (submitting) {
							<ion-spinner name="lines"></ion-spinner>
							}
						</button>
						} @if (question.type === 'plainText') {
						<button
							class="btn-validate-quiz btn-validate-primary"
							style="cursor: pointer"
							(click)="slideNext()"
						>
							<span>{{ "buttons.next" | translate }}</span>
							@if (submitting) {
							<ion-spinner name="lines"></ion-spinner>
							}
						</button>
						} @if (quiz.showQtsNavigationBtns || quiz.viewAnswered || currentQuestion.showGraphic) {
						<button
							class="btn-next"
							[style.background]="event.styling.btnBgColor"
							[style.color]="event.styling.btnTextColor"
							[style.cursor]="
								(indexSlide === quiz?.questions.length &&
									quiz?.questions[indexSlide] &&
									!quiz?.questions[indexSlide].showResults) ||
								submitting ||
								(!isAnswered(question) && !quiz.changeAnswers)
									? 'not-allowed'
									: 'pointer'
							"
							[style.opacity]="
								(indexSlide === quiz?.questions.length &&
									quiz?.questions[indexSlide] &&
									!quiz?.questions[indexSlide].showResults) ||
								submitting ||
								(!isAnswered(question) && !quiz.changeAnswers) ||
								quiz.activateTimer
									? 0.7
									: 1
							"
							[disabled]="
								(indexSlide === quiz?.questions.length &&
									quiz?.questions[indexSlide] &&
									!quiz?.questions[indexSlide].showResults) ||
								submitting ||
								(!isAnswered(question) && !quiz.changeAnswers) ||
								quiz.activateTimer
							"
							(click)="slideNext()"
						>
							<ion-icon src="assets/icon/ArrowRight.svg"></ion-icon>
							<div class="curve"></div>
						</button>
						}
					</div>
				</swiper-slide>
				}
			</swiper-container>
		</form>
		}
	</div>
</div>
} @if (event && !eventUser) {
<app-login-register-buttons [event]="event"></app-login-register-buttons>
}
