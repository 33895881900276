@if (designType === "card") {
<div
	#elementCard
	class="card-item-container"
	[style.background]="event.styling.cardBgColor ? event.styling.cardBgColor : '#ffffff'"
	[style.min-width]="isMobile ? '45vw' : '250px'"
	[style.width]="isMobile ? '45vw' : '250px'"
	[style.height]="'100%'"
	style="margin: 10px"
>
	@if (targetModule && targetModule.options && targetModule.options.showNewHintChip && checkIsNew()) {
	<ion-badge class="new-banner-container" style="--background: red; --color: white; font-size: x-small">{{
		"buttons.new" | translate
	}}</ion-badge>
	}
	<div class="actions-buttons-card">
		@if (data && eventUser && cardExchangeModule && !checkIsContact() && eventUser.uid !== data.uid) {
		<ion-icon
			style="margin: 0 5px"
			slot="end"
			name="add-contact"
			fill="{{ event.styling.contentTextColor }}"
			[style.color]="event.styling.contentTextColor"
			src="assets/icon/add-user.svg"
			(click)="addToContacts(data, $event)"
		></ion-icon>
		} @if (data && eventUser && favoriteModule && favoriteFolder && favoriteModule.options && eventUser.uid !==
		data.uid && (!favoriteModule.options.hideFavoriteMenuIcon || !favoriteModule.options.hideFavoriteHeaderIcon)) {
		<ion-icon
			slot="end"
			fill="{{ event.styling.contentTextColor }}"
			style="margin: 0 5px"
			[style.color]="event.styling.contentTextColor"
			[src]="checkIsFavorite() ? 'assets/icon/bookmark_remove.svg' : 'assets/icon/bookmarks.svg'"
			(click)="$event.stopPropagation(); changeFavorites(data.uid, !checkIsFavorite())"
		></ion-icon>
		}
	</div>

	<div
		style="
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 10px;
		"
	>
		<ion-avatar slot="start" [ngStyle]="{ padding: !data.photoUrl ? '20px 0' : '' }">
			@if (data.photoUrl) {
			<img class="picture" [src]="data.photoUrl" />
			} @else {
			<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
			}
		</ion-avatar>
	</div>

	<div class="card-item-title" [style.color]="event.styling.titleColor">{{ data?.name }}</div>

	<div class="card-item-fields">
		@for (customField of getTags(data); track customField.uid; let count = $count) {
		<div class="margin-padding-0">
			<div
				class="ellipsis-100 card-item-field"
				style="text-align: center"
				[style.color]="event.styling.contentTextColor"
				[style.fontSize]="count === 3 ? 'O.8rem' : '0.9rem'"
			>
				{{
						customField.field.text?.trim() ||
						customField.field.multiLanguageText?.[currentLanguage]?.trim()
						||
						customField.field.numeric?.toString()
				}}
			</div>
		</div>
		}
	</div>
</div>
} @else { @if (groupedLetters && groupedLetters[data.uid]) {
<div class="header-divider" [hidden]="!targetModule.options.showLetters" style="height: 50px">
	<div class="letter" [style.color]="event.styling.titleColor">
		{{ groupedLetters[data.uid] }}
	</div>
	<div class="bar" [style.background-color]="event.styling.titleColor"></div>
</div>
} @if (data) {
<ion-item
	[style.--background]="event.styling.cardBgColor ? event.styling.cardBgColor : '#ffffff'"
	lines="none"
	class="item-list"
>
	@if (targetModule && targetModule.options && targetModule.options.showNewHintChip && checkIsNew()) {
	<ion-badge
		slot="start"
		class="new-banner-container"
		style="--background: red; --color: white; font-size: x-small; z-index: 999"
		>{{ "buttons.new" | translate }}</ion-badge
	>
	}
	<ion-avatar slot="start" [ngStyle]="{ padding: !data.photoUrl ? '20px 0' : '' }">
		@if (data.photoUrl) {
		<img class="picture" [src]="data.photoUrl" />
		} @else {
		<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
		}
	</ion-avatar>

	<ion-label style="height: auto">
		<h2 class="line-clamp-2" [style.color]="event.styling.titleColor">
			{{ data?.name }}
		</h2>
		@for (customField of getTags(data); track customField.uid; let count = $count) {
		<div class="margin-padding-0">
			<h5
				class="ellipsis-100 custom-fields-event-users-module-item-tags"
				[style.color]="event.styling.contentTextColor"
				[style.fontSize]="count === 3 ? 'O.8rem' : '0.9rem'"
			>
				{{
					customField.field.text?.trim() ||
					customField.field.multiLanguageText?.[currentLanguage]?.trim() ||
					customField.field.numeric?.toString()
				}}
			</h5>
		</div>
		}
	</ion-label>

	@if (data && eventUser && cardExchangeModule && !checkIsContact() && eventUser.uid !== data.uid) {
	<ion-icon
		slot="end"
		name="add-contact"
		fill="{{ event.styling.contentTextColor }}"
		[style.color]="event.styling.contentTextColor"
		src="assets/icon/add-user.svg"
		(click)="addToContacts(data, $event)"
	></ion-icon>
	} @if (data && eventUser && favoriteModule && favoriteFolder && favoriteModule.options && eventUser.uid !== data.uid
	&& (!favoriteModule.options.hideFavoriteMenuIcon || !favoriteModule.options.hideFavoriteHeaderIcon)) {
	<ion-icon
		slot="end"
		fill="{{ event.styling.contentTextColor }}"
		[style.color]="event.styling.contentTextColor"
		[src]="checkIsFavorite() ? 'assets/icon/bookmark_remove.svg' : 'assets/icon/bookmarks.svg'"
		(click)="$event.stopPropagation(); changeFavorites(data.uid, !checkIsFavorite())"
	></ion-icon>
	}
</ion-item>
} }
