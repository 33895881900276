import { Injectable } from "@angular/core";
import * as _ from "lodash-es";
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, from, of } from "rxjs";
import { debounceTime, map, skipWhile, switchMap, take } from "rxjs/operators";
import { ISheet } from "../interfaces";
import { FirestoreService } from "./firestore.service";
import { ISearchFilter } from "../interfaces/search.interfaces";
import { filterSearch } from "../helpers-functions/filter-search";
import { Store } from "@ngrx/store";
import { getSpecificModule } from "../selectors/modules.selectors";
import { QueryConstraint, where } from "@angular/fire/firestore";
import { getCurrentEventUser } from "../selectors/auth.selectors";
import { getCurrentEvent } from "../selectors/events.selectors";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { getAllSheets, getSheetsForModule } from "../selectors/sheets.selectors";
import { GetAllSheets } from "../actions/sheets.actions";
import { MongodbService } from "./mongodb.service";

@Injectable({
	providedIn: "root"
})
export class SheetsService {
	subscriptionsStateReminder: any = {
		all: false
	};
	subscriptionsState: { moduleId: string; subscription: Subscription }[] = [];
	checkInitSubject: Subject<{ moduleId: string; type: "all" | "module" }> = new BehaviorSubject(
		this.subscriptionsStateReminder
	);

	constructor(private SFirestore: FirestoreService, private SMongo: MongodbService, private store: Store) {}

	initSubscriptionOfModule(eventId: string, moduleId: string) {
		// Check if subscription already exist
		const checkSub = this.subscriptionsState.find((sub) => sub.moduleId === moduleId);

		if (!checkSub || (checkSub && checkSub.subscription && checkSub.subscription.closed)) {
			this.subscriptionsState = this.subscriptionsState.filter((sub) => sub.moduleId !== moduleId);
			this.initSubscription(eventId, moduleId);
		} else {
			this.store.dispatch(InitSpecificEventDatasPart({ part: "initAttendees", payload: true }));
			this.store.dispatch(InitSpecificEventDatasPart({ part: "initSpeakers", payload: true }));
		}
	}

	initSubscription(eventId: string, moduleId: string) {
		const subscription = this.SFirestore.valueChangesDocuments(`events/${eventId}/modules/${moduleId}/sheets`, [])
			.pipe(
				switchMap((sheets: ISheet[]) => {
					return this.store.select(getAllSheets).pipe(
						take(1),
						switchMap((storedSheets) => {
							return of(storedSheets.filter((sheet) => sheet.moduleId !== moduleId).concat(sheets));
						})
					);
				})
			)
			.subscribe((sheets: ISheet[]) => {
				this.store.dispatch(GetAllSheets({ payload: sheets, eventId: eventId }));
				this.subscriptionsStateReminder[moduleId] = true;
				this.checkInitSubject.next(this.subscriptionsStateReminder);
			});
		this.subscriptionsState.push({
			moduleId: moduleId,
			subscription: subscription
		});
		this.store.dispatch(InitSpecificEventDatasPart({ part: "initSheets", payload: true }));
	}

	unsubscribeAll() {
		this.unsubscribeSheetsState();
	}

	unsubscribeSheetsState() {
		this.subscriptionsState.forEach((subscription) => {
			if (subscription && subscription.subscription && !subscription.subscription.closed) {
				subscription.subscription.unsubscribe();
			}
		});
		this.subscriptionsState = [];
		this.subscriptionsStateReminder = { all: false };
		this.store.dispatch(InitSpecificEventDatasPart({ part: "initSheets", payload: false }));
	}

	/**
	 * Get datas of a module in an event
	 * @param eventId
	 * @returns
	 */
	getDatasOfModuleForEvent(moduleId: string, filters: ISearchFilter, type: "aggregation" | "normal") {
		return combineLatest([
			this.store.select(getCurrentEvent),
			this.store.select(getCurrentEventUser),
			this.store.select(getSpecificModule(moduleId))
		]).pipe(
			take(1),
			switchMap((results) => {
				const event = results[0];
				const currentEventUser = results[1];
				const module = results[2];
				if (event && event.limitations && event.limitations.usersLimit <= 1000) {
					// If event has less or equal than 1000 users
					return this.checkInitSubject.pipe(
						take(1),
						switchMap((initState) => {
							if (initState && initState[moduleId]) {
								return this.store
									.select(
										getSheetsForModule({
											order: "asc",
											moduleId: moduleId
										})
									)
									.pipe(
										switchMap((datas) => {
											const datasFiltered = filterSearch(
												filters,
												datas.filter(
													(data) =>
														module &&
														data.moduleId === module.uid &&
														module.options &&
														(!module.options.viewOnlyGroupsContent ||
															(module.options.viewOnlyGroupsContent &&
																currentEventUser &&
																data.groups.some((grpId) =>
																	currentEventUser.groups.includes(grpId)
																)))
												)
											);
											return of({
												docs: datasFiltered.datas,
												totalDocuments: datasFiltered.totalItems
											});
										})
									);
							} else {
								this.initSubscriptionOfModule(event.uid, moduleId);
								return this.checkInitSubject.pipe(
									skipWhile((init) => !init || !init[moduleId]),
									take(1),
									switchMap(() => {
										return this.store
											.select(
												getSheetsForModule({
													order: "asc",
													moduleId: moduleId
												})
											)
											.pipe(
												debounceTime(200),
												switchMap((datas) => {
													const datasFiltered = filterSearch(
														filters,
														datas.filter(
															(data) =>
																module &&
																data.moduleId === module.uid &&
																module.options &&
																(!module.options.viewOnlyGroupsContent ||
																	(module.options.viewOnlyGroupsContent &&
																		currentEventUser &&
																		data.groups.some((grpId) =>
																			currentEventUser.groups.includes(grpId)
																		)))
														)
													);
													return of({
														docs: datasFiltered.datas,
														totalDocuments: datasFiltered.totalItems
													});
												})
											);
									})
								);
							}
						})
					);
				} else {
					// If event has more than 1000 sheets
					return this.SMongo.queryDatasMongodb(
						this.SMongo.convertSearchFilterToQueryOrAggregationMongo(filters, type, "eventUsersSearch"),
						"sheets",
						{},
						type
					);
				}
			})
		);
	}

	/**
	 * Getting one specific sheet
	 * @param eventId
	 * @param uid
	 * @returns
	 */
	getSpecificSheet(eventId: string, uid: string) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup("sheets", [
				where("eventId", "==", eventId),
				where("uid", "==", uid)
			])
		).pipe(
			map((snapshot) => snapshot.docs.map((doc) => doc.data() as ISheet)),
			switchMap((sheets) => {
				return of(sheets.length > 0 ? sheets[0] : null);
			})
		);
	}

	/**
	 * TODO REWORK THIS FUNCTION
	 */
	/**
	 * Get specific sheets of an event
	 * @param eventId
	 * @param sheetsIds
	 * @returns
	 */
	getSpecificSheetsForEvent(eventId: string, sheetsIds: string[]) {
		if (sheetsIds.length === 0) {
			return of([]);
		}
		const allIds = _.chunk(sheetsIds, 10);
		const obsArray: Observable<ISheet[]>[] = [];
		const queryConstraints: QueryConstraint[] = [where("eventId", "==", eventId)];

		allIds.forEach((ids) => {
			obsArray.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(
						`sheets`,
						queryConstraints.concat([where("uid", "in", ids)])
					)
				).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as ISheet)))
			);
		});
		return combineLatest(obsArray).pipe(
			switchMap((results) => {
				const sheets: ISheet[] = _.flatten(results);
				return of(sheets);
			})
		);
	}
}
