import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subscription, take, combineLatest, skipWhile, firstValueFrom } from "rxjs";
import { ResetHeaderState, GetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import { TypeUser } from "src/app/shared/enums/type-user";
import {
	IEvent,
	IEventUser,
	IUser,
	IModule,
	IFullCustomField,
	IDocument,
	ICustomField,
	IModuleCustomField,
	IGroup
} from "src/app/shared/interfaces";
import {
	ICardExchangeField,
	ICardExchangeFieldResultData,
	ICardExchangeForm
} from "src/app/shared/interfaces/card-exchange.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentUser, getMyEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getModulesCustomsFieldsOfModule,
	getGroups
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectUrl, selectRouteNestedParams } from "src/app/shared/selectors/router.selectors";
import {
	AuthService,
	EventUsersService,
	CardExchangeService,
	CustomFieldsService,
	DocumentsService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { CardExchangeFormComponent } from "../../components/card-exchange-form/card-exchange-form.component";

import { Browser } from "@capacitor/browser";

@Component({
	selector: "app-card-exchange-profile",
	templateUrl: "./card-exchange-profile.component.html",
	styleUrls: ["./card-exchange-profile.component.scss"]
})
export class CardExchangeProfileComponent implements OnInit, OnDestroy {
	languageSubscription: Subscription;
	necessaryDatasSub: Subscription;
	eventUserSub: Subscription;
	userDataSub: Subscription;
	cardsExchangeDatasSub: Subscription;
	eventUserModuleSub: Subscription;

	eventId: string;
	event: IEvent;
	eventUserProfileId: string;
	eventUserProfile: IEventUser;
	userDataProfile: IUser;
	moduleId: string;
	module: IModule;
	user: IUser;
	myEventUser: IEventUser;
	computedCustomFields: IFullCustomField[];

	typeModule = TypeModule;
	typeUser = TypeUser;
	userSelectedDoc: IDocument;
	loaderToast: HTMLIonLoadingElement;

	// card exchange properties

	cardExchangeForm: ICardExchangeForm;

	cardExchangeModule: IModule;
	userFieldDatas: ICardExchangeFieldResultData[] = [];
	fields: ICardExchangeField[] = [];
	form: UntypedFormGroup = new UntypedFormGroup({});

	isProfilMode: boolean = false;

	baseCF: ICustomField[];
	moduleCF: IModuleCustomField[];
	currentLanguage: string = environment.platform.defaultLanguage;
	isMobile: boolean = window.innerWidth < 768;
	groups: IGroup[] = [];
	loader: boolean = false;
	showMore: boolean = false;
	eventUserModule: IModule;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		public STranslate: TranslateService,
		private store: Store,
		private alertController: AlertController,
		private SAuth: AuthService,
		private SEventUsers: EventUsersService,
		private modalCtrl: ModalController,
		private snackbar: MatSnackBar,
		private SCardExchange: CardExchangeService,
		private router: Router,
		private snackBar: MatSnackBar,
		private navCtrl: NavController,
		public SDocuments: DocumentsService,
		public SCustomFields: CustomFieldsService
	) {
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
	}

	ngOnInit(): void {
		if (this.router.getCurrentNavigation().extras.state) {
			this.cardExchangeForm = this.router.getCurrentNavigation().extras.state.form;
			this.cardExchangeModule = this.router.getCurrentNavigation().extras.state.module;
		} else {
			this.cardExchangeForm = null;
			this.cardExchangeModule = null;
		}

		this.fields = this.cardExchangeForm
			? this.cardExchangeForm.fields?.map((field, index) => {
					return {
						...field,
						order: index
					};
			  })
			: [];
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.eventUserProfileId = params.userId;
						this.getNecessaryDatas();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		[
			this.languageSubscription,
			this.necessaryDatasSub,
			this.eventUserSub,
			this.userDataSub,
			this.cardsExchangeDatasSub,
			this.eventUserModuleSub
		].forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});
	}

	ngOnDestroy() {
		[
			this.languageSubscription,
			this.necessaryDatasSub,
			this.eventUserSub,
			this.userDataSub,
			this.cardsExchangeDatasSub,
			this.eventUserModuleSub
		].forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});
	}

	/**
	 * Getting all necessaries datas
	 */

	getNecessaryDatas() {
		this.necessaryDatasSub = combineLatest([
			this.store.select(getCurrentEvent),
			this.store.select(getCurrentUser).pipe(take(1)),
			this.store.select(getSpecificModule(this.moduleId)),
			this.store.select(getMyEventUser),
			this.store.select(getBaseCustomFields),
			this.store.select(getModulesCustomsFieldsOfModule(this.moduleId)),
			this.store.select(getGroups)
		]).subscribe((results) => {
			this.event = results[0];
			this.user = results[1];
			this.module = results[2];

			if (
				(results[3] &&
					this.myEventUser &&
					!this.SEventUsers.compareEventUsersWithoutConnectedProp(results[3], this.myEventUser)) ||
				!this.myEventUser
			) {
				this.myEventUser = results[3];
				if (!this.myEventUser.contactIds) {
					this.myEventUser.contactIds = [];
				}
			}

			if (this.event) {
				this.getEventUserProfile();
			}

			this.baseCF = results[4];
			this.moduleCF = results[5];
			this.groups = results[6];
		});
	}

	/**
	 * Returns the color of the background, according to the allowGradient boolean.
	 */
	backgroundColor(allowGradient: boolean) {
		if (allowGradient) {
			return (
				"linear-gradient(to right, " +
				this.event.styling.menuColor +
				", " +
				this.event.styling.menuColorGradient +
				")"
			);
		} else {
			return this.event.styling.menuColor;
		}
	}

	/**
	 * Get event user profile
	 */

	getEventUserProfile() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}
		this.eventUserSub = combineLatest([
			this.SEventUsers.getSpecificEventUser(this.event.uid, this.eventUserProfileId),
			this.SEventUsers.getSpecificEventUserUpdatedSettings(this.event.uid, this.moduleId, this.eventUserProfileId)
		])
			.pipe(skipWhile(() => !this.module))
			.subscribe({
				next: (results) => {
					const tmpEventUserProfile: IEventUser = results[0];
					tmpEventUserProfile.updatedSettings = results[1];
					this.eventUserProfile = _.cloneDeep(tmpEventUserProfile);

					this.isProfilMode =
						this.myEventUser && this.eventUserProfile && this.myEventUser.uid === this.eventUserProfile.uid;

					if (this.eventUserProfile) {
						this.store.dispatch(
							GetHeaderState({
								payload: {
									item: this.eventUserProfile,
									module: this.module,
									title: {
										ArAR: this.eventUserProfile.name,
										DeDE: this.eventUserProfile.name,
										EnUS: this.eventUserProfile.name,
										EsES: this.eventUserProfile.name,
										FrFR: this.eventUserProfile.name,
										PtBR: this.eventUserProfile.name
									},
									type: TypeHeader.PROFILE
								}
							})
						);
						if (this.cardExchangeForm) {
							this.getEventUserProfilDatas();
						}
						this.getEventUserModule();

						// Custom fields datas
						this.computedCustomFields = [];

						if (this.moduleCF.length > 0) {
							this.moduleCF.forEach((customField) => {
								const baseCustomFieldCorresponding = this.baseCF.find(
									(custField) => custField.uid === customField.uid
								);

								if (baseCustomFieldCorresponding) {
									const data = this.eventUserProfile.customFields.find(
										(data) => data?.uid === customField.uid
									);
									this.computedCustomFields.push({
										baseSettings: baseCustomFieldCorresponding,
										moduleSettings: customField,
										fieldDatas: data
											? data
											: {
													uid: "",
													field: {}
											  }
									});
								}
							});
						}
					}
				}
			});

		if (this.userDataSub && !this.userDataSub.closed) {
			this.userDataSub.unsubscribe();
		}
		this.userDataSub = this.SAuth.getUserData(this.eventUserProfileId).subscribe((userDataProfile) => {
			this.userDataProfile = userDataProfile;
		});
	}

	getEventUserModule() {
		if (this.eventUserModuleSub && !this.eventUserModuleSub.closed) {
			this.eventUserModuleSub.unsubscribe();
		}

		this.eventUserModuleSub = this.store.select(getSpecificModule(this.eventUserProfile.moduleId)).subscribe({
			next: (module) => {
				this.eventUserModule = module;
			},
			error: (error) => {
				console.error("Err: ", error);
			}
		});
	}

	/**
	 * getEventUserProfilDatas
	 */

	getEventUserProfilDatas() {
		if (this.cardsExchangeDatasSub && !this.cardsExchangeDatasSub.closed) {
			this.cardsExchangeDatasSub.unsubscribe();
		}

		this.cardsExchangeDatasSub = this.SCardExchange.getSpecificContactFormDatasOfUser(
			this.eventId,
			this.myEventUser.moduleId,
			this.myEventUser.uid,
			this.eventUserProfile.uid
		).subscribe({
			next: (datas) => {
				this.userFieldDatas = datas
					.map((data) => {
						if (data.formId === this.cardExchangeForm.uid) {
							const field = this.fields.find((f) => f.uid === data.fieldId);
							if (field) {
								if (field.type === "evaluation") {
									const evaluation: number[] = [];
									for (let i = 1; i <= data.evaluation; i++) {
										evaluation.push(i);
									}
									return {
										...data,
										fieldName: field.name,
										fieldType: field.type,
										evaluationArray: evaluation,
										order: field?.["order"]
									};
								} else {
									return {
										...data,
										fieldName: field.name,
										fieldType: field.type,
										order: field?.["order"]
									};
								}
							}
						} else {
							return null;
						}
					})
					.filter((data) => data !== null)
					.sort((a, b) => a.order - b.order);
			},

			error: (error) => {
				console.error("Err: ", error);
			}
		});
	}

	/**
	 * alertLoginRegister
	 * @param alertTitle
	 * @param message
	 */
	async alertLoginRegister(alertTitle: string, message?: string, isLoggedIn?: boolean) {
		const alert = await this.alertController.create({
			header: alertTitle,
			message: message ? message : this.STranslate.instant("texts.need_login_to_use"),
			buttons: !isLoggedIn
				? [
						{
							text: this.STranslate.instant("buttons.authenticate"),
							handler: async () => this.openLogin()
						},
						{
							text: this.STranslate.instant("buttons.cancel"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ]
				: [
						{
							text: this.STranslate.instant("buttons.ok"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ],
			backdropDismiss: true,
			cssClass: "center-alert-items"
		});

		alert.present();
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openLogin() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.loginModal,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openRegister) {
				this.openRegister();
			}

			// else if (data && data.openFillProfil) {
			// 	this.openFillProfil();
			// }
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegister() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openLogin) {
				this.openLogin();
			}

			<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
				((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
					"hidden");

			// else if (data && data.openFillProfil) {
			// 	this.openFillProfil();
			// }
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * getCorrespondingGroups
	 * @param groupId
	 * @returns
	 */
	getCorrespondingGroups(groupIds: string[]) {
		return this.groups.filter((gr) => groupIds.includes(gr.uid));
	}

	/**
	 * getAnswerById
	 * @param fieldId
	 * @param answerId
	 * @returns
	 */
	getAnswerById(fieldId: string, answerId: string | string[]) {
		const field = this.fields.find((f) => f.uid === fieldId);
		if (field && answerId) {
			if (field.type === "oneSelect") {
				return field.answers.find((ans) => ans.uid === answerId)?.answer?.[this.currentLanguage];
			} else if (field.type === "multipleSelect") {
				return field.answers
					?.filter((ans) => answerId.includes(ans.uid))
					?.map((data) => data.answer[this.currentLanguage])
					?.join(", ");
			} else {
				return null;
			}
		}
	}

	/**
	 * openForm
	 * @returns
	 */

	async openForm() {
		try {
			if (!this.cardExchangeForm) {
				this.cardExchangeForm = await firstValueFrom(
					this.SCardExchange.getSpecificFormByGroupId(
						this.eventId,
						this.cardExchangeModule.uid,
						this.myEventUser.groups
					)
				);
			}
			if (!this.cardExchangeModule) {
				this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
					duration: 3000,
					panelClass: ["snackbar-error"]
				});
				return;
			}

			const modal = await this.modalCtrl.create({
				component: CardExchangeFormComponent,
				componentProps: {
					event: this.event,
					module: this.cardExchangeModule,
					myEventUser: this.myEventUser,
					eventUserContact: this.eventUserProfile,
					eventUserContactId: this.eventUserProfile.uid,
					contactDatas: this.userFieldDatas
				},
				cssClass: this.isMobile ? "card-exhange-form-modal-css-mobile" : "card-exchange-form-modal-css"
			});

			await modal.present();

			await modal.onWillDismiss();
			if (this.cardExchangeForm) {
				this.getEventUserProfilDatas();
			}
		} catch (error) {
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * removeContact
	 * @param contactId
	 */
	async removeContact() {
		try {
			const alert = await this.alertController.create({
				header: this.STranslate.instant("alerts.delete_contact"),
				message: this.STranslate.instant("alerts.delte_contact_confirm"),
				buttons: [
					{
						text: this.STranslate.instant("buttons.cancel"),
						role: "cancel"
					},
					{
						text: this.STranslate.instant("buttons.delete"),
						handler: async () => {
							this.loader = true;
							await this.SCardExchange.removeCardExchangeResultDatas(
								this.eventId,
								this.myEventUser.moduleId,
								this.myEventUser.uid,
								this.eventUserProfile.uid
							);

							await this.SEventUsers.updateEventUser(this.eventId, this.myEventUser.moduleId, {
								...this.myEventUser,
								contactIds: this.myEventUser.contactIds.filter(
									(contactId) => contactId !== this.eventUserProfile.uid
								)
							});

							this.loader = false;

							this.snackBar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
								duration: 3000,
								panelClass: ["snackbar-success"]
							});
							this.goBack();
						}
					}
				]
			});

			await alert.present();
		} catch (error) {
			//
		}
	}

	isEmptyField(field: ICardExchangeFieldResultData) {
		switch (field.type) {
			case "evaluation":
				return field.evaluation === 0;
			case "oneSelect":
				return field.oneSelect.answerId === "";
			case "multipleSelect":
				return field.multipleSelect.answersIds.length === 0;
			case "date":
				return field.date === "";
			case "dissertative":
				return field.dissertative === "";
			case "document":
				return field.document.url === "";
			default:
				return true;
		}
	}

	/**
	 * goBack
	 */
	goBack() {
		this.navCtrl.navigateBack(`/event/${this.eventId}/card-exchange/${this.cardExchangeModule.uid}`);
	}

	trackById(index: number, item: ICardExchangeFieldResultData) {
		return item.uid;
	}

	/**
	 * openDocument
	 * @param document
	 */
	async openDocument(document: any) {
		try {
			if (document.docType.includes("office")) {
				// download the document
				const base64Data = await this.SDocuments.urlToBase64(document.url);
				await this.SDocuments.downloadFile(document.name, base64Data);
			} else {
				await Browser.open({
					url: document.url,
					windowName: document.name,
					presentationStyle: "popover"
				});
			}

			// type === "module" &&
			// 	this.SAnalytics.customFieldDocumentOpended(this.event.uid, this.eventUserProfile, document);
		} catch (error) {
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}
}
