<div class="settings">
	<ng-container *ngIf="chat">
		<ion-list lines="none">
			<ng-container *ngIf="eventUser">
				<ion-item-group>
					<ion-item-divider>
						<ion-label>
							{{ "chat.silent_mode" | translate }}
						</ion-label>
					</ion-item-divider>

					<ion-item>
						<ion-toggle
							[checked]="checkAllowNotifsUserChat()"
							(ionChange)="changeAllowNotifForUser($event)"
						>
						</ion-toggle>

						<!-- <ion-label text-wrap>
                            {{ "chat.toggle" | translate }}
                        </ion-label> -->
					</ion-item>
				</ion-item-group>
			</ng-container>

			<!-- <ion-item-group>
                <ion-item-divider>
                    <ion-label>
                        {{ "chat.group_info" | translate }}
                    </ion-label>
                </ion-item-divider>

                <ion-item [routerLink]="url" (click)="onDismiss()" detail>
                    <ion-label>
                        {{ "chat.display_group_info" | translate }}
                    </ion-label>
                </ion-item>
            </ion-item-group> -->
		</ion-list>
	</ng-container>
</div>
