<div *ngIf="event && eventUser" class="background">
	<ion-list>
		<ion-item
			lines="none"
			*ngIf="moduleTreasureHunts"
			[style.border-bottom-color]="event.styling.titleColor + '1c'"
			(click)="navigateTo(moduleTreasureHunts)"
			class="cursor-pointer"
		>
			<ion-avatar
				item-start
				[style.background]="event.styling.menuColor"
				[style.color]="event.styling.menuTextColor"
			>
				<ion-icon src="assets/icon/Star.svg"></ion-icon>
			</ion-avatar>
			<ion-label class="spaced ion-text-wrap">
				<h2 class="module-title" [style.color]="event.styling.titleColor">
					{{ moduleTreasureHunts.name[currentLanguage] }}
				</h2>

				<p [style.color]="event.styling.contentTextColor">
					{{ "gamification.treasure_hunt_description" | translate }}
				</p>
			</ion-label>
		</ion-item>
	</ion-list>
</div>
<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
