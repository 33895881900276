/* eslint-disable max-len */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { TypeModule } from "src/app/shared/enums/type-module";
import { ICustomFieldData, IEvent, IEventUser, IFullCustomField, IModule, ISheet } from "src/app/shared/interfaces";
import { ICardExchangeForm } from "src/app/shared/interfaces/card-exchange.interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { EventUsersService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { CardExchangeFormComponent } from "../../components/card-exchange-form/card-exchange-form.component";

@Component({
	selector: "app-sheet-item",
	templateUrl: "./sheet-item.component.html",
	styleUrls: ["./sheet-item.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SheetItemComponent {
	@Input() designType: "card" | "row" = "row";

	@Input() rootElement: Element;
	@Input() event: IEvent;
	@Input() mainModule: IModule;
	@Input() targetModule: IModule;
	@Input() customFields: IFullCustomField[] = [];
	@Input() groupedLetters: any = null;
	@Input() eventUser: IEventUser;
	@Input() data: ISheet;
	@Input() favoriteFolder: IFavoriteFolder;
	@Input() favoriteModule: IModule;
	@Input({ required: false }) cardExchangeForm: ICardExchangeForm;
	@Input({ required: false }) cardExchangeModule: IModule;
	@Input() currentLanguage: string = environment.platform.defaultLanguage;

	connectedStatusGetted: boolean = false;

	updating: boolean = false;

	isMobile = window.innerWidth < 768 ? true : false;

	typesModules = TypeModule;

	// card exchange
	eventUserStates: { [key: string]: boolean } = {};

	constructor(
		private cdr: ChangeDetectorRef,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private SEventUsers: EventUsersService,
		private modalCtrl: ModalController,
		public SUtility: UtilityService
	) {}

	/**
	 * getTags
	 * @param data
	 * @returns
	 */
	getTags(data: ISheet): ICustomFieldData[] {
		return data.customFields
			.filter((dataCustomFields) => {
				return (
					dataCustomFields &&
					this.customFields.find(
						(computedCustomField) =>
							dataCustomFields &&
							computedCustomField &&
							computedCustomField.baseSettings &&
							computedCustomField.baseSettings.uid === dataCustomFields.uid &&
							computedCustomField.moduleSettings.canBeTag
					) &&
					!this.isModuleFieldsVisibilityHidden(data, dataCustomFields.uid, "customFields") &&
					((dataCustomFields.field.text &&
						dataCustomFields.field.text.trim() &&
						dataCustomFields.field.text?.trim() !== "") ||
						(dataCustomFields.field.multiLanguageText &&
							dataCustomFields.field.multiLanguageText[this.currentLanguage]?.trim() &&
							dataCustomFields.field.multiLanguageText[this.currentLanguage]?.trim() !== "") ||
						(dataCustomFields.field.numeric && dataCustomFields.field.numeric !== -1))
				);
			})
			.sort((a, b) =>
				this.getCustomFieldOrder(a) > this.getCustomFieldOrder(b)
					? 1
					: this.getCustomFieldOrder(a) < this.getCustomFieldOrder(b)
					? -1
					: 0
			);
	}

	/**
	 * isModuleFieldsVisibilityHidden
	 * @param key
	 */
	isModuleFieldsVisibilityHidden(data: ISheet, key: string, type: "baseFields" | "customFields") {
		if (type === "baseFields") {
			return this.targetModule.options.requiredFields?.[key]?.hiding?.default ?? false;
		} else {
			const correspondingCus = this.customFields.find((cus) => cus.baseSettings.uid === key);

			if (correspondingCus) {
				return correspondingCus.moduleSettings.hiding?.default ?? false;
			}
		}
	}

	/**
	 * getCustomFieldOrder
	 */
	getCustomFieldOrder(customField: ICustomFieldData): number {
		return this.customFields.find((cus) => cus.baseSettings.uid === customField.uid)
			? this.customFields.find((cus) => cus.baseSettings.uid === customField.uid).moduleSettings.order
			: -1;
	}

	checkIsFavorite() {
		return this.eventUser.favorites?.includes(this.data.uid);
	}

	async changeFavorites(eventUserId: string, type: boolean) {
		try {
			if (!this.updating) {
				this.updating = true;
				if (this.eventUser) {
					if (this.eventUser.favorites) {
						if (type && !this.eventUser.favorites.includes(eventUserId)) {
							this.eventUser.favorites.push(eventUserId);
						} else {
							this.eventUser.favorites = this.eventUser.favorites.filter((fav) => fav !== eventUserId);
						}
					} else {
						this.eventUser["favorites"] = [eventUserId];
					}
					await this.SEventUsers.updatePartOfEventUser(
						this.eventUser.eventId,
						this.eventUser.moduleId,
						this.eventUser.uid,
						{
							favorites: this.eventUser.favorites
						}
					);
					this.snackbar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
						duration: 3000,
						panelClass: ["snackbar-success"]
					});
					this.updating = false;
					this.cdr.markForCheck();
				}
			}
		} catch (error) {
			// Snackbar error
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
			this.updating = false;
			this.cdr.markForCheck();
		}
	}

	checkIsNew() {
		return (
			DateTime.fromISO(this.data.startVisibilityTime) >
			DateTime.local().minus({
				minute:
					this.mainModule.options && this.mainModule.options.newHintChipDelay
						? this.mainModule.options.newHintChipDelay
						: 1440
			})
		);
	}

	checkIsContact() {
		return this.eventUser.contactIds.includes(this.data.uid);
	}

	/**
	 * addToContacts
	 * @param dataItem
	 * @param event
	 */
	async addToContacts(dataItem: ISheet, event) {
		try {
			event.stopPropagation();
			// open card exchange form component
			if (this.cardExchangeForm) {
				// add contact with form
				const modal = await this.modalCtrl.create({
					component: CardExchangeFormComponent,
					componentProps: {
						event: this.event,
						module: this.cardExchangeModule,
						myEventUser: this.eventUser,
						eventUserContact: dataItem,
						eventUserContactId: dataItem.uid
					},
					cssClass: this.isMobile ? "card-exhange-form-modal-css-mobile" : "card-exchange-form-modal-css"
				});

				await modal.present();

				const { data } = await modal.onWillDismiss();

				if (data && data?.success) {
					await this.storeContactInEventUserDoc(data.uid);
					this.SUtility.presentToast(
						this.STranslate.instant("snackbar.update_successfull"),
						3000,
						"bottom",
						"success"
					);
				}
			} else {
				await this.storeContactInEventUserDoc(dataItem.uid);
				this.SUtility.presentToast(
					this.STranslate.instant("snackbar.update_successfull"),
					3000,
					"bottom",
					"success"
				);
			}
		} catch (error) {
			console.error("🚀 ~ addToContacts ~ error:", error);
			this.eventUserStates[dataItem.uid] = false;
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * storeContactInEventUserDoc
	 * @param contactId
	 */
	async storeContactInEventUserDoc(contactId: string) {
		try {
			this.eventUser?.contactIds
				? this.eventUser?.contactIds.push(contactId)
				: (this.eventUser.contactIds = [contactId]);
			this.eventUserStates[contactId] = true;
			await this.SEventUsers.updatePartOfEventUser(this.event.uid, this.eventUser.moduleId, this.eventUser.uid, {
				contactIds: this.eventUser.contactIds
			});
		} catch (error) {
			console.error("🚀 ~ storeContactInEventUserDoc ~ error:", error);
		}
	}
}
