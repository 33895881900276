import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, combineLatest, of } from "rxjs";
import { map, skipWhile, switchMap, take } from "rxjs/operators";
import { selectRouteNestedParams } from "../selectors/router.selectors";
import { getInitContainers, getInitUser } from "../selectors/utility.selectors";
import { FirestoreService } from "../services";
import { IEvent } from "../interfaces";

@Injectable()
export class EventBlockedGuard {
	constructor(
		private store: Store,
		private snackbar: MatSnackBar,
		private SFirestore: FirestoreService,
		private STranslate: TranslateService
	) {}
	canActivate(_route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
		return combineLatest([
			this.store.select(getInitUser).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitContainers).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(selectRouteNestedParams)
		]).pipe(
			take(1),
			switchMap((results) => {
				const params = results[2];
				return this.SFirestore.getDocumentObs(`events/${params["eventId"]}`).pipe(
					take(1),
					map((snapshot) => snapshot.data() as IEvent)
				);
			}),
			switchMap((event) => {
				if (event && !event.blocked) {
					return of(true);
				} else {
					this.snackbar.open(this.STranslate.instant("alerts.blocked-event-info"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					return of(false);
				}
			})
		);
	}
}
