<div *ngIf="event && module && eventUser && wordsCloud" class="background">
	<div class="f-row-c-c" *ngIf="wordsCloud.type === 0">
		<input
			style="width: 300px; margin-right: 30px"
			type="text"
			[(ngModel)]="inputWord"
			class="form-control"
			placeholder="{{ 'word_cloud.add_word' | translate }}"
			[disabled]="!checkWordsLimit()"
		/>

		<button class="btn btn-custom btn-margin-right-default" (click)="addWord()" [disabled]="!checkWordsLimit()">
			{{ "buttons.add" | translate }}
		</button>
	</div>

	<div style="width: 100%">
		<angular-tag-cloud [data]="data" [config]="options"> </angular-tag-cloud>
	</div>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
