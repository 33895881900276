import { SafeResourceUrl } from "@angular/platform-browser";
import { createAction, props } from "@ngrx/store";
import { ILanguage } from "../interfaces";
import { IHeaderState } from "../interfaces/states.interfaces";

export enum UtilityActionsTypes {
	InitAuth = "Auth initialised",
	InitClaims = "Claims initialised",
	InitContainers = "Containers initialised",
	InitEvents = "Events initialised",
	InitUser = "User initialised",
	Loading = "Activate or deactivate loading",
	MenuActivated = "Activate or deactivate menu",
	GetScanning = "Get scanning",
	GetHeaderState = "Get header state",
	GetHeaderTitle = "Get header title",
	GetHeaderModule = "Get header module",
	ResetHeaderState = "Reset header state",
	GetHeaderType = "Get header type",
	GetHeaderItem = "Get header item",
	GetPaginationRecords = "Get pagination records",
	ChatsBadge = "Get chats badge",
	NotificationsBadge = "Get notification badge",
	GetCustomFont = "Get custom font",
	ResetUtilityState = "Reset utility state",
	InitSpecificEventDatasPart = "Init specific event part",
	ResetInitDatasEvent = "Reset init event state",
	NetworkStatus = "Network Status"
}

export const InitAuth = createAction(UtilityActionsTypes.InitAuth, props<{ payload: boolean }>());
export const InitClaims = createAction(UtilityActionsTypes.InitClaims, props<{ payload: boolean }>());
export const InitContainers = createAction(UtilityActionsTypes.InitContainers, props<{ payload: boolean }>());
export const InitEvents = createAction(UtilityActionsTypes.InitEvents, props<{ payload: boolean }>());
export const InitUser = createAction(UtilityActionsTypes.InitUser, props<{ payload: boolean }>());
export const Loading = createAction(UtilityActionsTypes.Loading, props<{ payload: boolean }>());
export const MenuActivated = createAction(UtilityActionsTypes.MenuActivated, props<{ payload: boolean }>());
export const GetScanning = createAction(UtilityActionsTypes.GetScanning, props<{ payload: boolean }>());
export const GetHeaderTitle = createAction(UtilityActionsTypes.GetHeaderTitle, props<{ payload: ILanguage }>());
export const GetHeaderType = createAction(UtilityActionsTypes.GetHeaderType, props<{ payload: number }>());
export const GetHeaderModule = createAction(UtilityActionsTypes.GetHeaderType, props<{ payload: number }>());
export const ResetHeaderState = createAction(UtilityActionsTypes.ResetHeaderState, props<any>());
export const GetHeaderItem = createAction(UtilityActionsTypes.GetHeaderItem, props<{ payload: any }>());
export const GetPaginationRecords = createAction(UtilityActionsTypes.GetPaginationRecords, props<{ payload: any }>());
export const SetChatsBadge = createAction(UtilityActionsTypes.ChatsBadge, props<{ payload: number }>());
export const SetNotificationBadge = createAction(UtilityActionsTypes.NotificationsBadge, props<{ payload: number }>());
export const GetHeaderState = createAction(
	UtilityActionsTypes.GetHeaderState,
	props<{
		payload: IHeaderState;
	}>()
);
export const GetCustomFont = createAction(UtilityActionsTypes.GetCustomFont, props<{ payload: SafeResourceUrl }>());
export const ResetUtilityState = createAction(UtilityActionsTypes.ResetUtilityState, props<any>());

export const InitSpecificEventDatasPart = createAction(
	UtilityActionsTypes.InitSpecificEventDatasPart,
	props<{
		part:
			| "initAsksQuestions"
			| "initAttendees"
			| "initChats"
			| "initCheckins"
			| "initColorsCharts"
			| "initCustomPages"
			| "initDocuments"
			| "initImages"
			| "initVideos"
			| "initFolders"
			| "initEvent"
			| "initEventUser"
			| "initExternalsInteractivity"
			| "initFeedNews"
			| "initBaseCustomFields"
			| "initModulesCustomFields"
			| "initFeedbacks"
			| "initGroups"
			| "initLocations"
			| "initTracks"
			| "initNotifications"
			| "initModules"
			| "initRankings"
			| "initSchedules"
			| "initSheets"
			| "initSpeakers"
			| "initQuizs"
			| "initSurveys"
			| "initTreasureHunts"
			| "initWidgets"
			| "initWordClouds"
			| "initAutomations"
			| "initFavorites"
			| "initRegisterForms"
			| "initCardsExchange";
		payload: boolean;
	}>()
);
export const ResetInitDatasEvent = createAction(UtilityActionsTypes.ResetInitDatasEvent, props<any>());
export const NetworkStatus = createAction(UtilityActionsTypes.NetworkStatus, props<{ payload: boolean }>());
