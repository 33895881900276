<div class="f-row-c-c full-w full-h" *ngIf="scheduleLoader">
	<div class="loader-default loader-widgets" [style.width]="'60px'" [style.height]="'60px'"></div>
</div>

<!-- Fabs buttons -->
<ion-fab
	[hidden]="scheduleLoader"
	class="fabs-buttons"
	vertical="bottom"
	horizontal="end"
	slot="fixed"
	[ngClass]="isMobile && module?.options.showFilter && canShowFilterBloc() ? 'p-r-b-100' : ''"
>
	<!-- Only displays on mobile -->
	<ion-fab-button
		*ngIf="
			event &&
			event.settings &&
			event.settings.allowTimezoneUserSwitch &&
			event.timezoneType === 'local' &&
			eventUser
		"
		class="basic-fab-button"
		(click)="openMobileTimezoneSwitchModal()"
	>
		<ion-icon name="time"></ion-icon>
	</ion-fab-button>
	<ion-fab-button
		*ngIf="module && module.options.showSearchIcon"
		class="basic-fab-button"
		(click)="this.showSearchBar = !this.showSearchBar"
	>
		<ion-icon name="search"></ion-icon>
	</ion-fab-button>
	<!-- Fabs buttons -->
	<ion-fab-button
		[class.fab-button-spinner]="scheduleDownloadButtonLoader"
		*ngIf="favoriteModule && favoriteModule.options.enableFavoriteSessionsDownload"
		[disabled]="scheduleDownloadButtonLoader"
		class="basic-fab-button"
		[ngClass]="{ 'fab-mobile': isMobile, 'fab-desktop': !isMobile }"
		(click)="openDownloadScheduleModal()"
	>
		<ion-icon src="assets/images/calendar_colloqium.svg"></ion-icon>
	</ion-fab-button>
</ion-fab>

<ion-content [hidden]="scheduleLoader" #blocSessions class="page">
	<div id="blocSessions" *ngIf="event && module && !loader" class="f-col-fs-fs full-h">
		<!-- Search bar -->
		<ion-searchbar
			*ngIf="showSearchBar"
			class="schedule-searchbar"
			[style.--background]="'rgb(234, 239, 242)'"
			searchIcon="assets/icon/Search.svg"
			mode="md"
			placeholder="{{ 'texts.search_plc' | translate }}"
			(ionClear)="resetFilter()"
			(keyup)="searchBar($event)"
		>
		</ion-searchbar>

		<!-- Left bar -->
		<div
			*ngIf="
				((module.options.showFilter && canShowFilterBloc()) ||
					(event &&
						event.settings &&
						event.settings.allowTimezoneUserSwitch &&
						event.timezoneType === 'local' &&
						eventUser)) &&
				!isMobile &&
				!loader
			"
			class="left-bloc"
			[style.marginTop]="showSearchBar ? '72px' : '14px'"
			[style.height]="showSearchBar ? 'calc(100% - 58px)' : '100%'"
		>
			<div
				*ngIf="module.options.showFilter && canShowFilterBloc()"
				class="filter-bloc"
				[style.height]="
					event &&
					event.settings &&
					event.settings.allowTimezoneUserSwitch &&
					event.timezoneType === 'local' &&
					eventUser
						? 'calc(100% - 140px)'
						: 'calc(100% - 20px)'
				"
			>
				<ion-chip
					*ngIf="
						filterIsActivated('locations') ||
						filterIsActivated('tracks') ||
						filterIsActivated('customs-fields')
					"
					color="danger"
					(click)="clearAllFilter()"
				>
					<ion-label class="chip-delete-text">{{ "filter.delete_all" | translate }}</ion-label>
					<ion-icon name="close-circle"></ion-icon>
				</ion-chip>
				<!-- Locations -->
				<div
					class="filter-content"
					*ngIf="module.options.baseFields.location.filter && filters.locations.length > 0"
				>
					<div
						(click)="collapseFilter('locations')"
						style="
							display: flex;
							justify-content: space-between;
							margin-top: 5px;
							margin-bottom: 5px;
							cursor: pointer;
						"
					>
						<h5 class="filter-name" [style.color]="event.styling.titleColor">
							{{
								module.options.locationName && module.options.locationName[currentLanguage] !== ""
									? module.options.locationName[currentLanguage]
									: ("labels.locations" | translate)
							}}:
						</h5>
						<ion-icon
							*ngIf="getFilterCollapseState('locations')"
							src="assets/icon/ArrowDown_thin.svg"
							style="position: relative; top: 6px; right: 15px; cursor: pointer"
						>
						</ion-icon>
						<ion-icon
							*ngIf="!getFilterCollapseState('locations')"
							src="assets/icon/ArrowUp_thin.svg"
							style="position: relative; top: 6px; right: 15px; cursor: pointer"
						>
						</ion-icon>
					</div>

					<ion-list class="accordion-container" [class.collapsed]="getFilterCollapseState('locations')">
						<ion-item *ngFor="let locationItem of filters.locations">
							<ion-label>{{ locationItem.name }}</ion-label>
							<ion-checkbox
								color="dark"
								slot="end"
								mode="md"
								[value]="locationItem.name"
								[(ngModel)]="locationItem.checked"
								(ionChange)="filterByDate('locations', locationItem, $event)"
								[disabled]="!locationItem.isReachable"
							>
							</ion-checkbox>
						</ion-item>
						<ion-chip
							*ngIf="filterIsActivated('locations')"
							color="danger"
							(click)="clearFilter('locations')"
						>
							<ion-label class="chip-delete-text"
								>{{ "filter.delete_all" | translate }} ({{ "labels.locations" | translate }})
							</ion-label>
							<ion-icon name="close-circle"></ion-icon>
						</ion-chip>
					</ion-list>
				</div>

				<!-- Tracks -->
				<div class="filter-content" *ngIf="module.options.baseFields.track.filter && filters.tracks.length > 0">
					<div
						(click)="collapseFilter('tracks')"
						style="
							display: flex;
							justify-content: space-between;
							margin-top: 5px;
							margin-bottom: 5px;
							cursor: pointer;
						"
					>
						<h5 class="filter-name" [style.color]="event.styling.titleColor">
							{{
								module.options.trackName && module.options.trackName[currentLanguage] !== ""
									? module.options.trackName[currentLanguage]
									: ("labels.tracks" | translate)
							}}:
						</h5>
						<ion-icon
							*ngIf="getFilterCollapseState('tracks')"
							src="assets/icon/ArrowDown_thin.svg"
							style="position: relative; top: 6px; right: 15px; cursor: pointer"
						>
						</ion-icon>
						<ion-icon
							*ngIf="!getFilterCollapseState('tracks')"
							src="assets/icon/ArrowUp_thin.svg"
							style="position: relative; top: 6px; right: 15px; cursor: pointer"
						>
						</ion-icon>
					</div>

					<ion-list class="accordion-container" [class.collapsed]="getFilterCollapseState('tracks')">
						<ion-item *ngFor="let trackItem of filters.tracks">
							<ion-label>{{ trackItem.name[currentLanguage] }}</ion-label>
							<ion-checkbox
								color="dark"
								slot="end"
								mode="md"
								[value]="trackItem.name[currentLanguage]"
								[(ngModel)]="trackItem.checked"
								(ionChange)="filterByDate('tracks', trackItem, $event)"
								[disabled]="!trackItem.isReachable"
							>
							</ion-checkbox>
						</ion-item>
						<ion-chip *ngIf="filterIsActivated('tracks')" color="danger" (click)="clearFilter('tracks')">
							<ion-label class="chip-delete-text"
								>{{ "filter.delete_all" | translate }} ({{ "labels.tracks" | translate }})</ion-label
							>
							<ion-icon name="close-circle"></ion-icon>
						</ion-chip>
					</ion-list>
				</div>

				<!-- Groups -->
				<div
					class="filter-content"
					*ngIf="module.options.baseFields.group.filter && storedDatas['filters']['groups'].length > 0"
				>
					<div
						(click)="collapseFilter('groups')"
						style="
							display: flex;
							justify-content: space-between;
							margin-top: 5px;
							margin-bottom: 5px;
							cursor: pointer;
						"
					>
						<h5 class="filter-name" [style.color]="event.styling.titleColor">
							{{ "labels.groups" | translate }}:
						</h5>
						<ion-icon
							*ngIf="getFilterCollapseState('groups')"
							src="assets/icon/ArrowDown_thin.svg"
							style="position: relative; top: 6px; right: 15px; cursor: pointer"
						>
						</ion-icon>
						<ion-icon
							*ngIf="!getFilterCollapseState('groups')"
							src="assets/icon/ArrowUp_thin.svg"
							style="position: relative; top: 6px; right: 15px; cursor: pointer"
						>
						</ion-icon>
					</div>
					<ion-list class="accordion-container" [class.collapsed]="getFilterCollapseState('groups')">
						<ion-item *ngFor="let groupItem of storedDatas['filters']['groups']">
							<ion-label>{{ groupItem.name }}</ion-label>
							<ion-checkbox
								color="dark"
								slot="end"
								mode="md"
								[value]="groupItem.name"
								[(ngModel)]="groupItem.checked"
								(ionChange)="filterByDate('groups', groupItem, $event)"
								[disabled]="!groupItem.isReachable"
							>
							</ion-checkbox>
						</ion-item>
						<ion-chip *ngIf="filterIsActivated('groups')" color="danger" (click)="clearFilter('groups')">
							<ion-label class="chip-delete-text"
								>{{ "filter.delete_all" | translate }} ({{ "labels.groups" | translate }})</ion-label
							>
							<ion-icon name="close-circle"></ion-icon>
						</ion-chip>
					</ion-list>
				</div>

				<!-- Customs fields -->
				<div class="filter-content" *ngFor="let customFieldItem of filters.customFields">
					<div class="filter-custom-field-item" *ngIf="customFieldItem.values.length > 0">
						<div
							(click)="collapseFilter(customFieldItem.uid)"
							style="
								display: flex;
								justify-content: space-between;
								margin-top: 5px;
								margin-bottom: 5px;
								cursor: pointer;
							"
						>
							<h5 class="filter-name" [style.color]="event.styling.titleColor">
								{{ customFieldItem.name }}:
							</h5>
							<ion-icon
								*ngIf="getFilterCollapseState(customFieldItem.uid)"
								src="assets/icon/ArrowDown_thin.svg"
								style="position: relative; top: 6px; right: 15px; cursor: pointer"
							>
							</ion-icon>
							<ion-icon
								*ngIf="!getFilterCollapseState(customFieldItem.uid)"
								src="assets/icon/ArrowUp_thin.svg"
								style="position: relative; top: 6px; right: 15px; cursor: pointer"
							>
							</ion-icon>
						</div>
						<ion-label
							*ngIf="customFieldItem.strictSelectionMode !== null"
							[style.color]="event.styling.titleColor"
							>Strict filter<ion-toggle (click)="changeSelectionMode(customFieldItem)"></ion-toggle>
						</ion-label>

						<ion-list
							class="accordion-container"
							[class.collapsed]="getFilterCollapseState(customFieldItem.uid)"
						>
							<ion-item *ngFor="let value of customFieldItem.values">
								<ion-label class="filter-item-value">{{ value.value }}</ion-label>
								<ion-checkbox
									color="dark"
									slot="end"
									mode="md"
									[value]="value"
									[(ngModel)]="value.isSelected"
									(ionChange)="filterByDate()"
									[disabled]="!value.isReachable"
								>
								</ion-checkbox>
							</ion-item>
							<ion-chip
								*ngIf="filterIsActivated('customs-fields', customFieldItem.uid)"
								color="danger"
								(click)="clearFilter('customs-fields', customFieldItem.uid)"
							>
								<ion-label class="chip-delete-text"
									>{{ "filter.delete_all" | translate }} ({{ customFieldItem.name }})</ion-label
								>
								<ion-icon name="close-circle"></ion-icon>
							</ion-chip>
						</ion-list>
					</div>
				</div>
			</div>
			<div
				*ngIf="
					event &&
					event.settings &&
					event.settings.allowTimezoneUserSwitch &&
					event.timezoneType === 'local' &&
					eventUser
				"
				class="f-col-c-c timezone-bloc"
			>
				<ion-label
					style="height: auto !important; text-align: center"
					[style.color]="event.styling.contentTextColor"
				>
					{{
						eventUser.updatedSettings && eventUser.updatedSettings.timezoneType === "local"
							? ("events.switch_timezone_event" | translate) + " (" + event.timezone + ")"
							: ("events.switch_timezone_user" | translate) + getCurrentTimezone()
					}}
				</ion-label>
				<ion-toggle
					[disabled]="updating"
					[checked]="timezoneType"
					mode="ios"
					[style.--background-checked]="event.styling.btnBgColor"
					[style.--handle-background-checked]="event.styling.btnTextColor"
					[style.--background]="event.styling.btnBgColor"
					[style.--handle-background]="event.styling.btnTextColor"
					(ionChange)="changeTimezoneType($event)"
				>
				</ion-toggle>
			</div>
		</div>

		<!-- Header -->
		<!-- <div #header [style.marginTop]="showSearchBar ? '58px' : '0' "
      [style.display]="module.options.showHeader ? 'block' : 'none'" style="z-index: 99;"
      [ngClass]="((module.options.showFilter && canShowFilterBloc()) || (event && event.settings && event.settings.allowTimezoneUserSwitch && event.timezoneType === 'local' && eventUser)) && !isMobile ? 'filtering-header-with-filter' : 'filtering-header' "
      [style.padding]="isMobile ? '0' : ((module.options.showFilter && canShowFilterBloc()) || (event && event.settings && event.settings.allowTimezoneUserSwitch && event.timezoneType === 'local' && eventUser)) ? '0' : '0 15%'">

      <ion-toolbar class="date-toolbar" [style.border-color]="event.styling.btnBgColor + '1A'">
        <ion-buttons slot="start" class="hide-in-small-screen">
          <ion-button class="swiper-prev" (click)="previousDatesSlide()">
            <ion-icon src="assets/icon/ArrowLeft.svg" style="color: #cccccc"></ion-icon>
          </ion-button>
        </ion-buttons>
        <swiper-container #datesSwiper [modules]="swiperModules" [effect]="swiperOptions.effect"
          [slidesPerView]="swiperOptions.slidesPerView" [slidesPerGroup]="swiperOptions.slidesPerGroup"
          [updateOnWindowResize]="swiperOptions.updateOnWindowResize"
          [centerInsufficientSlides]="swiperOptions.centerInsufficientSlides">
          <swiper-slide>
            <span class="{{ selectedDate === 'all' ? 'date-circle-selected' : 'date-circle' }}"
              (click)="selectedDate = 'all'; filterByDate()" [style.background]="
              selectedDate === 'all' ? event.styling.btnBgColor : event.styling.btnTextColor
            " [style.color]="
              selectedDate === 'all' ? event.styling.btnTextColor : event.styling.btnBgColor
            " [style.border-color]="selectedDate === 'all' ? 'none' : event.styling.btnBgColor + '26'">
              {{ "texts.all" | translate }}
            </span>
          </swiper-slide>
          <swiper-slide *ngFor="let date of dates; let i = index">
            <span *ngIf="date" class="{{ selectedDate === date ? 'date-circle-selected' : 'date-circle' }}"
              (click)="selectedDate = date; filterByDate()" [style.background]="
              selectedDate === date ? event.styling.btnBgColor : event.styling.btnTextColor
            " [style.color]="selectedDate === date ? event.styling.btnTextColor : event.styling.btnBgColor"
              [style.border-color]="selectedDate === date ? 'none' : event.styling.btnBgColor + '26'">
              <span style="font-size: 1.5rem">{{ SUtility.getPartOfDate(event, eventUser, date, "day")
                }}</span>
              <span>{{ SUtility.getPartOfDate(event, eventUser, date, "month") }}</span>
            </span>
          </swiper-slide>
        </swiper-container>

        <ion-buttons slot="end" class="hide-in-small-screen">
          <ion-button class="swiper-next" (click)="nextDatesSlide()">
            <ion-icon src="assets/icon/ArrowRight.svg" style="color: #cccccc"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </div> -->

		<div
			[ngClass]="
				((module.options.showFilter && canShowFilterBloc()) ||
					(event &&
						event.settings &&
						event.settings.allowTimezoneUserSwitch &&
						event.timezoneType === 'local' &&
						eventUser)) &&
				!isMobile
					? 'session-blocf'
					: 'session-bloc'
			"
			[style.width]="isMobile ? '100%' : ''"
			[style.padding-top.px]="58"
		>
			<ng-container *ngIf="sessionsFiltered.length > 0; else emptyContent">
				<div
					*ngIf="!module.options.activateTracksFiltering"
					class="viewHour"
					[style.padding]="'0'"
					[ngClass]="module.options.showFilter && canShowFilterBloc() && isMobile ? 'pb-100' : 'pb-0'"
				>
					<ion-list *ngFor="let date of getPaginatedDates()" style="padding: 0">
						<ion-list-header>
							<ion-label [style.color]="event.styling.titleColor" class="header-date">
								{{ SUtility.getPartOfDate(event, eventUser, date, "full") | titlecase }}
							</ion-label>
						</ion-list-header>
						<div
							id="{{ session.uid }}"
							*ngFor="let session of getPaginatedSessions(); let i = index"
							class="session-container scrollable"
						>
							<div
								class="session-item"
								*ngIf="checkSessionDate(session, date)"
								[style.background]="
									session['trackDatas'][0]
										? session['trackDatas'][0].textColor.includes('#fff')
											? session['trackDatas'][0]?.backgroundColor
											: session['trackDatas'][0].textColor
										: event.styling.titleColor
								"
								[style.margin-top]="i === 0 && 0"
								(click)="openSession(session)"
								[style.width]="'100%'"
							>
								<div
									class="label-bg"
									[style.border]="
										session.tracks.length === 0
											? '1px solid ' + event.styling.titleColor + '33'
											: 'none'
									"
								>
									<div
										[style.background]="
											session['trackDatas'][0]
												? session['trackDatas'][0].textColor.includes('#fff')
													? session['trackDatas'][0]?.backgroundColor + '1A'
													: session['trackDatas'][0].textColor + '1A'
												: '#fff'
										"
										class="label"
									>
										<div>
											<small
												[hidden]="!module.options.baseFields.track.canBeTag"
												class="track-name"
												*ngFor="let track of session['trackDatas']"
												[style.color]="track ? track.textColor : ''"
												[style.background]="track ? track?.backgroundColor : ''"
											>
												{{
													track &&
														track.name[
															eventUser && track.name[eventUser.updatedSettings.language]
																? eventUser.updatedSettings.language
																: track.name[currentLanguage]
																? currentLanguage
																: event.language
														]
												}}
											</small>
										</div>
										<div [style.color]="event.styling.titleColor" class="session-name">
											{{
												session.name[
													currentLanguage && session.name[currentLanguage]
														? currentLanguage
														: eventUser && session.name[eventUser.updatedSettings.language]
														? eventUser.updatedSettings.language
														: event.language
												]
											}}
										</div>
										<div
											*ngIf="
												!session.hideSchedule ||
												(checkSessionDate(session, date) &&
													favoriteModule &&
													favoriteModule.options.enableFavoriteSessionsDownload)
											"
											[style.color]="event.styling.contentTextColor"
											class="session-time"
										>
											<span *ngIf="!session.hideSchedule">{{
												SUtility.getPartOfDate(event, eventUser, session.startDate, "time")
											}}</span>
											<span *ngIf="session.endDate && !session.hideSchedule">
												-
												{{ SUtility.getPartOfDate(event, eventUser, session.endDate, "time") }}
											</span>
											<!-- <i *ngIf="checkSessionDate(session, date) && module.options.allowSessionsDownload"
                                              style="float: right; position: relative; right: 10px"
                                              (click)="openDownloadModal(session, $event)"
                                              class="material-icons ics-download">schedule</i> -->
											<ion-icon
												*ngIf="
													checkSessionDate(session, date) &&
													favoriteModule &&
													favoriteModule.options.enableFavoriteSessionsDownload
												"
												style="
													float: right;
													position: relative;
													right: 10px;
													width: 25px;
													height: 25px;
												"
												fill="{{ event.styling.menuColor }}"
												(click)="openDownloadModal(session, $event)"
												class="material-icons ics-download"
												src="assets/images/calendar_colloqium.svg"
												alt="download"
											></ion-icon>

											<!-- {{
                                          session.endDate
                                          ? " -
                                          " +
                                          getPartOfDate(session.endDate, "full")
                                          : ""
                                          }} -->
										</div>
										<div
											*ngIf="module.options.baseFields.description.canBeTag"
											[innerHTML]="
												session.description[
													eventUser && session.description[eventUser.updatedSettings.language]
														? eventUser.updatedSettings.language
														: event.language
												] | safeHtml
											"
											style="white-space: normal"
											class="session-description"
										></div>

										<!-- <br /> -->
										<div>
											<div class="tag list-tags">
												<div
													*ngFor="let tag of session['tags']; let j = index"
													class="f-col-fs-fs"
												>
													<div
														class="f-col w-90"
														*ngIf="tag.baseSettings.type !== typeCustomFields.MODULE"
													>
														<div
															[innerHTML]="
																tag.baseSettings.name[currentLanguage] | safeHtml
															"
															class="tag-color tag-name"
															[style.color]="event.styling.titleColor"
														>
															<span> : </span>
														</div>

														<div
															[innerHTML]="
																tag.fieldDatas.field.multiLanguageText &&
																tag.fieldDatas.field.multiLanguageText[
																	currentLanguage
																] !== ''
																	? tag.fieldDatas.field.multiLanguageText[
																			currentLanguage
																	  ]
																	: tag.fieldDatas.field.text &&
																	  tag.fieldDatas.field.text !== ''
																	? tag.fieldDatas.field.text
																	: tag.fieldDatas.field.multiLanguageTextArray &&
																	  tag.fieldDatas.field.multiLanguageTextArray[
																			currentLanguage
																	  ].length > 0
																	? tag.fieldDatas.field.multiLanguageTextArray[
																			currentLanguage
																	  ].join(', ')
																	: ((tag.fieldDatas.field.numeric !== null ||
																			tag.fieldDatas.field.numeric !== -1) &&
																			tag.fieldDatas.field.numeric | safeHtml)
															"
															class="generic-tag-title"
															style="display: block !important; width: 100%"
															[style.color]="
																tag.baseSettings.type !== 3 && event.styling.titleColor
															"
														></div>
													</div>

													<!-- Event users -->
													<div
														id="tata"
														*ngIf="checkEventUsersModule(tag)"
														style="margin-bottom: 2%"
														class="list-items-event-users"
													>
														<div
															class="f-col w-80"
															*ngIf="
																tag.baseSettings.type === typeCustomFields.MODULE &&
																(getNumberOfEventUsersTypeOfSession(session) ||
																	tag.fieldDatas.field.module.itemsDatas.length > 1)
															"
														>
															<div
																[innerHTML]="
																	tag.baseSettings.name[currentLanguage] | safeHtml
																"
																class="tag-color tag-name"
																style="white-space: pre-line"
																[style.color]="event.styling.titleColor"
															>
																<span> : </span>
															</div>
														</div>
														<div
															*ngFor="let user of tag.fieldDatas.field.module.itemsDatas"
															class="speaker"
														>
															<ion-avatar *ngIf="user.photoUrl" slot="start">
																<img
																	class="picture"
																	[src]="user.photoUrl"
																	*ngIf="user.photoUrl !== ''"
																/>
																<!-- <ion-img class="cus-picture"
                                                                  *ngIf="user?.photoUrl === ''"
                                                                  src="assets/icon/User2.svg">
                                                              </ion-img> -->
															</ion-avatar>
															<ion-label
																[ngClass]="
																	user.tags.length > 0
																		? 'label-with-tags'
																		: 'label-without-tags'
																"
																[style.color]="event.styling.titleColor"
															>
																<span
																	class="custom-fields-document-module-item-title"
																	[style.color]="event.styling.titleColor + 'F5'"
																	>{{ user.name }}</span
																>
																<span
																	class="custom-fields-document-module-item-infos"
																	style="
																		display: flex;
																		flex-direction: column;
																		align-items: flex-start;
																		justify-content: flex-start;
																	"
																	[style.color]="
																		event.styling.contentTextColor + 'E3'
																	"
																	><span
																		*ngFor="let tag of user['tags']; let i = index"
																		>{{
																			tag.field.multiLanguageText &&
																			tag.field.multiLanguageText[currentLanguage]
																				? tag.field.multiLanguageText[
																						currentLanguage
																				  ]
																				: tag.field.text
																				? tag.field.text
																				: tag.field.numeric
																		}}
																		<!-- <span *ngIf="i >= 0 && (i < user['tags'].length - 1) && user['tags'].length >= 2">,&nbsp;</span> -->
																	</span>
																</span>
															</ion-label>
														</div>
														<!-- <hr> -->
													</div>

													<!-- Documents -->
													<div
														*ngIf="checkDocumentsModule(tag)"
														class="list-items-documents"
														style="margin-bottom: 2%"
													>
														<div
															class="f-col w-80"
															*ngIf="
																tag.baseSettings.type === typeCustomFields.MODULE &&
																tag.fieldDatas.field.module.itemsDatas.length > 1
															"
														>
															<div
																[innerHTML]="
																	tag.baseSettings.name[currentLanguage] | safeHtml
																"
																class="tag-color tag-name"
																style="white-space: pre-line"
																[style.color]="event.styling.titleColor"
															>
																<span> : </span>
															</div>
														</div>
														<div
															*ngFor="let doc of tag.fieldDatas.field.module.itemsDatas"
															class="document"
														>
															<ion-thumbnail slot="start">
																<img
																	src="assets/icon/Files/{{
																		doc.icon | splitFirst : ['.']
																	}}.svg"
																/>
															</ion-thumbnail>
															<ion-label>
																<h4
																	[style.color]="event.styling.titleColor"
																	class="custom-fields-document-module-item-title"
																>
																	{{ doc.name[currentLanguage] }}
																</h4>
																<p class="custom-fields-document-module-item-type">
																	{{ SDocument.checkType(doc.type) }}
																</p>
															</ion-label>
														</div>
													</div>
												</div>
											</div>

											<small
												style="size: 10px"
												[hidden]="!module.options.baseFields.location.canBeTag"
												style="display: inline-flex; align-items: center; margin-right: 5px"
												*ngFor="let location of session['locationDatas']"
												[style.color]="event.styling.btnBgColor"
											>
												<ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
												<span style="font-size: 15px">{{ location?.name }}</span>
											</small>

											<!-- add bookmark icon  -->
											<ion-icon
												slot="end"
												name="bookmark"
												class="material-icons ics-download"
												style="
													float: right;
													position: relative;
													left: -12px;
													width: 25px;
													height: 25px;
												"
												fill="{{ event.styling.contentTextColor }}"
												[style.color]="event.styling.contentTextColor"
												src="assets/icon/bookmarks.svg"
												*ngIf="
													favoriteModule &&
													favoriteModule.options &&
													(!favoriteModule.options.hideFavoriteMenuIcon ||
														!favoriteModule.options.hideFavoriteHeaderIcon) &&
													!eventUserFavoritesState[session.uid]
												"
												(click)="addToFavorite(session.uid, $event)"
											></ion-icon>

											<ion-icon
												slot="end"
												name="bookmark_remove"
												class="material-icons ics-download"
												style="
													float: right;
													position: relative;
													left: -12px;
													width: 25px;
													height: 25px;
												"
												fill="{{ event.styling.contentTextColor }}"
												[style.color]="event.styling.contentTextColor"
												src="assets/icon/bookmark_remove.svg"
												*ngIf="
													favoriteModule &&
													favoriteModule.options &&
													(!favoriteModule.options.hideFavoriteMenuIcon ||
														!favoriteModule.options.hideFavoriteHeaderIcon) &&
													eventUserFavoritesState[session.uid]
												"
												(click)="removeFromFavorite(session.uid, $event)"
											>
											</ion-icon>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ion-list>
					<ion-infinite-scroll
						#ionInfiniteScroll
						threshold="100px"
						position="bottom"
						(ionInfinite)="moreSessions($event, 'normal')"
					>
						<ion-infinite-scroll-content loadingSpinner="dots"> </ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</div>

				<div *ngIf="module.options.activateTracksFiltering" class="tracks-view" [style.padding]="0">
					<div
						class="accordion-container"
						[style.maxHeight]="'auto'"
						*ngFor="let groupTrack of getPaginatedSessionsByTracks(); let i = index"
					>
						<ion-item (click)="collapse(groupTrack.track?.uid)" lines="none" slot="header" color="white">
							<ion-label
								style="white-space: pre-line !important; font-weight: bold"
								[style.color]="event.styling.titleColor"
								class="header-title-track"
								style="font-size: large"
							>
								<span
									*ngIf="
										i === 0 ||
										(getPaginatedSessionsByTracks()[i - 1] &&
											SUtility.getPartOfDate(
												event,
												eventUser,
												getPaginatedSessionsByTracks()[i - 1].beginDate,
												'full'
											) !==
												SUtility.getPartOfDate(
													event,
													eventUser,
													getPaginatedSessionsByTracks()[i].beginDate,
													'full'
												))
									"
									[style.color]="event.styling.menuTextColorGradient"
								>
									<i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;

									{{
										SUtility.getPartOfDate(event, eventUser, groupTrack.beginDate, "full")
											| titlecase
									}}
								</span>
								<div
									*ngIf="
										i === 0 ||
										(getPaginatedSessionsByTracks()[i - 1] &&
											SUtility.getPartOfDate(
												event,
												eventUser,
												getPaginatedSessionsByTracks()[i - 1].beginDate,
												'full'
											) !==
												SUtility.getPartOfDate(
													event,
													eventUser,
													getPaginatedSessionsByTracks()[i].beginDate,
													'full'
												))
									"
									style="display: flex"
								>
									<hr style="width: 100% !important" />
								</div>

								<div
									*ngIf="module.options.trackFilterShowLocations"
									[style.color]="event.styling.titleColor"
								>
									<span
										*ngFor="let location of getLocationsOfGroupTrack(groupTrack); let i = index"
										[style.color]="event.styling.menuTextColorGradient"
									>
										{{ location.name
										}}<span *ngIf="i < getLocationsOfGroupTrack(groupTrack).length - 1"
											>,&nbsp;</span
										>
									</span>
								</div>
								<!-- <br> -->

								<span
									*ngIf="groupTrack.track && groupTrack.track?.name"
									[style.color]="groupTrack.track?.backgroundColor"
									style="white-space: break-spaces"
									>{{ groupTrack.track?.name[currentLanguage] }}</span
								>
								<!-- <br> -->
								<!-- Event users -->
								<br
									*ngIf="
										module.options.enableHeaderGroupTag &&
										getRepeatedTagsByGroupTraks(groupTrack)?.length > 0
									"
								/>
								<div
									*ngIf="
										module.options.enableHeaderGroupTag &&
										getRepeatedTagsByGroupTraks(groupTrack)?.length > 0
									"
									style="margin-bottom: 0; margin-top: 2px !important"
									class="list-items-event-users"
								>
									<div
										[hidden]="getRepeatedTagsByGroupTraks(groupTrack)?.length === 0"
										*ngFor="let groupedUser of getRepeatedTagsByGroupTraks(groupTrack)"
										style="padding: 0; margin: 0"
									>
										<div class="f-col w-80">
											<div
												[innerHTML]="groupedUser[0]?.['tagName'] | safeHtml "
												class="tag-color tag-name"
												style="white-space: pre-line"
												[style.color]="event.styling.titleColor"
											>
												<span> : </span>
											</div>
										</div>
										<div
											*ngFor="let user of groupedUser"
											class="speaker"
											style="margin-bottom: 0 !important"
										>
											<ion-avatar *ngIf="user.photoUrl" slot="start">
												<img class="picture" [src]="user.photoUrl" />
											</ion-avatar>
											<ion-label
												[style.color]="event.styling.titleColor"
												style="
													position: relative;
													top: 0 !important;
													font-size: 1.1rem !important;
													font-weight: normal !important;
												"
											>
												<span
													class="custom-fields-document-module-item-title"
													[style.color]="event.styling.titleColor + 'F5'"
													style="font-size: 1.1rem !important; font-weight: normal !important"
													>{{ user.name }}
													<!-- <span
                                                      style="font-weight: 600; font-size: 0.7; font-style: italic;">({{user['tagName']}})</span> -->
												</span>
												<span
													class="custom-fields-document-module-item-infos"
													style="
														display: flex;
														flex-direction: column;
														align-items: flex-start;
														justify-content: flex-start;
													"
													[style.color]="event.styling.contentTextColor + 'E3'"
													style="font-size: 1.1rem !important; font-weight: normal !important"
													><span *ngFor="let tag of user['tags']; let i = index"
														>{{
															tag.field.multiLanguageText &&
															tag.field.multiLanguageText[currentLanguage]
																? tag.field.multiLanguageText[currentLanguage]
																: tag.field.text
																? tag.field.text
																: tag.field.numeric
														}}
													</span>
												</span>
											</ion-label>
										</div>
									</div>

									<!-- <hr> -->
								</div>
								<br />
								<div style="display: flex; justify-content: space-between">
									<span [style.color]="groupTrack.track?.backgroundColor">
										<!-- <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp; -->
										{{
											SUtility.getPartOfDate(event, eventUser, groupTrack.beginDate, "time")
												| uppercase
										}}
										-
										{{
											SUtility.getPartOfDate(event, eventUser, groupTrack.endDate, "time")
												| uppercase
										}}
									</span>
									<ion-icon
										*ngIf="!getTrackCollapseState(groupTrack.track?.uid)"
										src="assets/icon/ArrowUp_thin.svg"
										style="position: relative; top: 6px; right: 15px; cursor: pointer"
									>
									</ion-icon>
									<ion-icon
										*ngIf="getTrackCollapseState(groupTrack.track?.uid)"
										src="assets/icon/ArrowDown_thin.svg"
										style="position: relative; top: 6px; right: 15px; cursor: pointer"
									>
									</ion-icon>
								</div>
							</ion-label>
						</ion-item>
						<ion-list
							[class.collapsed]="getTrackCollapseState(groupTrack.track?.uid)"
							slot="content"
							style="margin: 0; padding: 10px 0 10px 0; width: 99% !important"
							[ngClass]="groupTrack.track ? 'label-bg-without-track' : 'label-bg-with-track'"
							[style.background]="
								module.options.enableTrackBackgroundColor
									? groupTrack.track
										? getSpecificTrack(groupTrack.track?.uid).textColor.includes('#fff')
											? getSpecificTrack(groupTrack.track?.uid)?.backgroundColor + '1A'
											: getSpecificTrack(groupTrack.track?.uid).textColor + '1A'
										: '#fff'
									: 'transparent'
							"
						>
							<div
								slot="content"
								id="{{ session.uid }}"
								*ngFor="let session of groupTrack.sessions; let i = index"
								class="session-container-trackFiltering"
							>
								<div
									class="session-item-track"
									[style.margin-top]="i === 0 && 5"
									(click)="openSession(session)"
								>
									<div
										class="label-bg-track-filtering-mode"
										[style.padding-left]="session.tracks.length > 0 ? '0%' : '5px'"
									>
										<div
											[style.background]="
												module.options.enableTrackBackgroundColor
													? getFirstTrackForSession(session) &&
													  getFirstTrackForSession(session).textColor.includes('#fff')
														? getFirstTrackForSession(session).textColor + '1A'
														: '#fff'
													: 'transparent'
											"
											class="label"
										>
											<div style="padding: 5px; padding-left: 15px">
												<div
													*ngIf="!session.hideSchedule"
													[style.color]="event.styling.contentTextColor"
													style="margin: 0 0 !important"
													class="session-time"
												>
													<!-- <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp; -->
													<span *ngIf="session.startDate">
														{{
															SUtility.getPartOfDate(
																event,
																eventUser,
																session.startDate,
																"time"
															) | uppercase
														}}
													</span>
													-
													<span *ngIf="session.endDate">
														{{
															SUtility.getPartOfDate(
																event,
																eventUser,
																session.endDate,
																"time"
															) | uppercase
														}}
													</span>
												</div>
												<div [style.color]="event.styling.titleColor" class="session-name">
													{{ session.name[currentLanguage] }}
												</div>
											</div>

											<div
												*ngIf="module.options.baseFields.description.canBeTag"
												[innerHTML]="
													session.description[
														eventUser &&
														session.description[eventUser.updatedSettings.language]
															? eventUser.updatedSettings.language
															: event.language
													] | safeHtml
												"
												class="session-description"
												style="white-space: normal"
											></div>
											<!-- <br /> -->
											<div style="padding: 0 0 0 15px !important; margin: 0 !important">
												<div
													class="list-tags"
													style="padding: 0 !important; margin: 0 !important"
												>
													<div
														*ngFor="let tag of session['tags']; let j = index"
														class="tag f-col-fs-fs"
														style="padding: 0 0 5px 10px !important; margin: 0 !important"
													>
														<div
															class="f-col w-80"
															*ngIf="tag.baseSettings.type !== typeCustomFields.MODULE"
														>
															<div
																[innerHTML]="
																	tag.baseSettings.name[currentLanguage] | safeHtml
																"
																class="tag-color tag-name"
															>
																<span> : </span>
															</div>
															<div
																[innerHTML]="
																	tag.fieldDatas.field.multiLanguageText &&
																	tag.fieldDatas.field.multiLanguageText[
																		currentLanguage
																	] !== ''
																		? tag.fieldDatas.field.multiLanguageText[
																				currentLanguage
																		  ]
																		: tag.fieldDatas.field.text &&
																		  tag.fieldDatas.field.text !== ''
																		? tag.fieldDatas.field.text
																		: tag.fieldDatas.field.multiLanguageTextArray &&
																		  tag.fieldDatas.field.multiLanguageTextArray[
																				currentLanguage
																		  ].length > 0
																		? tag.fieldDatas.field.multiLanguageTextArray[
																				currentLanguage
																		  ].join(', ')
																		: ((tag.fieldDatas.field.numeric !== null ||
																				tag.fieldDatas.field.numeric !== -1) &&
																				tag.fieldDatas.field.numeric | safeHtml)
																"
																class="generic-tag-title"
															></div>
														</div>

														<!-- Event users -->
														<div
															*ngIf="checkEventUsersModule(tag)"
															style="margin-bottom: 0"
															class="list-items-event-users"
														>
															<div
																class="f-col w-80"
																*ngIf="
																	tag.baseSettings.type === typeCustomFields.MODULE &&
																	tag.fieldDatas.field.module.itemsDatas.length > 0
																"
															>
																<div
																	[innerHTML]="
																		tag.baseSettings.name[currentLanguage]
																			| safeHtml
																	"
																	class="tag-color tag-name"
																	style="white-space: pre-line"
																	[style.color]="event.styling.titleColor"
																>
																	<span> : </span>
																</div>
															</div>
															<div style="padding: 0; margin: 0">
																<div
																	*ngFor="
																		let user of tag.fieldDatas.field.module
																			.itemsDatas
																	"
																	class="speaker"
																	style="margin-bottom: 0 !important"
																>
																	<ion-avatar *ngIf="user.photoUrl" slot="start">
																		<img class="picture" [src]="user.photoUrl" />
																	</ion-avatar>
																	<ion-label
																		[ngClass]="
																			user.tags.length > 0
																				? 'label-with-tags'
																				: 'label-without-tags'
																		"
																		[style.color]="event.styling.titleColor"
																		style="
																			position: relative;
																			top: 0 !important;
																			font-size: 1.1rem !important;
																			font-weight: normal !important;
																		"
																	>
																		<span
																			class="custom-fields-document-module-item-title"
																			[style.color]="
																				event.styling.titleColor + 'F5'
																			"
																			style="
																				font-size: 1.1rem !important;
																				font-weight: normal !important;
																			"
																			>{{ user.name }}</span
																		>
																		<span
																			class="custom-fields-document-module-item-infos"
																			style="
																				display: flex;
																				flex-direction: column;
																				align-items: flex-start;
																				justify-content: flex-start;
																			"
																			[style.color]="
																				event.styling.contentTextColor + 'E3'
																			"
																			style="
																				font-size: 1.1rem !important;
																				font-weight: normal !important;
																			"
																			><span
																				*ngFor="
																					let tag of user['tags'];
																					let i = index
																				"
																				>{{
																					tag.field.multiLanguageText &&
																					tag.field.multiLanguageText[
																						currentLanguage
																					]
																						? tag.field.multiLanguageText[
																								currentLanguage
																						  ]
																						: tag.field.text
																						? tag.field.text
																						: tag.field.numeric
																				}}
																			</span>
																		</span>
																	</ion-label>
																</div>
															</div>

															<!-- <hr> -->
														</div>

														<!-- Documents -->
														<div
															*ngIf="checkDocumentsModule(tag)"
															class="list-items-documents"
															style="margin-bottom: 2%"
														>
															<div
																class="f-col w-80"
																*ngIf="
																	tag.baseSettings.type === typeCustomFields.MODULE &&
																	tag.fieldDatas.field.module.itemsDatas.length > 1
																"
															>
																<div
																	[innerHTML]="
																		tag.baseSettings.name[currentLanguage]
																			| safeHtml
																	"
																	class="tag-color tag-name"
																	style="white-space: pre-line"
																	[style.color]="event.styling.titleColor"
																>
																	<span> : </span>
																</div>
															</div>
															<div
																*ngFor="
																	let doc of tag.fieldDatas.field.module.itemsDatas
																"
																class="document"
															>
																<ion-thumbnail slot="start">
																	<img
																		src="assets/icon/Files/{{
																			doc.icon | splitFirst : ['.']
																		}}.svg"
																	/>
																</ion-thumbnail>
																<ion-label>
																	<h4
																		[style.color]="event.styling.titleColor"
																		class="custom-fields-document-module-item-title"
																		style="
																			font-size: 1.1rem !important;
																			font-weight: normal !important;
																		"
																	>
																		{{ doc.name[currentLanguage] }}
																	</h4>
																	<p class="custom-fields-document-module-item-type">
																		{{ SDocument.checkType(doc.type) }}
																	</p>
																</ion-label>
															</div>
														</div>
													</div>
												</div>

												<div
													*ngIf="groupTrack.mainLocation"
													style="position: relative; top: 5px"
												>
													<small
														[hidden]="!module.options.baseFields.location.canBeTag"
														style="
															display: inline-flex;
															align-items: center;
															margin-right: 5px;
														"
														*ngFor="let locationId of session.locations"
														[style.color]="event.styling.btnBgColor"
													>
														<ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
														<span style="font-size: 17px">{{
															getSpecificLocation(locationId).name
														}}</span>
													</small>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr
									*ngIf="groupTrack.track && groupTrack.sessions[i + 1]"
									style="
										height: 0;
										border-width: 1px;
										box-sizing: content-box;
										border-top: 1px solid #8c8b8b;
										color: #8c8b8b;
										margin: 5px 10% 5px 0% !important;
									"
								/>
							</div>
						</ion-list>
						<br />
					</div>
					<!-- </ion-accordion> -->
					<br />
					<!-- </ion-accordion-group> -->

					<ion-infinite-scroll threshold="100px" (ionInfinite)="moreSessions($event, 'tracks')">
						<ion-infinite-scroll-content loadingSpinner="dots"> </ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</div>
			</ng-container>
			<ng-template #emptyContent>
				<div class="container">
					<span class="wait-content" [style.color]="event.styling.titleColor">{{
						(searchBarValue ? "texts.no_result_title" : "texts.waiting_for_content") | translate
					}}</span>
					<ion-icon
						[src]="
							!searchBarValue
								? 'assets/images/emptycontent/AGENDA.svg'
								: 'assets/images/emptycontent/AUCUNRESULTAT.svg'
						"
						[style.color]="
							event.styling.menuColor.includes('linear')
								? event.styling.menuColor.split(',')[1]
								: event.styling.menuColor
						"
					>
					</ion-icon>
					<div
						*ngIf="!searchBarValue"
						class="text-empty-content"
						[style.color]="event.styling.contentTextColor"
					>
						<span class="title"> {{ "schedule.empty_content_title" | translate }}</span>
						<span>{{ "schedule.empty_content_text" | translate }}</span>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</ion-content>

<!-- Filter bar section -->
<div *ngIf="event && isMobile && module?.options.showFilter && canShowFilterBloc()" class="filter-bar">
	<!-- filter text -->
	<div class="filter-bar-title">
		<span [style.color]="event.styling.contentTextColor" style="font-weight: 600">{{
			"filter.text-information" | translate
		}}</span>
	</div>
	<!-- Filter button -->
	<ion-button
		class="filter-bar-button"
		[style.--background]="event.styling.btnBgColor"
		[style.color]="event.styling.btnTextColor"
		style="font-weight: 600"
		(click)="openMobileFilterModal()"
	>
		{{ "texts.filter" | translate }}
	</ion-button>
</div>
