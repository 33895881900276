import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import { forkJoin, Observable, of } from "rxjs";
import { auditTime, switchMap, take } from "rxjs/operators";
import { AuthService } from "../../shared/services/auth.service";
import { getCurrentUser } from "../selectors/auth.selectors";
import { getContainersState } from "../selectors/containers.selectors";
import { getAllEvents } from "../selectors/events.selectors";

@Injectable()
export class AuthGuard {
	constructor(private SAuth: AuthService, private store: Store) {}
	canActivate(): boolean | Observable<boolean> {
		return forkJoin([
			this.store.select(getCurrentUser).pipe(auditTime(1000), take(1)),
			this.store.select(getAllEvents).pipe(auditTime(1000), take(1)),
			this.store.select(getContainersState).pipe(auditTime(1000), take(1))
		]).pipe(
			switchMap(() => {
				return of(true);
			})
		);
	}
}
