<div *ngIf="event && module" class="page-container">
	<ng-container
		*ngIf="
			(event.startDate && module.options.eventsFieldsOptions.title) ||
			(event.startDate && module.options.eventsFieldsOptions.date)
		"
	>
		<div style="background-size: auto 100%; height: fit-content; width: 98%; margin: 0 auto">
			<div class="title-container">
				<div class="title-content">
					<h1
						class="event-title basic-label"
						*ngIf="event.startDate && module.options.eventsFieldsOptions.title"
					>
						{{ event.title }}
					</h1>
					<span
						class="event-dates basic-label"
						*ngIf="event.startDate && module.options.eventsFieldsOptions.date"
					>
						{{ SUtility.getPartOfDate(event, null, event.startDate, "full") | titlecase }}
						-
						{{ SUtility.getPartOfDate(event, null, event.endDate, "full") | titlecase }}</span
					>
				</div>
			</div>
		</div>
	</ng-container>

	<ion-grid>
		<ion-row padding></ion-row>
		<ion-row
			*ngIf="
				(module.options.eventsFieldsOptions.description && module.options.eventsFields.description) ||
				(module.options.eventsFieldsOptions.site && module.options.eventsFields.site) ||
				(module.options.eventsFieldsOptions.emailSupport && module.options.eventsFields.emailSupport) ||
				(module.options.eventsFieldsOptions.location &&
					module.options.eventsFields.location.name &&
					module.options.eventsFields.location.address)
			"
		>
			<ion-col size="12" class="full-w no-paddings no-margins">
				<ion-card class="radius-10">
					<ion-card-content>
						<div *ngIf="module.options.eventsFieldsOptions.description" style="margin-bottom: 15px">
							<h6 class="description-event-title basic-label">
								{{ "labels.description" | translate }}
							</h6>

							<div
								[innerHTML]="module.options.eventsFields.description[currentLanguage] | safeHtml"
								class="description-event-text"
							></div>
						</div>
						<div class="flex-infos-and-map">
							<div
								class="left-side"
								*ngIf="
									module.options.eventsFieldsOptions.emailSupport ||
									module.options.eventsFieldsOptions.site
								"
							>
								<div class="support" *ngIf="module.options.eventsFieldsOptions.emailSupport">
									<h6 class="description-event-title basic-label">
										{{ "labels.support" | translate }}
									</h6>
									<a href="mailto:{{ module.options.eventsFields.emailSupport }}" class="basic-link">
										{{ module.options.eventsFields.emailSupport }}
									</a>
									<br />
									<br />
								</div>
								<div
									*ngIf="module.options.eventsFieldsOptions.site && module.options.eventsFields.site"
								>
									<h6 class="description-event-title basic-label">
										{{ "labels.internet_site" | translate }}
									</h6>
									<a
										class="basic-link cursor-pointer"
										(click)="openLink(module.options.eventsFields.site)"
									>
										<span>{{ module.options.eventsFields.site }}</span>
									</a>
								</div>
							</div>

							<ion-row
								*ngIf="
									module.options.eventsFieldsOptions.location &&
									module.options.eventsFields.location.name &&
									module.options.eventsFields.location.address
								"
							>
								<ion-col size="12" class="full-w no-paddings no-margins">
									<h6 class="address-event">
										{{ module.options.eventsFields.location.name }},
										{{ module.options.eventsFields.location.address }}
									</h6>
									<ion-card class="radius-10" style="margin: 15px 0 0 0">
										<div
											*ngIf="address"
											#planContainer
											class="f-row-c-c"
											[style.margin]="isMobile ? '30px 0' : '30px'"
										>
											<img
												[style.width]="
													isMobile && planContainer
														? planContainer.clientWidth - 60 + 'px'
														: '600px'
												"
												(click)="openGooglePlan(address)"
												[src]="
													getGooglePlan(
														address,
														isMobile && planContainer ? planContainer.clientWidth - 60 : 600
													)
												"
												class="section-plan"
											/>
										</div>
									</ion-card>
								</ion-col>
							</ion-row>
						</div>
					</ion-card-content>
				</ion-card>
			</ion-col>
		</ion-row>
	</ion-grid>
</div>
