<ion-content class="page">
	<div *ngIf="event && module" class="background">
		<form [formGroup]="noteForm">
			<ion-grid class="ion-padding">
				<!-- Input Title  -->
				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
						<ion-item lines="none" mode="md" class="input-item">
							<ion-label position="floating" mode="md" class="input-label-text label-color">
								{{ "labels.title" | translate }}</ion-label
							>
							<ion-input
								autocapitalize="sentences"
								mode="md"
								class="custom-input email-input input-color"
								type="title"
								formControlName="title"
							>
							</ion-input>
						</ion-item>
						<small
							*ngIf="noteForm.get('title').hasError('maxlength') && noteForm.get('title').touched"
							style="width: 100%; color: red; text-align: center; white-space: pre-wrap"
							>{{ "note-taking.title-too-long" | translate }}</small
						>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
						<ion-item
							lines="none"
							mode="md"
							style="
								background-color: fff;
								min-height: 80px;
								border: 1px solid #e1ddec;
								border-radius: 15px;
							"
						>
							<ion-label position="floating" mode="md" class="input-label-text label-color">
								{{ "labels.content" | translate }}</ion-label
							>
							<ion-textarea autoGrow="true" autocapitalize="sentences" formControlName="content">
							</ion-textarea>
						</ion-item>
						<small
							*ngIf="noteForm.get('content').hasError('maxlength') && noteForm.get('content').touched"
							style="width: 100%; color: red; text-align: center; white-space: pre-wrap"
							>{{ "note-taking.content-too-long" | translate }}</small
						>
					</ion-col>
				</ion-row>

				<!-- Buttons-->
				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3" text-center>
						<ion-button
							shape="round"
							class="btn-default access-btn"
							[style.--background]="event.styling.btnBgColor"
							(click)="createNote()"
							[disabled]="creating || !noteForm.valid"
							>{{ "buttons.create" | translate }}
							<!-- <ion-spinner name="crescent" color="light" *ngIf="creating"></ion-spinner> -->
						</ion-button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</form>
	</div>
</ion-content>
