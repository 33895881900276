<ion-content class="page">
	<div *ngIf="!loader && event && module" class="background">
		<div *ngIf="locations.length > 0">
			<ion-item
				detail
				*ngFor="let location of locations"
				(click)="openLocation(location)"
				class="item-page"
				lines="none"
				[style.border-color]="event.styling.titleColor + '33'"
				mode="md"
			>
				<ion-avatar
					slot="start"
					[style.background]="event.styling.menuColor"
					[style.color]="event.styling.menuTextColor"
				>
					<span class="b3-icons icon b3-icon-43"></span>
				</ion-avatar>
				<ion-label [style.color]="event.styling.titleColor">
					<h2 class="line-clamp-2">{{ location.name }}</h2>
				</ion-label>
			</ion-item>
		</div>

		<div *ngIf="locations.length === 0" class="container">
			<span class="wait-content" [style.color]="event.styling.titleColor">{{
				"texts.waiting_for_content" | translate
			}}</span>
			<ion-icon
				src="assets/images/emptycontent/AUCUNRESULTAT.svg"
				[style.color]="
					event.styling.menuColor.includes('linear')
						? event.styling.menuColor.split(',')[1]
						: event.styling.menuColor
				"
			>
			</ion-icon>
			<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
				<span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
				<span>{{ "interactivity.default_empty_content_text" | translate }}</span>
			</div>
		</div>
	</div>

	<div *ngIf="loader">
		<ion-item lines="none" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
			<ion-label>
				<h3>
					<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
				</h3>
			</ion-label>
		</ion-item>
	</div>
</ion-content>
