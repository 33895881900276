/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { combineLatest, from, map, Observable, of, switchMap } from "rxjs";
import { TypeModule } from "../enums/type-module";
import { IEventUser, IPointRecord, IRanking } from "../interfaces";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class PointsService {
	constructor(private SFirestore: FirestoreService) {}

	/**
	 * Get points records
	 * @param eventId
	 * @param type
	 * @returns
	 */
	getPointsRecordsForRanking(
		eventId: string,
		ranking: IRanking
	): Observable<{ eventUsers: IEventUser[]; pointsRecords: IPointRecord[] }> {
		const obs =
			ranking.type === 0 // ALL
				? this.SFirestore.collectionGroupValueChangesDocuments("event-user-points-records", [
						where("eventId", "==", eventId)
				  ])
				: ranking.type === 1 // Quizs
				? this.SFirestore.collectionGroupValueChangesDocuments("event-user-points-records", [
						where("eventId", "==", eventId),
						where("type", "==", TypeModule.QUIZ)
				  ])
				: ranking.type === 2 // Surveys
				? this.SFirestore.collectionGroupValueChangesDocuments("event-user-points-records", [
						where("eventId", "==", eventId),
						where("type", "==", TypeModule.SURVEY)
				  ])
				: this.SFirestore.collectionGroupValueChangesDocuments("event-user-points-records", [
						where("eventId", "==", eventId),
						where("type", "==", TypeModule.TREASURE_HUNTS)
				  ]);
		return obs.pipe(
			switchMap((pointsRecords) => {
				if (!pointsRecords || pointsRecords.length === 0) {
					return of({
						eventUsers: [],
						pointsRecords: []
					});
				}

				pointsRecords = pointsRecords.filter((record) => {
					if (ranking.type === 0) {
						return true;
					} else if (ranking.type === 1) {
						return ranking.linkedSpecifics.includes(record.options.quizId);
					} else if (ranking.type === 2) {
						return ranking.linkedSpecifics.includes(record.options.surveyId);
					} else {
						return ranking.linkedSpecifics.includes(record.options.treasureHuntId);
					}
				});
				const attendeesIds: string[] = [];
				const obsArray: Observable<IEventUser>[] = [];
				pointsRecords.forEach((pointRecord) => {
					if (!attendeesIds.includes(pointRecord.userId)) {
						attendeesIds.push(pointRecord.userId);
					}
				});
				attendeesIds.forEach((id) => {
					obsArray.push(
						from(
							this.SFirestore.getDocumentsCollectionGroup("event-users", [
								where("eventId", "==", eventId),
								where("uid", "==", id)
							])
						).pipe(
							switchMap((docs) => {
								return of(!docs.empty ? docs.docs[0].data() : null);
							})
						)
					);
				});
				return combineLatest(obsArray).pipe(
					switchMap((results) => {
						return of({
							eventUsers: results.filter(
								(user) =>
									user &&
									(!ranking.rankingByGroups ||
										(ranking.rankingByGroups &&
											(ranking.rankingByGroupsAllGroups ||
												(!ranking.rankingByGroupsAllGroups &&
													ranking.groups.some((grpId) => user.groups.includes(grpId))))))
							),
							pointsRecords: pointsRecords
						});
					})
				);
			})
		);
	}

	/**
	 * Check point record
	 * @param eventId
	 * @param moduleId
	 * @param pointRecord
	 * @param type
	 */
	checkPointRecord(eventId: string, moduleId: string, pointRecord: IPointRecord, eventUserId: string, type: string) {
		if (type === "treasureHunt") {
			return from(
				this.SFirestore.getDocumentsCollectionGroup("event-user-points-records", [
					where("eventId", "==", eventId),
					where("options.moduleId", "==", moduleId),
					where("userId", "==", eventUserId),
					where("options.treasureHuntId", "==", pointRecord.options.treasureHuntId),
					where("options.qrCodeId", "==", pointRecord.options.qrCodeId),
					where("type", "==", TypeModule.TREASURE_HUNTS)
				])
			).pipe(map((docs) => docs.docs.map((doc) => doc.data())));
		} else {
			return;
		}
	}

	/**
	 * Create point record
	 * @param eventId
	 * @param pointRecord
	 */
	createEventUserPointRecord(eventId: string, pointRecord: IPointRecord) {
		pointRecord.uid = pointRecord.uid
			? pointRecord.uid
			: this.SFirestore.createId(
					`events/${eventId}/modules/${pointRecord.moduleId}/event-users/${pointRecord.userId}/event-user-points-records`
			  );

		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${pointRecord.moduleId}/event-users/${pointRecord.userId}/event-user-points-records/${pointRecord.uid}`,
			pointRecord
		);
	}

	/**
	 * Create event user point record ref
	 * @param eventId
	 * @param pointRecord
	 * @returns
	 */
	getRefOfEventUserPointRecord(eventId: string, pointRecord: IPointRecord) {
		pointRecord.uid = pointRecord.uid
			? pointRecord.uid
			: this.SFirestore.createId(
					`events/${eventId}/modules/${pointRecord.moduleId}/event-users/${pointRecord.userId}/event-user-points-records`
			  );

		return this.SFirestore.docRef(
			`events/${eventId}/modules/${pointRecord.moduleId}/event-users/${pointRecord.userId}/event-user-points-records/${pointRecord.uid}`
		);
	}

	/**
	 * Delete point record
	 * @param eventId
	 * @param pointRecord
	 * @returns
	 */
	async deleteEventUserPointRecord(eventId: string, pointRecord: IPointRecord) {
		const result = (
			await this.SFirestore.getDocuments(
				`events/${eventId}/modules/${pointRecord.moduleId}/event-users/${pointRecord.userId}/event-user-points-records`,
				[where("options.questionId", "==", pointRecord.options.questionId)]
			)
		).docs.map((doc) => doc.data());

		pointRecord.uid = pointRecord.uid ? pointRecord.uid : result[0].uid;

		return this.SFirestore.deleteDocument(
			`events/${eventId}/modules/${pointRecord.moduleId}/event-users/${pointRecord.userId}/event-user-points-records/${pointRecord.uid}`
		);
	}
}
