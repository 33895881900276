import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { EventGuard, EventsListGuard, LoginGuard, ShortcodeGuard } from "./shared/guards";
import { ModulesGuard } from "./shared/guards/guards";
import { PathComponents } from "./shared/paths/path-components";
import {
	ChatsResolver,
	CustomFieldsResolver,
	DocumentsResolver,
	EventUserResolver,
	EventsResolver,
	GroupsResolver,
	NotificationsResolver,
	SchedulesResolver
} from "./shared/resolvers";
import { AutomationResolver, FavoritesResolver, RegisterFormResolver } from "./shared/resolvers/resolvers";
const routes: Routes = [
	{
		path: "",
		redirectTo: "/login",
		pathMatch: "full"
	},
	{
		path: "login",
		canActivate: [LoginGuard],
		component: PathComponents.login
	},

	{
		path: "login/:eventId",
		canActivate: [LoginGuard],
		component: PathComponents.login
	},
	{
		path: "events-list/:type", // Type can be all, publics or my-events
		canActivate: [EventsListGuard],
		component: PathComponents.eventsList
	},
	{
		path: "event/:eventId",
		canActivate: [EventGuard, ModulesGuard],
		resolve: {
			// asksQuestions: AskQuestionsResolver, // needed (à ameliorer)
			automations: AutomationResolver,
			chats: ChatsResolver, //needed
			customFields: CustomFieldsResolver, //needed
			// checkins: CheckinsResolver,
			// colorsCharts: ColorsChartsInteractivityResolver,
			// customPages: CustomPagesResolver, // needed (à ameliorer)
			documents: DocumentsResolver,
			event: EventsResolver, //needed
			eventUser: EventUserResolver, //needed
			favorites: FavoritesResolver, //needed
			groups: GroupsResolver,
			// externalsInteractivity: ExternalInteractivitiesResolver,
			// feedNews: FeedNewsResolver,
			// modules: ModulesResolver, //needed
			notifications: NotificationsResolver, //needed
			// rankings: RankingsResolver,
			schedules: SchedulesResolver,
			registerForm: RegisterFormResolver // TODO: optionnal (à deplacer)
			// quizs: QuizsResolver,
			// surveys: SurveysResolver,
			// treasureHunts: TreasureHuntsResolver
			// widgets: WidgetsResolver,
			// wordsClouds: WordCloudsResolver
		},
		loadChildren: () => import("./content/pages/event/event.module").then((m) => m.EventPageModule)
	},
	{
		path: "shortcode/:shortCodeId",
		canActivate: [ShortcodeGuard],
		component: PathComponents.splash // All logics for this route is on the guard
	},
	{ path: "reset-password", component: PathComponents.resetPassword },
	{
		path: "**",
		redirectTo: "/login",
		pathMatch: "full"
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
