import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IEventUsersState } from "../interfaces/event-users.interfaces";
import { getGroups } from "./generics-modules-data.selectors";
import { IGroup } from "../interfaces";
import * as _ from "lodash-es";
import { TypeUser } from "../enums/type-user";

export const getEventUsersState = createFeatureSelector<IEventUsersState>("eventUsers");

export const checkSameEvent = (uid: string) =>
	createSelector(getEventUsersState, (state: IEventUsersState) => {
		return state.eventId === uid ? true : false;
	});

export const getAllEventUsers = (type: "all" | "attendees" | "speakers") =>
	createSelector(getEventUsersState, getGroups, (state: IEventUsersState, groups: IGroup[]) => {
		const eventUsers = _.cloneDeep(state.eventUsers).filter(
			(eventUser) =>
				type === "all" ||
				(type === "attendees" && eventUser.type === TypeUser.ATTENDEE) ||
				(type === "speakers" && eventUser.type === TypeUser.SPEAKER)
		);
		const groupsSorted = groups.sort((a, b) =>
			a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
		);

		return eventUsers.map((eventUser) => {
			eventUser.groupsDatas = groupsSorted.filter((group) => eventUser.groups.includes(group.uid));
			return eventUser;
		});
	});

export const getEventUsersForModule = (data: { order: string; moduleId: string }) =>
	createSelector(getEventUsersState, (state: IEventUsersState) => {
		const eventUsers = _.cloneDeep(state.eventUsers).filter((eventUser) => eventUser.moduleId === data.moduleId);
		if (data.order === "asc") {
			return eventUsers.sort((a, b) =>
				a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
			);
		} else if (data.order === "desc") {
			return eventUsers.sort((a, b) =>
				b.name.localeCompare(a.name, undefined, { numeric: true, sensitivity: "base" })
			);
		} else if (data.order === "oldest") {
			return eventUsers.sort((a, b) =>
				a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
			);
		} else if (data.order === "recent") {
			return eventUsers.sort((a, b) =>
				a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0
			);
		} else {
			return eventUsers.sort((a, b) => (a.identifier > b.identifier ? 1 : a.identifier < b.identifier ? -1 : 0));
		}
	});
