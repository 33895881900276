import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getGroups } from "./generics-modules-data.selectors";
import { IGroup, ISheetsState } from "../interfaces";
import * as _ from "lodash-es";

export const getSheetsState = createFeatureSelector<ISheetsState>("sheets");

export const checkSameEvent = (uid: string) =>
	createSelector(getSheetsState, (state: ISheetsState) => {
		return state.eventId === uid ? true : false;
	});

export const getAllSheets = createSelector(getSheetsState, getGroups, (state: ISheetsState, groups: IGroup[]) => {
	const sheets = _.cloneDeep(state.sheets);
	const groupsSorted = groups.sort((a, b) =>
		a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
	);

	return sheets.map((sheet) => {
		sheet.groupsDatas = groupsSorted.filter((group) => sheet.groups.includes(group.uid));
		return sheet;
	});
});

export const getSheetsForModule = (data: { order: string; moduleId: string }) =>
	createSelector(getSheetsState, (state: ISheetsState) => {
		const sheets = _.cloneDeep(state.sheets).filter((sheet) => sheet.moduleId === data.moduleId);
		if (data.order === "asc") {
			return sheets.sort((a, b) =>
				a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
			);
		} else if (data.order === "desc") {
			return sheets.sort((a, b) =>
				b.name.localeCompare(a.name, undefined, { numeric: true, sensitivity: "base" })
			);
		} else if (data.order === "oldest") {
			return sheets.sort((a, b) =>
				a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
			);
		} else if (data.order === "recent") {
			return sheets.sort((a, b) =>
				a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0
			);
		} else {
			return sheets.sort((a, b) => (a.identifier > b.identifier ? 1 : a.identifier < b.identifier ? -1 : 0));
		}
	});
