<ion-content class="page">
	<div *ngIf="!loader && event && module" class="background">
		<div *ngIf="!customPageId || customPageId === 'all'">
			<div *ngIf="customPages.length > 0">
				<ion-item
					detail
					*ngFor="let page of customPages"
					(click)="openCustomPage(page)"
					class="item-page"
					lines="none"
					[style.border-color]="event.styling.titleColor + '33'"
					mode="md"
				>
					<ion-avatar
						slot="start"
						[style.background]="event.styling.menuColor"
						[style.color]="event.styling.menuTextColor"
					>
						<ion-icon *ngIf="!page.icon && !page.iconFamily" src="assets/icon/Html.svg"> </ion-icon>
						<i class="material-icons icon" *ngIf="page.iconFamily === 'material-icons'">{{ page.icon }}</i>
						<span
							class="b3-icons icon {{ page.iconFamily }}"
							*ngIf="page.iconFamily !== 'material-icons' && page.iconFamily !== 'custom-icon'"
						></span>

						<span *ngIf="page.iconFamily === 'custom-icon'" style="margin: auto; display: flex">
							<ion-icon
								*ngIf="page.icon.includes('data:image/svg+xml')"
								[src]="page.icon"
								class="custom-icon menu-icon-img"
							></ion-icon>
							<img
								*ngIf="!page.icon.includes('data:image/svg+xml')"
								[src]="page.icon"
								class="custom-icon-img"
							/>
						</span>

						<span
							*ngIf="
								page.icon && page.iconFamily !== 'custom-icon' && page.iconFamily !== 'material-icons'
							"
							class="b3-icons {{ page.iconFamily }} icon"
							style="margin: auto"
						>
						</span>
					</ion-avatar>
					<ion-label [style.color]="event.styling.titleColor">
						<h2 class="line-clamp-2">
							{{ page.name[currentLanguage] ? page.name[currentLanguage] : page.name[event.language] }}
						</h2>
					</ion-label>
				</ion-item>
			</div>

			<div *ngIf="customPages.length === 0" class="container">
				<span class="wait-content" [style.color]="event.styling.titleColor">{{
					"texts.waiting_for_content" | translate
				}}</span>
				<ion-icon
					src="assets/images/emptycontent/AUCUNRESULTAT.svg"
					[style.color]="
						event.styling.menuColor.includes('linear')
							? event.styling.menuColor.split(',')[1]
							: event.styling.menuColor
					"
				>
				</ion-icon>
				<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
					<span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
					<span>{{ "interactivity.default_empty_content_text" | translate }}</span>
				</div>
			</div>
		</div>

		<div *ngIf="customPageId && customPageId !== 'all' && customPageSelected" style="padding: 30px 15px">
			<app-pinch-zoom [wheel]="false">
				<ng-container class="background">
					<div
						[innerHTML]="customPageSelected.htmlContent[currentLanguage] | safeHtml"
						style="margin-left: 0px; padding-left: 0px; margin: 0px; width: 100%"
					></div>
				</ng-container>
			</app-pinch-zoom>
		</div>
	</div>

	<div *ngIf="loader">
		<ion-item lines="none" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
			<ion-label>
				<h3>
					<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
				</h3>
			</ion-label>
		</ion-item>
	</div>
</ion-content>
