/**
 * TypeTracking
 */
export enum TypeTracking {
	/* GENERAL type tracking */
	FIRST_ACCESS_TO_EVENT = 1, // DONE
	ACCESS_TO_EVENT = 2, // DONE
	LEAVING_EVENT = 3, // DONE

	/* LOCATIONS type tracking */
	ACCESS_TO_LOCATIONS_MODULE = 20, // DONE
	ACCESS_TO_LOCATION = 21, // DONE

	/* WIDGETS type tracking */
	ACCESS_TO_WIDGET_MODULE = 50, // DONE

	/* EVENTUSERS - GENERAL type tracking */
	ACCESS_TO_EVENT_USERS_MODULE = 100, // DONE
	EDIT_EVENT_USER_PROFILE = 101, // DONE

	/* EVENTUSERS -> ATTENDEES type tracking */
	ACCESS_TO_OWN_ATTENDEE_PROFILE = 102,
	ACCESS_TO_AN_ATTENDEE_PROFILE = 103,
	EDIT_ATTENDEE_PROFILE = 104, // DONE

	/* EVENTUSERS -> SPEAKERS type tracking */
	ACCESS_TO_OWN_SPEAKER_PROFILE = 105,
	ACCESS_TO_A_SPEAKER_PROFILE = 106,
	EDIT_SPEAKER_PROFILE = 107, // DONE

	/* SCHEDULES type tracking */
	ACCESS_TO_SCHEDULE_MODULE = 150, // DONE
	ACCESS_TO_SCHEDULE_SESSION = 151, // DONE
	LEAVING_SCHEDULE_SESSION = 152, // DONE

	/* FEEDS type tracking */
	ACCESS_TO_FEED_MODULE = 200, // DONE
	SEND_LIKE_ON_FEED = 201, // DONE
	REMOVE_LIKE_ON_FEED = 202, // DONE
	SEND_COMMENT_ON_FEED = 203, // DONE
	DELETE_COMMENT_ON_FEED = 204, // DONE

	/* SURVEY type tracking */
	ACCESS_TO_SURVEYS_MODULE = 250, // DONE
	ACCESS_TO_SURVEY = 251, // DONE
	SEND_SURVEY = 252, // DONE

	/* QUIZ type tracking */
	ACCESS_TO_QUIZS_MODULE = 300, // DONE
	ACCESS_TO_QUIZ = 301, // DONE
	SEND_QUIZ = 302, // DONE

	/* ASK_QUESTION type tracking */
	ACCESS_TO_ASK_QUESTION_MODULE = 350, // DONE
	ACCESS_TO_ASK_QUESTION = 351, // DONE
	SEND_QUESTION = 352, // DONE

	/* DOCUMENT type tracking */
	ACCESS_TO_DOCUMENTS_MODULE = 400, // DONE
	ACCESS_TO_DOCUMENTS_FOLDER = 401, // DONE
	OPEN_DOCUMENT = 402, // DONE
	OPEN_CUSTOM_FIELD_DOCUMENT = 403, // DONE

	/* IMAGE type tracking */
	ACCESS_TO_GALLERY_MODULE = 450, // DONE
	ACCESS_TO_GALLERY_FOLDER = 451, // DONE
	OPEN_IMAGE = 452, // DONE

	/* CHAT type tracking */
	ACCESS_TO_CHATS_MODULE = 500, // DONE
	ACCESS_TO_CHAT = 501, // DONE
	SEND_MESSAGE_ON_CHAT = 502, // DONE

	/* CHAT -> GROUP type tracking */
	ACCESS_TO_DISCUSSION_GROUP = 503, // DONE
	SEND_MESSAGE_ON_GROUP = 504, // DONE

	/* CHAT -> VISIO type tracking */
	SEND_VISIO_MESSAGE_ON_CHAT = 505, // DONE
	SEND_VISIO_MESSAGE_ON_GROUP = 506, // ----

	/* VISIO  PART */
	ACCESS_TO_VISIO = 520, // DONE

	/* CHECKIN type tracking */
	ACCESS_TO_CHECKIN_MODULE = 550, // DONE
	CHECKIN_ACTION_PROCESSED = 551, // TODO:
	CHECKIN_MULTI_CHECK_ADD_ACTION_PROCESSED = 552, // TODO:
	CHECKIN_MULTI_CHECK_REMOVE_ACTION_PROCESSED = 553, // TODO:

	/* CHECKIN type tracking */
	ACCESS_TO_SELF_CHECKIN_MODULE = 600, // DONE
	SELF_CHECKIN_ACTION_PROCESSED = 661, // TODO:

	/* RANKING type tracking */
	ACCESS_TO_RANKING_MODULE = 650, // DONE

	/* APPOINT type tracking */
	ACCESS_TO_APPOINTMENT_MODULE = 700, // DONE

	/* INTERACTIVITY type tracking */
	ACCESS_TO_INTERACTIVITY_MODULE = 750, // DONE

	/* GAMIFICATION type tracking */
	ACCESS_TO_GAMIFICATION_MODULE = 800, // ---

	/* CUSTOM_PAGES type tracking */
	ACCESS_TO_CUSTOM_PAGES_MODULE = 850, // DONE

	/* TREASURE_HUNTS type tracking */
	ACCESS_TO_TREASURE_HUNTS_MODULE = 900, // DONE

	/* PERSONNAL_SCHEDULE type tracking */
	ACCESS_TO_PERSONNAL_SCHEDULE_MODULE = 950, // DONE

	/* EVENT_MODULE type tracking */
	ACCESS_TO_INFO_MODULE = 1000, // DONE

	/* TRAININGS_MODULE type tracking */
	ACCESS_TO_TRAININGS_MODULE = 1050, // ----

	/* WORDS_CLOUDS type tracking */
	ACCESS_TO_WORDS_CLOUDS_MODULE = 1100, // DONE

	/* EXTERNALS_INTERACTIVITY type tracking */
	ACCESS_TO_EXTERNALS_INTERACTIVITY_MODULE = 1150, // DONE

	/* CARD EXCHANGE type tracking */
	ACCESS_TO_CARD_EXCHANGE_MODULE = 1200, // ----

	/* OTHER type tracking */
	GO_OUT_OF_APP = 10000, // DONE
	GO_BACK_ON_APP = 10001, // DONE

	/* SHEETS - GENERAL type tracking */
	ACCESS_TO_SHEETS_MODULE = 1300 // DONE
}

/**
 * TypeNameTracking
 */
export const TypeNameTracking = {
	[TypeTracking.ACCESS_TO_WIDGET_MODULE]: "Access to WIDGET module",
	[TypeTracking.ACCESS_TO_EVENT_USERS_MODULE]: "Access to EVENTUSERS module",
	[TypeTracking.ACCESS_TO_AN_ATTENDEE_PROFILE]: "Access to an attendee profile",
	[TypeTracking.ACCESS_TO_OWN_ATTENDEE_PROFILE]: "Access to his own attendee profile",
	[TypeTracking.ACCESS_TO_A_SPEAKER_PROFILE]: "Access to a speaker profile",
	[TypeTracking.ACCESS_TO_OWN_SPEAKER_PROFILE]: "Access to his own speaker profile",
	[TypeTracking.ACCESS_TO_EVENT]: "Access to event",
	[TypeTracking.LEAVING_EVENT]: "Leaving event",
	[TypeTracking.FIRST_ACCESS_TO_EVENT]: "First access to event",
	[TypeTracking.EDIT_EVENT_USER_PROFILE]: "Edit Event User profile",
	[TypeTracking.EDIT_ATTENDEE_PROFILE]: "Edit attendee profile",
	[TypeTracking.EDIT_SPEAKER_PROFILE]: "Edit speaker profile",
	[TypeTracking.ACCESS_TO_SCHEDULE_MODULE]: "Access to SCHEDULE module",
	[TypeTracking.ACCESS_TO_SCHEDULE_SESSION]: "Access to schedule session",
	[TypeTracking.LEAVING_SCHEDULE_SESSION]: "Leaving schedule session",
	[TypeTracking.ACCESS_TO_FEED_MODULE]: "Access to FEED module",
	[TypeTracking.SEND_LIKE_ON_FEED]: "Send like on feed",
	[TypeTracking.REMOVE_LIKE_ON_FEED]: "Remove like on feed",
	[TypeTracking.SEND_COMMENT_ON_FEED]: "Send comment on feed",
	[TypeTracking.DELETE_COMMENT_ON_FEED]: "Delete comment on feed",
	[TypeTracking.ACCESS_TO_SURVEYS_MODULE]: "Access to SURVEYS module",
	[TypeTracking.ACCESS_TO_SURVEY]: "Access to survey",
	[TypeTracking.SEND_SURVEY]: "Send survey",
	[TypeTracking.ACCESS_TO_QUIZS_MODULE]: "Access to QUIZS module",
	[TypeTracking.ACCESS_TO_QUIZ]: "Access to quiz",
	[TypeTracking.SEND_QUIZ]: "Send quiz",
	[TypeTracking.ACCESS_TO_ASK_QUESTION_MODULE]: "Access to ASK QUESTION module",
	[TypeTracking.ACCESS_TO_ASK_QUESTION]: "Access to ask question",
	[TypeTracking.SEND_QUESTION]: "Send question",
	[TypeTracking.ACCESS_TO_DOCUMENTS_MODULE]: "Access to DOCUMENTS module",
	[TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER]: "Access to documents folder",
	[TypeTracking.OPEN_DOCUMENT]: "Open document",
	[TypeTracking.OPEN_CUSTOM_FIELD_DOCUMENT]: "Open custom field document",
	[TypeTracking.ACCESS_TO_GALLERY_MODULE]: "Access to GALLERY module",
	[TypeTracking.ACCESS_TO_GALLERY_FOLDER]: "Access to gallery folder",
	[TypeTracking.OPEN_IMAGE]: "Open image",
	[TypeTracking.ACCESS_TO_CHECKIN_MODULE]: "Access to CHECKIN module",
	[TypeTracking.CHECKIN_ACTION_PROCESSED]: "Checkin action processed",
	[TypeTracking.CHECKIN_MULTI_CHECK_ADD_ACTION_PROCESSED]: "Checkin, multi-action checkin action processed (add)",
	[TypeTracking.CHECKIN_MULTI_CHECK_REMOVE_ACTION_PROCESSED]:
		"Checkin, multi-action checkin action processed (remove)",
	[TypeTracking.SELF_CHECKIN_ACTION_PROCESSED]: "Self checkin action processed",
	[TypeTracking.ACCESS_TO_RANKING_MODULE]: "Access to RANKING module",
	[TypeTracking.ACCESS_TO_LOCATIONS_MODULE]: "Access to LOCATIONS module",
	[TypeTracking.ACCESS_TO_LOCATION]: "Access to location",
	[TypeTracking.ACCESS_TO_CHATS_MODULE]: "Access to CHATS module",
	[TypeTracking.ACCESS_TO_CHAT]: "Access to chat",
	[TypeTracking.SEND_MESSAGE_ON_CHAT]: "Send message on chat",
	[TypeTracking.SEND_VISIO_MESSAGE_ON_CHAT]: "Send visio message on chat",
	[TypeTracking.ACCESS_TO_VISIO]: "Access to visio",
	[TypeTracking.ACCESS_TO_DISCUSSION_GROUP]: "Access to group discussion",
	[TypeTracking.SEND_MESSAGE_ON_GROUP]: "Send message on discussion group",
	[TypeTracking.SEND_VISIO_MESSAGE_ON_GROUP]: "Send visio message on discussion group",
	[TypeTracking.ACCESS_TO_APPOINTMENT_MODULE]: "Access to APPOINTMENT module",
	[TypeTracking.ACCESS_TO_INTERACTIVITY_MODULE]: "Access to INTERACTIVITY module",
	[TypeTracking.ACCESS_TO_GAMIFICATION_MODULE]: "Access to GAMIFICATION module",
	[TypeTracking.ACCESS_TO_CUSTOM_PAGES_MODULE]: "Access to CUSTOM_PAGES module",
	[TypeTracking.ACCESS_TO_TREASURE_HUNTS_MODULE]: "Access to TREASURE HUNTS module",
	[TypeTracking.ACCESS_TO_PERSONNAL_SCHEDULE_MODULE]: "Access to PERSONNAL SCHEDULE module",
	[TypeTracking.ACCESS_TO_INFO_MODULE]: "Access to EVENT INFOS module",
	[TypeTracking.ACCESS_TO_TRAININGS_MODULE]: "Access to TRAININGS module",
	[TypeTracking.ACCESS_TO_WORDS_CLOUDS_MODULE]: "Access to WORDS_CLOUDS module",
	[TypeTracking.ACCESS_TO_EXTERNALS_INTERACTIVITY_MODULE]: "Access to EXTERNALS_INTERACTIVITY module",
	[TypeTracking.ACCESS_TO_CARD_EXCHANGE_MODULE]: "Access to CARD EXCHANGE module",
	[TypeTracking.GO_OUT_OF_APP]: "Leaving application",
	[TypeTracking.GO_BACK_ON_APP]: "Reentering application",
	[TypeTracking.ACCESS_TO_SHEETS_MODULE]: "Access to SHEETS module"
};

/**
 * TypeFormatedNameTracking
 */
export const TypeFormatedNameTracking = {
	[TypeTracking.ACCESS_TO_WIDGET_MODULE]: "ACCESS_TO_WIDGET_MODULE",
	[TypeTracking.ACCESS_TO_EVENT_USERS_MODULE]: "ACCESS_TO_EVENT_USERS_MODULE",
	[TypeTracking.ACCESS_TO_AN_ATTENDEE_PROFILE]: "ACCESS_TO_AN_ATTENDEE_PROFILE",
	[TypeTracking.ACCESS_TO_OWN_ATTENDEE_PROFILE]: "ACCESS_TO_OWN_ATTENDEE_PROFILE",
	[TypeTracking.ACCESS_TO_A_SPEAKER_PROFILE]: "ACCESS_TO_A_SPEAKER_PROFILE",
	[TypeTracking.ACCESS_TO_OWN_SPEAKER_PROFILE]: "ACCESS_TO_OWN_SPEAKER_PROFILE",
	[TypeTracking.ACCESS_TO_EVENT]: "ACCESS_TO_EVENT",
	[TypeTracking.LEAVING_EVENT]: "LEAVING_EVENT",
	[TypeTracking.FIRST_ACCESS_TO_EVENT]: "FIRST_ACCESS_TO_EVENT",
	[TypeTracking.EDIT_EVENT_USER_PROFILE]: "EDIT_EVENT_USER_PROFILE",
	[TypeTracking.EDIT_ATTENDEE_PROFILE]: "EDIT_ATTENDEE_PROFILE",
	[TypeTracking.EDIT_SPEAKER_PROFILE]: "EDIT_SPEAKER_PROFILE",
	[TypeTracking.ACCESS_TO_SCHEDULE_MODULE]: "ACCESS_TO_SCHEDULE_MODULE",
	[TypeTracking.ACCESS_TO_SCHEDULE_SESSION]: "ACCESS_TO_SCHEDULE_SESSION",
	[TypeTracking.LEAVING_SCHEDULE_SESSION]: "LEAVING_SCHEDULE_SESSION",
	[TypeTracking.ACCESS_TO_FEED_MODULE]: "ACCESS_TO_FEED_MODULE",
	[TypeTracking.SEND_LIKE_ON_FEED]: "SEND_LIKE_ON_FEED",
	[TypeTracking.REMOVE_LIKE_ON_FEED]: "REMOVE_LIKE_ON_FEED",
	[TypeTracking.SEND_COMMENT_ON_FEED]: "SEND_COMMENT_ON_FEED",
	[TypeTracking.DELETE_COMMENT_ON_FEED]: "DELETE_COMMENT_ON_FEED",
	[TypeTracking.ACCESS_TO_SURVEYS_MODULE]: "ACCESS_TO_SURVEYS_MODULE",
	[TypeTracking.ACCESS_TO_SURVEY]: "ACCESS_TO_SURVEY",
	[TypeTracking.SEND_SURVEY]: "SEND_SURVEY",
	[TypeTracking.ACCESS_TO_QUIZS_MODULE]: "ACCESS_TO_QUIZS_MODULE",
	[TypeTracking.ACCESS_TO_QUIZ]: "ACCESS_TO_QUIZ",
	[TypeTracking.SEND_QUIZ]: "SEND_QUIZ",
	[TypeTracking.ACCESS_TO_ASK_QUESTION_MODULE]: "ACCESS_TO_ASK_QUESTION_MODULE",
	[TypeTracking.ACCESS_TO_ASK_QUESTION]: "ACCESS_TO_ASK_QUESTION",
	[TypeTracking.SEND_QUESTION]: "SEND_QUESTION",
	[TypeTracking.ACCESS_TO_DOCUMENTS_MODULE]: "ACCESS_TO_DOCUMENTS_MODULE",
	[TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER]: "ACCESS_TO_DOCUMENTS_FOLDER",
	[TypeTracking.OPEN_DOCUMENT]: "OPEN_DOCUMENT",
	[TypeTracking.OPEN_CUSTOM_FIELD_DOCUMENT]: "OPEN_CUSTOM_FIELD_DOCUMENT",
	[TypeTracking.ACCESS_TO_GALLERY_MODULE]: "ACCESS_TO_GALLERY_MODULE",
	[TypeTracking.ACCESS_TO_GALLERY_FOLDER]: "ACCESS_TO_GALLERY_FOLDER",
	[TypeTracking.OPEN_IMAGE]: "OPEN_IMAGE",
	[TypeTracking.ACCESS_TO_CHECKIN_MODULE]: "ACCESS_TO_CHECKIN_MODULE",
	[TypeTracking.ACCESS_TO_RANKING_MODULE]: "ACCESS_TO_RANKING_MODULE",
	[TypeTracking.ACCESS_TO_CHATS_MODULE]: "ACCESS_TO_CHATS_MODULE",
	[TypeTracking.ACCESS_TO_CHAT]: "ACCESS_TO_CHAT",
	[TypeTracking.SEND_MESSAGE_ON_CHAT]: "SEND_MESSAGE_ON_CHAT",
	[TypeTracking.SEND_VISIO_MESSAGE_ON_CHAT]: "SEND_VISIO_MESSAGE_ON_CHAT",
	[TypeTracking.ACCESS_TO_VISIO]: "ACCESS_TO_VISIO",
	[TypeTracking.ACCESS_TO_DISCUSSION_GROUP]: "ACCESS_TO_DISCUSSION_GROUP",
	[TypeTracking.SEND_MESSAGE_ON_GROUP]: "SEND_MESSAGE_ON_GROUP",
	[TypeTracking.SEND_VISIO_MESSAGE_ON_GROUP]: "ACCESS_TO_APPOINTMENT_MODULE",
	[TypeTracking.ACCESS_TO_APPOINTMENT_MODULE]: "ACCESS_TO_APPOINTMENT_MODULE",
	[TypeTracking.ACCESS_TO_INTERACTIVITY_MODULE]: "ACCESS_TO_INTERACTIVITY_MODULE",
	[TypeTracking.ACCESS_TO_GAMIFICATION_MODULE]: "ACCESS_TO_GAMIFICATION_MODULE",
	[TypeTracking.ACCESS_TO_CUSTOM_PAGES_MODULE]: "ACCESS_TO_CUSTOM_PAGES_MODULE",
	[TypeTracking.ACCESS_TO_TREASURE_HUNTS_MODULE]: "ACCESS_TO_TREASURE_HUNTS_MODULE",
	[TypeTracking.ACCESS_TO_PERSONNAL_SCHEDULE_MODULE]: "ACCESS_TO_PERSONNAL_SCHEDULE_MODULE",
	[TypeTracking.ACCESS_TO_INFO_MODULE]: "ACCESS_TO_EVENT_INFO_MODULE",
	[TypeTracking.ACCESS_TO_TRAININGS_MODULE]: "ACCESS_TO_TRAININGS_MODULE",
	[TypeTracking.ACCESS_TO_WORDS_CLOUDS_MODULE]: "ACCESS_TO_WORDS_CLOUDS_MODULE",
	[TypeTracking.ACCESS_TO_EXTERNALS_INTERACTIVITY_MODULE]: "ACCESS_TO_EXTERNALS_INTERACTIVITY_MODULE",
	[TypeTracking.ACCESS_TO_CARD_EXCHANGE_MODULE]: "ACCESS_TO_CARD_EXCHANGE_MODULE",
	[TypeTracking.GO_OUT_OF_APP]: "GO_OUT_OF_APP",
	[TypeTracking.GO_BACK_ON_APP]: "GO_BACK_ON_APP",
	[TypeTracking.ACCESS_TO_SHEETS_MODULE]: "ACCESS_TO_SHEETS_MODULE"
};

export const TypeTrackingNumber = {
	550: TypeTracking.ACCESS_TO_CHECKIN_MODULE,
	551: TypeTracking.CHECKIN_ACTION_PROCESSED,
	552: TypeTracking.CHECKIN_MULTI_CHECK_ADD_ACTION_PROCESSED,
	553: TypeTracking.CHECKIN_MULTI_CHECK_REMOVE_ACTION_PROCESSED,
	600: TypeTracking.ACCESS_TO_SELF_CHECKIN_MODULE,
	661: TypeTracking.SELF_CHECKIN_ACTION_PROCESSED
};

export const checkinActionTypes = [
	TypeTracking.CHECKIN_ACTION_PROCESSED,
	TypeTracking.CHECKIN_MULTI_CHECK_ADD_ACTION_PROCESSED,
	TypeTracking.CHECKIN_MULTI_CHECK_REMOVE_ACTION_PROCESSED
];
