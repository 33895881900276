<ion-header *ngIf="isMobile">
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-button (click)="modalCtr.dismiss()">
				<ion-icon
					slot="icon-only"
					src="assets/icon/ArrowRight 2.svg"
					style="transform: rotateY(180deg)"
				></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>{{ "texts.reactions" | translate }}</ion-title>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-grid>
		<ion-row>
			<ion-segment (ionChange)="segmentChanged($event)" value="all">
				<ion-segment-button value="all">
					<ion-label
						>{{ "texts.all" | translate }} <small>({{ allUser.length }})</small></ion-label
					>
				</ion-segment-button>
				<ion-segment-button
					*ngFor="let icon of groupedReaction"
					[value]="icon.name"
					layout="icon-start"
					[class]="icon.name"
				>
					<ion-label
						>{{ icon.text }} <small>({{ icon.totalUser }})</small></ion-label
					>
					<ion-icon src="assets/icon/Reactions/{{ icon.name }}.svg"></ion-icon>
				</ion-segment-button>
			</ion-segment>
		</ion-row>
		<ion-row>
			<ion-col col-12>
				<ion-list>
					<ion-item
						*ngFor="let eventUser of filtredUser"
						(click)="visiteProfile(eventUser)"
						class="item-list"
						lines="none"
					>
						<ion-avatar slot="start" [ngStyle]="{ padding: eventUser?.photoUrl === '' ? '12px 0' : '' }">
							<ion-img class="picture" [src]="eventUser?.photoUrl" *ngIf="eventUser.photoUrl"></ion-img>
							<ion-img
								class="cus-picture"
								src="assets/icon/User2.svg"
								*ngIf="!eventUser.photoUrl"
							></ion-img>
							<div class="reaction">
								<ion-icon src="assets/icon/Reactions/{{ getIcon(eventUser)?.name }}.svg"></ion-icon>
							</div>
						</ion-avatar>

						<ion-label>
							<h2 [style.color]="event.styling.titleColor">{{ eventUser?.name }}</h2>
							<div
								class="margin-padding-0"
								*ngFor="let customField of getEventUserTags(eventUser); let count = count"
							>
								<h5
									class="custom-fields-event-users-module-item-tags"
									*ngIf="
										(customField.field.text && customField.field.text?.trim() !== '') ||
										(customField.field.multiLanguageText &&
											customField.field.multiLanguageText[event.language]?.trim() !== '')
									"
									[style.color]="event.styling.contentTextColor"
									[style.fontSize]="count === 3 ? 'O.8rem' : '0.9rem'"
								>
									{{
										customField.field.text?.trim() ||
											customField.field.multiLanguageText[event.language]?.trim()
									}}
								</h5>
							</div>
						</ion-label>
					</ion-item>
				</ion-list>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
