import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest, of, skipWhile, switchMap, take } from "rxjs";
import { selectRouteNestedParams } from "../selectors/router.selectors";
import { checkIfDatasEventReady } from "../selectors/utility.selectors";
import {
	AskQuestionsService,
	ChatsService,
	CheckinsService,
	CustomFieldsService,
	CustomPagesService,
	DocumentsService,
	EventUsersService,
	EventsService,
	ExternalInteractivityService,
	FavoritesService,
	FeedNewsService,
	FeedbacksService,
	InteractivityService,
	LocationsService,
	ModulesManagerService,
	NotificationsService,
	QuizsService,
	RankingsService,
	SchedulesService,
	SurveysService,
	TracksService,
	TreasureHuntsService,
	WidgetsService,
	WordCloudsService
} from "../services";
import { AutomationsService } from "../services/automations.service";
import { GroupsService } from "../services/groups.service";
import { RegisterFormService } from "../services/register-form.service";

export const AskQuestionsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SAsksQuestions = inject(AskQuestionsService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initAsksQuestions"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SAsksQuestions.getAskQuestionsOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initAsksQuestions"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const ChatsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SChats = inject(ChatsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initChats"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SChats.getChatsOnStore(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initChats"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const CheckinsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SCheckins = inject(CheckinsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initCheckins"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SCheckins.getCheckinsOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initCheckins"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const ColorsChartResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SInteractivity = inject(InteractivityService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initColorsCharts"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SInteractivity.getColorCharts(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initColorsCharts"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const CustomFieldsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SCustomFields = inject(CustomFieldsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initBaseCustomFields", "initModulesCustomFields"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SCustomFields.getBaseCustomFields(results[0].eventId);
				SCustomFields.getModulesCustomFields(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initBaseCustomFields", "initModulesCustomFields"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const CustomPagesResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SCustomPages = inject(CustomPagesService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initCustomPages"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SCustomPages.getCustomPagesOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initCustomPages"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const DocumentsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SDocuments = inject(DocumentsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initDocuments"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SDocuments.getAllFoldersOfEvent(results[0].eventId);
				SDocuments.getAllDocumentsOfEvent(results[0].eventId);
				SDocuments.getAllImagesOfEvent(results[0].eventId);
				SDocuments.getAllVideosOfEvent(results[0].eventId);
				return store
					.select(checkIfDatasEventReady(["initFolders", "initDocuments", "initImages", "initVideos"]))
					.pipe(
						skipWhile((init) => !init),
						take(1)
					);
			} else {
				return of(true);
			}
		})
	);
};

export const EventUserResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SEventUsers = inject(EventUsersService);
	return store.select(checkIfDatasEventReady(["initEventUser"])).pipe(
		take(1),
		switchMap((results) => {
			if (!results) {
				SEventUsers.initMyEventUser();
				return store.select(checkIfDatasEventReady(["initEventUser"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const EventsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SEvents = inject(EventsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initEvent"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SEvents.getCurrentEventOnStore(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initEvent"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const ExternalInteractivitiesResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SExternalInteractivities = inject(ExternalInteractivityService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initExternalsInteractivity"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SExternalInteractivities.getExternalInteractivityOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initExternalsInteractivity"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const FavoritesResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SFavorites = inject(FavoritesService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initFavorites"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SFavorites.getAllFavoriteFoldersOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initFavorites"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return store.select(selectRouteNestedParams).pipe(
					take(1),
					switchMap((results) => {
						SFavorites.getAllFavoriteFoldersOfEvent(results.eventId);
						return store.select(checkIfDatasEventReady(["initFavorites"])).pipe(
							skipWhile((init) => !init),
							take(1)
						);
					})
				);
			}
		})
	);
};

export const FeedbacksResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SFeedbacks = inject(FeedbacksService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initFeedbacks"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SFeedbacks.getFeedbacksOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initFeedbacks"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const FeedNewsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SFeedNews = inject(FeedNewsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initFeedNews"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SFeedNews.getPosts(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initFeedNews"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const GroupsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SGroups = inject(GroupsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initGroups"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SGroups.getGroupsForEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initGroups"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const LocationsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SLocations = inject(LocationsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initLocations"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SLocations.getLocationsForEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initLocations"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const ModulesResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SModules = inject(ModulesManagerService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initModules"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SModules.getModulesOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initModules"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const NotificationsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SNotifications = inject(NotificationsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initNotifications"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SNotifications.getNotificationsForEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initNotifications"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const QuizsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SQuizs = inject(QuizsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initQuizs"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SQuizs.getQuizsOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initQuizs"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const RankingsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SRankings = inject(RankingsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initRankings"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SRankings.getRankingsOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initRankings"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const SchedulesResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SSchedules = inject(SchedulesService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initSchedules"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SSchedules.initDatasOfAllModules(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initSchedules"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const SurveysResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SSurveys = inject(SurveysService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initSurveys"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SSurveys.getSurveysOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initSurveys"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const TracksResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const STracks = inject(TracksService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initTracks"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				STracks.getTracksOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initTracks"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const TreasureHuntsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const STreasureHunts = inject(TreasureHuntsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initTreasureHunts"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				STreasureHunts.getTreasureHuntsOfEvent(results[0].eventId);
				STreasureHunts.getTreasureHuntsQrcodesOfEvent(results[0].eventId);

				return store.select(checkIfDatasEventReady(["initTreasureHunts"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const WidgetsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SWidgets = inject(WidgetsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initWidgets"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SWidgets.getAllWidgets(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initWidgets"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const WordCloudsResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SWordClouds = inject(WordCloudsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initWordClouds"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SWordClouds.getWordsCloudsOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initWordClouds"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const AutomationResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SAutomations = inject(AutomationsService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initAutomations"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SAutomations.getAllAutomationForEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initAutomations"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		}),
		switchMap(() => {
			return store.select(selectRouteNestedParams).pipe(
				take(1),
				switchMap((results) => {
					SAutomations.getAllAutomationForEvent(results.eventId);
					return store.select(checkIfDatasEventReady(["initAutomations"])).pipe(
						skipWhile((init) => !init),
						take(1)
					);
				})
			);
		})
	);
};

export const RegisterFormResolver: ResolveFn<any> = () => {
	const store = inject(Store);
	const SRegisterForms = inject(RegisterFormService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initRegisterForms"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SRegisterForms.getAllEventRegisterForms(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initRegisterForms"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};
