<ion-header>
	<ion-toolbar
		class="ion-color"
		[style.background]="event.styling.menuColor"
		[style.color]="event.styling.menuTextColor"
	>
		<ion-buttons slot="start">
			<ion-button slot="icon-only" *ngIf="!displayNotifID" (click)="close()">
				<ion-icon src="assets/icon/Close.svg"></ion-icon>
			</ion-button>
			<ion-button slot="icon-only" *ngIf="displayNotifID" (click)="goBack()">
				<ion-icon src="assets/icon/Arrow.svg"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>
			<span *ngIf="!displayNotifID">{{ "chat.notifications" | translate }}</span>
			<span *ngIf="displayNotifID">
				{{ getNotificationTitle(displayNotifID) }}
			</span>
		</ion-title>
	</ion-toolbar>
</ion-header>
<ion-content class="hide-scrollbar" style="--background: var(--bg-color-content)">
	<div class="background" style="width: 100% !important">
		<ng-container *ngIf="!displayNotifID; else details">
			<ion-item
				class="notif-item"
				*ngFor="let notification of notifications"
				(click)="notificationOpen(notification)"
			>
				<ng-container
					*ngIf="
						notification.functionalityType === null ||
						notification.functionalityType === undefined ||
						notification.functionalityType === typeModules.NOTIFICATION
					"
				>
					<ion-label
						[ngStyle]="{
							background: isNotificationRead(notification) ? '' : event.styling.menuColor + '1A'
						}"
					>
						<div class="notif-contents">
							<h2 class="notification-title" [style.color]="event.styling.titleColor">
								{{ getNotificationTitle(notification) }}
							</h2>
							<span class="notif-content-not-detailed" [style.color]="event.styling.contentTextColor">
								<span style="font-weight: 100">{{ getNotificationContent(notification) }}</span>
							</span>
							<p class="time">
								<small> {{ notification.deliveryDate | date : "dd/MM HH:mm" }} </small>
							</p>
						</div>
						<div
							class="badge-not-read"
							slot="end"
							[style.background]="event.styling.menuActiveColor"
							*ngIf="
								currentUser &&
								!currentUser?.updatedSettings?.readNotifications?.includes(notification.uid)
							"
						></div>
					</ion-label>
				</ng-container>
				<ng-container
					*ngIf="
						notification.functionalityType && notification.functionalityType === typeModules.APPOINTMENTS
					"
				>
					<ion-label
						[ngStyle]="{
							background: isNotificationRead(notification) ? '' : event.styling.menuColor + '1A'
						}"
					>
						<div class="notif-contents">
							<h2 class="notification-title" [style.color]="event.styling.titleColor">
								{{ getNotificationTitle(notification) }}
							</h2>
							<div
								*ngIf="notification.functionalityDatas && notification.functionalityDatas.startSchedule"
								style="
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: flex-start;
								"
							>
								<ion-icon
									style="font-size: 12px; margin-right: 10px"
									[style.color]="event.styling.contentTextColor"
									name="calendar-clear-outline"
								></ion-icon>
								<span
									[style.color]="event.styling.contentTextColor"
									style="font-size: 12px; font-weight: 600"
								>
									{{
										getPartOfDate(notification.functionalityDatas.startSchedule, "full") | titlecase
									}}
								</span>
							</div>
							<div
								*ngIf="notification.functionalityDatas && notification.functionalityDatas.startSchedule"
								style="
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: flex-start;
								"
							>
								<ion-icon
									[style.color]="event.styling.contentTextColor"
									style="font-size: 12px; margin-right: 10px"
									name="time-outline"
								></ion-icon>
								<span
									[style.color]="event.styling.contentTextColor"
									style="font-size: 12px; font-weight: 600"
									>{{
										getPartOfDate(notification.functionalityDatas.startSchedule, "time") | titlecase
									}}
									-
									{{
										getPartOfDate(
											getEndScheduleDate(
												notification.functionalityDatas.startSchedule,
												notification.functionalityDatas.duration
											),
											"time"
										) | titlecase
									}}</span
								>
							</div>
							<div
								*ngIf="
									notification.functionalityDatas &&
									notification.functionalityDatas.location &&
									getSpecificLocation(notification.functionalityDatas.location)
								"
								style="
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: flex-start;
								"
							>
								<ion-icon
									[style.color]="event.styling.contentTextColor"
									style="font-size: 12px; margin-right: 10px"
									name="location-outline"
								></ion-icon>
								<span
									[style.color]="event.styling.contentTextColor"
									style="font-size: 12px; font-weight: 600"
								>
									{{ getSpecificLocation(notification.functionalityDatas.location).name | titlecase }}
								</span>
							</div>
							<p class="time">
								<small> {{ notification.creationDate | date : "dd/MM HH:mm" }} </small>
							</p>
						</div>
						<div
							class="badge-not-read"
							slot="end"
							[style.background]="event.styling.menuActiveColor"
							*ngIf="
								currentUser &&
								!currentUser?.updatedSettings?.readNotifications?.includes(notification.uid)
							"
						></div>
					</ion-label>
				</ng-container>
			</ion-item>
		</ng-container>
		<ng-template #details>
			<ion-item>
				<ion-label>
					<h2 class="notification-title" [style.color]="event.styling.titleColor">
						{{ getNotificationTitle(detailNotification) }}
					</h2>
					<span class="notif-content-detailed" [style.color]="event.styling.contentTextColor">
						{{ getNotificationContent(detailNotification) }}  </span
					>
					<p class="time">
						<small>{{ detailNotification.deliveryDate | date : "dd/MM HH:mm" }}</small>
					</p>
				</ion-label>
			</ion-item>
		</ng-template>
	</div>
</ion-content>
