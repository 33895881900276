/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { Firestore, runTransaction, increment, where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { BehaviorSubject, combineLatest, firstValueFrom, from, skipWhile, switchMap, take } from "rxjs";
import { TypeNameTracking, TypeTracking, TypeTrackingNumber, checkinActionTypes } from "../enums/type-analytics";
import { TypeUser } from "../enums/type-user";
import {
	IAskQuestionsResult,
	IDocument,
	IEventUser,
	IMessage,
	IModule,
	IQuizQuestionResult,
	ISchedule,
	IUser,
	IVisio
} from "../interfaces";
import { IAnalyticsUserRoadTracking, IOptionsAnalytics } from "../interfaces/analytics.interfaces";
import { ISurveyQuestionResult } from "../interfaces/surveys.interfaces";
import { getCurrentEventUser, getCurrentUser, getMyEventUser } from "../selectors/auth.selectors";
import { getSpecificChat } from "../selectors/chats.selectors";
import { getSpecificDocument, getSpecificFolder, getSpecificImage } from "../selectors/documents.selectors";
import { getCurrentEvent } from "../selectors/events.selectors";
import { getSpecificPost } from "../selectors/feed-news.selectors";
import { getSpecificLocation } from "../selectors/generics-modules-data.selectors";
import {
	getSpecificAskQuestions,
	getSpecificExternalInteractivity,
	getSpecificQuiz,
	getSpecificSurvey
} from "../selectors/interactivity.selectors";
import { getSpecificModule } from "../selectors/modules.selectors";
import { getInitUser } from "../selectors/utility.selectors";
import { AuthService } from "./auth.service";
import { EventUsersService } from "./event-users.service";
import { FirestoreService } from "./firestore.service";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { SchedulesService } from "./schedules.service";
import { TypeModule } from "../enums/type-module";

@Injectable({
	providedIn: "root"
})
export class AnalyticsService {
	currentEventUser: IEventUser;
	appIsActive$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	user: IUser;
	eventUser: IEventUser;

	constructor(
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private SSchedules: SchedulesService,
		private firestore: Firestore,
		private store: Store,
		private SAuth: AuthService
	) {
		combineLatest([this.store.select(getMyEventUser), this.store.select(getCurrentUser)]).subscribe((results) => {
			this.eventUser = results[0];
			this.user = results[1];
		});
	}

	/*********************************************************/
	/******************* Global analytics ********************/
	/*********************************************************/

	/**
	 * Track leaving application
	 */
	async goOutOfApp() {
		return firstValueFrom(
			combineLatest([
				this.store.select(getMyEventUser),
				this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
			]).pipe(
				take(1),
				switchMap(async (results) => {
					const eventUser = results[0];
					const event = results[1];
					if (!event || !event.settings.allowAnalyticsPlus) return;

					if (eventUser) {
						const tracking: IAnalyticsUserRoadTracking = {
							creationDate: DateTime.now().toISO(),
							eventId: event.uid,
							moduleId: eventUser.moduleId,
							options: {
								targetModuleId: null,
								targetItemId: null,
								pathUids: [],
								pathTypes: []
							} as IOptionsAnalytics,
							type: TypeTracking.GO_OUT_OF_APP,
							typeName: TypeNameTracking[TypeTracking.GO_OUT_OF_APP],
							uid: this.SFirestore.createId(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
							),
							userId: eventUser.uid
						};

						return this.SFirestore.setDocument(
							`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
							tracking
						);
					} else {
						// If there is no eventUser or user
						if (event && (!this.currentEventUser || !eventUser)) {
							const client: any = await firstValueFrom(this.SAuth.getClientIP());
							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: event.uid,
								moduleId: null,
								options: {
									targetModuleId: null,
									targetItemId: null,
									pathUids: [],
									pathTypes: []
								},
								type: TypeTracking.GO_OUT_OF_APP,
								typeName: TypeNameTracking[TypeTracking.GO_OUT_OF_APP],
								uid: this.SFirestore.createId(`anonymous-analytics/${event.uid}/analytics-tracking`),
								userId: client.ip
							};

							return this.SFirestore.setDocument(
								`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
								tracking
							);
						}
					}
				})
			)
		);
	}

	/**
	 * Track leaving event
	 */
	async leavingEvent() {
		return firstValueFrom(
			combineLatest([
				this.store.select(getMyEventUser),
				this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
			]).pipe(
				take(1),
				switchMap(async (results) => {
					const eventUser = results[0];
					const event = results[1];

					if (!event || !event.settings.allowAnalyticsPlus) return;

					if (eventUser) {
						const tracking: IAnalyticsUserRoadTracking = {
							creationDate: DateTime.now().toISO(),
							eventId: event.uid,
							moduleId: eventUser.moduleId,
							options: {
								targetModuleId: null,
								targetItemId: null,
								pathUids: [],
								pathTypes: []
							} as IOptionsAnalytics,
							type: TypeTracking.LEAVING_EVENT,
							typeName: TypeNameTracking[TypeTracking.LEAVING_EVENT],
							uid: this.SFirestore.createId(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
							),
							userId: eventUser.uid
						};

						return this.SFirestore.setDocument(
							`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
							tracking
						);
					} else {
						// If there is no eventUser or user
						if (event && (!this.currentEventUser || !eventUser)) {
							const client: any = await firstValueFrom(this.SAuth.getClientIP());
							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: event.uid,
								moduleId: null,
								options: {
									targetModuleId: null,
									targetItemId: null,
									pathUids: [],
									pathTypes: []
								},
								type: TypeTracking.LEAVING_EVENT,
								typeName: TypeNameTracking[TypeTracking.LEAVING_EVENT],
								uid: this.SFirestore.createId(`anonymous-analytics/${event.uid}/analytics-tracking`),
								userId: client.ip
							};

							return this.SFirestore.setDocument(
								`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
								tracking
							);
						}
					}
				})
			)
		);
	}

	/**
	 * Track going back on application
	 */
	goBackOnApp(device: string) {
		return firstValueFrom(
			combineLatest([
				this.store.select(getMyEventUser),
				this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
			]).pipe(
				take(1),
				switchMap(async (results) => {
					const eventUser = results[0];
					const event = results[1];

					if (!event || !event.settings.allowAnalyticsPlus) return;

					if (eventUser) {
						const tracking: IAnalyticsUserRoadTracking = {
							creationDate: DateTime.now().toISO(),
							eventId: event.uid,
							moduleId: eventUser.moduleId,
							options: {
								device: device,
								targetModuleId: null,
								targetItemId: null,
								pathUids: [],
								pathTypes: []
							},
							type: TypeTracking.GO_BACK_ON_APP,
							typeName: TypeNameTracking[TypeTracking.GO_BACK_ON_APP],
							uid: this.SFirestore.createId(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
							),
							userId: eventUser.uid
						};

						return this.SFirestore.setDocument(
							`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
							tracking
						);
					} else {
						// If there is no eventUser or user
						if (event && (!this.currentEventUser || !eventUser)) {
							const client: any = await firstValueFrom(this.SAuth.getClientIP());
							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: event.uid,
								moduleId: null,
								options: {
									targetModuleId: null,
									targetItemId: null,
									pathUids: [],
									pathTypes: []
								},
								type: TypeTracking.GO_BACK_ON_APP,
								typeName: TypeNameTracking[TypeTracking.GO_BACK_ON_APP],
								uid: this.SFirestore.createId(`anonymous-analytics/${event.uid}/analytics-tracking`),
								userId: client.ip
							};

							return this.SFirestore.setDocument(
								`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
								tracking
							);
						}
					}
				})
			)
		);
	}

	// /*********************************************************/
	// /******************* Events analytics ********************/
	// /*********************************************************/

	/**
	 * eventAccess
	 * @description event access tracking
	 * @returns promise
	 */
	eventAccess(typeTracking: number, device: string) {
		if (typeTracking) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getCurrentEventUser),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];

						if (!event) return;

						if (eventUser && event) {
							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: event.uid,
								moduleId: eventUser.moduleId,
								options: {
									device: device,
									targetModuleId: null,
									targetItemId: null,
									pathUids: [],
									pathTypes: []
								},
								type: typeTracking,
								typeName: TypeNameTracking[typeTracking],
								uid: this.SFirestore.createId(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
								),
								userId: eventUser.uid
							};

							return this.SFirestore.setDocument(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
								tracking
							);
						} else {
							// If there is no eventUser or user
							if (event && (!this.currentEventUser || !eventUser)) {
								const client: any = await firstValueFrom(this.SAuth.getClientIP());
								const tracking: IAnalyticsUserRoadTracking = {
									creationDate: DateTime.now().toISO(),
									eventId: event.uid,
									moduleId: null,
									options: {
										targetModuleId: null,
										targetItemId: null,
										pathUids: [],
										pathTypes: []
									},
									type: typeTracking,
									typeName: TypeNameTracking[typeTracking],
									uid: this.SFirestore.createId(
										`anonymous-analytics/${event.uid}/analytics-tracking`
									),
									userId: client.ip
								};

								return this.SFirestore.setDocument(
									`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
									tracking
								);
							}
						}
					})
				)
			);
		}
	}

	// /*********************************************************/
	// /****************** Modules analytics ********************/
	// /*********************************************************/

	/**
	 * Update module total access
	 * @param eventId
	 * @param moduleId
	 */
	async moduleAccess(eventId: string, trackedModuleId: string, typeTracking: number) {
		return firstValueFrom(
			combineLatest([
				this.store.select(getInitUser).pipe(skipWhile((init) => !init)),
				this.store.select(getInitSpecificEventDatasPart("initEventUser")).pipe(skipWhile((init) => !init))
			]).pipe(
				take(1),
				switchMap(() =>
					combineLatest([
						this.store.select(getMyEventUser),
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
					]).pipe(
						take(1),
						switchMap(async (results) => {
							const eventUser = results[0];
							const event = results[1];
							if (!event) return;
							const trackedModule = await firstValueFrom(
								this.store.select(getSpecificModule(trackedModuleId))
							);

							if (eventId && eventUser && trackedModule && typeTracking) {
								const tracking: IAnalyticsUserRoadTracking = {
									creationDate: DateTime.now().toISO(),
									eventId: eventId,
									moduleId: eventUser.moduleId,
									options: {
										targetModuleId: trackedModule?.uid ? trackedModule.uid : null,
										targetItemId: null,
										pathUids: [trackedModule?.uid ? trackedModule.uid : null],
										pathTypes: [trackedModule?.type ? trackedModule.type : null]
									} as IOptionsAnalytics,
									type: typeTracking,
									typeName: TypeNameTracking[typeTracking],
									uid: this.SFirestore.createId(
										`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
									),
									userId: eventUser.uid
								};

								// Update access module on event user settings
								if (
									eventUser.updatedSettings &&
									eventUser.updatedSettings.accessModulesArray &&
									!eventUser.updatedSettings.accessModulesArray.includes(trackedModuleId)
								) {
									eventUser.updatedSettings.accessModule[trackedModuleId] = DateTime.local().toISO();
									eventUser.updatedSettings.accessModulesArray.push(trackedModuleId);
									this.SEventUsers.updateEventUserUpdatedSettings(
										eventId,
										eventUser.moduleId,
										eventUser.uid,
										{
											accessModule: eventUser.updatedSettings.accessModule,
											accessModulesArray: eventUser.updatedSettings.accessModulesArray
										}
									);
								} else if (eventUser.updatedSettings && !eventUser.updatedSettings.accessModulesArray) {
									eventUser.updatedSettings.accessModule[trackedModuleId] = DateTime.local().toISO();
									eventUser.updatedSettings.accessModulesArray = [trackedModuleId];
									this.SEventUsers.updateEventUserUpdatedSettings(
										eventId,
										eventUser.moduleId,
										eventUser.uid,
										{
											accessModule: eventUser.updatedSettings.accessModule,
											accessModulesArray: eventUser.updatedSettings.accessModulesArray
										}
									);
								}
								return this.SFirestore.setDocument(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
									tracking
								);
							} else if (event && event.settings.visibility) {
								// If there is no eventUser or user
								if (trackedModule && typeTracking && (!this.currentEventUser || !eventUser)) {
									const client: any = await firstValueFrom(this.SAuth.getClientIP());
									const tracking: IAnalyticsUserRoadTracking = {
										creationDate: DateTime.now().toISO(),
										eventId: eventId,
										moduleId: null,
										options: {
											targetModuleId: trackedModule?.uid ? trackedModule.uid : null,
											targetItemId: null,
											pathUids: [trackedModule?.uid ? trackedModule.uid : null],
											pathTypes: [trackedModule?.type ? trackedModule.type : null]
										},
										type: typeTracking,
										typeName: TypeNameTracking[typeTracking],
										uid: this.SFirestore.createId(
											`anonymous-analytics/${event.uid}/analytics-tracking`
										),
										userId: client.ip
									};

									return this.SFirestore.setDocument(
										`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
										tracking
									);
								}
							}
						})
					)
				)
			)
		);
	}

	// /*********************************************************/
	// /******************* Attendees analytics *****************/
	// /*********************************************************/

	editEventUserProfile(eventUser: IEventUser) {
		if (!eventUser) return;

		return firstValueFrom(
			this.store.select(getCurrentEvent).pipe(
				skipWhile((event) => !event),
				take(1),
				switchMap((event) => {
					if (!event) return;

					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventUser.eventId,
						moduleId: eventUser.moduleId,
						options: {
							targetModuleId: eventUser?.moduleId ? eventUser.moduleId : null,
							targetItemId: eventUser?.uid ? eventUser.uid : null,
							pathUids: [eventUser?.uid ? eventUser.uid : null],
							pathTypes: [eventUser?.type ? eventUser.type : null]
						} as IOptionsAnalytics,
						type:
							eventUser.type === TypeUser.ATTENDEE
								? TypeTracking.EDIT_ATTENDEE_PROFILE
								: TypeTracking.EDIT_SPEAKER_PROFILE,
						typeName:
							eventUser.type === TypeUser.ATTENDEE
								? TypeNameTracking[TypeTracking.EDIT_ATTENDEE_PROFILE]
								: TypeNameTracking[TypeTracking.EDIT_SPEAKER_PROFILE],
						uid: this.SFirestore.createId(
							`events/${eventUser.eventId}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
						),
						userId: eventUser.uid
					};

					return this.SFirestore.setDocument(
						`events/${eventUser.eventId}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
						tracking
					);
				})
			)
		);
	}

	// /*********************************************************/
	// /******************* Schedules analytics *****************/
	// /*********************************************************/

	/**
	 * Track access to schedule session
	 * @param eventId
	 * @param moduleId
	 * @param sessionId
	 */
	async scheduleSessionAccess(eventId: string, trackedModuleId: string, sessionId: string) {
		if (eventId && trackedModuleId && sessionId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event || !event.settings.allowAnalyticsPlus) return;

							const trackedModule = await firstValueFrom(
								this.store.select(getSpecificModule(trackedModuleId))
							);
							const trackedSession = await firstValueFrom(
								this.SSchedules.getSpecificSession(eventId, trackedModuleId, sessionId)
								// this.store.select(getSpecificSession(sessionId))
							);

							if (!trackedModule || !trackedSession)
								throw new Error("No trackedModule or trackedSession found");

							if (eventUser) {
								const tracking: IAnalyticsUserRoadTracking = {
									creationDate: DateTime.now().toISO(),
									eventId: eventUser.eventId,
									moduleId: eventUser.moduleId,
									options: {
										targetModuleId: trackedModule?.uid ? trackedModule.uid : null,
										targetItemId: trackedSession?.uid ? trackedSession.uid : null,
										pathUids: [trackedModule.uid, trackedSession.uid],
										pathTypes: [trackedModule.type, null]
									} as IOptionsAnalytics,
									type: TypeTracking.ACCESS_TO_SCHEDULE_SESSION,
									typeName: TypeNameTracking[TypeTracking.ACCESS_TO_SCHEDULE_SESSION],
									uid: this.SFirestore.createId(
										`events/${eventId}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
									),
									userId: eventUser.uid
								};

								return this.SFirestore.setDocument(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
									tracking
								);
							} else {
								// If there is no eventUser or user
								const client: any = await firstValueFrom(this.SAuth.getClientIP());
								const tracking: IAnalyticsUserRoadTracking = {
									creationDate: DateTime.now().toISO(),
									eventId: eventId,
									moduleId: null,
									options: {
										targetModuleId: trackedModule?.uid ? trackedModule.uid : null,
										targetItemId: trackedSession?.uid ? trackedSession.uid : null,
										pathUids: [trackedModule.uid, trackedSession.uid],
										pathTypes: [trackedModule.type, null]
									},
									type: TypeTracking.ACCESS_TO_SCHEDULE_SESSION,
									typeName: TypeNameTracking[TypeTracking.ACCESS_TO_SCHEDULE_SESSION],
									uid: this.SFirestore.createId(
										`anonymous-analytics/${event.uid}/analytics-tracking`
									),
									userId: client.ip
								};

								return this.SFirestore.setDocument(
									`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
									tracking
								);
							}
						} catch (error) {
							console.error("Error on 'scheduleSessionAccess()' : ", error);
						}
					})
				)
			);
		} else {
			return;
		}
	}

	/**
	 * Track leaving from a schedule session
	 * @param eventId
	 * @param moduleId
	 * @param sessionId
	 */
	async scheduleSessionLeaving(eventId: string, moduleId: string, eventUser: IEventUser, sessionId: string) {
		if (eventId && moduleId && sessionId) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificModule(moduleId)),
						this.SSchedules.getSpecificSession(eventId, moduleId, sessionId)
						// this.store.select(getSpecificSession(sessionId))
					])
				);

				const event = results[0];
				const trackedModule = results[1];
				const trackedSession = results[2];

				if (!event || !event.settings.allowAnalyticsPlus) return;

				if (!trackedModule || !trackedSession) throw new Error("No tracked datas found for module " + moduleId);

				if (eventUser) {
					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventUser.eventId,
						moduleId: eventUser.moduleId,
						options: {
							targetModuleId: trackedModule?.uid ? trackedModule.uid : null,
							targetItemId: trackedSession?.uid ? trackedSession.uid : null,
							pathUids: [trackedModule.uid, trackedSession.uid],
							pathTypes: [trackedModule.type, null]
						} as IOptionsAnalytics,
						type: TypeTracking.LEAVING_SCHEDULE_SESSION,
						typeName: TypeNameTracking[TypeTracking.LEAVING_SCHEDULE_SESSION],
						uid: this.SFirestore.createId(
							`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
						),
						userId: eventUser.uid
					};

					return this.SFirestore.setDocument(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
						tracking
					);
				} else {
					// If there is no eventUser or user
					const client: any = await firstValueFrom(this.SAuth.getClientIP());
					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventId,
						moduleId: null,
						options: {
							targetModuleId: trackedModule?.uid ? trackedModule.uid : null,
							targetItemId: trackedSession?.uid ? trackedSession.uid : null,
							pathUids: [trackedModule.uid, trackedSession.uid],
							pathTypes: [trackedModule.type, null]
						},
						type: TypeTracking.LEAVING_SCHEDULE_SESSION,
						typeName: TypeNameTracking[TypeTracking.LEAVING_SCHEDULE_SESSION],
						uid: this.SFirestore.createId(`anonymous-analytics/${event.uid}/analytics-tracking`),
						userId: client.ip
					};

					return this.SFirestore.setDocument(
						`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
						tracking
					);
				}
			} catch (error) {
				console.error("Error on 'scheduleSessionLeaving()' : ", error);
			}
		} else {
			return;
		}
	}

	// /*********************************************************/
	// /******************* DOCUMENTS analytics *****************/
	// /*********************************************************/

	/**
	 * documentFolderAccess
	 * @param eventId
	 * @param moduleId
	 * @param folderId
	 * @returns
	 */
	async documentFolderAccess(eventId: string, moduleId: string, folderId: string) {
		if (eventId && moduleId && folderId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event || !event.settings.allowAnalyticsPlus) return;

							const trackedFolder = await firstValueFrom(this.store.select(getSpecificFolder(folderId)));
							const trackedDocumentModule = await firstValueFrom(
								this.store.select(getSpecificModule(moduleId))
							);

							if (!trackedFolder || !trackedDocumentModule) throw new Error("No tracked datas found");

							if (eventUser) {
								const tracking: IAnalyticsUserRoadTracking = {
									creationDate: DateTime.now().toISO(),
									eventId: eventUser.eventId,
									moduleId: eventUser.moduleId,
									options: {
										targetModuleId: trackedDocumentModule?.uid ? trackedDocumentModule.uid : null,
										targetItemId: trackedFolder?.uid ? trackedFolder.uid : null,
										pathUids: [trackedDocumentModule.uid, trackedFolder.uid],
										pathTypes: [trackedDocumentModule.type, null]
									} as IOptionsAnalytics,
									type: TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER,
									typeName: TypeNameTracking[TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER],
									uid: this.SFirestore.createId(
										`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
									),
									userId: eventUser.uid
								};

								return this.SFirestore.setDocument(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
									tracking
								);
							} else {
								// If there is no eventUser or user
								const client: any = await firstValueFrom(this.SAuth.getClientIP());
								const tracking: IAnalyticsUserRoadTracking = {
									creationDate: DateTime.now().toISO(),
									eventId: eventId,
									moduleId: null,
									options: {
										targetModuleId: trackedDocumentModule?.uid ? trackedDocumentModule.uid : null,
										targetItemId: trackedFolder?.uid ? trackedFolder.uid : null,
										pathUids: [trackedDocumentModule.uid, trackedFolder.uid],
										pathTypes: [trackedDocumentModule.type, null]
									},
									type: TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER,
									typeName: TypeNameTracking[TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER],
									uid: this.SFirestore.createId(
										`anonymous-analytics/${event.uid}/analytics-tracking`
									),
									userId: client.ip
								};

								return this.SFirestore.setDocument(
									`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
									tracking
								);
							}
						} catch (error) {
							console.error("Error on 'documentFolderAccess()' : ", error);
						}
					})
				)
			);
		}
	}

	/**
	 * updateDocumentTotalAccess
	 * @param eventId
	 * @param moduleId
	 * @param eventUser
	 * @param folderId
	 * @param documentId
	 * @returns
	 */
	updateDocumentTotalAccess(eventId: string, moduleId: string, folderId: string, documentId: string) {
		if (eventId && moduleId && folderId && documentId) {
			return runTransaction(this.firestore, (transaction) => {
				return transaction
					.get(
						this.SFirestore.docRef(
							`events/${eventId}/modules/${moduleId}/folders/${folderId}/documents/${documentId}`
						)
					)
					.then((moduleDoc) => {
						if (!moduleDoc.exists()) {
							throw new Error("Document does not exist!");
						}

						transaction.update(
							this.SFirestore.docRef(
								`events/${eventId}/modules/${moduleId}/folders/${folderId}/documents/${documentId}`
							),
							{
								totalAccess: increment(1)
							}
						);
					})
					.catch((error) => {
						console.error("Transaction failed : ", error);
					});
			});
		}
	}

	/**
	 * documentOpended
	 * @param eventId
	 * @param moduleId
	 * @param eventUser
	 * @param folderId
	 * @param documentId
	 * @returns
	 */
	async documentOpended(
		eventId: string,
		moduleId: string,
		eventUser: IEventUser,
		folderId: string,
		documentId: string
	) {
		if (eventId && moduleId && folderId) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificDocument(documentId)),
						this.store.select(getSpecificFolder(folderId)),
						this.store.select(getSpecificModule(moduleId))
					])
				);
				const event = results[0];
				const trackedDocument = results[1];
				const trackedFolder = results[2];
				const trackedDocumentModule = results[3];

				if (!event) return;

				if (!trackedDocument || !trackedFolder || !trackedDocumentModule)
					throw new Error("No tracked datas found");

				if (eventUser) {
					this.updateDocumentTotalAccess(eventId, moduleId, folderId, documentId);
					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventUser.eventId,
						moduleId: eventUser.moduleId,
						options: {
							targetModuleId: trackedDocumentModule?.uid ? trackedDocumentModule.uid : null,
							targetItemId: trackedDocument?.uid ? trackedDocument.uid : null,
							pathUids: [trackedDocumentModule.uid, trackedFolder.uid, trackedDocument.uid],
							pathTypes: [trackedDocumentModule.type, null, trackedDocument.type],
							pathNames: [trackedDocumentModule.name, trackedFolder.name, trackedDocument.name]
						} as IOptionsAnalytics,
						type: TypeTracking.OPEN_DOCUMENT,
						typeName: TypeNameTracking[TypeTracking.OPEN_DOCUMENT],
						uid: this.SFirestore.createId(
							`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
						),
						userId: eventUser.uid
					};

					return this.SFirestore.setDocument(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
						tracking
					);
				} else {
					// If there is no eventUser or user
					const client: any = await firstValueFrom(this.SAuth.getClientIP());
					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventId,
						moduleId: null,
						options: {
							targetModuleId: trackedDocumentModule?.uid ? trackedDocumentModule.uid : null,
							targetItemId: trackedDocument?.uid ? trackedDocument.uid : null,
							pathUids: [trackedDocumentModule.uid, trackedFolder.uid, trackedDocument.uid],
							pathTypes: [trackedDocumentModule.type, null, trackedDocument.type],
							pathNames: [trackedDocumentModule.name, trackedFolder.name, trackedDocument.name]
						},
						type: TypeTracking.OPEN_DOCUMENT,
						typeName: TypeNameTracking[TypeTracking.OPEN_DOCUMENT],
						uid: this.SFirestore.createId(`anonymous-analytics/${event.uid}/analytics-tracking`),
						userId: client.ip
					};

					return this.SFirestore.setDocument(
						`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
						tracking
					);
				}
			} catch (error) {
				console.error("Error on 'documentOpended()' : ", error);
			}
		} else {
			return;
		}
	}

	/**
	 * Custom field document Opended
	 * @param eventId
	 * @param eventUser
	 * @param trackedDocument
	 * @returns
	 */
	async customFieldDocumentOpended(eventId: string, eventUser: IEventUser, trackedDocument: IDocument) {
		!eventUser && (eventUser = this.currentEventUser);

		if (eventId && trackedDocument) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificFolder(trackedDocument.folderId)),
						this.store.select(getSpecificModule(trackedDocument.moduleId))
					])
				);
				const event = results[0];
				const trackedFolder = results[1];
				const trackedDocumentModule = results[2];

				if (!event) return;

				if (eventUser) {
					const eventUserModule = await firstValueFrom(
						this.store.select(getSpecificModule(eventUser.moduleId))
					);

					if (!trackedDocument || !trackedFolder || !trackedDocumentModule)
						throw new Error("No tracked datas found");

					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventUser.eventId,
						moduleId: eventUser.moduleId,
						options: {
							pathUids: [trackedDocumentModule.uid, trackedFolder.uid, trackedDocument.uid],
							targetItemId: trackedDocument?.uid ? trackedDocument.uid : null,
							pathTypes: [trackedDocument.type, null, trackedDocument.type],
							pathNames: [trackedDocumentModule.name, eventUserModule.name, trackedDocument.name]
						} as IOptionsAnalytics,
						type: TypeTracking.OPEN_CUSTOM_FIELD_DOCUMENT,
						typeName: TypeNameTracking[TypeTracking.OPEN_CUSTOM_FIELD_DOCUMENT],
						uid: this.SFirestore.createId(
							`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
						),
						userId: eventUser.uid
					};

					return this.SFirestore.setDocument(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
						tracking
					);
				} else {
					// If there is no eventUser or user
					const client: any = await firstValueFrom(this.SAuth.getClientIP());
					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventId,
						moduleId: null,
						options: {
							targetModuleId: trackedDocumentModule?.uid ? trackedDocumentModule.uid : null,
							targetItemId: trackedDocument?.uid ? trackedDocument.uid : null,
							pathUids: [trackedDocumentModule.uid, trackedFolder.uid, trackedDocument.uid],
							pathTypes: [trackedDocument.type, null, trackedDocument.type],
							pathNames: [trackedDocumentModule.name, null, trackedDocument.name]
						},
						type: TypeTracking.OPEN_CUSTOM_FIELD_DOCUMENT,
						typeName: TypeNameTracking[TypeTracking.OPEN_CUSTOM_FIELD_DOCUMENT],
						uid: this.SFirestore.createId(`anonymous-analytics/${event.uid}/analytics-tracking`),
						userId: client.ip
					};

					return this.SFirestore.setDocument(
						`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
						tracking
					);
				}
			} catch (error) {
				console.error("Error on 'documentOpended()' : ", error);
			}
		} else {
			return;
		}
	}

	// /*********************************************************/
	// /******************* GALLERY analytics *****************/
	// /*********************************************************/

	/**
	 * galleryFolderAccess
	 * @param eventId
	 * @param trackedModuleId
	 * @param folderId
	 * @returns
	 */
	galleryFolderAccess(eventId: string, trackedModuleId: string, folderId: string) {
		if (eventId && trackedModuleId && folderId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event || !event.settings.allowAnalyticsPlus) return;

							const trackedGalleryModule = await firstValueFrom(
								this.store.select(getSpecificModule(trackedModuleId))
							);

							const trackedFolder = await firstValueFrom(this.store.select(getSpecificFolder(folderId)));

							if (!trackedFolder || !trackedGalleryModule) throw new Error("No tracked datas found");

							if (eventUser) {
								const tracking: IAnalyticsUserRoadTracking = {
									creationDate: DateTime.now().toISO(),
									eventId: eventUser.eventId,
									moduleId: eventUser.moduleId,
									options: {
										targetModuleId: trackedGalleryModule?.uid ? trackedGalleryModule.uid : null,
										targetItemId: trackedFolder?.uid ? trackedFolder.uid : null,
										pathUids: [trackedGalleryModule.uid, trackedFolder.uid],
										pathTypes: [trackedGalleryModule.type, null]
									} as IOptionsAnalytics,
									type: TypeTracking.ACCESS_TO_GALLERY_FOLDER,
									typeName: TypeNameTracking[TypeTracking.ACCESS_TO_GALLERY_FOLDER],
									uid: this.SFirestore.createId(
										`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
									),
									userId: eventUser.uid
								};

								return this.SFirestore.setDocument(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
									tracking
								);
							} else {
								// If there is no eventUser or user
								const client: any = await firstValueFrom(this.SAuth.getClientIP());
								const tracking: IAnalyticsUserRoadTracking = {
									creationDate: DateTime.now().toISO(),
									eventId: eventId,
									moduleId: null,
									options: {
										targetModuleId: trackedGalleryModule?.uid ? trackedGalleryModule.uid : null,
										targetItemId: trackedFolder?.uid ? trackedFolder.uid : null,
										pathUids: [trackedGalleryModule.uid, trackedFolder.uid],
										pathTypes: [trackedGalleryModule.type, null]
									},
									type: TypeTracking.ACCESS_TO_GALLERY_FOLDER,
									typeName: TypeNameTracking[TypeTracking.ACCESS_TO_GALLERY_FOLDER],
									uid: this.SFirestore.createId(
										`anonymous-analytics/${event.uid}/analytics-tracking`
									),
									userId: client.ip
								};

								return this.SFirestore.setDocument(
									`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
									tracking
								);
							}
						} catch (error) {
							console.error("Error on 'galleryFolderAccess()': ", error);
						}
					})
				)
			);
		}
	}

	/**
	 * imageOpended
	 * @param eventId
	 * @param trackedModuleId
	 * @param eventUser
	 * @param folderId
	 * @param imageId
	 * @returns
	 */
	async imageOpended(
		eventId: string,
		trackedModuleId: string,
		eventUser: IEventUser,
		folderId: string,
		imageId: string
	) {
		if (eventId && trackedModuleId && folderId && imageId) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificImage(imageId)),
						this.store.select(getSpecificFolder(folderId)),
						this.store.select(getSpecificModule(trackedModuleId))
					])
				);
				const event = results[0];
				const trackedImage = results[1];
				const trackedFolder = results[2];
				const trackedImageModule = results[3];

				if (!event) return;

				if (!trackedImage || !trackedFolder || !trackedImageModule) throw new Error("No tracked datas found");

				if (eventUser) {
					this.updateImageTotalAccess(eventId, trackedModuleId, folderId, imageId);
					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventUser.eventId,
						moduleId: eventUser.moduleId,
						options: {
							targetModuleId: trackedImageModule?.uid ? trackedImageModule.uid : null,
							targetItemId: trackedImage?.uid ? trackedImage.uid : null,
							pathUids: [trackedImageModule.uid, trackedFolder.uid, trackedImage.uid],
							pathTypes: [trackedImageModule.type, null, trackedImage.type]
						} as IOptionsAnalytics,
						type: TypeTracking.OPEN_IMAGE,
						typeName: TypeNameTracking[TypeTracking.OPEN_IMAGE],
						uid: this.SFirestore.createId(
							`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
						),
						userId: eventUser.uid
					};

					return this.SFirestore.setDocument(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
						tracking
					);
				} else {
					// If there is no eventUser or user
					const client: any = await firstValueFrom(this.SAuth.getClientIP());
					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventId,
						moduleId: null,
						options: {
							targetModuleId: trackedImageModule?.uid ? trackedImageModule.uid : null,
							targetItemId: trackedImage?.uid ? trackedImage.uid : null,
							pathUids: [trackedImageModule.uid, trackedFolder.uid, trackedImage.uid],
							pathTypes: [trackedImageModule.type, null, trackedImage.type]
						},
						type: TypeTracking.OPEN_IMAGE,
						typeName: TypeNameTracking[TypeTracking.OPEN_IMAGE],
						uid: this.SFirestore.createId(`anonymous-analytics/${event.uid}/analytics-tracking`),
						userId: client.ip
					};

					return this.SFirestore.setDocument(
						`anonymous-analytics/${event.uid}/analytics-tracking/${tracking.uid}`,
						tracking
					);
				}
			} catch (error) {
				console.error("Error on 'imageOpended() : ", error);
			}
		} else {
			return;
		}
	}

	/**
	 * updateDocumentTotalAccess
	 */
	updateImageTotalAccess(eventId: string, moduleId: string, folderId: string, imageId: string) {
		if (eventId && moduleId && folderId && imageId) {
			return runTransaction(this.firestore, (transaction) => {
				return transaction
					.get(
						this.SFirestore.docRef(
							`events/${eventId}/modules/${moduleId}/folders/${folderId}/images/${imageId}`
						)
					)
					.then((moduleDoc) => {
						if (!moduleDoc.exists()) {
							throw new Error("Document does not exist!");
						}

						transaction.update(
							this.SFirestore.docRef(
								`events/${eventId}/modules/${moduleId}/folders/${folderId}/images/${imageId}`
							),
							{
								totalAccess: increment(1)
							}
						);
					})
					.catch((error) => {
						console.error("Transaction failed : ", error);
					});
			});
		}
	}

	// /*********************************************************/
	// /******************* FEED analytics **********************/
	// /*********************************************************/

	/**
	 * sendOrRemoveLike
	 * @description *tracking function of send_remove_like actions
	 * @param eventId
	 * @param trackedModuleId
	 * @param postId
	 * @param typeTracking
	 * @returns
	 */
	async sendOrRemoveLike(
		eventId: string,
		trackedModuleId: string,
		eventUser: IEventUser,
		postId: string,
		typeTracking: number
	) {
		if (eventId && trackedModuleId && eventUser && postId && typeTracking) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificModule(trackedModuleId)),
						this.store.select(getSpecificPost(postId))
					])
				);
				const event = results[0];
				const trackedFeedModule = results[1];
				const trackedPost = results[2];

				if (!event) return;

				if (trackedFeedModule && trackedPost) {
					const tracking: IAnalyticsUserRoadTracking = {
						creationDate: DateTime.now().toISO(),
						eventId: eventId,
						moduleId: eventUser.moduleId,
						options: {
							targetModuleId: trackedFeedModule?.uid ? trackedFeedModule.uid : null,
							targetItemId: trackedPost?.uid ? trackedPost.uid : null,
							pathUids: [trackedFeedModule.uid, trackedPost.uid],
							pathTypes: [trackedFeedModule.type, null]
						} as IOptionsAnalytics,
						type: typeTracking,
						typeName: TypeNameTracking[typeTracking],
						uid: this.SFirestore.createId(
							`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
						),
						userId: eventUser.uid
					};

					return this.SFirestore.setDocument(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
						tracking
					);
				}
			} catch (error) {
				console.error("Error on 'sendOrRemoveLike' function : ", error);
			}
		}
	}

	/**
	 * sendOrRemoveComment
	 * @description * tracking function of send_delete_comment actions
	 * @param eventId
	 * @param trackedModuleId
	 * @param eventUser
	 * @param postId
	 * @param typeTracking
	 * @param commentCreationDate
	 * @returns
	 */
	async sendOrRemoveComment(
		eventId: string,
		trackedModuleId: string,
		eventUser: IEventUser,
		postId: string,
		typeTracking: number,
		commentCreationDate: string
	) {
		try {
			const results = await firstValueFrom(
				combineLatest([
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
					this.store.select(getSpecificModule(trackedModuleId)),
					this.store.select(getSpecificPost(postId)),
					from(
						this.SFirestore.getDocuments(
							`events/${eventId}/modules/${trackedModuleId}/feed-news/${postId}/feed-news-comments`,
							[where("creationDate", "==", commentCreationDate)]
						)
					)
				])
			);
			const event = results[0];
			const trackedFeedModule = results[1];
			const trackedPost = results[2];
			const trackedComment = results[3];

			if (!event) return;

			if (trackedFeedModule && trackedPost && trackedComment) {
				const tracking: IAnalyticsUserRoadTracking = {
					creationDate: DateTime.now().toISO(),
					eventId: eventUser.eventId,
					moduleId: eventUser.moduleId,
					options: {
						targetModuleId: trackedFeedModule?.uid ? trackedFeedModule.uid : null,
						targetItemId: trackedComment.docs[0]?.data()?.uid ? trackedComment.docs[0].data().uid : null,
						pathUids: [
							trackedFeedModule.uid,
							trackedPost.uid,
							trackedComment.docs[0]?.data()?.uid ? trackedComment.docs[0].data().uid : null
						],
						pathTypes: [trackedFeedModule.type, null, null]
					} as IOptionsAnalytics,
					type: typeTracking,
					typeName: TypeNameTracking[typeTracking],
					uid: this.SFirestore.createId(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
					),
					userId: eventUser.uid
				};

				return this.SFirestore.setDocument(
					`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
					tracking
				);
			}
		} catch (error) {
			console.error("Error : ", error);
		}
	}

	// /*********************************************************/
	// /******************* SURVEY analytics ********************/
	// /*********************************************************/

	/**
	 * surveyAccess
	 * @param eventId
	 * @param trackedModuleId
	 * @param surveyId
	 * @returns
	 */
	async surveyAccess(eventId: string, trackedModuleId: string, surveyId: string) {
		if (eventId && trackedModuleId && surveyId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser).pipe(skipWhile((eventUser) => !eventUser)),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event || !event.settings.allowAnalyticsPlus) return;

							const trackedSurvey = await firstValueFrom(this.store.select(getSpecificSurvey(surveyId)));
							const trackedSurveyModule = await firstValueFrom(
								this.store.select(getSpecificModule(trackedModuleId))
							);

							if (!trackedSurveyModule || !trackedSurvey)
								throw new Error("No trackedSurveyModule or trackedSurvey found");

							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: eventUser.eventId,
								moduleId: eventUser.moduleId,
								options: {
									targetModuleId: trackedSurveyModule?.uid ? trackedSurveyModule.uid : null,
									targetItemId: trackedSurvey.uid ? trackedSurvey.uid : null,
									pathUids: [trackedSurveyModule.uid, trackedSurvey.uid],
									pathTypes: [trackedSurveyModule.type, null]
								} as IOptionsAnalytics,
								type: TypeTracking.ACCESS_TO_SURVEY,
								typeName: TypeNameTracking[TypeTracking.ACCESS_TO_SURVEY],
								uid: this.SFirestore.createId(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
								),
								userId: eventUser.uid
							};

							return this.SFirestore.setDocument(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
								tracking
							);
						} catch (error) {
							console.error("Error on 'surveyAccess()' : ", error);
						}
					})
				)
			);
		} else {
			return;
		}
	}

	/**
	 * sendSurvey
	 * @param eventId
	 * @param trackedModuleId
	 * @param eventUser
	 * @param surveyId
	 * @param results
	 * @returns
	 */
	async sendSurvey(
		eventId: string,
		trackedModuleId: string,
		eventUser: IEventUser,
		surveyId: string,
		resultsSurvey: ISurveyQuestionResult[]
	) {
		if (eventId && trackedModuleId && eventUser && surveyId) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificSurvey(surveyId)),
						this.store.select(getSpecificModule(trackedModuleId))
					])
				);
				const event = results[0];
				const trackedSurvey = results[1];
				const trackedSurveyModule = results[2];

				if (!event || !event.settings.allowAnalyticsPlus) return;

				if (!trackedSurvey || !trackedSurveyModule)
					throw new Error("No trackedSurvey or TrackedSurveyModule found");

				const tracking: IAnalyticsUserRoadTracking = {
					creationDate: DateTime.now().toISO(),
					eventId: eventUser.eventId,
					moduleId: eventUser.moduleId,
					options: {
						targetModuleId: trackedSurveyModule?.uid ? trackedSurveyModule.uid : null,
						targetItemId: resultsSurvey
							.map((result) => result.uid)
							?.toString()
							?.replace(",", ";")
							? resultsSurvey
									.map((result) => result.uid)
									?.toString()
									?.replace(",", ";")
							: null,
						pathUids: [
							trackedSurveyModule.uid,
							trackedSurvey.uid,
							resultsSurvey
								.map((result) => result.uid)
								?.toString()
								?.replace(",", ";")
						],
						pathTypes: [
							trackedSurveyModule.type,
							null,
							resultsSurvey
								.map((result) => result.type)
								?.toString()
								?.replace(",", ";")
						]
					} as IOptionsAnalytics,
					type: TypeTracking.SEND_SURVEY,
					typeName: TypeNameTracking[TypeTracking.SEND_SURVEY],
					uid: this.SFirestore.createId(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
					),
					userId: eventUser.uid
				};

				return this.SFirestore.setDocument(
					`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
					tracking
				);
			} catch (error) {
				console.error("Error on 'sendSurvey()' :", error);
			}
		}
	}

	// /*********************************************************/
	// /*********** EXTERNALS INTERACTIVITY analytics ***********/
	// /*********************************************************/

	/**
	 * externalInteractivityAccess
	 * @param eventId
	 * @param trackedModuleId
	 * @param externalInteractivityId
	 * @returns
	 */
	async externalInteractivityAccess(eventId: string, trackedModuleId: string, externalInteractivityId: string) {
		if (eventId && trackedModuleId && externalInteractivityId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser).pipe(skipWhile((eventUser) => !eventUser)),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event || !event.settings.allowAnalyticsPlus) return;

							const trackedExternalInteractivity = await firstValueFrom(
								this.store.select(getSpecificExternalInteractivity(externalInteractivityId))
							);
							const trackedExternalInteractivityModule = await firstValueFrom(
								this.store.select(getSpecificModule(trackedModuleId))
							);

							if (!trackedExternalInteractivityModule || !trackedExternalInteractivity)
								throw new Error(
									"No trackedExternalInteractivityModule or trackedExternalInteractivity found"
								);

							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: eventUser.eventId,
								moduleId: eventUser.moduleId,
								options: {
									targetModuleId: trackedExternalInteractivityModule?.uid
										? trackedExternalInteractivityModule.uid
										: null,
									targetItemId: trackedExternalInteractivity.uid
										? trackedExternalInteractivity.uid
										: null,
									pathUids: [
										trackedExternalInteractivityModule.uid,
										trackedExternalInteractivity.uid
									],
									pathTypes: [trackedExternalInteractivityModule.type, null]
								} as IOptionsAnalytics,
								type: TypeTracking.ACCESS_TO_SURVEY,
								typeName: TypeNameTracking[TypeTracking.ACCESS_TO_SURVEY],
								uid: this.SFirestore.createId(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
								),
								userId: eventUser.uid
							};

							return this.SFirestore.setDocument(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
								tracking
							);
						} catch (error) {
							console.error("Error on 'externalInteractivityAccess()' : ", error);
						}
					})
				)
			);
		} else {
			return;
		}
	}

	// /*********************************************************/
	// /****************** LOCATIONS analytics ******************/
	// /*********************************************************/

	/**
	 * locationAccess
	 * @param eventId
	 * @param trackedModuleId
	 * @param locationId
	 * @returns
	 */
	async locationAccess(eventId: string, trackedModuleId: string, locationId: string) {
		if (eventId && trackedModuleId && locationId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser).pipe(skipWhile((eventUser) => !eventUser)),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event || !event.settings.allowAnalyticsPlus) return;

							const trackedLocation = await firstValueFrom(
								this.store.select(getSpecificLocation(locationId))
							);
							const trackedLocationModule = await firstValueFrom(
								this.store.select(getSpecificModule(trackedModuleId))
							);

							if (!trackedLocationModule || !trackedLocation)
								throw new Error("No trackedLocationModule or trackedLocation found");

							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: eventUser.eventId,
								moduleId: eventUser.moduleId,
								options: {
									pathUids: [trackedLocationModule.uid, trackedLocation.uid],
									pathTypes: [trackedLocationModule.type, null]
								} as IOptionsAnalytics,
								type: TypeTracking.ACCESS_TO_LOCATION,
								typeName: TypeNameTracking[TypeTracking.ACCESS_TO_LOCATION],
								uid: this.SFirestore.createId(
									`events/${eventId}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
								),
								userId: eventUser.uid
							};

							return this.SFirestore.setDocument(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
								tracking
							);
						} catch (error) {
							console.error("Error on 'locationAccess()' : ", error);
						}
					})
				)
			);
		} else {
			return;
		}
	}

	// /*********************************************************/
	// /******************** QUIZ analytics *********************/
	// /*********************************************************/

	/**
	 * quizAccess
	 * @param eventId
	 * @param trackedModuleId
	 * @param quizId
	 * @returns
	 */
	async quizAccess(eventId: string, trackedModuleId: string, quizId: string) {
		if (eventId && trackedModuleId && quizId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser).pipe(skipWhile((eventUser) => !eventUser)),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event || !event.settings.allowAnalyticsPlus) return;

							const trackedQuiz = await firstValueFrom(this.store.select(getSpecificQuiz(quizId)));
							const trackedQuizModule = await firstValueFrom(
								this.store.select(getSpecificModule(trackedModuleId))
							);

							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: eventUser.eventId,
								moduleId: eventUser.moduleId,
								options: {
									targetModuleId: trackedQuizModule?.uid ? trackedQuizModule.uid : null,
									targetItemId: trackedQuiz.uid ? trackedQuiz.uid : null,
									pathUids: [trackedQuizModule.uid, trackedQuiz.uid],
									pathTypes: [trackedQuizModule.type, null]
								} as IOptionsAnalytics,
								type: TypeTracking.ACCESS_TO_QUIZ,
								typeName: TypeNameTracking[TypeTracking.ACCESS_TO_QUIZ],
								uid: this.SFirestore.createId(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
								),
								userId: eventUser.uid
							};

							return this.SFirestore.setDocument(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
								tracking
							);
						} catch (error) {
							console.error("Error on 'quizAccess()' : ", error);
						}
					})
				)
			);
		}
	}

	/**
	 * sendQuiz
	 * @param eventId
	 * @param moduleId
	 * @param eventUser
	 * @param quizId
	 * @param result
	 * @returns
	 */
	async sendQuiz(
		eventId: string,
		trackedModuleId: string,
		eventUser: IEventUser,
		quizId: string,
		result: IQuizQuestionResult
	) {
		if (eventId && eventUser && trackedModuleId && quizId && result) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificQuiz(quizId)),
						this.store.select(getSpecificModule(trackedModuleId))
					])
				);
				const event = results[0];
				const trackedQuiz = results[1];
				const trackedQuestion = trackedQuiz.questions.find((question) => question.uid === result.questionId);
				const trackedQuizModule = results[2];

				if (!event || !event.settings.allowAnalyticsPlus) return;

				if (!trackedQuiz || !trackedQuestion || !trackedQuizModule) throw new Error("No tracked datas found !");

				const tracking: IAnalyticsUserRoadTracking = {
					creationDate: DateTime.now().toISO(),
					eventId: eventUser.eventId,
					moduleId: eventUser.moduleId,
					options: {
						targetModuleId: trackedQuizModule?.uid ? trackedQuizModule.uid : null,
						targetItemId: result.uid ? result.uid : null,
						pathUids: [trackedQuizModule.uid, trackedQuiz.uid, trackedQuestion.uid, result.uid],
						pathTypes: [trackedQuizModule.type, null, trackedQuestion.type, result.type]
					} as IOptionsAnalytics,
					type: TypeTracking.SEND_QUIZ,
					typeName: TypeNameTracking[TypeTracking.SEND_QUIZ],
					uid: this.SFirestore.createId(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
					),
					userId: eventUser.uid
				};

				return this.SFirestore.setDocument(
					`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
					tracking
				);
			} catch (error) {
				console.error("Error on 'sendQuiz()' : ", error);
			}
		} else {
			return;
		}
	}

	// /*********************************************************/
	// /**************** ASK QUESTION analytics *****************/
	// /*********************************************************/

	/**
	 * askQuestionAccess
	 * @param eventId
	 * @param trackedModuleId
	 * @param askQuestionId
	 * @returns
	 */
	askQuestionAccess(eventId: string, trackedModuleId: string, askQuestionId: string) {
		if (eventId && trackedModuleId && askQuestionId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser).pipe(skipWhile((eventUser) => !eventUser)),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event || !event.settings.allowAnalyticsPlus) return;

							const trackedAskQuestion = await firstValueFrom(
								this.store.select(getSpecificAskQuestions(askQuestionId))
							);
							const trackedAskQuestionModule = await firstValueFrom(
								this.store.select(getSpecificModule(trackedModuleId))
							);

							if (!trackedAskQuestion || !trackedAskQuestionModule) return;

							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: eventUser.eventId,
								moduleId: eventUser.moduleId,
								options: {
									targetModuleId: trackedAskQuestionModule?.uid ? trackedAskQuestionModule.uid : null,
									targetItemId: trackedAskQuestion.uid ? trackedAskQuestion.uid : null,
									pathUids: [trackedAskQuestionModule.uid, trackedAskQuestion.uid],
									pathTypes: [trackedAskQuestionModule.type, null]
								} as IOptionsAnalytics,
								type: TypeTracking.ACCESS_TO_ASK_QUESTION,
								typeName: TypeNameTracking[TypeTracking.ACCESS_TO_ASK_QUESTION],
								uid: this.SFirestore.createId(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
								),
								userId: eventUser.uid
							};

							return this.SFirestore.setDocument(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
								tracking
							);
						} catch (error) {
							console.error("Error on 'askQuestionAccess()' : ", error);
						}
					})
				)
			);
		}
	}

	/**
	 * sendQuestion
	 * @param eventId
	 * @param moduleId
	 * @param eventUser
	 * @param askQuestionId
	 * @param result
	 * @returns
	 */
	async sendQuestion(
		eventId: string,
		moduleId: string,
		eventUser: IEventUser,
		askQuestionId: string,
		result: IAskQuestionsResult
	) {
		if (eventId && moduleId && eventUser && askQuestionId && result) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificAskQuestions(askQuestionId)),
						this.store.select(getSpecificModule(moduleId))
					])
				);
				const event = results[0];
				const trackedAskQuestion = results[1];
				const trackedQuizModule = results[2];

				if (!event || !event.settings.allowAnalyticsPlus) return;

				if (!trackedAskQuestion || !trackedQuizModule) throw new Error("No tracked data found for module ");

				const tracking: IAnalyticsUserRoadTracking = {
					creationDate: DateTime.now().toISO(),
					eventId: eventUser.eventId,
					moduleId: eventUser.moduleId,
					options: {
						targetModuleId: trackedQuizModule?.uid ? trackedQuizModule.uid : null,
						targetItemId: result.uid ? result.uid : null,
						pathUids: [trackedQuizModule.uid, trackedAskQuestion.uid, result.uid],
						pathTypes: [trackedQuizModule.type, null, null]
					} as IOptionsAnalytics,
					type: TypeTracking.SEND_QUESTION,
					typeName: TypeNameTracking[TypeTracking.SEND_QUESTION],
					uid: this.SFirestore.createId(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
					),
					userId: eventUser.uid
				};

				return this.SFirestore.setDocument(
					`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
					tracking
				);
			} catch (error) {
				console.error("Error on 'sendQuestionerror()' : " + error);
			}
		}
	}

	// /*********************************************************/
	// /******************* CHAT analytics **********************/
	// /*********************************************************/

	/**
	 * chatAccess
	 * @param eventId
	 * @param moduleId
	 * @param chatId
	 * @returns
	 */
	async chatAccess(eventId: string, moduleId: string, chatId: string) {
		if (eventId && moduleId && chatId) {
			return firstValueFrom(
				combineLatest([
					this.store.select(getMyEventUser).pipe(skipWhile((eventUser) => !eventUser)),
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				]).pipe(
					take(1),
					switchMap(async (results) => {
						const eventUser = results[0];
						const event = results[1];
						try {
							if (!event) return;

							const trackedChat = await firstValueFrom(this.store.select(getSpecificChat(chatId)));
							const trackedChatModule = await firstValueFrom(
								this.store.select(getSpecificModule(moduleId))
							);

							if (!trackedChat || !trackedChatModule) return;

							const tracking: IAnalyticsUserRoadTracking = {
								creationDate: DateTime.now().toISO(),
								eventId: eventUser.eventId,
								moduleId: eventUser.moduleId,
								options: {
									targetModuleId: trackedChatModule?.uid ? trackedChatModule.uid : null,
									targetItemId: trackedChat.uid ? trackedChat.uid : null,
									pathUids: [trackedChatModule.uid, trackedChat.uid],
									pathTypes: [trackedChatModule.type, trackedChat.type]
								} as IOptionsAnalytics,
								type:
									trackedChat.type === 0
										? TypeTracking.ACCESS_TO_CHAT
										: TypeTracking.ACCESS_TO_DISCUSSION_GROUP,
								typeName:
									trackedChat.type === 0
										? TypeNameTracking[TypeTracking.ACCESS_TO_CHAT]
										: TypeNameTracking[TypeTracking.ACCESS_TO_DISCUSSION_GROUP],
								uid: this.SFirestore.createId(
									`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
								),
								userId: eventUser.uid
							};

							return this.SFirestore.setDocument(
								`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
								tracking
							);
						} catch (error) {
							console.error("Error on 'chatAccess()' : ", error);
						}
					})
				)
			);
		} else {
			return;
		}
	}

	/**
	 * sendChatOrGroupMessage
	 * @param eventId
	 * @param trackedModuleId
	 * @param eventUser
	 * @param chatId
	 * @param typeTracking
	 * @param message
	 * @returns
	 */
	async sendChatOrGroupMessage(
		eventId: string,
		trackedModuleId: string,
		eventUser: IEventUser,
		chatId: string,
		typeTracking: number,
		message?: IMessage
	) {
		if (eventId && trackedModuleId && eventUser && typeTracking && chatId) {
			try {
				const results = await firstValueFrom(
					combineLatest([
						this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
						this.store.select(getSpecificChat(chatId)),
						this.store.select(getSpecificModule(trackedModuleId))
					])
				);
				const event = results[0];
				const trackedChat = results[1];
				const trackedChatModule = results[2];

				if (!event) return;

				if (!trackedChat || !trackedChatModule) return;

				const tracking: IAnalyticsUserRoadTracking = {
					creationDate: DateTime.now().toISO(),
					eventId: eventUser.eventId,
					moduleId: eventUser.moduleId,
					options: {
						targetModuleId: trackedChatModule?.uid ? trackedChatModule.uid : null,
						targetItemId: message?.uid ? message.uid : null,
						pathUids: [trackedChatModule.uid, trackedChat.uid, message?.uid ? message.uid : null],
						pathTypes: [trackedChatModule.type, null, message?.type ? message.type : null]
					} as IOptionsAnalytics,
					type: typeTracking,
					typeName: TypeNameTracking[typeTracking],
					uid: this.SFirestore.createId(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
					),
					userId: eventUser.uid
				};

				return this.SFirestore.setDocument(
					`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
					tracking
				);
			} catch (error) {
				console.error("Error on 'sendChatOrGroupMessage()' : ", error);
			}
		} else {
			return;
		}
	}

	// /*********************************************************/
	// /******************* VISIO analytics **********************/
	// /*********************************************************/

	async visioAccess(
		eventId: string,
		eventUser: IEventUser,
		trackedItem?: ISchedule | IVisio,
		itemType?: TypeModule.APPOINTMENTS | TypeModule.SCHEDULE
	) {
		if (eventId && eventUser) {
			try {
				const event = await firstValueFrom(
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				);
				if (!event) return;

				let trackedModule: IModule;
				if (trackedItem) {
					if (itemType === TypeModule.SCHEDULE) {
						trackedModule = await firstValueFrom(
							this.store.select(getSpecificModule(trackedItem.moduleId))
						);
					} else {
						trackedModule = await firstValueFrom(
							this.store.select(getSpecificModule(trackedItem.moduleId))
						);
					}
				}

				const tracking: IAnalyticsUserRoadTracking = {
					creationDate: DateTime.now().toISO(),
					eventId: eventUser.eventId,
					moduleId: eventUser.moduleId,
					options: {
						targetModuleId: trackedModule?.uid ? trackedModule.uid : null,
						targetItemId: trackedItem?.uid ? trackedItem.uid : null,
						pathUids: [trackedModule ? trackedModule.uid : null, trackedItem ? trackedItem.uid : null],
						pathTypes: [trackedModule ? trackedModule.type : null, null]
					} as IOptionsAnalytics,
					type: TypeTracking.ACCESS_TO_VISIO,
					typeName: TypeNameTracking[TypeTracking.ACCESS_TO_VISIO],
					uid: this.SFirestore.createId(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
					),
					userId: eventUser.uid
				};

				return this.SFirestore.setDocument(
					`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
					tracking
				);
			} catch (error) {
				console.error("Error on 'scheduleSessionAccess()' : ", error);
			}
		} else {
			return;
		}
	}

	// /*********************************************************/
	// /******************* Checkin analytics *****************/
	// /*********************************************************/
	async checkinProcessed(eventId: string, eventUser: IEventUser, checkinData: any) {
		if (eventId && eventUser && checkinData) {
			try {
				const event = await firstValueFrom(
					this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event))
				);
				if (!event) return;

				const tracking: IAnalyticsUserRoadTracking = {
					creationDate: DateTime.now().toISO(),

					eventId: eventUser.eventId,
					moduleId: eventUser.moduleId,
					options: {
						data: {
							...checkinData
						},
						targetModuleId: checkinData.moduleId,
						targetItemId: checkinData.checkinId,
						pathUids: [checkinData.moduleId, checkinData.checkinId],
						pathTypes: [
							checkinActionTypes.includes(checkinData.type) ? TypeModule.CHECKIN : TypeModule.ATTENDEE,
							checkinData.type
						]
					} as IOptionsAnalytics,
					type: TypeTrackingNumber[checkinData.type],
					typeName: TypeNameTracking[checkinData.type],
					uid: this.SFirestore.createId(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user`
					),
					userId: eventUser.uid
				};

				return this.SFirestore.setDocument(
					`events/${event.uid}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}/analytics-tracking-user/${tracking.uid}`,
					tracking
				);
			} catch (error) {
				console.error("Error on 'checkinProcessed()' : ", error);
			}
		} else {
			return;
		}
	}
}
