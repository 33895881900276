import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IAutomationState } from "../interfaces";

export const getAutomationsState = createFeatureSelector<IAutomationState>("automations");

export const checkSameEvent = (uid: string) =>
	createSelector(getAutomationsState, (state: IAutomationState) => {
		return state.eventId === uid ? true : false;
	});

export const getAutomationsOfEvent = createSelector(getAutomationsState, (state: IAutomationState) =>
	_.cloneDeep(state.automations)
);

export const getSpecificAutomation = createSelector(getAutomationsState, (state: IAutomationState, uid: string) =>
	_.cloneDeep(state.automations).find((automation) => automation.uid === uid)
);
