<ion-content class="page">
	<div *ngIf="!loader && event && module && eventUser" class="background">
		<div *ngIf="notes.length > 0">
			<ion-item
				detail
				*ngFor="let note of notes"
				style="cursor: pointer"
				(click)="openNote(note)"
				class="item-page"
				lines="none"
				[style.border-color]="event.styling.titleColor + '33'"
				mode="md"
			>
				<div
					style="
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
						width: 100%;
					"
				>
					<ion-label [style.color]="event.styling.titleColor">
						<h2 class="line-clamp-2">{{ note.title }}</h2>
					</ion-label>
					<span style="font-size: small; opacity: 0.5">{{
						SUtility.getPartOfDate(event, null, note.creationDate, "full")
					}}</span>
				</div>
				<div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-end">
					<ion-icon
						style="margin-right: 20px; font-size: 25px"
						icon="pencil-outline"
						[style.color]="'black'"
						(click)="$event.stopPropagation(); editNote(note)"
					>
					</ion-icon>
					<ion-icon
						style="margin-right: 20px; font-size: 25px"
						icon="trash-outline"
						[style.color]="'red'"
						(click)="$event.stopPropagation(); deleteNote(note)"
					>
					</ion-icon>
				</div>
			</ion-item>
		</div>

		<div
			*ngIf="notes.length === 0"
			class="container"
			style="display: flex; flex-direction: column; align-items: center; justify-content: center"
		>
			<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
				<span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
				<span>{{ "interactivity.note-taking-description" | translate }}</span>
			</div>
		</div>
		<ion-fab
			class="fabs-buttons"
			[style.bottom]="'30px'"
			[style.right]="'calc(30px + var(--ion-safe-area-right, 0px))'"
			slot="fixed"
			vertical="bottom"
			horizontal="end"
		>
			<ion-fab-button class="basic-fab-button" (click)="addNote()">
				<ion-icon name="add"></ion-icon>
			</ion-fab-button>
		</ion-fab>
	</div>

	<div *ngIf="loader">
		<ion-item lines="none" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
			<ion-label>
				<h3>
					<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
				</h3>
			</ion-label>
		</ion-item>
	</div>

	<app-login-register-buttons *ngIf="!loader && event && !eventUser" [event]="event"></app-login-register-buttons>
</ion-content>
