import { Injectable } from "@angular/core";
import { combineLatest, from, skipWhile, Subscription, switchMap, take } from "rxjs";
import { Store } from "@ngrx/store";
import { checkSameEvent } from "../selectors/interactivity.selectors";
import { IQuiz, IQuizQuestionResult } from "../interfaces/quiz.interfaces";
import { GetAllQuizs, GetAllQuizsResults } from "../actions/interactivity.actions";
import { getCurrentEventUser } from "../selectors/auth.selectors";
import * as _ from "lodash-es";
import { FirestoreService } from "./firestore.service";
import { where } from "@angular/fire/firestore";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IEventUser } from "../interfaces";

@Injectable({
	providedIn: "root"
})
export class QuizsService {
	quizsSub: Subscription;
	quizsResultsSub: Subscription;
	previousResults: any;
	currentEventUser: IEventUser;

	constructor(private SFirestore: FirestoreService, private store: Store) {}

	unsubscribeAll() {
		if (this.quizsSub && !this.quizsSub.closed) {
			this.quizsSub.unsubscribe();
		}
		if (this.quizsResultsSub && !this.quizsResultsSub.closed) {
			this.quizsResultsSub.unsubscribe();
		}
	}

	/**
	 * Get all quizs of event
	 * @param eventId
	 */
	getQuizsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "quizs", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.quizsSub && !this.quizsSub.closed) {
					return;
				} else if (!sameEvent && this.quizsSub && !this.quizsSub.closed) {
					this.quizsSub.unsubscribe();
				}

				this.quizsSub = this.SFirestore.collectionGroupValueChangesDocuments("quizs", [
					where("eventId", "==", eventId)
				]).subscribe({
					next: (quizs: IQuiz[]) => {
						this.store.dispatch(GetAllQuizs({ payload: quizs, eventId: eventId }));

						this.store
							.select(getInitSpecificEventDatasPart("initQuizs"))
							.pipe(take(1))
							.subscribe((init) => {
								if (!init) {
									this.store.dispatch(
										InitSpecificEventDatasPart({ part: "initQuizs", payload: true })
									);
								}
							});
					}
				});

				this.store
					.select(getCurrentEventUser)
					.pipe(
						skipWhile((eventUser) => !eventUser),
						take(1)
					)
					.subscribe((eventUser) => {
						if (
							(sameEvent &&
								this.quizsResultsSub &&
								!this.quizsResultsSub.closed &&
								eventUser &&
								this.currentEventUser &&
								eventUser.uid === this.currentEventUser.uid) ||
							!eventUser
						) {
							return;
						} else if (!sameEvent && this.quizsResultsSub && !this.quizsResultsSub.closed) {
							this.quizsResultsSub.unsubscribe();
						}

						this.currentEventUser = eventUser;

						this.quizsResultsSub = this.SFirestore.collectionGroupValueChangesDocuments("quiz-results", [
							where("eventId", "==", eventId),
							where("userId", "==", eventUser.uid)
						]).subscribe({
							next: (quizsResults: IQuizQuestionResult[]) => {
								this.store.dispatch(GetAllQuizsResults({ payload: quizsResults }));
							}
						});
					});
			});
	}

	/**
	 * Get results of a quiz question
	 * @param eventId
	 * @param moduleId
	 * @param quizId
	 * @param questionId
	 * @returns
	 */
	getResultsOfQuestion(eventId: string, moduleId: string, quizId: string, questionId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/quizs/${quizId}/quiz-results`,
			[where("questionId", "==", questionId)]
		);
	}

	/**
	 * Create quiz result
	 * @param eventId
	 * @param moduleId
	 * @param quizId
	 * @param result
	 */
	createQuizResults(eventId: string, moduleId: string, quizId: string, result: IQuizQuestionResult) {
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/quizs/${quizId}/quiz-results/${result.uid}`,
			result
		);
	}

	/**
	 * Update quiz result
	 * @param eventId
	 * @param moduleId
	 * @param quizId
	 * @param result
	 */
	updateQuizResults(eventId: string, moduleId: string, quizId: string, result: IQuizQuestionResult) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/quizs/${quizId}/quiz-results/${result.uid}`,
			_.omit(result, ["eventId", "moduleId", "uid", "quizId", "questionId", "userId", "type", "creationDate"])
		);
	}

	/**
	 * Clear results of a quiz for a event user
	 * @param eventId
	 * @param moduleId
	 * @param quizId
	 * @param eventUserId
	 */
	clearResultQuizOfEventUser(eventId: string, moduleId: string, quizId: string, eventUserId: string) {
		return combineLatest([
			from(
				this.SFirestore.getDocumentsCollectionGroup(`quiz-results`, [
					where("eventId", "==", eventId),
					where("quizId", "==", quizId),
					where("userId", "==", eventUserId)
				])
			).pipe(
				switchMap((results) => {
					const batch = this.SFirestore.getBatch();
					results.forEach((result) => {
						batch.delete(result.ref);
					});
					return batch.commit();
				})
			),
			from(
				this.SFirestore.getDocumentsCollectionGroup(`event-user-points-records`, [
					where("eventId", "==", eventId),
					where("options.moduleId", "==", moduleId),
					where("options.quizId", "==", quizId),
					where("userId", "==", eventUserId)
				])
			).pipe(
				switchMap((results) => {
					const batch = this.SFirestore.getBatch();
					results.forEach((result) => {
						batch.delete(result.ref);
					});
					return batch.commit();
				})
			)
		]);
	}
}
