import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { skipWhile, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { IEvent, IEventUser, IFeedback, IModule, ISchedule } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getAccessiblesFeedbacksByType } from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { SchedulesService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-feedbacks",
	templateUrl: "./feedbacks.component.html",
	styleUrls: ["./feedbacks.component.scss"]
})
export class FeedbacksComponent implements OnDestroy, OnChanges {
	subscriptions: Subscription[] = [];

	@Input() componentMode: boolean;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;

	@Input() sessionId: string;
	session: ISchedule;
	eventUser: IEventUser;

	feedbacks: IFeedback[] = [];

	unansweredFeedbacks: IFeedback[] = [];
	answeredFeedbacks: IFeedback[] = [];
	feedbacksSaved: any;

	feedbackSelectedId: string;
	currentLanguage: string = environment.platform.defaultLanguage;

	isMobile: boolean = false;

	constructor(
		private platform: Platform,
		private store: Store,
		private navCtrl: NavController,
		private SSchedules: SchedulesService,
		private STranslate: TranslateService
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;

							if (this.sessionId) {
								this.initDatas();
							}
						});
				});
		}
	}

	ngOnChanges() {
		if (this.componentMode) {
			this.subscriptions.forEach((sub) => sub.unsubscribe());
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		if (this.sessionId) {
			this.getSession();
		}
		this.getAccessiblesFeedbacks();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
				if (this.module && !this.componentMode) {
					this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
			})
		);
	}

	/**
	 * Get session
	 */
	getSession() {
		this.subscriptions.push(
			this.SSchedules.getSpecificSession(this.eventId, this.moduleId, this.sessionId).subscribe((session) => {
				// this.store.select(getSpecificSession(this.sessionId)).subscribe((session) => {
				this.session = session;
			})
		);
	}

	/**
	 * Get accessibles feedbacks
	 */
	getAccessiblesFeedbacks() {
		this.subscriptions.push(
			this.store
				.pipe(
					skipWhile(() => this.componentMode && !this.session),
					select(
						getAccessiblesFeedbacksByType(
							this.componentMode ? 1 : 0,
							this.componentMode && this.session ? this.session : null
						)
					)
				)
				.subscribe((feedbacks) => {
					this.feedbacks = feedbacks;
					if (this.componentMode) {
						this.answeredFeedbacks = this.feedbacks.filter(
							(feedback) =>
								feedback.eventUserResults &&
								feedback.eventUserResults.length > 0 &&
								feedback.eventUserResults.every((res) => res.userId === this.eventUser.uid) &&
								(feedback.link.linkedSpecifics.includes(this.sessionId) ||
									feedback.link.linkedModules.includes(this.moduleId))
						);
						this.unansweredFeedbacks = this.feedbacks.filter(
							(feedback) => !feedback.eventUserResults || feedback.eventUserResults.length === 0
						);
					}
					if (this.unansweredFeedbacks.length === 1 && !this.componentMode) {
						this.openFeedback(this.unansweredFeedbacks[0]);
					}
				})
		);
	}

	/**
	 * Get answered or not feedbacks
	 * @param type
	 * @returns
	 */
	getAnsweredOrNotFeedbacks(type: string) {
		return type === "answered"
			? this.feedbacks.filter((feedback) => feedback.eventUserResults && feedback.eventUserResults.length > 0)
			: this.feedbacks.filter((feedback) => !feedback.eventUserResults || feedback.eventUserResults.length === 0);
	}

	/**
	 * Navigate to feedback or open it in component mode
	 * @param feedback
	 */
	openFeedback(feedback: IFeedback) {
		if (!this.componentMode) {
			this.navCtrl.navigateForward(`/event/${this.eventId}/feedback/${feedback.moduleId}/${feedback.uid}`);
		} else {
			// Switch to feedback view on component mode
			this.changeView.emit({ segmentType: "feedbacks", type: "feedback", uid: feedback.uid });
		}
	}
}
