/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { or, where } from "@angular/fire/firestore";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Observable, combineLatest, from, of } from "rxjs";
import { debounceTime, map, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
	ChatsService,
	FirestoreService,
	LanguagesService,
	NotificationsService,
	UtilityService,
	VisiosService
} from ".";
import { TypeModule } from "../enums/type-module";
import { IEvent, IEventUser, IModule, INotification, INotificationLanguages } from "../interfaces";
import { getModulesByType, getSpecificModule } from "../selectors/modules.selectors";
import { EventUsersService } from "./event-users.service";
import { getEmailDataFopAppointmentTaken } from "../datas/mails-templates";
import { HttpClient } from "@angular/common/http";
import {
	IAppointment,
	IAppointmentRule,
	IAvailableTimeSlotSchedule,
	IAvailableTimeSlots
} from "../interfaces/appointments.interfaces";
import { AppointmentTimeSlotStatus } from "../enums/type-appointments";
import { getCurrentEvent } from "../selectors/events.selectors";

@Injectable({
	providedIn: "root"
})
export class AppointmentsService {
	constructor(
		private https: HttpClient,
		private store: Store,
		private SFirestore: FirestoreService,
		private SChats: ChatsService,
		private SVisios: VisiosService,
		private SEventUsers: EventUsersService,
		private SLanguages: LanguagesService,
		private SNotifications: NotificationsService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		public platform: Platform
	) {}

	/**
	 *
	 * New appointments
	 *
	 */

	canRequestAppointment(applicantEventUser: IEventUser, recipientEventUser: IEventUser) {
		return this.store.select(getModulesByType(TypeModule.APPOINTMENTS)).pipe(
			take(1),
			switchMap((modules) => {
				if (modules.length > 0) {
					const module = modules[0];

					return combineLatest([
						this.getAppointmentsOfEventUserByTypes(
							applicantEventUser.eventId,
							module.uid,
							applicantEventUser.uid,
							[
								AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED,
								AppointmentTimeSlotStatus.APPOINTMENT_PENDING
							]
						),
						this.getAppointmentsOfEventUserByTypes(
							recipientEventUser.eventId,
							module.uid,
							recipientEventUser.uid,
							[
								AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED,
								AppointmentTimeSlotStatus.APPOINTMENT_PENDING
							]
						)
					]).pipe(
						take(1),
						switchMap((results) => {
							const applicantAppointments = results[0];
							const recipientAppointments = results[1];

							const rules =
								module.options && module.options.rules && module.options.rules.length > 0
									? module.options.rules
									: [];

							const rulesForEach: IAppointmentRule[] = [];
							if (rules.length === 0) {
								return of(false);
							}
							let check: boolean = false;
							rules.forEach((rule) => {
								if (
									(rule.groupsRules.applicantsGroupId === "allUsers" ||
										applicantEventUser.groups.includes(rule.groupsRules.applicantsGroupId)) &&
									(rule.groupsRules.recipientsGroupId === "allUsers" ||
										recipientEventUser.groups.includes(rule.groupsRules.recipientsGroupId))
								) {
									check = true;
									rulesForEach.push(rule);
								}
							});

							let checkMax: boolean = true;
							// Check max appointments
							rulesForEach.forEach((rule) => {
								const applicantAppointmentsForRule = applicantAppointments.filter(
									(appointment) => appointment.sourceRuleId === rule.uid
								);
								const recipientAppointmentsForRule = recipientAppointments.filter(
									(appointment) => appointment.sourceRuleId === rule.uid
								);

								if (
									applicantAppointmentsForRule.length >=
										rule.maximumAppointmentsByUserForApplicants ||
									recipientAppointmentsForRule.length > rule.maximumAppointmentsByUserForRecipients
								) {
									checkMax = false;
								}
							});
							if (!checkMax) {
								return of(false);
							}
							return of(check);
						})
					);
				} else {
					return of(false);
				}
			})
		);
	}

	getAppointmentsOfEventUser(eventId: string, moduleId: string, eventUserId: string) {
		return this.SFirestore.valueChangesDocuments(`events/${eventId}/modules/${moduleId}/appointments`, [
			or(where("applicant.uid", "==", eventUserId), where("recipient.uid", "==", eventUserId))
		]);
	}

	getAppointmentsOfEventUserByType(eventId: string, moduleId: string, eventUserId: string, type: number) {
		return this.SFirestore.getDocumentsObs(`events/${eventId}/modules/${moduleId}/appointments`, [
			where("applicant.uid", "==", eventUserId),
			where("status", "==", type)
		]).pipe(
			take(1),
			map((snapshot) => snapshot.docs.map((doc) => doc.data() as IAppointment))
		);
	}

	getAppointmentsOfEventUserByTypes(eventId: string, moduleId: string, eventUserId: string, types: number[]) {
		return this.SFirestore.getDocumentsObs(`events/${eventId}/modules/${moduleId}/appointments`, [
			where("applicant.uid", "==", eventUserId),
			where("status", "in", types)
		]).pipe(
			take(1),
			map((snapshot) => snapshot.docs.map((doc) => doc.data() as IAppointment))
		);
	}

	getAvailableTimeSlotsForEventUser(
		appointmentModuleId: string,
		applicantEventUser: IEventUser,
		recipientEventUser: IEventUser
	) {
		return combineLatest([
			this.store.select(getCurrentEvent).pipe(take(1)),
			this.store.select(getSpecificModule(appointmentModuleId)).pipe(take(1)),
			this.getAppointmentsOfEventUser(applicantEventUser.eventId, appointmentModuleId, applicantEventUser.uid),
			this.getAppointmentsOfEventUser(recipientEventUser.eventId, appointmentModuleId, recipientEventUser.uid)
		]).pipe(
			debounceTime(100),
			switchMap((results) => {
				const event = results[0];
				const module = results[1];
				const applicantAppointments = results[2];
				const recipientAppointments = results[3];

				const availablesTimeSlots: IAvailableTimeSlots[] = [];

				if (module) {
					const rules =
						module.options && module.options.rules && module.options.rules.length > 0
							? module.options.rules
							: [];

					if (rules.length === 0) {
						return of(availablesTimeSlots);
					}
					const dateNow = this.SUtility.getDateWithTimezoneType(
						event,
						applicantEventUser.updatedSettings.timezoneType,
						DateTime.local().toISO()
					);

					const eventUserRules =
						recipientEventUser.updatedSettings && recipientEventUser.updatedSettings.appointmentsSchedules
							? recipientEventUser.updatedSettings &&
							  recipientEventUser.updatedSettings.appointmentsSchedules
							: [];

					rules
						.filter(
							(rule) =>
								rule.groupsRules.applicantsGroupId === "allUsers" ||
								applicantEventUser.groups.includes(rule.groupsRules.applicantsGroupId)
						)
						.forEach((rule) => {
							const initStartDate = this.SUtility.getDateWithTimezoneType(
								event,
								applicantEventUser.updatedSettings.timezoneType,
								rule.startDate
							);
							const initEndDate = this.SUtility.getDateWithTimezoneType(
								event,
								applicantEventUser.updatedSettings.timezoneType,
								rule.endDate
							);
							let startDate = this.SUtility.getDateWithTimezoneType(
								event,
								applicantEventUser.updatedSettings.timezoneType,
								rule.startDate
							);
							const endDate = this.SUtility.getDateWithTimezoneType(
								event,
								applicantEventUser.updatedSettings.timezoneType,
								rule.endDate
							);
							let check: boolean = true;
							while (check) {
								if (
									startDate <= endDate ||
									(startDate.hasSame(endDate, "day") &&
										startDate.hasSame(endDate, "month") &&
										startDate.hasSame(endDate, "year"))
								) {
									if (
										!availablesTimeSlots.find(
											(daySlots) =>
												this.SUtility.getDateWithTimezoneType(
													event,
													applicantEventUser.updatedSettings.timezoneType,
													daySlots.day
												).hasSame(startDate, "day") &&
												this.SUtility.getDateWithTimezoneType(
													event,
													applicantEventUser.updatedSettings.timezoneType,
													daySlots.day
												).hasSame(startDate, "month") &&
												this.SUtility.getDateWithTimezoneType(
													event,
													applicantEventUser.updatedSettings.timezoneType,
													daySlots.day
												).hasSame(startDate, "year")
										)
									) {
										const availableTimeSlotEventUser = eventUserRules.find(
											(availableTimeSlotEventUser) =>
												availableTimeSlotEventUser.ruleLinked.uid === rule.uid
										);
										const schedules: IAvailableTimeSlotSchedule[] = rule.schedules.map(
											(schedule) => {
												let startDateSchedule = this.SUtility.getDateWithTimezoneType(
													event,
													applicantEventUser.updatedSettings.timezoneType,
													schedule.startSchedule
												);
												startDateSchedule = startDateSchedule.set({
													year: startDate.year,
													month: startDate.month,
													day: startDate.day
												});
												const endDateSchedule = startDateSchedule.plus({
													minutes: schedule.duration
												});

												let correspondingSchedule: IAvailableTimeSlotSchedule = null;
												if (availableTimeSlotEventUser) {
													correspondingSchedule = this.getScheduleOfAvailableTimeSlots(
														availableTimeSlotEventUser,
														schedule.startSchedule
													);
												}

												if (schedule.disabled) {
													return {
														available: false,
														startSchedule: startDateSchedule.toISO(),
														disabled: schedule.disabled,
														disabledEventUser: correspondingSchedule
															? correspondingSchedule.disabledEventUser
															: false,
														duration: schedule.duration
													};
												}

												const checkApplicantAppointments = applicantAppointments.filter(
													(appointment) => {
														const startDateAppointment =
															this.SUtility.getDateWithTimezoneType(
																event,
																applicantEventUser.updatedSettings.timezoneType,
																appointment.startDate
															);
														const endDateAppointment =
															this.SUtility.getDateWithTimezoneType(
																event,
																applicantEventUser.updatedSettings.timezoneType,
																appointment.startDate
															).plus({
																minutes: appointment.duration
															});

														return (
															(appointment.status ===
																AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
																appointment.status ===
																	AppointmentTimeSlotStatus.APPOINTMENT_PENDING) &&
															((startDateAppointment >= startDateSchedule &&
																startDateAppointment < endDateSchedule) ||
																(startDateAppointment <= startDateSchedule &&
																	endDateAppointment >= endDateSchedule) ||
																(endDateAppointment > startDateSchedule &&
																	endDateAppointment < endDateSchedule))
														);
													}
												);

												const checkRecipientAppointments = recipientAppointments.filter(
													(appointment) => {
														const startDateAppointment =
															this.SUtility.getDateWithTimezoneType(
																event,
																applicantEventUser.updatedSettings.timezoneType,
																appointment.startDate
															);
														const endDateAppointment =
															this.SUtility.getDateWithTimezoneType(
																event,
																applicantEventUser.updatedSettings.timezoneType,
																appointment.startDate
															).plus({
																minutes: appointment.duration
															});

														if (!module.options.autoHidePendingAppointmentsAvailability) {
															return (
																appointment.status ===
																	AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
																((startDateAppointment >= startDateSchedule &&
																	startDateAppointment < endDateSchedule) ||
																	(startDateAppointment <= startDateSchedule &&
																		endDateAppointment >= endDateSchedule) ||
																	(endDateAppointment > startDateSchedule &&
																		endDateAppointment < endDateSchedule))
															);
														} else {
															return (
																(appointment.status ===
																	AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
																	appointment.status ===
																		AppointmentTimeSlotStatus.APPOINTMENT_PENDING) &&
																((startDateAppointment >= startDateSchedule &&
																	startDateAppointment < endDateSchedule) ||
																	(startDateAppointment <= startDateSchedule &&
																		endDateAppointment >= endDateSchedule) ||
																	(endDateAppointment > startDateSchedule &&
																		endDateAppointment < endDateSchedule))
															);
														}
													}
												);

												return {
													available:
														initStartDate > startDateSchedule ||
														initEndDate < endDateSchedule.minus({ second: 1 }) ||
														dateNow > startDateSchedule ||
														checkApplicantAppointments.length > 0 ||
														checkRecipientAppointments.length > 0 ||
														(correspondingSchedule &&
															correspondingSchedule.disabledEventUser)
															? false
															: true,
													startSchedule: startDateSchedule.toISO(),
													disabled: schedule.disabled,
													disabledEventUser: correspondingSchedule
														? correspondingSchedule.disabledEventUser
														: false,
													duration: schedule.duration
												};
											}
										);
										if (schedules.filter((schedule) => schedule.available).length > 0) {
											availablesTimeSlots.push({
												day: startDate.toISO(),
												ruleLinked: rule,
												schedules: schedules
													.filter((schedule) => schedule.available)
													.sort((a, b) => {
														const aDate = DateTime.fromISO(a.startSchedule);
														const bDate = DateTime.fromISO(b.startSchedule);
														return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
													})
											});
										}
									}
								} else {
									check = false;
								}
								startDate = startDate.plus({ day: 1 });
							}
							return of(
								this.buildAvailableTimeSlotsBasedOnTimezone(
									event,
									applicantEventUser,
									availablesTimeSlots
								)
							);
						});
					return of(
						this.buildAvailableTimeSlotsBasedOnTimezone(event, applicantEventUser, availablesTimeSlots)
					);
				} else {
					return of(
						this.buildAvailableTimeSlotsBasedOnTimezone(event, applicantEventUser, availablesTimeSlots)
					);
				}
			})
		);
	}

	buildAvailableTimeSlotsBasedOnTimezone(
		event: IEvent,
		eventUser: IEventUser,
		availablesTimeSlots: IAvailableTimeSlots[]
	) {
		availablesTimeSlots.forEach((availableTimeSlot) => {
			const schedulesNotInDay = availableTimeSlot.schedules.filter((schedule) => {
				const dateDay = this.SUtility.getDateWithTimezoneType(
					event,
					eventUser.updatedSettings.timezoneType,
					availableTimeSlot.day
				);
				const dateSchedule = this.SUtility.getDateWithTimezoneType(
					event,
					eventUser.updatedSettings.timezoneType,
					schedule.startSchedule
				);
				return (
					!dateDay.hasSame(dateSchedule, "year") ||
					!dateDay.hasSame(dateSchedule, "month") ||
					!dateDay.hasSame(dateSchedule, "day")
				);
			});

			if (schedulesNotInDay.length > 0) {
				availableTimeSlot.schedules = availableTimeSlot.schedules.filter((schedule) => {
					const dateDay = this.SUtility.getDateWithTimezoneType(
						event,
						eventUser.updatedSettings.timezoneType,
						availableTimeSlot.day
					);
					const dateSchedule = this.SUtility.getDateWithTimezoneType(
						event,
						eventUser.updatedSettings.timezoneType,
						schedule.startSchedule
					);
					return (
						dateDay.hasSame(dateSchedule, "year") &&
						dateDay.hasSame(dateSchedule, "month") &&
						dateDay.hasSame(dateSchedule, "day")
					);
				});

				const dayOfSchedulesNotInDay = availablesTimeSlots.find((availableSlot) => {
					const dateDay = this.SUtility.getDateWithTimezoneType(
						event,
						eventUser.updatedSettings.timezoneType,
						availableSlot.day
					);
					const dateSchedule = this.SUtility.getDateWithTimezoneType(
						event,
						eventUser.updatedSettings.timezoneType,
						schedulesNotInDay[0].startSchedule
					);

					return (
						dateDay.hasSame(dateSchedule, "year") &&
						dateDay.hasSame(dateSchedule, "month") &&
						dateDay.hasSame(dateSchedule, "day")
					);
				});
				if (dayOfSchedulesNotInDay) {
					dayOfSchedulesNotInDay.schedules = dayOfSchedulesNotInDay.schedules.concat(schedulesNotInDay);
				} else {
					const refDay = this.SUtility.getDateWithTimezoneType(
						event,
						eventUser.updatedSettings.timezoneType,
						schedulesNotInDay[0].startSchedule
					);
					// If day don't exist create it
					availablesTimeSlots.push({
						day: DateTime.fromObject(
							{
								year: refDay.year,
								month: refDay.month,
								day: refDay.day,
								hour: refDay.hour,
								minute: refDay.minute,
								second: refDay.second,
								millisecond: refDay.millisecond
							},
							{ zone: event.timezone }
						).toISO(),
						ruleLinked: availableTimeSlot.ruleLinked,
						schedules: schedulesNotInDay
					});
				}
			}

			availableTimeSlot.schedules = availableTimeSlot.schedules
				.filter((schedule) => schedule.available)
				.sort((a, b) => {
					const aDate = DateTime.fromISO(a.startSchedule);
					const bDate = DateTime.fromISO(b.startSchedule);
					return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
				});
		});
		return availablesTimeSlots;
	}

	getScheduleOfAvailableTimeSlots(availableTimeSlots: IAvailableTimeSlots, startDateSchedule: string) {
		const correspondingSchedule = availableTimeSlots.schedules.find((eventUserSchedule) => {
			return eventUserSchedule.startSchedule === startDateSchedule;
		});
		return correspondingSchedule;
	}

	sendAppointmentNotifications(
		event: IEvent,
		module: IModule,
		currentEventUser: IEventUser,
		applicant: IEventUser,
		recipient: IEventUser,
		appointment: IAppointment
	) {
		let notificationMessage: INotificationLanguages;

		if (!notificationMessage) {
			if (
				appointment.status &&
				(appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED_AUTOMATICALLY ||
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED_AUTOMATICALLY)
			) {
				const status =
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
						? "accepted"
						: appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED
						? "rejected"
						: appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
						? "cancelled"
						: appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED_AUTOMATICALLY
						? "rejected_automatically"
						: "cancelled_automatically";
				notificationMessage = {
					ar: this.SLanguages.getLanguageJson("ArAR")["appointments"]["notification"][status].replace(
						"{{subject}}",
						appointment.appointmentInformations.subject
					),
					fr: this.SLanguages.getLanguageJson("FrFR")["appointments"]["notification"][status].replace(
						"{{subject}}",
						appointment.appointmentInformations.subject
					),
					en: this.SLanguages.getLanguageJson("EnUS")["appointments"]["notification"][status].replace(
						"{{subject}}",
						appointment.appointmentInformations.subject
					),
					es: this.SLanguages.getLanguageJson("EsES")["appointments"]["notification"][status].replace(
						"{{subject}}",
						appointment.appointmentInformations.subject
					),
					pt: this.SLanguages.getLanguageJson("PtBR")["appointments"]["notification"][status].replace(
						"{{subject}}",
						appointment.appointmentInformations.subject
					),
					de: this.SLanguages.getLanguageJson("DeDE")["appointments"]["notification"][status].replace(
						"{{subject}}",
						appointment.appointmentInformations.subject
					)
				};
			} else {
				notificationMessage = {
					ar: this.SLanguages.getLanguageJson("ArAR")["appointments"]["notification"]["pending"].replace(
						"{{date}}",
						this.SUtility.formatDate(event, recipient, appointment.startDate, "full")
					),
					fr: this.SLanguages.getLanguageJson("FrFR")["appointments"]["notification"]["pending"].replace(
						"{{date}}",
						this.SUtility.formatDate(event, recipient, appointment.startDate, "full")
					),
					en: this.SLanguages.getLanguageJson("EnUS")["appointments"]["notification"]["pending"].replace(
						"{{date}}",
						this.SUtility.formatDate(event, recipient, appointment.startDate, "full")
					),
					es: this.SLanguages.getLanguageJson("EsES")["appointments"]["notification"]["pending"].replace(
						"{{date}}",
						this.SUtility.formatDate(event, recipient, appointment.startDate, "full")
					),
					pt: this.SLanguages.getLanguageJson("PtBR")["appointments"]["notification"]["pending"].replace(
						"{{date}}",
						this.SUtility.formatDate(event, recipient, appointment.startDate, "full")
					),
					de: this.SLanguages.getLanguageJson("DeDE")["appointments"]["notification"]["pending"].replace(
						"{{date}}",
						this.SUtility.formatDate(event, recipient, appointment.startDate, "full")
					)
				};
			}
		}

		const obsArray: Observable<any>[] = [];

		// Send in app notification
		obsArray.push(
			this.store.select(getModulesByType(TypeModule.NOTIFICATION)).pipe(
				take(1),
				switchMap((modulesNotif) => {
					if (modulesNotif.length === 0) {
						return of(null);
					}

					const moduleNotif = modulesNotif[0];

					const notification: INotification = {
						contents: notificationMessage,
						creationDate: DateTime.local().toISO(),
						datas: {},
						deliveryDate: 0,
						destinationPlatform: {
							inApp: true,
							mobilePush: false,
							email: false,
							inAppSpecificUsers: false
						},
						eventId: event.uid,
						functionalityType: TypeModule.APPOINTMENTS,
						functionalityDatas: {
							eventId: appointment.eventId,
							moduleId: appointment.moduleId,
							uid: appointment.uid,
							duration: appointment.duration,
							location: appointment.location,
							startSchedule: appointment.startDate
						},
						groupsIds: [],
						titles: {
							ar: this.SLanguages.getLanguageJson("ArAR")["appointments"][
								"someone-want-to-make-appointment"
							].replace("{{user}}", applicant.name),
							fr: this.SLanguages.getLanguageJson("FrFR")["appointments"][
								"someone-want-to-make-appointment"
							].replace("{{user}}", applicant.name),
							en: this.SLanguages.getLanguageJson("EnUS")["appointments"][
								"someone-want-to-make-appointment"
							].replace("{{user}}", applicant.name),
							es: this.SLanguages.getLanguageJson("EsES")["appointments"][
								"someone-want-to-make-appointment"
							].replace("{{user}}", applicant.name),
							pt: this.SLanguages.getLanguageJson("PtBR")["appointments"][
								"someone-want-to-make-appointment"
							].replace("{{user}}", applicant.name),
							de: this.SLanguages.getLanguageJson("DeDE")["appointments"][
								"someone-want-to-make-appointment"
							].replace("{{user}}", applicant.name)
						},
						icon: "",
						language: event.language, // Event language
						minutesReminder: 0,
						moduleId: moduleNotif.uid,
						notificationId: "", //one signal id ???
						params: null,
						redirectTarget: `event/${event.uid}/appointments/${appointment.moduleId}/${appointment.uid}`,
						scheduled: false,
						scheduledDate: "",
						specificUsers: [recipient.uid],
						subscriptionSettings: {
							setSubscription: false,
							moduleSubscriptionId: "",
							sessionId: ""
						},
						sendTo: "specific",
						timezone: event.timezone,
						uid: this.SFirestore.createId(`events/${event.uid}/modules/${moduleNotif.uid}/notifications`),
						usersEmails: [],
						usersIds: []
					};

					return this.SFirestore.setDocument(
						`events/${event.uid}/modules/${moduleNotif.uid}/notifications/${notification.uid}`,
						notification
					);
				})
			)
		);

		// Send push notifications
		if (
			notificationMessage &&
			((recipient &&
				recipient.options &&
				recipient.options.notifOneSignalConfig &&
				recipient.options.notifOneSignalConfig.userId &&
				appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING) ||
				(applicant &&
					applicant.options &&
					applicant.options.notifOneSignalConfig &&
					applicant.options.notifOneSignalConfig.userId &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
					!module.options.autoAcceptAppointment) ||
				(recipient &&
					module &&
					module.options &&
					module.options.autoAcceptAppointment &&
					recipient.options &&
					recipient.options.notifOneSignalConfig &&
					recipient.options.notifOneSignalConfig.userId &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) ||
				(applicant &&
					applicant.options &&
					applicant.options.notifOneSignalConfig &&
					applicant.options.notifOneSignalConfig.userId &&
					(appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
						appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED)) ||
				(recipient &&
					recipient.options &&
					recipient.options.notifOneSignalConfig &&
					recipient.options.notifOneSignalConfig.userId &&
					(appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
						appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED)))
		) {
			obsArray.push(
				this.SNotifications.sendNotification(
					event,
					module,
					appointment,
					{
						ar: this.SLanguages.getLanguageJson("ArAR")["appointments"]["appointment"].replace(
							"{{user}}",
							appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
								((appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) &&
									currentEventUser.uid === appointment.applicant.uid)
								? recipient.name
								: applicant.name
						),
						fr: this.SLanguages.getLanguageJson("FrFR")["appointments"]["appointment"].replace(
							"{{user}}",
							appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
								((appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) &&
									currentEventUser.uid === appointment.applicant.uid)
								? recipient.name
								: applicant.name
						),
						en: this.SLanguages.getLanguageJson("EnUS")["appointments"]["appointment"].replace(
							"{{user}}",
							appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
								((appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) &&
									currentEventUser.uid === appointment.applicant.uid)
								? recipient.name
								: applicant.name
						),
						es: this.SLanguages.getLanguageJson("EsES")["appointments"]["appointment"].replace(
							"{{user}}",
							appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
								((appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) &&
									currentEventUser.uid === appointment.applicant.uid)
								? recipient.name
								: applicant.name
						),
						pt: this.SLanguages.getLanguageJson("PtBR")["appointments"]["appointment"].replace(
							"{{user}}",
							appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
								((appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) &&
									currentEventUser.uid === appointment.applicant.uid)
								? recipient.name
								: applicant.name
						),
						de: this.SLanguages.getLanguageJson("DeDE")["appointments"]["appointment"].replace(
							"{{user}}",
							appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
								((appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
									appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) &&
									currentEventUser.uid === appointment.applicant.uid)
								? recipient.name
								: applicant.name
						)
					},
					notificationMessage,
					[
						appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
						((appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
							appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
							appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) &&
							currentEventUser.uid === appointment.applicant.uid)
							? recipient.options.notifOneSignalConfig.userId
							: applicant.options.notifOneSignalConfig.userId
					],
					{
						redirectUrl: `event/${appointment.eventId}/appointments/${appointment.moduleId}/details/${appointment.uid}`
					},
					""
				)
			);
		}

		// this.manageRemindersAppointment(event, module, appointment, applicant, recipient);

		return combineLatest(obsArray).pipe(take(1));
	}

	manageRemindersAppointment(
		event: IEvent,
		module: IModule,
		appointment: IAppointment,
		applicant: IEventUser,
		recipient: IEventUser
	) {
		// If appointment accepted create scheduled notification 10 min before it
		if (appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) {
			const titles = {
				ar: this.SLanguages.getLanguageJson("ArAR")["appointments"]["notification"]["reminder-title"],
				fr: this.SLanguages.getLanguageJson("FrFR")["appointments"]["notification"]["reminder-title"],
				en: this.SLanguages.getLanguageJson("EnUS")["appointments"]["notification"]["reminder-title"],
				es: this.SLanguages.getLanguageJson("EsES")["appointments"]["notification"]["reminder-title"],
				pt: this.SLanguages.getLanguageJson("PtBR")["appointments"]["notification"]["reminder-title"],
				de: this.SLanguages.getLanguageJson("DeDE")["appointments"]["notification"]["reminder-title"]
			};
			const contentsApplicant = {
				ar: this.SLanguages.getLanguageJson("ArAR")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", recipient.name)
					.replace("{{time}}", "10"),
				fr: this.SLanguages.getLanguageJson("FrFR")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", recipient.name)
					.replace("{{time}}", "10"),
				en: this.SLanguages.getLanguageJson("EnUS")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", recipient.name)
					.replace("{{time}}", "10"),
				es: this.SLanguages.getLanguageJson("EsES")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", recipient.name)
					.replace("{{time}}", "10"),
				pt: this.SLanguages.getLanguageJson("PtBR")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", recipient.name)
					.replace("{{time}}", "10"),
				de: this.SLanguages.getLanguageJson("DeDE")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", recipient.name)
					.replace("{{time}}", "10")
			};
			const contentsRecipient = {
				ar: this.SLanguages.getLanguageJson("ArAR")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", applicant.name)
					.replace("{{time}}", "10"),
				fr: this.SLanguages.getLanguageJson("FrFR")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", applicant.name)
					.replace("{{time}}", "10"),
				en: this.SLanguages.getLanguageJson("EnUS")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", applicant.name)
					.replace("{{time}}", "10"),
				es: this.SLanguages.getLanguageJson("EsES")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", applicant.name)
					.replace("{{time}}", "10"),
				pt: this.SLanguages.getLanguageJson("PtBR")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", applicant.name)
					.replace("{{time}}", "10"),
				de: this.SLanguages.getLanguageJson("DeDE")
					["appointments"]["notification"]["reminder-content"].replace("{{user}}", applicant.name)
					.replace("{{time}}", "10")
			};
			const obsArray: Observable<any>[] = [];
			if (
				applicant &&
				applicant.options &&
				applicant.options.notifOneSignalConfig &&
				applicant.options.notifOneSignalConfig.userId
			) {
				obsArray.push(
					this.SNotifications.sendNotification(
						event,
						module,
						appointment,
						titles,
						contentsApplicant,
						[applicant.options.notifOneSignalConfig.userId],
						{
							redirectUrl: `event/${appointment.eventId}/appointments/${appointment.moduleId}/details/${appointment.uid}`
						},
						""
					)
				);
			}
			if (
				recipient &&
				recipient.options &&
				recipient.options.notifOneSignalConfig &&
				recipient.options.notifOneSignalConfig.userId
			) {
				obsArray.push(
					this.SNotifications.sendNotification(
						event,
						module,
						appointment,
						titles,
						contentsRecipient,
						[recipient.options.notifOneSignalConfig.userId],
						{
							redirectUrl: `event/${appointment.eventId}/appointments/${appointment.moduleId}/details/${appointment.uid}`
						},
						""
					)
				);
			}
			combineLatest(obsArray)
				.pipe(take(1))
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				.subscribe(() => {});
		}

		// If appointment cancelled delete all scheduled notification linked to it
	}

	changeAppointmentStatus(currentEventUser: IEventUser, appointment: IAppointment, newStatus: number) {
		if (appointment.recipientDatas) delete appointment.recipientDatas;
		if (appointment.applicantDatas) delete appointment.applicantDatas;
		appointment.status = newStatus;
		if (newStatus === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) {
			appointment.updateInformations.acceptedBy = currentEventUser.uid;
		} else if (newStatus === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED) {
			appointment.updateInformations.cancelledBy = currentEventUser.uid;
		} else if (newStatus === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED) {
			appointment.updateInformations.rejectedBy = currentEventUser.uid;
		}
		return combineLatest([
			this.store.select(getCurrentEvent),
			this.store.select(getSpecificModule(appointment.moduleId)),
			this.SFirestore.getDocumentObs(
				`events/${appointment.applicant.eventId}/modules/${appointment.applicant.moduleId}/event-users/${appointment.applicant.uid}`
			).pipe(map((snapshot) => snapshot.data() as IEventUser)),
			this.SFirestore.getDocumentObs(
				`events/${appointment.recipient.eventId}/modules/${appointment.recipient.moduleId}/event-users/${appointment.recipient.uid}`
			).pipe(map((snapshot) => snapshot.data() as IEventUser))
		]).pipe(
			take(1),
			switchMap((results) => {
				const event = results[0];
				const module = results[1];
				const applicant = results[2];
				const recipient = results[3];

				return combineLatest([
					from(
						this.SFirestore.updateDocument(
							`events/${appointment.eventId}/modules/${appointment.moduleId}/appointments/${appointment.uid}`,
							{
								status: appointment.status,
								updateInformations: appointment.updateInformations,
								location: appointment.location
							}
						)
					),
					this.sendAppointmentNotifications(
						event,
						module,
						currentEventUser,
						applicant,
						recipient,
						appointment
					),
					this.sendEmailForAppointmentTaken(
						event,
						module,
						appointment,
						event.language,
						appointment.appointmentInformations.subject,
						appointment.status
					)
				]).pipe(take(1));
			})
		);
	}

	sendEmailForAppointmentTaken(
		event: IEvent,
		module: IModule,
		appointment: IAppointment,
		language: string,
		subject: string,
		status: number
	) {
		// Emailing part
		if (status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING) {
			return of(null);
		}

		if (
			module &&
			module.options &&
			module.options.allowEmailNotif &&
			(appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
				appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
				appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED)
		) {
			const startDateTime = DateTime.fromISO(appointment.startDate);
			const endDateTime = DateTime.fromISO(appointment.startDate).plus({ minutes: appointment.duration });
			const dataEmailAttendee = getEmailDataFopAppointmentTaken(
				status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
					? this.STranslate.instant("appointments.appointment_accepted")
					: status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
					? this.STranslate.instant("appointments.appointment_cancelled")
					: this.STranslate.instant("appointments.appointment_rejected"),
				language,
				appointment.recipient.name,
				startDateTime.toFormat("EEEE dd MMMM yyyy")[0].toUpperCase() +
					startDateTime.toFormat("EEEE dd MMMM yyyy").substring(1).toLowerCase(),
				`${startDateTime.toFormat("HH:mm")} - ${endDateTime.toFormat("HH:mm")}`,
				subject,
				event.identity.logo,
				event.styling.menuColor,
				event.styling.titleColor,
				event.styling.contentTextColor,
				status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
					? environment.platform.appointments["confirmed-rdv-icon"]
					: status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
					? environment.platform.appointments["cancelled-rdv-icon"]
					: environment.platform.appointments["cancelled-rdv-icon"]
			);
			const dataEmailPracti = getEmailDataFopAppointmentTaken(
				status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
					? this.STranslate.instant("appointments.appointment_accepted")
					: status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
					? this.STranslate.instant("appointments.appointment_cancelled")
					: this.STranslate.instant("appointments.appointment_rejected"),
				language,
				appointment.applicant.name,
				startDateTime.toFormat("EEEE dd MMMM yyyy")[0].toUpperCase() +
					startDateTime.toFormat("EEEE dd MMMM yyyy").substring(1).toLowerCase(),
				`${startDateTime.toFormat("HH:mm")} - ${endDateTime.toFormat("HH:mm")}`,
				subject,
				event.identity.logo,
				event.styling.menuColor,
				event.styling.titleColor,
				event.styling.contentTextColor,
				status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
					? environment.platform.appointments["confirmed-rdv-icon"]
					: status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
					? environment.platform.appointments["cancelled-rdv-icon"]
					: environment.platform.appointments["cancelled-rdv-icon"]
			);
			return combineLatest([
				this.https.post(
					environment.platform.apiV2BaseUrl
						? "https://mailings-sendBasicEmail" + environment.platform.apiV2BaseUrl
						: environment.platform.apiBaseUrl + "mailings-sendBasicEmail",
					{
						emails: [appointment.recipient.email],
						subject: dataEmailPracti.subject,
						html: dataEmailPracti.html
					}
				),
				this.https.post(
					environment.platform.apiV2BaseUrl
						? "https://mailings-sendBasicEmail" + environment.platform.apiV2BaseUrl
						: environment.platform.apiBaseUrl + "mailings-sendBasicEmail",
					{
						emails: [appointment.applicant.email],
						subject: dataEmailAttendee.subject,
						html: dataEmailAttendee.html
					}
				)
			]);
		} else {
			return of(null);
		}
	}

	/**
	 * Create visio for appointment
	 * @param eventId
	 * @param moduleId
	 * @param appointment
	 * @returns
	 */
	createVisioForAppointment(eventId: string, moduleId: string, appointment: IAppointment) {
		return this.SVisios.createNewVisioFor2(eventId, moduleId, appointment.uid, [
			appointment.applicant.uid,
			appointment.recipient.uid
		]).pipe(
			switchMap((visioData: any) => {
				appointment.urlVisio = visioData && visioData.url ? visioData.url : "";
				return from(
					this.SFirestore.updateDocument(
						`events/${eventId}/modules/${moduleId}/appointments/${appointment.uid}`,
						{ urlVisio: appointment.urlVisio }
					)
				).pipe(
					switchMap(() => {
						return of(visioData);
					})
				);
			})
		);
	}

	updateAppointmentFeedback(eventId: string, appointmentId: string, appointment: IAppointment) {
		const updatedDatas = { ...appointment };
		["eventId", "moduleId", "uid"].forEach((key) => {
			delete updatedDatas[key];
		});
		return this.SFirestore.updateDocument(`events/${eventId}/appointments/${appointmentId}`, updatedDatas);
	}

	/**
	 * Download a file
	 * @param filename
	 * @param text
	 */
	async downloadFile(filename: string, base64Data) {
		try {
			if (!(this.platform.is("mobile") && window.innerWidth < 768)) {
				const element = document.createElement("a");
				element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(base64Data));
				element.setAttribute("download", filename);
				element.setAttribute("target", "_blank");
				element.style.display = "none";
				element.click();
			} else {
				if (this.platform.is("ios")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: filename,
						data: encodeURIComponent(base64Data),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				} else if (this.platform.is("android")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: `download/${filename}`,
						data: encodeURIComponent(base64Data),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				}
			}
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.download_error"),
				2000,
				"bottom",
				"danger"
			);
		}
	}

	// Generate ics file part

	/**
	 * generateAppointmentIcsFile
	 * @param appointment
	 */
	generateAppointmentIcsFile(appointment: IAppointment) {
		const event = {
			summary: appointment.appointmentInformations.subject,
			location: "",
			description: "",
			start: this.formatDateTime(new Date(appointment.startDate)),
			end: this.formatDateTime(
				new Date(DateTime.fromISO(appointment.startDate).plus({ minutes: appointment.duration }).toISO())
			),
			organizer: appointment.applicant,
			attendee: appointment.recipient
		};

		let icsFileContent = `BEGIN:VCALENDAR
		  VERSION:2.0
		  BEGIN:VEVENT
		  SUMMARY:${event.summary}
		  LOCATION:${event.location}
		  DESCRIPTION:${event.description}
		  DTSTART:${event.start}
		  DTEND:${event.end}
		  ORGANIZER;CN=${event.organizer?.name}
		  ATTENDEE;CN=${event.attendee?.name}
		  END:VEVENT
		  END:VCALENDAR`;

		// eslint-disable-next-line no-useless-escape
		icsFileContent = icsFileContent.replace(/\ /g, "");
		icsFileContent = icsFileContent.replace(/\t/g, "");
		const blob = new Blob([icsFileContent], { type: "charset=utf-8" });
		// convent blob to base64 text string
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			reader.result;
			this.downloadFile("Appointment.ics", icsFileContent);
		};

		// return saveAs(blob, `Appointment.ics`);
	}

	/**
	 * linkToCalendar
	 */
	linkToCalendar(evt: IEvent, eventUser: IEventUser, location: string, appointment: IAppointment) {
		const googleLinkPrefix = "https://calendar.google.com/calendar/render?action=TEMPLATE&";

		const event = {
			summary: `${evt.title} - ${this.STranslate.instant("appointments.appointment-with")} ${
				eventUser.uid === appointment.applicant.uid ? appointment.recipient.name : appointment.applicant.name
			}`,
			location: location ? location : "",
			description: appointment.appointmentInformations.informations,
			start: this.formatDateTime(new Date(appointment.startDate)),
			end: this.formatDateTime(
				new Date(DateTime.fromISO(appointment.startDate).plus({ minutes: appointment.duration }).toISO())
			),
			organizer: appointment.applicant,
			attendee: appointment.recipient
		};

		const appointmentNameEncoded = encodeURI(event.summary);
		const datesEncoded = encodeURI(event.start + "/" + event.end);
		const appointmentDescriptionEncoded = encodeURI(event.description);

		const link = `${googleLinkPrefix}dates=${datesEncoded}&text=${appointmentNameEncoded}&details=${appointmentDescriptionEncoded}${
			location ? "&location=" + encodeURI(event.location) : ""
		}`;

		return link;
	}

	generateAppointmentsIcsFile(appointments: IAppointment[]) {
		// return promise function
		return new Promise((resolve, reject) => {
			if (!appointments || appointments.length === 0) reject("No appointments to export");
			const events = appointments.map((appointment, i) => {
				const appointmentData = {
					summary: appointment.appointmentInformations.subject,
					location: "",
					description: "",
					start: this.formatDateTime(new Date(appointment.startDate)),
					end: this.formatDateTime(
						new Date(
							DateTime.fromISO(appointment.startDate).plus({ minutes: appointment.duration }).toISO()
						)
					),
					organizer: appointment.applicant ? appointment.applicant.name : "",
					attendee: appointment.recipient ? appointment.recipient.name : ""
				};

				const event = `BEGIN:VEVENT
					UID:${appointmentData.summary}
					SUMMARY:${appointmentData.summary}
					LOCATION:${appointmentData.location}
					DESCRIPTION:${appointmentData.description}
					DTSTAMP:${appointmentData.start}
					DTSTART:${appointmentData.start}
					DTEND:${appointmentData.end}
					ORGANIZER:${appointmentData.organizer}
					ATTENDEE:${appointmentData.attendee}
					END:VEVENT${i < appointments.length - 1 ? "\n" : ""}`;

				return event;
			});

			// concat array of string to one string
			const eventsConcated = events.join("");

			let icsFileContent = `BEGIN:VCALENDAR
				VERSION:2.0
				PRODID:${environment.platform.appName}
				${eventsConcated}
				END:VCALENDAR`;

			icsFileContent = icsFileContent.replace(/\t/g, "");
			const blob = new Blob([icsFileContent], { type: "charset=utf-8" });
			// convent blob to base64 text string
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				reader.result;
				this.downloadFile(`Appointment.ics`, icsFileContent);
				resolve("File downloaded");
			};
		});
	}

	/**
	 * formatDateTime
	 */
	formatDateTime = (date: Date) => {
		return (
			date.toISOString().concat(date.toLocaleTimeString()).replaceAll("-", "").replaceAll(":", "").slice(0, 15) +
			"Z"
		);
	};
}
