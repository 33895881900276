<ion-content *ngIf="event && module && eventUserProfile" [style.--background]="isMobile ? '#fff' : 'transparent'">
	<div
		class="complete-header"
		[style.background]="'var(--bg-header)'"
		[style.color]="event.styling.menuTextColor"
	></div>

	<app-profile-general-info
		[event]="event"
		[module]="module"
		[myEventUser]="myEventUser"
		[eventUserProfile]="eventUserProfile"
		[currentLanguage]="currentLanguage"
		[isPrivateMode]="isProfilMode"
	>
	</app-profile-general-info>

	<div class="background" [style.min-height]="isMobile ? 'fit-content !important' : 'calc(100% - 164px) !important'">
		<div class="profil-content">
			<!-- QR Code -->
			<ion-grid
				*ngIf="event.settings.allowProfileQR && myEventUser && myEventUser?.uid === eventUserProfile?.uid"
			>
				<ion-row>
					<ion-col text-center>
						<div class="qr-code-container">
							<ion-icon src="assets/icon/scan-qr-code.svg"></ion-icon>
							<qrcode
								[qrdata]="event.uid + '+' + eventUserProfile?.uid"
								[elementType]="'canvas'"
								[width]="256"
								[errorCorrectionLevel]="'L'"
							>
							</qrcode>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>

			<div
				*ngIf="myEventUser?.groups?.length > 0 && cardExchangeForm"
				class="edit-notes-bloc"
				style="float: right"
			>
				<ion-label class="title" (click)="openForm()">{{ "buttons.update" | translate }}</ion-label>
			</div>
			<ion-accordion-group>
				<ion-accordion class="accordion-style">
					<ion-item slot="header" color="light">
						<ion-label class="accordion-title">{{ "labels.my_notes" | translate }}</ion-label>
					</ion-item>

					<!-- Event users fields -->
					<div class="ion-padding" slot="content">
						<ion-card
							class="custom-card-notes"
							style="cursor: pointer"
							[hidden]="isEmptyField(data)"
							*ngFor="let data of userFieldDatas; let i = index; trackBy: trackById"
						>
							<ion-card-header>
								<div class="f-row-sb">
									<ion-card-title
										style="width: 90%"
										>{{ $any(data)?.fieldName?.[currentLanguage] ?? "" }}</ion-card-title
									>
								</div>
							</ion-card-header>
							<ion-card-content>
								<div class="container-description">
									<!-- Todo -->
									<!-- Date -->
									<ion-label class="html-card-title" *ngIf="$any(data)?.type === 'date'">
										{{ $any(data)?.date | date : "dd/MM/yyyy" }}
									</ion-label>

									<!-- Todo -->
									<!-- oneSelect -->
									<ion-label class="html-card-title" *ngIf="$any(data)?.type === 'oneSelect'">
										{{ getAnswerById(data?.fieldId, data?.oneSelect?.answerId) ?? "" }}
									</ion-label>

									<!-- Todo -->
									<!-- MultipleSelect -->
									<ion-label class="html-card-title" *ngIf="$any(data)?.type === 'multipleSelect'">
										{{ getAnswerById(data?.fieldId, data?.multipleSelect?.answersIds) ?? "" }}
									</ion-label>

									<!-- Evaluation -->
									<ion-label class="html-card-title" *ngIf="$any(data)?.type === 'evaluation'">
										<img
											src="assets/icon/star_filled.svg"
											alt=""
											*ngFor="let data of $any(data)?.evaluationArray"
										/>
									</ion-label>

									<!-- Dissertative & plainText -->
									<ion-label
										class="html-card-title"
										*ngIf="$any(data)?.type === 'dissertative' || $any(data)?.type === 'plainText'"
									>
										{{ $any(data)?.dissertative ?? "" }}
									</ion-label>

									<!-- Todo -->
									<!-- Document -->
									<div
										class="html-card-title document-type"
										*ngIf="$any(data)?.type === 'document'"
										(click)="openDocument(data.document)"
									>
										<ion-thumbnail class="margin-padding-0" slot="start">
											<img
												src="assets/icon/Files/{{
													SDocuments.typeFormatFiles.includes(
														data.document.docType.split('/')[1]
													)
														? data.document.docType.split('/')[1]
														: 'generic-file'
												}}_ico.svg"
											/>
										</ion-thumbnail>
										<ion-label
											class="margin-padding-10"
											style="padding-left: 10px"
											[style.color]="event.styling.titleColor"
										>
											{{ $any(data)?.document?.name ?? "No name" }}
										</ion-label>
									</div>

									<span class="html-card-content" style="white-space: pre-line">
										{{ $any(data)?.value?.[currentLanguage] ?? "" }}
									</span>
								</div>
							</ion-card-content>
						</ion-card>

						<label class="empty-notes-card" *ngIf="userFieldDatas.length === 0">
							{{ "labels.empty_notes" | translate }}
						</label>

						<hr />
						<!-- Delete account -->
						<div class="f-row-c-c">
							<ion-button shape="round" (click)="removeContact()" color="danger">
								{{ "alerts.delete_contact" | translate }}
							</ion-button>
						</div>
					</div>
				</ion-accordion>
			</ion-accordion-group>

			<!-- Event user's description -->
			<br />
			<ion-card
				class="custom-card"
				*ngIf="
					((isProfilMode && !eventUserModule.options.requiredFields.description?.hiding.profil) ||
						(!isProfilMode &&
							!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
								eventUserModule,
								moduleCF,
								eventUserProfile,
								'description',
								'baseFields'
							) &&
							!eventUserModule.options.requiredFields.description?.hiding.public)) &&
					eventUserProfile?.description &&
					eventUserProfile?.description[currentLanguage] &&
					eventUserProfile?.description[currentLanguage] !== ''
				"
			>
				<ion-card-content>
					<div
						class="container-description"
						[class.show]="showMore"
						[style.maxHeight]="
							eventUserModule.options.showMoreOrLess && !showMore ? '100px' : 'fit-content'
						"
					>
						<span
							class="html-card-content"
							style="white-space: pre-line"
							[innerHTML]="eventUserProfile?.description[currentLanguage] | linky | safeHtml"
						>
						</span>
					</div>
					<button *ngIf="eventUserModule.options.showMoreOrLess" (click)="showMore = !showMore">
						{{ showMore ? ("buttons.show_less" | translate) : ("buttons.show_more" | translate) }}
					</button>
					visibility chip
					<span
						*ngIf="eventUserModule.options.enableUserFieldsHideAbility && isProfilMode"
						class="visibility-state"
						style="top: 0 !important"
					>
						<ion-chip
							*ngIf="
								!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
									eventUserModule,
									moduleCF,
									eventUserProfile,
									'description',
									'baseFields'
								)
							"
							color="success"
							>{{ "labels.visible" | translate }}</ion-chip
						>
						<ion-chip
							*ngIf="
								SCustomFields.isUserOrModuleFieldsVisibilityHidden(
									eventUserModule,
									moduleCF,
									eventUserProfile,
									'description',
									'baseFields'
								)
							"
							color="danger"
							>{{ "labels.hidden" | translate }}</ion-chip
						>
					</span>
				</ion-card-content>
			</ion-card>

			<!-- Customs fields -->
			<app-custom-fields-view
				[event]="event"
				[module]="eventUserModule"
				[eventUserProfile]="eventUserProfile"
				[scheduleId]="null"
				[moduleType]="eventUserProfile?.type === typeModule.SPEAKER ? typeModule.SPEAKER : typeModule.ATTENDEE"
				[currentLanguage]="currentLanguage"
			>
			</app-custom-fields-view>
		</div>
	</div>

	<div *ngIf="loader" class="loader-container">
		<div class="loader-main" [style.width]="'60px'" [style.height]="'60px'"></div>
	</div>
</ion-content>
