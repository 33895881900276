<div
	*ngIf="event && module && eventUser"
	class="background"
	[class.full-w]="componentMode"
	[style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'"
>
	<ng-container *ngIf="quizs.length > 0; else noContent">
		<!-- Unanswered quizs list -->
		<ng-container
			*ngIf="
				(componentMode &&
					(unansweredQuizs.length > 1 || (unansweredQuizs.length === 1 && answeredQuizs.length > 0))) ||
				(!componentMode && unansweredQuizs.length > 0)
			"
		>
			<ion-item-divider *ngIf="unansweredQuizs.length > 0">
				<h6 class="section-title">
					&nbsp;&nbsp;&nbsp;
					{{ "quizs.not_answered" | translate }}
				</h6>
			</ion-item-divider>
			<div *ngFor="let quiz of unansweredQuizs">
				<ion-item
					detail
					class="card-content cursor-pointer"
					[style.--background]="'transparent'"
					(click)="openQuiz(quiz)"
				>
					<ion-avatar slot="start" [style.background]="event.styling.menuColor">
						<div>
							<ion-icon src="assets/icon/Star.svg"></ion-icon>
						</div>
					</ion-avatar>
					<ion-label>
						<h2 [style.color]="event.styling.titleColor">
							{{ quiz.name[currentLanguage] ? quiz.name[currentLanguage] : quiz.name[event.language] }}
						</h2>
						<p
							*ngIf="checkNumberOfQuestionsActive(quiz.questions) <= 1"
							[style.color]="event.styling.contentTextColor"
						>
							{{ checkNumberOfQuestionsActive(quiz.questions) }}
							{{ "schedule_detail.interactivity.ask-question" | translate | lowercase }}
						</p>
						<p
							*ngIf="checkNumberOfQuestionsActive(quiz.questions) > 1"
							[style.color]="event.styling.contentTextColor"
						>
							{{ checkNumberOfQuestionsActive(quiz.questions) }}
							{{ "schedule_detail.interactivity.ask-questions" | translate | lowercase }}
						</p>
					</ion-label>
				</ion-item>
			</div>
		</ng-container>
		<ng-container *ngIf="unansweredQuizs.length === 1 && answeredQuizs.length === 0 && componentMode">
			<app-quiz
				[componentMode]="true"
				[eventId]="eventId"
				[moduleId]="unansweredQuizs[0].moduleId"
				[quizId]="unansweredQuizs[0].uid"
			>
			</app-quiz>
		</ng-container>

		<ng-container *ngIf="answeredQuizs.length > 0">
			<ion-item-divider *ngIf="answeredQuizs.length > 0">
				<h6 class="section-title">
					&nbsp;&nbsp;&nbsp;
					{{ "quizs.answered" | translate }}
				</h6>
			</ion-item-divider>

			<div *ngFor="let quiz of answeredQuizs">
				<ion-item
					detail
					*ngIf="(quiz.changeAnswers || quiz.viewAnswered) && quiz.visibility"
					(click)="openQuiz(quiz)"
					class="card-content cursor-pointer"
					[disabled]="!quiz.viewAnswered"
				>
					<ion-avatar slot="start" [style.background]="event.styling.menuColor">
						<div>
							<ion-icon src="assets/icon/Star.svg"></ion-icon>
						</div>
					</ion-avatar>
					<ion-label>
						<h2 [style.color]="event.styling.titleColor">
							{{
								quiz.name[
									eventUser.updatedSettings.language
										? eventUser.updatedSettings.language
										: currentLanguage
								]
							}}
						</h2>
						<p *ngIf="countQuestions(quiz.questions) > 1" [style.color]="event.styling.contentTextColor">
							{{ countQuestions(quiz.questions) }}
							{{ "schedule_detail.interactivity.ask-questions" | translate | lowercase }}
						</p>
						<p *ngIf="countQuestions(quiz.questions) <= 1" [style.color]="event.styling.contentTextColor">
							{{ countQuestions(quiz.questions) }}
							{{ "schedule_detail.interactivity.ask-question" | translate | lowercase }}
						</p>
					</ion-label>
				</ion-item>

				<ion-item
					*ngIf="!quiz.changeAnswers && !quiz.viewAnswered && quiz.visibility"
					class="card-content cursor-pointer"
					[disabled]="!quiz.viewAnswered"
				>
					<ion-avatar slot="start" [style.background]="event.styling.menuColor">
						<div>
							<ion-icon src="assets/icon/Star.svg"></ion-icon>
						</div>
					</ion-avatar>
					<ion-label>
						<h2 [style.color]="event.styling.titleColor">
							{{
								quiz.name[
									eventUser.updatedSettings.language
										? eventUser.updatedSettings.language
										: currentLanguage
								]
							}}
						</h2>
						<p *ngIf="countQuestions(quiz.questions) > 1" [style.color]="event.styling.contentTextColor">
							{{ countQuestions(quiz.questions) }}
							{{ "schedule_detail.interactivity.ask-questions" | translate | lowercase }}
						</p>
						<p *ngIf="countQuestions(quiz.questions) === 1" [style.color]="event.styling.contentTextColor">
							{{ countQuestions(quiz.questions) }}
							{{ "schedule_detail.interactivity.ask-question" | translate | lowercase }}
						</p>
					</ion-label>
				</ion-item>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #noContent>
		<div class="container-no-content">
			<span class="wait-content" [style.color]="event.styling.titleColor">{{
				"texts.waiting_for_content" | translate
			}}</span>
			<ion-icon
				src="assets/images/emptycontent/ENQUETE.svg"
				[style.color]="
					event.styling.menuColor.includes('linear')
						? event.styling.menuColor.split(',')[1]
						: event.styling.menuColor
				"
			>
			</ion-icon>
			<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
				<span class="title"> {{ "interactivity.quiz_empty_content_title" | translate }}</span>
				<span>{{ "interactivity.quiz_empty_content_text" | translate }}</span>
			</div>
		</div>
	</ng-template>

	<div *ngIf="!event || !module">
		<ion-item-divider style="height: 60px">
			<ion-skeleton-text animated style="width: 150px; margin-left: 20px"></ion-skeleton-text>
		</ion-item-divider>
		<ion-item lines="none" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
			<ion-avatar
				slot="start"
				style="display: flex; flex-direction: row; align-items: center; justify-content: center"
			>
				<ion-skeleton-text style="width: 32px; height: 32px"></ion-skeleton-text>
			</ion-avatar>
			<ion-label>
				<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
			</ion-label>
		</ion-item>
	</div>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
