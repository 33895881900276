import { Component, OnDestroy } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription, take } from "rxjs";
import { GetHeaderState, Loading, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { IEvent, IEventUser, IModule } from "src/app/shared/interfaces";
import { INote } from "src/app/shared/interfaces/notes.interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { FirestoreService, UtilityService } from "src/app/shared/services";
import * as _ from "lodash-es";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { TypeModule } from "src/app/shared/enums/type-module";
import { orderBy, where } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";

@Component({
	selector: "app-note-taking",
	templateUrl: "./note-taking.component.html",
	styleUrls: ["./note-taking.component.scss"]
})
export class NoteTakingComponent implements OnDestroy {
	subscriptions: Subscription[] = [];
	loader: boolean = true;
	deleting: boolean = false;

	event: IEvent;
	eventId: string;
	moduleId: string;
	module: IModule;
	notes: INote[] = [];
	eventUser: IEventUser;

	isMobile: boolean = false;

	constructor(
		private store: Store,
		private platform: Platform,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private SFirestore: FirestoreService,
		public SUtility: UtilityService,
		private navCtrl: NavController
	) {}

	ionViewWillEnter() {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;

						this.getEventUser();
						this.getEvent();
						this.getModule();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.store
			.select(getCurrentEvent)
			.pipe(take(1))
			.subscribe((event) => {
				this.event = event;
			});
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.store
			.select(getSpecificModule(this.moduleId))
			.pipe(take(1))
			.subscribe((module) => {
				this.module = module;
				this.store.dispatch(
					GetHeaderState({
						payload: {
							item: null,
							module: this.module,
							title: this.module.name,
							type: TypeModule.NOTE_TAKING
						}
					})
				);
			});
	}

	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;

					if (this.eventUser) {
						this.getNotes();
					} else {
						this.loader = false;
					}
				}
			})
		);
	}

	/**
	 * Getting notes list
	 */
	getNotes() {
		this.SFirestore.valueChangesDocuments(`events/${this.eventId}/modules/${this.moduleId}/notes`, [
			where("userId", "==", this.eventUser.uid),
			orderBy("creationDate", "desc")
		]).subscribe((notes) => {
			this.notes = notes;
			this.loader = false;
		});
	}

	/**
	 * Open note
	 * @param note
	 */
	openNote(note: INote) {
		this.navCtrl.navigateForward(`event/${this.event.uid}/note-taking/${this.moduleId}/note/${note.uid}`);
	}

	addNote() {
		this.navCtrl.navigateForward(`event/${this.eventId}/note-taking/${this.moduleId}/add`);
	}

	editNote(note: INote) {
		this.navCtrl.navigateForward(`event/${this.eventId}/note-taking/${this.moduleId}/edit-note/${note.uid}`);
	}

	async deleteNote(note: INote) {
		if (!this.deleting) {
			this.deleting = true;
			try {
				this.store.dispatch(Loading({ payload: true }));
				await this.SFirestore.deleteDocument(
					`events/${this.eventId}/modules/${this.moduleId}/notes/${note.uid}`
				);
				this.deleting = false;
				this.store.dispatch(Loading({ payload: false }));
				this.snackbar.open(this.STranslate.instant("note-taking.success-delete"), "", {
					duration: 3000,
					panelClass: "success-snackbar"
				});
				this.navCtrl.navigateBack(`event/${this.eventId}/note-taking/${this.moduleId}`);
			} catch (error) {
				this.deleting = false;
				this.store.dispatch(Loading({ payload: false }));
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		}
	}
}
