<div
	*ngIf="event && module && eventUser"
	class="background"
	[class.full-w]="componentMode"
	[style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'"
>
	<ng-container *ngIf="feedbacks.length > 0; else noContent">
		<!-- Unanswered feedbacks list -->
		<ion-item-divider *ngIf="getAnsweredOrNotFeedbacks('unanswered').length > 0">
			<h6 class="section-title">
				&nbsp;&nbsp;&nbsp;
				{{ "feedbacks.not_answered" | translate }}
			</h6>
		</ion-item-divider>

		<div *ngFor="let feedback of getAnsweredOrNotFeedbacks('unanswered')">
			<ion-item
				detail
				class="card-content cursor-pointer"
				[style.--background]="'transparent'"
				(click)="openFeedback(feedback)"
			>
				<ion-avatar slot="start" [style.background]="event.styling.menuColor">
					<div>
						<ion-icon src="assets/icon/Star.svg"></ion-icon>
					</div>
				</ion-avatar>
				<ion-label>
					<h2 [style.color]="event.styling.titleColor">{{ feedback.name[currentLanguage] }}</h2>
					<p [style.color]="event.styling.contentTextColor">
						{{ feedback.questions.length }}
						{{ "schedule_detail.interactivity.ask-questions" | translate | lowercase }}
					</p>
				</ion-label>
			</ion-item>
		</div>

		<ion-item-divider *ngIf="getAnsweredOrNotFeedbacks('answered').length > 0">
			<h6 class="section-title">
				&nbsp;&nbsp;&nbsp;
				{{ "feedbacks.answered" | translate }}
			</h6>
		</ion-item-divider>

		<div *ngFor="let feedback of getAnsweredOrNotFeedbacks('answered')">
			<ion-item
				detail
				*ngIf="feedback.changeAnswers && feedback.visibility"
				(click)="openFeedback(feedback)"
				class="card-content cursor-pointer"
			>
				<ion-avatar slot="start" [style.background]="event.styling.menuColor">
					<div>
						<ion-icon src="assets/icon/Star.svg"></ion-icon>
					</div>
				</ion-avatar>
				<ion-label>
					<h2 [style.color]="event.styling.titleColor">{{ feedback.name[currentLanguage] }}</h2>
					<p [style.color]="event.styling.contentTextColor">
						{{ feedback.questions.length }}
						{{ "schedule_detail.interactivity.ask-questions" | translate | lowercase }}
					</p>
				</ion-label>
			</ion-item>

			<ion-item *ngIf="!feedback.changeAnswers && feedback.visibility" class="card-content cursor-pointer">
				<ion-avatar slot="start" [style.background]="event.styling.menuColor">
					<div>
						<ion-icon src="assets/icon/Star.svg"></ion-icon>
					</div>
				</ion-avatar>
				<ion-label>
					<h2 [style.color]="event.styling.titleColor">{{ feedback.name[currentLanguage] }}</h2>
					<p [style.color]="event.styling.contentTextColor">
						{{ feedback.questions.length }}
						{{ "schedule_detail.interactivity.ask-questions" | translate | lowercase }}
					</p>
				</ion-label>
			</ion-item>
		</div>
	</ng-container>
	<ng-template #noContent>
		<div class="container-no-content">
			<span class="wait-content" [style.color]="event.styling.titleColor">{{
				"texts.waiting_for_content" | translate
			}}</span>
			<ion-icon
				src="assets/images/emptycontent/ENQUETE.svg"
				[style.color]="
					event.styling.menuColor.includes('linear')
						? event.styling.menuColor.split(',')[1]
						: event.styling.menuColor
				"
			>
			</ion-icon>
			<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
				<span class="title"> {{ "interactivity.feedback_empty_content_title" | translate }}</span>
				<span>{{ "interactivity.feedback_empty_content_text" | translate }}</span>
			</div>
		</div>
	</ng-template>

	<div *ngIf="!event || !module">
		<ion-item-divider style="height: 60px">
			<ion-skeleton-text animated style="width: 150px; margin-left: 20px"></ion-skeleton-text>
		</ion-item-divider>
		<ion-item lines="none" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
			<ion-avatar
				slot="start"
				style="display: flex; flex-direction: row; align-items: center; justify-content: center"
			>
				<ion-skeleton-text style="width: 32px; height: 32px"></ion-skeleton-text>
			</ion-avatar>
			<ion-label>
				<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
			</ion-label>
		</ion-item>
	</div>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
