<ion-content style="width: 100vw; height: 100vh">
	<div
		*ngIf="container && container !== null"
		class="background"
		[ngStyle]="{
			'background-image': isMobile
				? 'url(' + container?.loginBgImgSecond + ')'
				: 'url(' + container?.loginBgImgFirst + ')'
		}"
	>
		<div class="align-loader" *ngIf="loader">
			<div class="loader-default"></div>
		</div>
		<div *ngIf="!loader">
			<!-- Only banner now -->
			<!-- <div class="p-t-40" *ngIf="container?.logoClass === 'basic-logo'">
              <img [src]="container?.logo" class="basic-logo">
          </div> -->
			<div class="p-t-40">
				<img [src]="container?.logo" class="banner-logo" />
			</div>
			<br />
			<!-- : 'color-1st-screen'  -->
			<form [formGroup]="loginForm" *ngIf="passwordView">
				<div>
					<ion-grid class="ion-padding">
						<ion-row>
							<ion-col
								size-sm="12"
								size-md="6"
								size-lg="6"
								push-md="3"
								push-lg="3"
								[ngClass]="'color-2nd-screen'"
							>
								<p class="login-title">{{ "login.recovery_pass_title" | translate }}</p>
								<p class="font-roobert align-center">
									{{
										!container.loginSettings.passwordSecurityLevel ||
										container.loginSettings.passwordSecurityLevel === 1
											? ("login.make_pass_txt" | translate)
											: ("login.make_pass_strong_txt" | translate)
									}}
								</p>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label
										position="floating"
										mode="md"
										style="color: var(--text-color-2nd-login-desktop)"
										class="input-label-text"
									>
										{{ "login.new_pass" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input"
										[type]="showPwd ? 'text' : 'password'"
										formControlName="password"
									>
									</ion-input>
									<ion-button slot="end" class="pwd-btn" (click)="showPwd = !showPwd">
										<ion-icon
											slot="icon-only"
											[src]="
												showPwd ? 'assets/icon/Password_1.svg' : 'assets/icon/Password_2.svg'
											"
											style="color: var(--text-color-2nd-login-desktop)"
										></ion-icon>
									</ion-button>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label
										position="floating"
										mode="md"
										style="color: var(--text-color-2nd-login-desktop)"
										class="input-label-text"
									>
										{{ "login.confirm_new_pass" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input"
										[type]="showPwd ? 'text' : 'password'"
										formControlName="confirmPassword"
									>
									</ion-input>
									<ion-button slot="end" class="pwd-btn" (click)="showPwd = !showPwd">
										<ion-icon
											slot="icon-only"
											[src]="
												showPwd ? 'assets/icon/Password_1.svg' : 'assets/icon/Password_2.svg'
											"
											style="color: var(--text-color-2nd-login-desktop)"
										></ion-icon>
									</ion-button>
								</ion-item>
								<span
									*ngIf="
										loginForm.getRawValue().password &&
										loginForm.getRawValue().confirmPassword &&
										loginForm.getRawValue().password !== loginForm.getRawValue().confirmPassword
									"
									class="red-color"
									>{{ "login.password_not_match" | translate }}</span
								>
							</ion-col>
						</ion-row>
						<br />

						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="4" push-md="4" push-lg="4" text-center>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									(click)="updatePassword()"
									[disabled]="
										activeLoader ||
										!loginForm.get('password').valid ||
										loginForm.getRawValue().password !== loginForm.getRawValue().confirmPassword
									"
								>
									<span *ngIf="!activeLoader">{{ "login.btn_make_pass" | translate }}</span>
									<ion-spinner name="crescent" color="secondary" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>
			</form>

			<!-- Success View -->
			<div *ngIf="successView" class="success-view">
				<div class="success-view-content">
					<p class="success-title login-title">{{ "login.recovery_pass_title" | translate }}</p>
					<img src="assets/images/check.svg" class="success-icon" />
					<p class="success-text">{{ "snackbar.confirm_reset_password_success" | translate }}</p>
					<ion-button
						*ngIf="showLoginButon"
						shape="round"
						class="btn-default access-btn"
						(click)="goToLogin()"
					>
						<span>{{ "login.go_to_login" | translate }}</span>
					</ion-button>
				</div>
			</div>

			<!-- Error View -->
			<div *ngIf="errorView" class="error-view">
				<div class="error-view-content">
					<p class="error-title login-title">{{ "login.recovery_pass_title" | translate }}</p>
					<img src="assets/images/error.svg" class="error-icon" />
					<p class="error-text">{{ "snackbar.invalid_reset_password_code" | translate }}</p>
					<ion-button
						*ngIf="showLoginButon"
						shape="round"
						class="btn-default access-btn"
						(click)="goToLogin()"
					>
						<span>{{ "login.go_to_login" | translate }}</span>
					</ion-button>
				</div>
			</div>
		</div>
	</div>
</ion-content>
