<div
	*ngIf="event && module && feedback && eventUser"
	class="background"
	[class.full-w]="componentMode"
	[style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'"
	[style.minHeight]="componentMode && '100% !important'"
>
	<ion-row *ngIf="feedback && componentMode && showBackBtn">
		<ion-icon class="icons-25" name="arrow-back" [style.color]="event.styling.titleColor" (click)="goBackToList()">
		</ion-icon>
	</ion-row>

	<form [formGroup]="questionsForm">
		<div *ngFor="let question of feedback.questions; let i = index" style="padding-top: 10px">
			<div
				*ngIf="question.visibility"
				[ngStyle]="{
					'border-bottom':
						i !== feedback.questions.length - 1 ? '1px solid ' + event.styling.titleColor + '4D' : 'none',
					'padding-bottom': '10px'
				}"
			>
				<div [class.evaluation-box]="question.type === 'evaluation' || question.type === 'document'">
					<div class="question-title-infobooth">
						<p class="question-title ion-text-wrap" [style.color]="event.styling.titleColor">
							{{ question.name[currentLanguage] }}
						</p>

						<div class="infobooth" *ngIf="question.description && question.description[currentLanguage]">
							<small [style.color]="event.styling.contentTextColor">
								<p [innerHTML]="question.description[currentLanguage] | safeHtml"></p>
							</small>
						</div>
					</div>
					<div class="questions-to-user" *ngIf="question.type === 'oneSelect'">
						<ion-list (click)="checkCanChangeAnswerMsg(question)">
							<ion-radio-group
								[style.opacity]="checkCanChangeAnswer(question) ? 1 : 0.7"
								[formControlName]="question.uid"
								[name]="question.uid"
							>
								<ion-item
									class="justify-text"
									*ngFor="let option of question.answers; let ind = index"
									[lines]="ind !== question.answers.length - 1 ? 'full' : 'none'"
								>
									<ion-label
										class="option-size ion-text-wrap"
										[style.color]="event.styling.contentTextColor"
									>
										{{ option.answer[currentLanguage] }}
									</ion-label>
									<ion-radio
										legacy="true"
										[disabled]="questionsForm.get(question.uid).disabled"
										[value]="option.uid"
									>
										<!-- <span [style.color]="event.styling.contentTextColor">
                                            {{ option.answer[currentLanguage] }}
                                        </span> -->
									</ion-radio>
								</ion-item>
							</ion-radio-group>
						</ion-list>
					</div>

					<div class="questions-to-user" *ngIf="question.type === 'multipleSelect'">
						<ion-list (click)="checkCanChangeAnswerMsg(question)">
							<div *ngFor="let option of question.answers; let ind = index">
								<ion-item
									class="justify-text"
									[lines]="ind !== question.answers.length - 1 ? 'full' : 'none'"
								>
									<ion-label
										class="option-size ion-text-wrap"
										[style.color]="event.styling.contentTextColor"
									>
										{{ option.answer[currentLanguage] }}
									</ion-label>
									<ion-checkbox
										[checked]="checkCheckbox(question, option)"
										mode="ios"
										[disabled]="!checkCanChangeAnswer(question)"
										(ionChange)="updateCheckboxs(question, option, $event)"
									>
									</ion-checkbox>
								</ion-item>
							</div>
						</ion-list>
					</div>

					<div class="justify-text evaluation" lines="none" *ngIf="question.type === 'evaluation'">
						<div class="evalution-align">
							<ngx-star-rating
								(click)="checkCanChangeAnswerMsg(question)"
								[disabled]="!checkCanChangeAnswer(question)"
								[style.opacity]="checkCanChangeAnswer(question) ? 1 : 0.7"
								[formControlName]="question.uid"
								[id]="sessionId + question.uid"
							>
							</ngx-star-rating>
						</div>
					</div>

					<div class="questions-to-user" *ngIf="question.type === 'dissertative'">
						<ion-list (click)="checkCanChangeAnswerMsg(question)">
							<ion-item class="ion-text-wrap" lines="none">
								<ion-textarea
									autocapitalize="sentences"
									[style.opacity]="checkCanChangeAnswer(question) ? 1 : 0.7"
									[formControlName]="question.uid"
									[name]="question.uid"
									placeholder="{{ 'feedbacks.answer_plc' | translate }}"
									style="--padding-start: 10px"
								>
								</ion-textarea>
							</ion-item>
						</ion-list>
					</div>

					<div class="questions-to-user" *ngIf="question.type === 'yesOrNo'">
						<ion-list (click)="checkCanChangeAnswerMsg(question)">
							<ion-radio-group
								[style.opacity]="checkCanChangeAnswer(question) ? 1 : 0.7"
								[formControlName]="question.uid"
								[name]="question.uid"
							>
								<ion-item class="justify-text" lines="full">
									<ion-label
										class="option-size ion-text-wrap"
										[style.color]="event.styling.contentTextColor"
									>
										{{ "labels.yes" | translate }}
									</ion-label>
									<ion-radio
										legacy="true"
										[disabled]="questionsForm.get(question.uid).disabled"
										value="yes"
									>
										<!-- <span [style.color]="event.styling.contentTextColor">
                                            {{ "labels.yes" | translate }}
                                        </span> -->
									</ion-radio>
								</ion-item>
								<ion-item class="justify-text" lines="none">
									<ion-label
										class="option-size ion-text-wrap"
										[style.color]="event.styling.contentTextColor"
									>
										{{ "labels.no" | translate }}
									</ion-label>
									<ion-radio
										legacy="true"
										[disabled]="questionsForm.get(question.uid).disabled"
										value="no"
									>
										<!-- <span [style.color]="event.styling.contentTextColor">
                                            {{ "labels.no" | translate }}
                                        </span> -->
									</ion-radio>
								</ion-item>
							</ion-radio-group>
						</ion-list>
					</div>
				</div>
			</div>
		</div>
	</form>

	<br />

	<ion-spinner name="lines" class="spinner" *ngIf="loadingSend"></ion-spinner>

	<div
		style="display: flex; width: 100%; padding-top: 7px"
		[ngStyle]="{ 'border-top': '1px solid ' + event.styling.titleColor + '4D' }"
	>
		<button
			shape="round"
			class="btn-validate btn-validate-secondary"
			[style.opacity]="checkCanSendFeedback() ? 1 : 0.7"
			[disabled]="!checkCanSendFeedback()"
			(click)="sendFeedback()"
			*ngIf="!loadingSend"
		>
			{{ "buttons.answer" | translate }}
		</button>
	</div>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
