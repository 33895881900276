import { Component, OnDestroy } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	IAskQuestions,
	IEvent,
	IEventUser,
	IExternalInteractivity,
	IModule,
	IQuiz,
	ISurvey,
	IWordCloud
} from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getAccessiblesAsksQuestionsByType,
	getAccessiblesExternalsInteractivityByType,
	getAccessiblesQuizsByType,
	getAccessiblesSurveysByType,
	getAccessiblesWordsClouds
} from "src/app/shared/selectors/interactivity.selectors";
import { getInteractivityModule, getInteractivityModules } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-interactivity",
	templateUrl: "./interactivity.component.html",
	styleUrls: ["./interactivity.component.scss"]
})
export class InteractivityComponent implements OnDestroy {
	subscriptions: Subscription[] = [];

	eventId: string;
	event: IEvent;
	eventUser: IEventUser;

	moduleInteractivity: IModule;
	moduleSurveys: IModule;
	moduleQuizs: IModule;
	moduleTrainings: IModule;
	moduleAskQuestions: IModule;
	moduleExternalsInteractivity: IModule;
	moduleWordClouds: IModule;

	modulesShowed: IModule[] = [];

	quizs: IQuiz[] = [];
	surveys: ISurvey[] = [];
	asksQuestions: IAskQuestions[] = [];
	externalsInteractivity: IExternalInteractivity[] = [];
	wordsClouds: IWordCloud[] = [];
	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(private store: Store, private STranslate: TranslateService, private navCtrl: NavController) {}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						this.subscriptions.push(
							this.STranslate.onLangChange.subscribe((lang) => {
								this.currentLanguage = lang.lang;
							})
						);

						this.getEvent();
						this.getNecessaryModules();
						this.getNecessaryDatas();
						this.getEventUser();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Get all interactivity module
	 */
	getNecessaryModules() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getInteractivityModule),
				this.store.select(getInteractivityModules)
			]).subscribe((results) => {
				this.modulesShowed = [];
				this.moduleInteractivity = results[0];
				this.moduleSurveys = results[1].find((module) => module.type === TypeModule.SURVEY);
				if (this.moduleSurveys) this.modulesShowed.push(this.moduleSurveys);
				this.moduleQuizs = results[1].find((module) => module.type === TypeModule.QUIZ);
				if (this.moduleQuizs) this.modulesShowed.push(this.moduleQuizs);
				this.moduleTrainings = results[1].find((module) => module.type === TypeModule.TRAINING);
				if (this.moduleTrainings) this.modulesShowed.push(this.moduleTrainings);
				this.moduleAskQuestions = results[1].find((module) => module.type === TypeModule.ASK_QUESTION);
				if (this.moduleAskQuestions) this.modulesShowed.push(this.moduleAskQuestions);
				this.moduleExternalsInteractivity = results[1].find(
					(module) => module.type === TypeModule.EXTERNAL_INTERACTIVITY
				);
				if (this.moduleExternalsInteractivity) this.modulesShowed.push(this.moduleExternalsInteractivity);
				this.moduleWordClouds = results[1].find((module) => module.type === TypeModule.WORDCLOUD);
				if (this.moduleWordClouds) this.modulesShowed.push(this.moduleWordClouds);
				this.modulesShowed = this.modulesShowed.sort((a, b) =>
					a.options.interactivityOrder > b.options.interactivityOrder
						? 1
						: a.options.interactivityOrder < b.options.interactivityOrder
						? -1
						: 0
				);
				if (this.moduleInteractivity) {
					this.store.dispatch(GetHeaderTitle({ payload: this.moduleInteractivity.name }));
				}
			})
		);
	}

	/**
	 * Get necessary datas
	 */
	getNecessaryDatas() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getAccessiblesQuizsByType(0, null)),
				this.store.select(getAccessiblesAsksQuestionsByType(0, null)),
				this.store.select(getAccessiblesSurveysByType(0, null)),
				this.store.select(getAccessiblesWordsClouds),
				this.store.select(getAccessiblesExternalsInteractivityByType(0, null))
			]).subscribe((results) => {
				this.quizs = results[0];
				this.asksQuestions = results[1];
				this.surveys = results[2];
				this.wordsClouds = results[3];
				this.externalsInteractivity = results[4];
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
			})
		);
	}

	/**
	 * Navigate to path
	 * @param path
	 */
	navigateTo(path: string, type: string) {
		if (type === "surveys") {
			this.navCtrl.navigateForward(this.surveys.length === 1 ? `${path}/${this.surveys[0].uid}` : path);
		} else if (type === "quizs") {
			this.navCtrl.navigateForward(this.quizs.length === 1 ? `${path}/${this.quizs[0].uid}` : path);
		} else if (type === "asksQuestions") {
			this.navCtrl.navigateForward(
				this.asksQuestions.length === 1 ? `${path}/${this.asksQuestions[0].uid}` : path
			);
		} else if (type === "wordsClouds") {
			this.navCtrl.navigateForward(this.wordsClouds.length === 1 ? `${path}/${this.wordsClouds[0].uid}` : path);
		} else if (type === "externalsInteractivity") {
			this.navCtrl.navigateForward(
				this.externalsInteractivity.length === 1 ? `${path}/${this.externalsInteractivity[0].uid}` : path
			);
		} else {
			this.navCtrl.navigateForward(path);
		}
	}
}
