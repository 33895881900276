<app-visio
	*ngIf="showVisioWebApp"
	[type]="'chat'"
	[joined]="true"
	[showJoinBtn]="false"
	[event]="event"
	[visio]="visio"
	[height]="'100%'"
	(closeVisioEvent)="onCloseVisio()"
	#visioConference
>
</app-visio>

<div *ngIf="event && module && eventUser" class="background">
	<ion-content role="feed">
		<ion-segment
			mode="md"
			[value]="AppointmentTimeSlotStatus.APPOINTMENT_PENDING"
			(ionChange)="segmentChanged($event)"
		>
			<ion-segment-button
				[style.--background-hover]="event.styling.btnBgColor"
				[style.--color-checked]="event.styling.contentTextColor"
				[style.--color-focused]="event.styling.contentTextColor"
				[style.--color-hover]="event.styling.contentTextColor"
				[style.--border-color]="event.styling.btnBgColor"
				[style.--indicator-color]="event.styling.btnBgColor"
				mode="md"
				[value]="AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED"
			>
				<ion-label>{{ "appointments.app_status.coming" | translate }}</ion-label>
			</ion-segment-button>
			<ion-segment-button
				[style.--background-hover]="event.styling.btnBgColor"
				[style.--color-checked]="event.styling.contentTextColor"
				[style.--color-focused]="event.styling.contentTextColor"
				[style.--color-hover]="event.styling.contentTextColor"
				[style.--border-color]="event.styling.btnBgColor"
				[style.--indicator-color]="event.styling.btnBgColor"
				mode="md"
				[value]="AppointmentTimeSlotStatus.APPOINTMENT_PENDING"
			>
				<ion-label>{{ "appointments.app_status.waiting" | translate }}</ion-label>
			</ion-segment-button>
			<ion-segment-button
				[style.--background-hover]="event.styling.btnBgColor"
				[style.--color-checked]="event.styling.contentTextColor"
				[style.--color-focused]="event.styling.contentTextColor"
				[style.--color-hover]="event.styling.contentTextColor"
				[style.--border-color]="event.styling.btnBgColor"
				[style.--indicator-color]="event.styling.btnBgColor"
				mode="md"
				[value]="AppointmentTimeSlotStatus.APPOINTMENT_ENDED"
			>
				<ion-label>{{ "appointments.app_status.ended" | translate }}</ion-label>
			</ion-segment-button>
			<ion-segment-button
				[style.--background-hover]="event.styling.btnBgColor"
				[style.--color-checked]="event.styling.contentTextColor"
				[style.--color-focused]="event.styling.contentTextColor"
				[style.--color-hover]="event.styling.contentTextColor"
				[style.--border-color]="event.styling.btnBgColor"
				[style.--indicator-color]="event.styling.btnBgColor"
				mode="md"
				[value]="AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED"
			>
				<ion-label>{{ "appointments.app_status.cancelled" | translate }}</ion-label>
			</ion-segment-button>
		</ion-segment>

		<ion-fab
			*ngIf="filteredAppointments.length > 0 && module.options.allowIcsDownload && (segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED || segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_PENDING || segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_ENDED)"
			(click)="downloadAppointments()"
			slot="fixed"
			horizontal="right"
			vertical="bottom"
			style="right: 15px; bottom: 15px"
		>
			<ion-fab-button class="basic-fab-button" style="width: 50px; height: 50px">
				<ion-icon
					[color]="event.styling.btnTextColor"
					src="assets/images/add-on-calendar.svg"
					style="font-size: 26px"
				></ion-icon>
			</ion-fab-button>
		</ion-fab>
		<ion-list *ngIf="showedAppointments.length > 0 else emptyContent" lines="none" style="padding: 10px">
			<div
				*ngFor="let appointment of showedAppointments"
				style="
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border-radius: 10px;
					border: 1px solid #e0e0e0;
					padding: 10px;
					margin: 10px 0px;
				"
			>
				<div
					style="
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
					"
				>
					<div
						style="
							width: 100%;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: flex-start;
						"
					>
						<span [style.color]="event.styling.contentTextColor" style="font-size: 12px; font-weight: 600"
							>{{ getPartOfDate(appointment.startDate, "full") | titlecase }}</span
						>
						<span
							[style.color]="event.styling.contentTextColor"
							style="font-size: 12px; font-weight: 600; margin-left: 10px"
							>{{ getPartOfDate(appointment.startDate, "time") | titlecase }} - {{
							getPartOfDate(getEndScheduleDate(appointment.startDate, appointment.duration), "time") |
							titlecase }}</span
						>
					</div>

					<ion-icon
						*ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && !appointmentIsEnded(appointment) && checkVisioAllowed(appointment)"
						(click)="joinVisio(appointment)"
						[style.color]="event.styling.contentTextColor"
						style="font-size: 20px"
						name="videocam-outline"
					></ion-icon>
				</div>

				<div style="width: 100%; height: 1px; background: rgba(0, 0, 0, 0.1); margin: 10px 0"></div>

				<div
					*ngIf="appointment && ((appointment.recipientDatas && appointment.recipientDatas.uid !== eventUser.uid) || (appointment.applicantDatas && appointment.applicantDatas.uid !== eventUser.uid))"
					style="
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
						position: relative;
					"
				>
					<img
						[src]="
                            appointment.recipientDatas && appointment.recipientDatas.uid !== eventUser.uid && appointment.recipientDatas.photoUrl && appointment.recipientDatas.photoUrl !== ''
                                ? appointment.recipientDatas.photoUrl
                                : appointment.applicantDatas && appointment.applicantDatas.uid !== eventUser.uid && appointment.applicantDatas.photoUrl && appointment.applicantDatas.photoUrl !== ''
								? appointment.applicantDatas.photoUrl
								: 'assets/icon/User2.svg'
                        "
						class="profile-picture"
						[style.border-color]="isMobile ? '#fff' : event.styling.menuTextColorGradient"
					/>
					<div
						style="
							width: 100%;
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							justify-content: center;
							line-height: normal;
						"
					>
						<span style="font-size: 18px" [style.color]="event.styling.titleColor"
							>{{ appointment.recipientDatas.uid !== eventUser.uid ? appointment.recipientDatas.name :
							appointment.applicantDatas.name }}</span
						>
						<app-custom-fields-tags
							[event]="event"
							[module]="module"
							[modulesCustomsFields]="filterModulesCustomFields(appointment.recipientDatas)"
							[eventUserProfile]="appointment.recipientDatas.uid !== eventUser.uid ? appointment.recipientDatas :
							appointment.applicantDatas"
							[customFields]="buildCustomFieldsForEventUser(appointment.recipientDatas.uid !== eventUser.uid ? appointment.recipientDatas :
							appointment.applicantDatas)"
							[fontSize]="12"
							[isPrivateMode]="eventUser && ((appointment.recipientDatas && eventUser.uid === appointment.recipientDatas.uid) || (appointment.applicantDatas && eventUser.uid === appointment.applicantDatas.uid)) ? true : false"
							[currentLanguage]="currentLanguage"
						></app-custom-fields-tags>
						<div
							*ngIf="appointment.location && getLocation(appointment.location)"
							style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start"
						>
							<ion-icon
								[style.color]="event.styling.contentTextColor"
								style="font-size: 12px; margin-right: 5px"
								name="location-outline"
							></ion-icon>
							<span
								[style.color]="event.styling.contentTextColor"
								style="font-size: 12px; font-weight: 600"
							>
								{{ getLocation(appointment.location).name }}
							</span>
						</div>
					</div>

					<ion-icon
						*ngIf="module.options.allowIcsDownload"
						(click)="openDownloadModal(appointment)"
						[style.color]="event.styling.contentTextColor"
						style="font-size: 22px; position: absolute; bottom: 0; right: 0; cursor: pointer"
						src="assets/images/add-on-calendar.svg"
					></ion-icon>
				</div>

				<div style="width: 100%; height: 1px; background: rgba(0, 0, 0, 0.1); margin: 10px 0"></div>

				<div
					style="
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						width: 100%;
					"
				>
					<div
						*ngIf="eventUser && appointment.recipientDatas && appointment.applicantDatas && event.settings.allowChat && appointment.recipientDatas?.email && appointment.applicantDatas?.email && (appointment.recipientDatas?.uid !== eventUser?.uid || appointment.applicantDatas?.uid !== eventUser?.uid) && (appointment.status !== AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED)"
						(click)="openChat(appointment.applicant.uid === eventUser.uid ? appointment.recipientDatas : appointment.applicantDatas)"
						[style.background-color]="event.styling.btnBgColor"
						[style.border-color]="event.styling.btnBgColor"
						[style.color]="event.styling.btnTextColor"
						style="
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							text-align: center;
							border: 1px solid;
							font-size: 12px;
							font-weight: 700;
							border-radius: 28px;
							padding: 10px 20px;
							margin: 0 10px 0 10px;
							cursor: pointer;
						"
					>
						{{ "appointments.buttons.send-message" | translate }}
					</div>
					<div
						(click)="openAppointment(appointment)"
						[style.background-color]="'white'"
						[style.border-color]="event.styling.btnBgColor"
						[style.color]="'black'"
						style="
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							text-align: center;
							border: 1px solid;
							font-size: 12px;
							font-weight: 700;
							border-radius: 28px;
							padding: 10px 20px;
							margin: 0 10px 0 10px;
							cursor: pointer;
						"
					>
						{{ "appointments.buttons.see-details" | translate }}
					</div>
				</div>

				<div
					*ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && !appointmentIsEnded(appointment) && checkVisioAllowed(appointment)"
					style="
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						width: 100%;
						margin-top: 10px;
					"
				>
					<div
						(click)="joinVisio(appointment)"
						[style.background-color]="event.styling.btnBgColor"
						[style.border-color]="event.styling.btnBgColor"
						[style.color]="event.styling.btnTextColor"
						style="
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							text-align: center;
							border: 1px solid;
							font-size: 12px;
							font-weight: 700;
							border-radius: 28px;
							padding: 10px 20px;
							margin: 0 10px 0 10px;
							cursor: pointer;
						"
					>
						<ion-icon
							[style.color]="event.styling.btnTextColor"
							style="font-size: 20px; margin-right: 15px"
							name="videocam-outline"
						></ion-icon>
						{{ "appointments.join_visio" | translate }}
					</div>
				</div>

				<div
					*ngIf="showLastSlot(appointment)"
					style="width: 100%; height: 1px; background: rgba(0, 0, 0, 0.1); margin: 10px 0"
				></div>

				<div
					style="
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 100%;
					"
				>
					<div
						*ngIf="appointment.recipient && appointment.recipient.uid === eventUser.uid && appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING && getLocationsOfRule(appointment).length > 0"
						style="width: 100%; margin-bottom: 10px"
					>
						<ion-select
							mode="md"
							interface="action-sheet"
							[(ngModel)]="appointment.location"
							[style.--padding-bottom]="'5px'"
							[style.--padding-end]="'5px'"
							[style.--padding-start]="'5px'"
							[style.--padding-top]="'5px'"
							style="width: 100%; border: 0.5px solid rgba(0, 0, 0, 0.2); border-radius: 3px"
							mode="md"
							[style.--placeholder-color]="event.styling.contentTextColor"
							[style.--placeholder-opacity]="1"
							placeholder="{{ 'appointments.select-location' | translate }}"
							type="text"
						>
							<ion-select-option value="none"> {{ "buttons.nonees" | translate }} </ion-select-option>
							<ion-select-option
								*ngFor="let location of getLocationsOfRule(appointment);"
								value="{{ location.uid }}"
							>
								{{ location.name }}
							</ion-select-option>
						</ion-select>
					</div>
					<div
						style="
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							width: 100%;
						"
					>
						<div
							*ngIf="appointment.recipient && appointment.recipient.uid === eventUser.uid && appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING"
							[style.pointer-events]="appointment.location ? 'all' : 'none'"
							[style.opacity]="appointment.location ? 1 : 0.3"
							(click)="acceptAppointment(appointment)"
							[style.background-color]="'#4CB169'"
							[style.border-color]="'#4CB169'"
							[style.color]="'white'"
							style="
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								text-align: center;
								border: 1px solid;
								font-size: 12px;
								font-weight: 700;
								border-radius: 28px;
								padding: 5px 10px;
								margin: 0 10px 0 10px;
								cursor: pointer;
							"
						>
							{{ "appointments.buttons.accept" | translate }}
						</div>
						<div
							*ngIf="appointment.recipient && appointment.recipient.uid === eventUser.uid && appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING"
							(click)="refuseAppointment(appointment)"
							[style.background-color]="'#D6242A'"
							[style.border-color]="'#D6242A'"
							[style.color]="'white'"
							style="
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								text-align: center;
								border: 1px solid;
								font-size: 12px;
								font-weight: 700;
								border-radius: 28px;
								padding: 5px 10px;
								margin: 0 10px 0 10px;
								cursor: pointer;
							"
						>
							{{ "appointments.buttons.refuse" | translate }}
						</div>
						<div
							*ngIf="(appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING && appointment.applicant.uid === eventUser.uid) || (appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && !appointmentIsEnded(appointment))"
							(click)="cancelAppointment(appointment)"
							[style.background-color]="'#D6242A'"
							[style.border-color]="'#D6242A'"
							[style.color]="'white'"
							style="
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								text-align: center;
								border: 1px solid;
								font-size: 12px;
								font-weight: 700;
								border-radius: 28px;
								padding: 5px 10px;
								margin: 0 10px 0 10px;
								cursor: pointer;
							"
						>
							{{ "appointments.buttons.cancel" | translate }}
						</div>
					</div>
				</div>

				<div
					*ngIf="appointmentIsEnded(appointment)"
					style="
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 100%;
					"
				>
					<div
						style="
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							width: 100%;
						"
					>
						<div
							[style.color]="event.styling.contentTextColor"
							style="
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								text-align: center;
								font-size: 12px;
								font-weight: 700;
							"
						>
							{{ "appointments.app_status.ended" | translate }}
						</div>
					</div>
				</div>

				<div
					*ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED"
					style="
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 100%;
					"
				>
					<div
						style="
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							width: 100%;
						"
					>
						<div
							[style.color]="event.styling.contentTextColor"
							style="
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								text-align: center;
								font-size: 12px;
								font-weight: 700;
							"
						>
							{{ "appointments.app_status.cancelled-by" | translate:{ user: appointment.updateInformations
							&& appointment.updateInformations.cancelledBy ? getWhoOnAppointment(appointment,
							appointment.updateInformations.cancelledBy) : "Unknown"} }}
						</div>
					</div>
				</div>
			</div>
		</ion-list>
		<ion-infinite-scroll
			*ngIf="showedAppointments.length > 0"
			#ionInfiniteScroll
			threshold="150px"
			position="bottom"
			(ionInfinite)="moreAppointments($event)"
		>
			<ion-infinite-scroll-content loadingSpinner="dots"> </ion-infinite-scroll-content>
		</ion-infinite-scroll>

		<ng-template #emptyContent>
			<div class="container">
				<span class="wait-content" [style.color]="event.styling.titleColor"
					>{{ (segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ?
					"appointments.no-rdv-accepted" : segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ?
					"appointments.no-rdv-pending" : segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_ENDED ?
					"appointments.no-rdv-ended" : "appointments.no-rdv-cancelled" ) | translate }}</span
				>
				<ion-icon
					[src]="'assets/images/emptycontent/AUCUNRESULTAT.svg'"
					[style.color]="
						event.styling.menuColor.includes('linear')
							? event.styling.menuColor.split(',')[1]
							: event.styling.menuColor
					"
				>
				</ion-icon>
			</div>
		</ng-template>
	</ion-content>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
