import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IVideoPlayerLink, IVideoPlayerState } from "../interfaces/video-player.interfaces";

export const getVideoPlayerState = createFeatureSelector<IVideoPlayerState>("videoPlayer");

export const getBasicVideoPlayer = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.videoPlayer;
});

export const getBasicVideoPlayerLink = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.videoPlayer.link;
});

export const getBasicVideoPlayerSrc = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.videoPlayer.src;
});

export const getBasicVideoPlayerType = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.videoPlayer.type;
});

export const getBasicVideoPlayerStart = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.videoPlayer.start;
});

export const getBasicVideoPlayerState = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return _.cloneDeep(state.videoPlayer.options.state);
});

export const getBasicVideoPlayerActivated = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.videoPlayer.activated;
});

export const getBaseVideoPlayer = createSelector(
	getBasicVideoPlayerLink,
	getBasicVideoPlayerSrc,
	getBasicVideoPlayerStart,
	getBasicVideoPlayerType,
	(link: IVideoPlayerLink, src: string, start: number, type: string) => {
		return _.cloneDeep({
			link: link,
			src: src,
			type: type,
			start: start
		});
	}
);

export const getMiniVideoPlayer = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.miniVideoPlayer;
});

export const getMiniVideoPlayerLink = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.miniVideoPlayer.link;
});

export const getMiniVideoPlayerSrc = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.miniVideoPlayer.src;
});

export const getMiniVideoPlayerType = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.miniVideoPlayer.type;
});

export const getMiniVideoPlayerStart = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.miniVideoPlayer.start;
});

export const getMiniVideoPlayerState = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.miniVideoPlayer.options.state;
});

export const getMiniVideoPlayerActivated = createSelector(getVideoPlayerState, (state: IVideoPlayerState) => {
	return state.miniVideoPlayer.activated;
});

export const getBaseMiniVideoPlayer = createSelector(
	getMiniVideoPlayerLink,
	getMiniVideoPlayerSrc,
	getMiniVideoPlayerStart,
	getMiniVideoPlayerType,
	(link: IVideoPlayerLink, src: string, start: number, type: string) => {
		return _.cloneDeep({
			link: link,
			src: src,
			type: type,
			start: start
		});
	}
);

export const checkSamePage = (itemId: string, type: string) =>
	createSelector(
		type === "mini" ? getBasicVideoPlayerLink : getMiniVideoPlayerLink,
		(videoPlayerLink: IVideoPlayerLink) => (videoPlayerLink.itemId === itemId ? true : false)
	);
