import { DOCUMENT } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Injector,
	NgZone,
	OnDestroy,
	OnInit,
	WritableSignal,
	signal
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Params } from "@angular/router";
import { SwPush } from "@angular/service-worker";
import { Browser } from "@capacitor/browser";
import { Device, DeviceInfo } from "@capacitor/device";
import { Keyboard } from "@capacitor/keyboard";
import { Network } from "@capacitor/network";
import {
	AlertController,
	MenuController,
	ModalController,
	NavController,
	PickerController,
	Platform
} from "@ionic/angular";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { where } from "firebase/firestore";
import * as _ from "lodash";
import { DateTime } from "luxon";
import OneSignal from "onesignal-cordova-plugin";
import { Subscription, combineLatest, firstValueFrom, of } from "rxjs";
import { skipWhile, switchMap, take } from "rxjs/operators";
import { Loading, MenuActivated, SetNotificationBadge } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, IModule, INotification, IPost, IUser } from "src/app/shared/interfaces";
import { IRegisterForm } from "src/app/shared/interfaces/register-form.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentUser, getCurrentUserLanguage } from "src/app/shared/selectors/auth.selectors";
import { getCheckinsOfModule } from "src/app/shared/selectors/checkins.selectors";
import { getFoldersOfModule } from "src/app/shared/selectors/documents.selectors";
import { getCurrentEvent, getSpecificEvent } from "src/app/shared/selectors/events.selectors";
import { getPosts } from "src/app/shared/selectors/feed-news.selectors";
import { getNotifications } from "src/app/shared/selectors/generics-modules-data.selectors";
import {
	getChatsModule,
	getFavoriteModule,
	getModulesByType,
	getNotGenericModules
} from "src/app/shared/selectors/modules.selectors";
import { getRankingsOfModule } from "src/app/shared/selectors/rankings.selectors";
import { getRegisterForms } from "src/app/shared/selectors/register-form.selectors";
import { selectQueryParams, selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	getChatsBadge,
	getCustomFont,
	getMenuActivated,
	getNotificationsBadge
} from "src/app/shared/selectors/utility.selectors";
import { getMiniVideoPlayerActivated } from "src/app/shared/selectors/video-player.selectors";
import {
	AuthService,
	ChatsService,
	EventsService,
	EventUsersService,
	FavoritesService,
	FirestoreService,
	NotificationsService,
	PresenceService,
	SchedulesService,
	StateManagerService,
	StorageService,
	UtilityService
} from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";
import { getCurrentEventUser } from "./../../../shared/selectors/auth.selectors";

@Component({
	selector: "app-event",
	templateUrl: "./event.page.html",
	styleUrls: ["./event.page.scss"]
})
export class EventPage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	eventUserSubscription: Subscription;
	userSubscription: Subscription;
	postsSub: Subscription;
	onPauseSubscription: Subscription;
	onResumeSubscription: Subscription;
	appIsActiveSub: Subscription;

	deviceInfo: DeviceInfo;

	videoPlayerActivated: boolean = false;

	typesModules = TypeModule;

	headerHeight: any = "";

	languages: string[] = [];
	language: string;

	hamburgerActivated: boolean = false;
	menuActivated: boolean = false;
	isMobile: WritableSignal<boolean> = signal(false);
	isIos: boolean = false;
	marginTop: number = 56;

	event: WritableSignal<IEvent> = signal(null);
	events: IEvent[] = [];
	modules: IModule[] = [];
	menuModules: IModule[] = [];
	chatsModule: IModule;

	user: IUser;
	eventUser: IEventUser;

	activeModuleId: string;
	activeModule: IModule;

	currentUrl: string;
	currentParams: Params;
	typeUser: any;
	moduleId: any;
	userId: any;

	serviceWorker: Worker;
	notifications: INotification[] = [];

	chatsBadge: number = 0;
	notificationsBadge: number = 0;
	customFont: any;

	posts: IPost[] = [];

	typeModule = TypeModule;
	notShowModuleWhenNoUser: number[] = [TypeModule.SELF_CHECKIN, TypeModule.PERSONALSCHEDULE];

	eventAccess: boolean = false;
	updating: boolean = false;

	loader: boolean = true;
	initProgress: number = 0;

	init: boolean = true;
	initOneSignal: boolean = false;
	initEventUserCheck: boolean = false;

	openFillProfilOpened: boolean = false;
	openFillFormOpened: boolean = false;
	openAdsModalOpened: boolean = false;
	confirmationModalShowed: boolean = false;

	logo: string = "";
	loaderColor: string = "#4f8db2";
	loaderBackgroundColor: string = "#ffffff";

	currentLanguage: string = environment.platform.defaultLanguage;

	keyBoardHeight: number = 0;
	pageHeight: number = 0;
	inApp: boolean = false;
	updateOnUserSub: Subscription;
	updateOnDisconnectSub: Subscription;
	SPresenceService: PresenceService;

	// Forms
	registerForms: IRegisterForm[] = [];
	defaultRegisterForm: IRegisterForm;

	queryParams: WritableSignal<any> = signal(null);
	eventUserModules: IModule[] = [];

	menuAndHeaderVisibility: boolean = true;
	deviceWidth: number;

	showNavBar: boolean = false;

	@HostListener("document:visibilitychange", ["$event"])
	changingTabs() {
		// if (this.platform.is("desktop")) {
		if (this.inApp && document.hidden) {
			// Track leave app analytics
			this.SAnalytics.goOutOfApp();
			this.SPresence.setPresence("offline");

			// Updating subject
			this.SAnalytics.appIsActive$.next(false);
		} else if (!this.inApp && !document.hidden) {
			// Reentering in app
			this.SAnalytics.goBackOnApp(
				this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "desktop"
			);
			this.SPresence.setPresence("online");

			// Updating subject
			this.SAnalytics.appIsActive$.next(true);
		}
		this.inApp = !document.hidden;
		return true;
	}

	constructor(
		private route: ActivatedRoute,
		private SAnalytics: AnalyticsService,
		private SUtility: UtilityService,
		private SChats: ChatsService,
		private SSchedules: SchedulesService,
		private store: Store,
		public platform: Platform,
		private alertCtrl: AlertController,
		private menuCtrl: MenuController,
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		private pickerCtrl: PickerController,
		private snackbar: MatSnackBar,
		private SAuth: AuthService,
		private STranslate: TranslateService,
		private SEventUsers: EventUsersService,
		private SNotifications: NotificationsService,
		public push: SwPush,
		@Inject(DOCUMENT) private _document: Document,
		private title: Title,
		private elementRef: ElementRef,
		private SStateManager: StateManagerService,
		private cdr: ChangeDetectorRef,
		private zone: NgZone,
		public SPresence: PresenceService,
		private injector: Injector,
		private SFirestore: FirestoreService,
		private SStorage: StorageService,
		private SEvent: EventsService,
		private SFarvorites: FavoritesService
	) {
		Device.getInfo().then((info) => {
			this.deviceInfo = info;
		});
		this.store.dispatch(Loading({ payload: false }));

		this.isMobile.set(
			this.platform.is("ios") ||
				this.platform.is("android") ||
				this.platform.is("mobileweb") ||
				window.innerWidth < 768
				? true
				: false
		);
		if (this.isMobile()) {
			if (this.platform.is("android")) {
				this.headerHeight = 56;
			} else if (this.platform.is("ios")) {
				this.isIos = true;
				this.headerHeight = 44;
			} else {
				this.headerHeight = 56;
			}
		} else {
			this.headerHeight = 56;
		}

		this.SPresenceService = this.injector.get<PresenceService>(PresenceService);

		this.subscriptions.push(this.SPresence.getEvent());
		this.subscriptions.push(this.SPresence.getUser());
		this.updateOnUserSub = this.SPresence.updateOnUser().subscribe();
		this.updateOnDisconnectSub = this.SPresence.updateOnDisconnect().subscribe();

		// For loading
		this.route.params.pipe(take(1)).subscribe((params) => {
			this.store
				.select(getSpecificEvent(params.eventId as string))
				.pipe(take(1))
				.subscribe((event) => {
					if (event) {
						this.logo = event.identity && event.identity.logo ? event.identity.logo : "";
						this.loaderColor =
							event.styling && event.styling.menuColor ? event.styling.menuColor : "#4f8db2";
					}
				});
		});

		this.subscriptions.push(
			this.SUtility.headerMenuVisibility.subscribe((visibility) => {
				this.menuAndHeaderVisibility = visibility;
			})
		);

		// Network status & event user presence status
		Network.addListener("networkStatusChange", (status) => {
			if (status.connected) {
				if (this.appIsActiveSub && !this.appIsActiveSub.closed) {
					this.appIsActiveSub.unsubscribe();
				}
				this.appIsActiveSub = this.SAnalytics.appIsActive$.subscribe((isActive) => {
					if (isActive && this.inApp && !document.hidden) {
						this.SPresence.setPresence("online");
						this.SAnalytics.goBackOnApp(
							this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "desktop"
						);
					} else if (!isActive && !this.inApp && document.hidden) {
						this.SPresence.setPresence("offline");
						this.SAnalytics.goOutOfApp();
					}
				});
			}
		});

		// Manage eventuser prensence for mobile devices
		platform.ready().then(() => {
			if ((this.platform.is("mobile") && window.innerWidth < 768) || this.platform.is("mobileweb")) {
				this.onPauseSubscription = this.platform.pause.subscribe(() => {
					this.SAnalytics.goOutOfApp();
					this.SPresence.setPresence("offline");

					// Updating subject
					this.SAnalytics.appIsActive$.next(false);
				});

				this.onResumeSubscription = this.platform.resume.subscribe(() => {
					// Reentering in app
					this.SAnalytics.goBackOnApp(
						this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "desktop"
					);
					this.SPresence.setPresence("online");

					// Updating subject
					this.SAnalytics.appIsActive$.next(true);
				});
			}
		});

		// Manage event language
		this.subscriptions.push(
			combineLatest([
				this.store.select(getCurrentUserLanguage),
				this.store.select(getCurrentEvent),
				this.store.select(selectQueryParams)
			])
				.pipe(
					skipWhile((results) => !results[1]),
					take(1)
				)
				.subscribe((results) => {
					const userLanguage = results[0];
					const event = results[1];
					this.queryParams.set(results[2]);
					if (
						this.queryParams() &&
						this.queryParams()["lang"] &&
						this.currentLanguage !== this.queryParams()["lang"]
					) {
						this.setLanguageUse(this.queryParams()["lang"]);
					} else {
						this.setLanguageUse(
							event && userLanguage && event.languagesSettings[userLanguage]
								? userLanguage
								: event && event.language
								? event.language
								: environment.platform.defaultLanguage
						);
					}

					this.showNavBar =
						event &&
						event.settings.navigationBarLinks &&
						event.settings.navigationBarLinks.length > 0 &&
						Object.keys(this.queryParams()).length === 0 &&
						((this.isMobile() && event.settings.activateNavigationBarOnMobile) ||
							(!this.isMobile() && event.settings.activateNavigationBarOnDesktop));
				})
		);
		this.currentLanguage = this.STranslate.currentLang;
		this.language = this.currentLanguage.slice(0, 2).toLowerCase();
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
				this.language = lang.lang.slice(0, 2).toLowerCase();
			})
		);

		this.deviceWidth = window.innerWidth;

		if (!this.isMobile()) {
			this.SNotifications.initServiceWorker();
		}

		if (this.platform.is("mobile") && window.innerWidth < 768 && !this.platform.is("mobileweb")) {
			this.zone.run(() => {
				Keyboard.addListener("keyboardDidShow", (info) => {
					this.pageHeight = window.innerHeight;
					this.keyBoardHeight = info.keyboardHeight;
					this.cdr.detectChanges();
					this.cdr.markForCheck();
				});
				Keyboard.addListener("keyboardDidHide", () => {
					this.pageHeight = window.innerHeight;
					this.keyBoardHeight = 0;
					this.cdr.detectChanges();
					this.cdr.markForCheck();
				});
			});
		}
		this.pageHeight = window.innerHeight;
		this.SPresence.setPresence("online");
	}

	/**
	 * Get height of content
	 */
	getHeight() {
		return this.platform.is("mobileweb")
			? "calc(100% - " + this.keyBoardHeight + "px - " + this.headerHeight + "px" + ") !important"
			: "calc(100% - " + this.headerHeight + "px" + "56px" + ") !important";
	}

	ngOnInit() {
		this.subscriptions.push(
			this.store.select(getCustomFont).subscribe((customFont) => {
				this.customFont = customFont;
			})
		);

		// this.SAnalytics.updateConnectedStatus(true);

		/**
		 * Initialise event and routing subscriptions
		 */
		this.subscriptions.push(
			combineLatest([
				this.store.select(selectUrl).pipe(
					skipWhile((url) => !url),
					switchMap((url) => {
						return this.store.select(selectRouteNestedParams).pipe(
							switchMap((params) => {
								return of({
									url: url,
									params: params
								});
							})
						);
					})
				),
				this.store.select(getCurrentEvent),
				this.store.select(getNotGenericModules),
				this.store.select(getChatsModule),
				this.store.select(getFavoriteModule),
				this.store.select(getCurrentEventUser),
				this.store.select(getModulesByType(TypeModule.ATTENDEE)),
				this.store.select(getRegisterForms())
			]).subscribe((results) => {
				this.currentParams = results[0].params;
				const eventUser = results[5];
				this.eventUserModules = results[6];
				this.registerForms = results[7];

				if (!_.isEqual(this.event, results[1])) {
					this.event.set(results[1]);
					if (this.event()) {
						// Set default language of event
						this.STranslate.setDefaultLang(this.event().language);

						this.title.setTitle(this.event().title);
						this.languages = [];

						for (const key of Object.keys(this.event().languagesSettings)) {
							if (this.event().languagesSettings[key]) {
								this.languages.push(key);
							}
						}

						// Set colors
						document.body.style.setProperty(`--bg-color-content`, this.event().styling.bgContentColor);
						const bgColor = this.event().styling.bgContentColor.includes("rgba")
							? this.event()
									.styling.bgContentColor.split(",")
									.filter((item, i, tab) => i !== tab.length - 1)
									.join(",") + ",1)"
							: this.event().styling.bgContentColor;
						document.body.style.setProperty(`--bg-color`, bgColor);

						const bgModal = this.event().styling.bgContentColor.includes("rgba")
							? this.event().styling.bgContentColor
							: `${bgColor.substring(0, bgColor.length - 1)},0.1)`.replace("rgb", "rgba");

						document.body.style.setProperty(`--bg-color-modal`, bgModal);
						if (this.event().identity && this.event().identity.touchIcon) {
							this._document
								.getElementById("appFavicon")
								.setAttribute("href", this.event().identity.touchIcon);
							this._document
								.getElementById("appAppleFavicon")
								.setAttribute("href", this.event().identity.touchIcon);
							this._document
								.getElementById("appAppleStartupIcon")
								.setAttribute("href", this.event().identity.touchIcon);
						}

						if (this.registerForms && this.registerForms.length > 0) {
							this.defaultRegisterForm = this.registerForms.find(
								(registerForm) =>
									registerForm.eventId === this.event().uid &&
									registerForm.moduleId === this.event().settings.defaultAttendeesModule
							);
						}
					}
				}

				this.modules = results[2];

				this.menuModules = results[2]
					.concat(eventUser && eventUser !== null ? results[4] : [])
					.filter((module) => module)
					.filter((module) => {
						if (module.type === TypeModule.FAVORITES && !module.options.hideFavoriteMenuIcon) {
							return true;
						} else if (module.type === TypeModule.FAVORITES && module.options.hideFavoriteMenuIcon) {
							return false;
						} else {
							return (
								(this.event && !this.event().settings.visibility && module.habilitedApp) ||
								(module.habilitedApp &&
									this.event &&
									this.event().settings.visibility &&
									(module.publicConnectedVisibility ||
										module.publicConnectedVisibility === undefined) &&
									this.eventUser) ||
								(module.habilitedApp &&
									this.event &&
									this.event().settings.visibility &&
									(module.publicNotConnectedVisibility ||
										module.publicNotConnectedVisibility === undefined) &&
									!this.eventUser)
							);
						}
					})
					.filter(
						(module) =>
							(this.notShowModuleWhenNoUser.includes(module.type) && eventUser) ||
							!this.notShowModuleWhenNoUser.includes(module.type)
					);

				this.chatsModule = results[3];

				if ((this.modules && this.modules.length > 0) || this.currentUrl !== results[0].url) {
					const previousActiveModuleId = this.activeModuleId;
					this.activeModuleId = this.currentParams.moduleId
						? this.currentParams.moduleId
						: previousActiveModuleId;
					// this.activeModuleId = this.currentParams.moduleId;
					this.activeModule =
						this.modules.find((module) => this.activeModuleId === module.uid) || !this.activeModuleId
							? this.modules.find((module) => this.activeModuleId === module.uid)
							: this.modules[0];
				}
				this.currentUrl = results[0].url;

				this.getNews();

				if (this.event && !this.initEventUserCheck) {
					this.initEventUserCheck = true;

					if (this.platform.is("desktop")) {
						this.menuActivated =
							this.event().settings.openMenuFromStart === undefined
								? false
								: this.event().settings.openMenuFromStart;
						this.hamburgerActivated = this.menuActivated;
					}
					this.store.dispatch(MenuActivated({ payload: this.menuActivated }));

					this.initUser();
					this.initEventUser();

					if (!this.user && this.event().settings.visibility && this.event().settings.requiredSignup) {
						this.event().settings.defaultAuthentificationPage === "signin" && this.openLogin();
						this.event().settings.defaultAuthentificationPage === "signup" && this.openRegister();
					}
				}
			})
		);

		/**
		 * Menu activation subscription
		 */
		this.subscriptions.push(
			this.store.select(getMenuActivated).subscribe((activated) => {
				this.menuActivated = activated;
			})
		);

		this.subscriptions.push(
			this.store.select(getChatsBadge).subscribe((count) => {
				this.chatsBadge = count;
			})
		);

		this.subscriptions.push(
			this.store.select(getNotificationsBadge).subscribe((count) => {
				this.notificationsBadge = count;
			})
		);

		this.initNotifications();

		this.SChats.initChatsBadge();

		this.SEvent.getEventsQuery([], 0, 2).subscribe((results) => {
			this.events = results.docs;
		});

		this.subscriptions.push(
			this.store.select(getMiniVideoPlayerActivated).subscribe((activated) => {
				this.videoPlayerActivated = activated;
			})
		);

		this.SPresence.setPresence("online");
	}

	ngOnDestroy() {
		this.SPresence.setPresence("offline");
		this.SPresenceService.user = null;
		this.SPresenceService.eventId = null;
		this.SPresenceService.event = null;

		this.subscriptions
			.concat([
				this.eventUserSubscription,
				this.userSubscription,
				this.updateOnUserSub,
				this.updateOnDisconnectSub
			])
			.forEach((sub) => sub?.unsubscribe());
		this.SNotifications.destroyServiceWorker();
	}

	/**
	 * Init user
	 */
	initUser() {
		if (!this.userSubscription || this.userSubscription.closed) {
			this.userSubscription = this.store
				.select(getCurrentUser)
				.pipe(skipWhile(() => !this.event))
				.subscribe((user) => {
					if (!_.isEqual(this.user, user)) {
						this.user = _.cloneDeep(user);
					}
				});
		}
	}

	/**
	 * Init event user
	 */
	initEventUser() {
		if (this.eventUserSubscription && !this.eventUserSubscription.closed) {
			this.eventUserSubscription.unsubscribe();
		}
		this.eventUserSubscription = this.store.select(getCurrentEventUser).subscribe((user) => {
			if (!_.isEqual(this.eventUser, user) || !user) {
				this.initOneSignalToken(user);

				this.eventUser = user;

				if (
					this.event &&
					this.event().settings &&
					this.event().settings.requiredEditProfile &&
					this.eventUser &&
					!this.eventUser.editedProfile &&
					!this.openFillProfilOpened
				) {
					this.openFillProfilOpened = true;
					this.openFillProfil();
				}

				if (
					this.event &&
					this.event().settings &&
					((this.event().settings.requiredFillForm && this.eventUser && !this.eventUser.isAccompanying) ||
						(this.eventUser &&
							this.registerForms.find((m) => m.moduleId === this.eventUser?.moduleId)?.formSettings
								?.requiredFillFormForAccompnaying &&
							this.eventUser.isAccompanying)) &&
					// this.eventUser.moduleId === this.event().settings.defaultAttendeesModule &&
					!this.eventUser.hasFilledRegisterForm &&
					!this.openFillFormOpened
				) {
					this.openFillFormOpened = true;
					this.openFillForm();
				}

				if (
					this.event &&
					this.event().settings &&
					this.event().settings.enableAds &&
					!this.SEvent.openAdsModalOpened
				) {
					this.SEvent.openAdsModalOpened = true;
					this.openAdsModal();
				}
				// Event access tracking analytic
				if (!this.eventAccess) {
					this.SAnalytics.eventAccess(
						TypeTracking.ACCESS_TO_EVENT,
						this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "web"
					);
					this.eventAccess = true;
				}
			}
		});
	}

	async initOneSignalToken(user: IEventUser) {
		// Set notification config
		if (
			this.platform.is("mobile") &&
			window.innerWidth < 768 &&
			!this.platform.is("mobileweb") &&
			(!user || (user && (!this.eventUser || user.uid !== this.eventUser.uid)))
		) {
			try {
				const oneSignalId = await OneSignal.User.pushSubscription.getIdAsync();
				// Event private or public when user connected
				if (
					user &&
					user.options &&
					oneSignalId &&
					(!user.options.notifOneSignalConfig ||
						!user.options.notifOneSignalConfig.userId ||
						user.options.notifOneSignalConfig.userId !== oneSignalId)
				) {
					this.updating = true;
					await this.SEventUsers.setUserNotificationConfig(user, {
						userId: oneSignalId
					});
					this.updating = false;
				}

				// Event public event when no event user connected
				if (
					this.event().settings &&
					this.event().settings.visibility &&
					!this.event().settings.requiredSignup &&
					!user &&
					oneSignalId &&
					!this.updating
				) {
					this.updating = true;
					const notifModule = await firstValueFrom(
						this.store.pipe(select(getModulesByType(TypeModule.NOTIFICATION)), take(1))
					);
					notifModule &&
						notifModule[0] &&
						(await this.SNotifications.addDeviceIdPublicEvent(
							this.event().uid,
							notifModule[0].uid,
							oneSignalId
						));
					this.updating = false;
				}
			} catch (error) {
				this.updating = false;
			}
			// OneSignal.getDeviceState(async (response) => {
			// 	try {
			// 		// Event private or public when user connected
			// 		if (
			// 			user &&
			// 			user.options &&
			// 			response.userId &&
			// 			(!user.options.notifOneSignalConfig ||
			// 				!user.options.notifOneSignalConfig.userId ||
			// 				user.options.notifOneSignalConfig.userId !== response.userId)
			// 		) {
			// 			this.updating = true;
			// 			await this.SEventUsers.setUserNotificationConfig(this.event().uid, user, {
			// 				userId: response.userId
			// 			});
			// 			this.updating = false;
			// 		}

			// 		// Event public event when no event user connected
			// 		if (
			// 			this.event().settings &&
			// 			this.event().settings.visibility &&
			// 			!this.event().settings.requiredSignup &&
			// 			!user &&
			// 			response.userId &&
			// 			!this.updating
			// 		) {
			// 			this.updating = true;
			// 			const notifModule = await firstValueFrom(
			// 				this.store.pipe(select(getModulesByType(TypeModule.NOTIFICATION)), take(1))
			// 			);
			// 			notifModule &&
			// 				notifModule[0] &&
			// 				(await this.SNotifications.addDeviceIdPublicEvent(
			// 					this.event().uid,
			// 					notifModule[0].uid,
			// 					response.userId
			// 				));
			// 			this.updating = false;
			// 		}
			// 	} catch (error) {
			// 		this.updating = false;
			// 	}
			// });
		}
	}

	/**
	 * Init notifications badge
	 */
	initNotifications() {
		this.subscriptions.push(
			this.store.select(getNotifications()).subscribe(async (res) => {
				this.notifications = _.cloneDeep(res);
				let lastSeenPublicTime;
				if (this.event) {
					lastSeenPublicTime = await firstValueFrom(this.SStorage.get("lastSeenNotif" + this.event()?.uid));
				}

				const notifications = res.filter((notif) => {
					const lastSeenNotifTime =
						this.eventUser &&
						this.eventUser.updatedSettings &&
						this.eventUser.updatedSettings.lastSeenNotifTime
							? this.eventUser.updatedSettings.lastSeenNotifTime
							: !this.eventUser && lastSeenPublicTime
							? lastSeenPublicTime
							: 0;
					return notif.deliveryDate >= lastSeenNotifTime && notif.deliveryDate <= DateTime.now().valueOf();
				});
				this.store.dispatch(SetNotificationBadge({ payload: notifications.length }));
				if (notifications.length > 0) {
					if (!this.isMobile()) {
						this.SNotifications.postMessage({ notifs: notifications });
					}
				}
			})
		);
	}

	/**
	 * Track by uid for flickering
	 * @param index
	 * @param item
	 * @returns
	 */
	trackById(index: number, item: IModule): string {
		return item.uid;
	}

	/**
	 * Event for hamburger click
	 */
	hamburgerClicked(state: boolean) {
		this.hamburgerActivated = state;
		if (this.hamburgerActivated) {
			this.menuCtrl.open();
			this.store.dispatch(MenuActivated({ payload: true }));
		} else {
			this.menuCtrl.close();
			this.store.dispatch(MenuActivated({ payload: false }));
		}
	}

	/**
	 * Mouse enter menu event
	 */
	mouseEnterMenu() {
		if (!this.isMobile() && !this.hamburgerActivated) {
			this.menuCtrl.open();
			this.store.dispatch(MenuActivated({ payload: true }));
		}
	}

	/**
	 * Mouse leave menu
	 */
	mouseLeaveMenu() {
		if (!this.isMobile() && !this.hamburgerActivated && !this.event().settings.openMenuFromStart) {
			this.menuCtrl.close();
			this.store.dispatch(MenuActivated({ payload: false }));
		}
	}

	/**
	 * Menu open event
	 */
	menuOpen() {
		this.store.dispatch(MenuActivated({ payload: true }));
	}

	/**
	 * Menu close event
	 */
	menuClose() {
		this.store.dispatch(MenuActivated({ payload: false }));
	}

	/**
	 * Open terms and privacy modal
	 * @returns
	 */
	async openLegalTerms() {
		const modal = await this.modalCtrl.create({
			component: PathComponents.termAndPrivacy,
			componentProps: {
				eventId: this.event().uid
			}
			// cssClass: "full-sized-modal"
		});
		return await modal.present();
	}

	/**
	 * Open notification modal
	 * @returns
	 */
	async openNotifications(uid?: string) {
		const modal = await this.modalCtrl.create({
			component: PathComponents.notifications,
			componentProps: {
				eventId: this.event().uid,
				// currentUser: this.eventUser,
				openTime: DateTime.now().valueOf(),
				// notifications: this.notifications.filter((n) => n.deliveryDate <= DateTime.now().valueOf()),
				displayNotifID: uid ? uid : ""
			},
			cssClass: "full-sized-modal"
		});
		return await modal.present();
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openLogin(manualOpen?: boolean) {
		try {
			const modal = await this.modalCtrl.create({
				id: "login-modal",
				component: PathComponents.loginModal,
				componentProps: {
					eventId: this.event().uid,
					manualOpen: manualOpen ? manualOpen : false
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openRegister) {
				this.openRegister(manualOpen ? manualOpen : false);
			}
			// else if (data && data.openFillProfil) {
			// 	this.openFillProfil();
			// }
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegister(manualOpen?: boolean) {
		try {
			const totalEventUsers = await firstValueFrom(
				this.SFirestore.getCountOfQueryObs("event-users", "group", [where("eventId", "==", this.event().uid)])
			);

			if (this.event() && this.event().limitations && totalEventUsers >= this.event().limitations?.usersLimit) {
				this.snackbar.open(this.STranslate.instant("register.event_users_limit_reached"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}
			const modal = await this.modalCtrl.create({
				id: "register-modal",
				component: PathComponents.registerForm,
				backdropDismiss: false,
				componentProps: {
					eventId: this.event().uid,
					moduleId: this.eventUserModules.find((m) => m.uid === this.event().settings.defaultAttendeesModule)
						?.uid,
					mode: "register-modal",
					manualOpen: manualOpen ? manualOpen : false
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();

			if (data && data.openLogin) {
				this.openLogin(manualOpen ? manualOpen : false);
			}

			<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
				((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
					"hidden");

			// else if (data && data.openFillProfil) {
			// 	this.openFillProfil();
			// }
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open fill profil modal
	 */
	async openFillProfil() {
		try {
			const modal = await this.modalCtrl.create({
				id: "fill-profil-modal",
				component: PathComponents.profileEdit,
				backdropDismiss: false,
				componentProps: {
					eventId: this.event().uid,
					moduleId: this.eventUser.moduleId,
					eventUserProfileId: this.eventUser.uid,
					mode: "fill-profil-modal",
					manualOpen: false
				},
				cssClass: "full-sized-modal"
			});

			await modal.present();
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open fill profil modal
	 */
	async openFillForm() {
		try {
			// Check if there's form attached to the module
			if (!this.registerForms.find((m) => m.moduleId === this.eventUser.moduleId)) {
				return;
			}

			const modal = await this.modalCtrl.create({
				id: "fill-form-modal",
				component: PathComponents.registerForm,
				backdropDismiss: false,
				componentProps: {
					eventId: this.event().uid,
					moduleId: this.eventUser.moduleId,
					eventUserProfileId: this.eventUser.uid,
					mode: "fill-form-modal",
					manualOpen: false
				},
				cssClass: "full-sized-modal"
			});

			await modal.present();
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	async openAdsModal() {
		try {
			const modal = await this.modalCtrl.create({
				id: "add-modal",
				component: PathComponents.adsModal,
				backdropDismiss: false,
				componentProps: {
					event: this.event(),
					language: this.currentLanguage,
					mode: "add-modal"
				},
				cssClass: "full-sized-modal"
			});

			await modal.present();
		} catch (error) {
			// this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
			// 	duration: 3000,
			// 	panelClass: "error-snackbar"
			// });
		}
	}

	/**
	 * Alert for confirming redirection to events list
	 */
	async confirmRedirectListEvents() {
		const alert = await this.alertCtrl.create({
			header: this.STranslate.instant("alerts.list_events"),
			message: this.STranslate.instant("alerts.go_to_container_confirm"),
			buttons: [
				{
					text: this.STranslate.instant("buttons.cancel")
				},
				{
					text: this.STranslate.instant("buttons.go"),
					cssClass: "red-color",
					handler: () => {
						// Analytics
						this.SPresence.setPresence("offline");
						this.SAnalytics.leavingEvent();

						this.SStateManager.unsubscribeEvent();
						this.SStateManager.resetStateEvent();
						this.SEventUsers.unsubscribeAll();
						this.SFarvorites.unsubscribeAll();

						// this.eventUser = null;

						this.navCtrl.navigateRoot(this.user ? "/events-list/all" : "/login", {
							replaceUrl: true
						});
						this.elementRef.nativeElement.remove();
					}
				}
			]
		});

		await alert.present();
	}

	async goBackToLogin() {
		const alert = await this.alertCtrl.create({
			header: this.STranslate.instant("alerts.login-page"),
			message: this.STranslate.instant("alerts.go-to-login-confirm"),
			buttons: [
				{
					text: this.STranslate.instant("buttons.cancel")
				},
				{
					text: this.STranslate.instant("buttons.go"),
					cssClass: "red-color",
					handler: () => {
						// Analytics
						this.SPresence.setPresence("offline");
						this.SAnalytics.leavingEvent();

						this.SStateManager.unsubscribeAll();
						this.SStateManager.resetStateAll();
						this.SEventUsers.unsubscribeAll();

						this.navCtrl.navigateRoot("/login", {
							replaceUrl: true
						});
						this.elementRef.nativeElement.remove();
					}
				}
			]
		});

		await alert.present();
	}

	/**
	 * Open module
	 * @param module
	 */
	async openModule(module: IModule) {
		this.activeModule = module;
		this.activeModuleId = module.uid;
		if (module.type === TypeModule.EXTERNAL_LINK && module.options && module.options.link) {
			// try {
			if (module.options.openLinkBehavior === "sf") {
				await Browser.open({
					url: module.options.link
				});
			} else if (module.options.openLinkBehavior === "in-app" || !module.options.openLinkBehavior) {
				window.open(module.options.link, "_blank");
			}
			// } catch (error) {}
		} else {
			if (module.options.redirectUrl) {
				if (
					module.options.redirectUrl.includes("asks-questions") ||
					module.options.redirectUrl.includes("quizs") ||
					module.options.redirectUrl.includes("surveys")
				) {
					this.navCtrl.navigateForward([module.options.redirectUrl]);
				} else {
					this.navCtrl.navigateForward(module.options.redirectUrl);
				}
			} else {
				if (module.type === TypeModule.DOCUMENT || module.type === TypeModule.GALLERY) {
					const folders = await firstValueFrom(
						this.store.pipe(
							select(
								getFoldersOfModule({
									moduleId: module.uid,
									order: module.typeOrder,
									language: this.currentLanguage
								})
							),
							take(1)
						)
					);
					if (folders && folders.length === 1) {
						this.navCtrl.navigateForward(
							module.type === TypeModule.DOCUMENT
								? `/event/${this.event().uid}/documents/${module.uid}/folder/${folders[0].uid}`
								: `/event/${this.event().uid}/gallery/${module.uid}/folder/${folders[0].uid}`
						);
					} else {
						this.navCtrl.navigateForward(module.urlApp);
					}
				} else if (module.type === TypeModule.SCHEDULE) {
					this.SSchedules.getSessionsOfModuleWithFilters(this.event().uid, module.uid, null, false)
						.pipe(take(1))
						.subscribe((sessions) => {
							if (sessions.datas.length === 1) {
								this.navCtrl.navigateForward(
									`event/${this.event().uid}/schedule/${module.uid}/session/${sessions.datas[0].uid}`
								);
							} else {
								this.navCtrl.navigateForward(module.urlApp);
							}
						});
				} else if (module.type === TypeModule.RANKINGS) {
					this.store
						.select(getRankingsOfModule(module.uid))
						.pipe(take(1))
						.subscribe((rankings) => {
							if (rankings.length === 1) {
								this.navCtrl.navigateForward(
									`event/${this.event().uid}/rankings/${module.uid}/${rankings[0].uid}`
								);
							} else {
								this.navCtrl.navigateForward(module.urlApp);
							}
						});
				} else if (module.type === TypeModule.CHECKIN) {
					this.store
						.select(getCheckinsOfModule(module.uid))
						.pipe(take(1))
						.subscribe((checkins) => {
							if (checkins.length === 1) {
								this.navCtrl.navigateForward(
									`event/${this.event().uid}/checkins/${module.uid}/checkin/${checkins[0].uid}`
								);
							} else {
								this.navCtrl.navigateForward(module.urlApp);
							}
						});
				} else {
					this.navCtrl.navigateForward(module.urlApp);
				}
			}
		}
	}

	/**
	 * Check show module
	 * @param module
	 * @returns
	 */
	checkShowModule(module: IModule) {
		if ((this.eventUser && module.hideForLoggedUsers) || (!this.eventUser && module.hideForNotLoggedUsers)) {
			return of(true);
		}
		return of(false);
	}

	/**
	 * Get language
	 */
	getLanguage() {
		return this.user && this.user.language
			? this.user.language.slice(0, 2).toLowerCase()
			: this.event()?.language.slice(0, 2).toLowerCase();
	}

	/**
	 * Changing language
	 */
	async changeLanguage() {
		const picker = await this.pickerCtrl.create({
			columns: [
				{
					name: "lang",
					options: [
						{
							text: this.STranslate.instant("languages.arabic"),
							value: "ArAR"
						},
						{
							text: this.STranslate.instant("languages.german"),
							value: "DeDE"
						},
						{
							text: this.STranslate.instant("languages.english"),
							value: "EnUS"
						},
						{
							text: this.STranslate.instant("languages.spanish"),
							value: "EsES"
						},
						{
							text: this.STranslate.instant("languages.french"),
							value: "FrFR"
						},
						{
							text: this.STranslate.instant("languages.portuguese"),
							value: "PtBR"
						}
					].filter((lang) => {
						if (this.event().languagesSettings[lang.value] && lang.value !== this.currentLanguage) {
							return true;
						} else {
							return false;
						}
					})
				}
			],
			buttons: [
				{
					text: this.STranslate.instant("buttons.cancel"),
					role: "cancel"
				},
				{
					text: this.STranslate.instant("buttons.ok"),
					handler: (option) => {
						const lang = option.lang.value;
						if (this.user) {
							this.user.language = lang;
							this.eventUser.updatedSettings.language = lang;

							this.SAuth.updateUser(this.user);
							this.SEventUsers.updateEventUserUpdatedSettings(
								this.eventUser.eventId,
								this.eventUser.moduleId,
								this.eventUser.uid,
								{
									language: lang
								}
							);
						}
						this.language = lang.slice(0, 2).toLowerCase();
						this.STranslate.use(lang);
					}
				}
			]
		});
		await picker.present();
	}

	/**
	 * Log out user
	 */
	logOut() {
		// Analytics
		this.SAnalytics.leavingEvent();

		this.SAuth.logoutUser();
		this.elementRef.nativeElement.remove();
	}

	/**
	 * Navigate to
	 * @param path
	 */
	navigateTo(path: string) {
		this.navCtrl.navigateForward(path);
	}

	/**
	 * Getting news badge number
	 */
	getNews() {
		if (this.postsSub && !this.postsSub.closed) {
			this.postsSub.unsubscribe();
		}
		if (this.eventUser && this.eventUser.updatedSettings && this.eventUser.updatedSettings.accessModule) {
			this.postsSub = this.store.select(getPosts).subscribe((posts) => {
				this.posts = posts.sort((a, b) =>
					a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
				);
			});
		}
	}

	/**
	 * Get news count of each module
	 */
	getNewsCount(module: IModule) {
		if (this.eventUser) {
			return this.posts.filter(
				(post) =>
					post.moduleId === module.uid &&
					this.eventUser.updatedSettings &&
					this.eventUser.updatedSettings.accessModule &&
					this.eventUser.updatedSettings.accessModule[module.uid] &&
					this.eventUser.updatedSettings.accessModule[module.uid] < post.creationDate &&
					post.active
			).length;
		} else {
			return 0;
		}
	}

	/**
	 * Returns the color of the background, according to the allowGradient boolean.
	 */
	backgroundColor(allowGradient: boolean) {
		if (allowGradient) {
			return (
				"linear-gradient(to right, " +
				this.event().styling.menuColor +
				", " +
				this.event().styling.menuColorGradient +
				")"
			);
		} else {
			return this.event().styling.menuColor;
		}
	}

	/**
	 * Close menu on mobile
	 */
	closeMenuOnMobile() {
		if (this.isMobile()) {
			this.store.dispatch(MenuActivated({ payload: false }));
			this.menuCtrl.close();
		}
	}

	showProfile() {
		this.navigateTo(
			"/event/" + this.event().uid + "/profile/" + this.eventUser.moduleId + "/" + this.eventUser.uid
		);
	}

	/**
	 * Get background colord
	 * @returns
	 */
	getBackgroundColor() {
		if (this.currentUrl?.includes("widget")) {
			const module = this.modules.find((module) => module.uid === this.currentParams.moduleId);
			if (module && module.type === TypeModule.WIDGETS) {
				if (!module.options.backgroundTypeImage) {
					if (!module.options.backgroundIsDegrade) {
						return module.options.backgroundColor;
					} else {
						// eslint-disable-next-line max-len
						return `linear-gradient(to right, ${module.options.backgroundColor}, ${module.options.backgroundColorSecondary})`;
					}
				} else {
					return this.event().styling.bgContentColor;
				}
			} else {
				return this.event().styling.bgContentColor;
			}
		} else {
			return this.event().styling.bgContentColor;
		}
	}

	/**
	 * Setting used language
	 * @param language
	 */
	setLanguageUse(language: string) {
		this.STranslate.setDefaultLang(language);
		this.STranslate.use(language);
	}

	getModuleByUid(moduleId: string) {
		return this.modules.find((module) => module.uid === moduleId);
	}

	showRegisterConfirmModal(registerForm: IRegisterForm) {
		// Confirm alert
		if (registerForm.formSettings.enableConfirmationMsg) {
			this.openConfirmRegisterModal(
				registerForm.formSettings?.confirmationSettings?.confirmationText?.[this.currentLanguage],
				registerForm
			);
		}
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openConfirmRegisterModal(message: string, registerForm: IRegisterForm) {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.confirmRegisterModal,
				componentProps: {
					event: this.event,
					eventUser: this.eventUser,
					message: message,
					enableAddToCalendar: registerForm.formSettings.confirmationSettings.enableAddToCalendar,
					calendarEvent: registerForm.formSettings.confirmationSettings.calendarEvent
				},
				cssClass: this.isMobile() ? "full-sized-modal" : "confirm-register-modal"
			});
			await modal.present();

			await modal.onWillDismiss();
			this.eventUser.editedProfile = true;
			await this.SEventUsers.updateEventUser(this.event().uid, this.eventUser.moduleId, this.eventUser);
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	async showAccompanyingModal(registerForm: IRegisterForm) {
		// Accompanying forms
		if (
			registerForm.formSettings.enableAccompanyingUsers
			// &&
			// this.eventUser.updatedSettings.accompanyingUsers &&
			// this.eventUser.updatedSettings.accompanyingUsers.length <
			// 	this.registerForm.formSettings.accompanyingUsersLimit
		) {
			const accompanyingRegistrationAlert = await this.SUtility.presentAlert(
				this.STranslate.instant("register.register_an_accompanying_user_title"),
				this.STranslate.instant("register.register_an_accompanying_user_question"),
				[
					{
						text: this.STranslate.instant("buttons.yes"),
						handler: () => this.openAccompanyingForm(registerForm)
					},
					{
						text: this.STranslate.instant("buttons.no"),
						handler: async () => {
							this.eventUser.editedProfile = true;
							await this.SEventUsers.updateEventUser(
								this.event().uid,
								this.eventUser.moduleId,
								this.eventUser
							);
						}
					}
				]
			);
			accompanyingRegistrationAlert.present();
		}
	}

	/**
	 * Open openAccompanyingForm
	 */
	async openAccompanyingForm(registerForm: IRegisterForm) {
		try {
			const [totalEventUsers, totalModuleEventUsers, totalEventUsersAccompanying] = await Promise.all([
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs("event-users", "group", [
						where("eventId", "==", this.event().uid)
					])
				),
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs(
						`events/${this.event().uid}/modules/${this.eventUser.moduleId}/event-users`,
						"default",
						[]
					)
				),
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs(
						`events/${this.event().uid}/modules/${this.eventUser.moduleId}/event-users`,
						"default",
						[where("isAccompanying", "==", true), where("registeredBy", "==", this.eventUser.uid)]
					)
				)
			]);

			if (
				totalEventUsers >= this.event()?.limitations?.usersLimit ||
				(registerForm &&
					registerForm.formSettings.enableRegisterLimit &&
					totalModuleEventUsers >= registerForm.formSettings.registerLimit) ||
				(registerForm &&
					registerForm.formSettings.enableAccompanyingUsers &&
					this.eventUser &&
					totalEventUsersAccompanying >= registerForm.formSettings.accompanyingUsersLimit)
			) {
				this.snackbar.open(this.STranslate.instant("register.accompanying_users_limit_reached"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			const modal = await this.modalCtrl.create({
				id: "accompanying-form-modal",
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event().uid,
					moduleId: this.eventUser.moduleId,
					eventUserProfileId: this.eventUser.uid,
					mode: "accompanying-form-modal"
				},
				cssClass: "full-sized-modal"
			});

			await modal.present();

			this.eventUser.editedProfile = true;
			await this.SEventUsers.updateEventUser(this.event().uid, this.eventUser.moduleId, this.eventUser);
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}
}
