@if (event && module && data) {
<ion-content [style.--background]="isMobile ? '#fff' : 'transparent'">
	<div
		class="complete-header"
		[style.background]="'var(--bg-header)'"
		[style.color]="event.styling.menuTextColor"
	></div>
	<app-profile-general-info
		[event]="event"
		[module]="module"
		[myEventUser]="myEventUser"
		[eventUserProfile]="data"
		[currentLanguage]="currentLanguage"
		[isPrivateMode]="isProfilMode"
	>
	</app-profile-general-info>
	<div class="background" [style.min-height]="isMobile ? 'fit-content !important' : 'calc(100% - 164px) !important'">
		@if (module.options.allowChat && data && data['email'] && data['email'] !== '' && ( (event.settings.allowChat &&
		canRequestAppointment) || (event.settings.allowChat) || (event.settings.allowVisio &&
		event.settings.allowVisioForTwo ) )) {
		<div class="chat-row" [style.grid-template-columns]="checkTemplateColumns()">
			@if (event.settings.allowChat && data && data['email'] && data?.uid !== myEventUser?.uid) {
			<button
				[class.chat_button]="chat_button_loader"
				shape="round"
				class="btn-chat"
				(click)="openChat()"
				[style.width]="!(event.settings.allowVisio && event.settings.allowVisioForTwo) && '85%'"
				[style.background]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor"
				[style.grid-column-end]=" (!(event.settings.allowVisio && event.settings.allowVisioForTwo) && !canRequestAppointment) ? 3 :'auto'"
			>
				@if (!chat_button_loader) {
				<ion-icon src="assets/icon/Message.svg"></ion-icon>
				} @if (!chat_button_loader) {
				<span class="btn-txt">{{"profile.start_chat" | translate}}</span>
				}
			</button>
			} @if (event && event.settings.allowVisio && event.settings.allowVisioForTwo && module &&
			module.options.showVisioButton && data?.uid !== myEventUser?.uid && data && data['email']) {
			<button
				shape="round"
				class="icon-camera"
				[class.visio_button]="visio_button_loader"
				(click)="createVisio()"
				[style.border-color]="event.styling.btnBgColor"
				[style.color]="event.styling.btnBgColor"
			>
				@if (!visio_button_loader) {
				<ion-icon src="assets/icon/Camera2.svg"></ion-icon>
				} @if (!visio_button_loader) {
				<span class="btn-txt">{{"profile.start_visio" | translate}}</span>
				}
			</button>
			} @if (event.settings.allowAppointments && canRequestAppointment && data?.uid !== myEventUser?.uid && data
			&& data['email'] && data['type'] === typeUser.ATTENDEE) {
			<button
				shape="round"
				class="appointment-btn"
				[style.background]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor"
				[style.width]="event && event.settings.allowVisio && event.settings.allowVisioForTwo && module && module.options.showVisioButton &&
                    data?.uid !== myEventUser?.uid && data && data['email'] ? '45%' : '85%' "
				[style.grid-column-start]="event && event.settings.allowVisio && event.settings.allowVisioForTwo && module && module.options.showVisioButton &&
                    data?.uid !== myEventUser?.uid && data && data['email'] ? 1 : 'auto'"
				[style.grid-column-end]="event && event.settings.allowVisio && event.settings.allowVisioForTwo && module && module.options.showVisioButton &&
                    data?.uid !== myEventUser?.uid && data && data['email'] ? 3 : 'auto'"
				(click)="openUserTimeslots()"
			>
				<ion-icon src="assets/icon/Calendar2.svg"></ion-icon>
				<span>{{ "appointments.make_appointment" | translate }}</span>
			</button>
			}
		</div>
		}
		<div class="profil-content">
			<!-- QR Code -->
			@if (event.settings.allowProfileQR && myEventUser && myEventUser?.uid === data?.uid) {
			<ion-grid>
				<ion-row>
					<ion-col text-center>
						<div class="qr-code-container">
							<ion-icon src="assets/icon/scan-qr-code.svg"></ion-icon>
							<qrcode
								[qrdata]="event.uid + '+' + data?.uid"
								[elementType]="'canvas'"
								[width]="256"
								[errorCorrectionLevel]="'L'"
							>
							</qrcode>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>
			}
			<!-- Event user's description -->
			@if (((isProfilMode && !module.options.requiredFields.description?.hiding.profil)|| (!isProfilMode &&
			!SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, data, 'description', 'baseFields') &&
			!module.options.requiredFields.description?.hiding.public)) && data?.description &&
			data?.description[currentLanguage] && data?.description[currentLanguage] !== '') {
			<ion-card class="custom-card">
				<ion-card-content>
					<div
						class="container-description"
						[class.show]="showMore"
						[style.maxHeight]="module.options.showMoreOrLess && !showMore ? '100px' : 'fit-content'"
					>
						<span
							class="html-card-content"
							style="white-space: pre-line"
							[innerHTML]="data?.description[currentLanguage] | linky | safeHtml"
						>
						</span>
					</div>
					@if (module.options.showMoreOrLess) {
					<button (click)="showMore = !showMore">
						{{ showMore ? ("buttons.show_less" | translate) : ("buttons.show_more" | translate)}}
					</button>
					}
					<!-- visibility chip -->
					@if ( module.options.enableUserFieldsHideAbility && isProfilMode ) {
					<span class="visibility-state" style="top: 0 !important">
						@if (!SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, data, 'description',
						'baseFields')) {
						<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
						} @if (SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, data, 'description',
						'baseFields')) {
						<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
						}
					</span>
					}
				</ion-card-content>
			</ion-card>
			}
			<!-- Event user's group(s) -->
			@if (((isProfilMode && !module.options.requiredFields.group?.hiding.profil)|| (!isProfilMode &&
			!module.options.requiredFields.group?.hiding.public)) && data.groups.length > 0) {
			<div class="group-field">
				<div class="item-name" [style.color]="event.styling.titleColor">
					<i class="fa fa-users icon-people" aria-hidden="true"></i>
					@if (data.groups.length === 1) {
					<h1 class="group-title">{{ "labels.group" | translate }}</h1>
					} @if (data.groups.length > 1) {
					<h1 class="group-title">{{ "labels.groups" | translate }}</h1>
					}
				</div>
				<div class="group-value">
					@for (group of getCorrespondingGroups(data.groups); track group) {
					<small
						class="group-name"
						[style.color]="group ? group.textColor : ''"
						[style.background]="group
                            ? group.backgroundColor
                            : ''
                    "
					>
						{{ group.name }}
					</small>
					}
				</div>
			</div>
			}
			<!-- Email -->
			@if (((isProfilMode && !module.options.requiredFields.email?.hiding.profil)|| (!isProfilMode &&
			!SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, data, 'email', 'baseFields') &&
			!module.options.requiredFields.email?.hiding.public)) && data && data['email']) {
			<ion-card>
				<ion-card-content>
					<div class="email-bloc">
						<ion-icon src="assets/icon/Send.svg"></ion-icon>
						<div>
							<span class="email-label">{{ "labels.email" | translate }}:</span>
							<a
								class="email-text"
								href="mailto:{{ data && data['email'] }}"
								[style.color]="event.styling.btnBgColor"
							>
								{{ data && data['email'] }}
							</a>
						</div>
						<!-- visibility chip -->
						@if ( module.options.enableUserFieldsHideAbility && isProfilMode ) {
						<span class="visibility-state">
							@if (!SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, data, 'email',
							'baseFields')) {
							<ion-chip color="success">{{ "labels.visible" | translate }}</ion-chip>
							} @if (SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, data, 'email',
							'baseFields')) {
							<ion-chip color="danger">{{ "labels.hidden" | translate }}</ion-chip>
							}
						</span>
						}
					</div>
				</ion-card-content>
			</ion-card>
			}
			<!-- Customs fields -->
			<app-custom-fields-view
				#customFieldComponent
				[event]="event"
				[module]="module"
				[data]="data"
				[scheduleId]="null"
				[moduleType]="!data['type'] ? typeModule.SHEETS : data['type'] === typeModule.SPEAKER ? typeModule.SPEAKER : typeModule.ATTENDEE"
				[currentLanguage]="currentLanguage"
			>
			</app-custom-fields-view>
			<!-- Feedbacks -->
			@if (data && user && data?.uid !== user.uid && feedbacks.length > 0) {
			<div class="feedbacks-containerS">
				@for (feedback of feedbacks; track feedback) {
				<h6 class="module-name-block line-clamp-2" [style.color]="event.styling.titleColor">
					<ion-icon class="module-icon" src="assets/icon/icon-32.svg"></ion-icon>
					<span class="module-name"
						>{{"feedbacks.default_title" | translate}}: {{ feedback.name[currentLanguage] }}</span
					>
				</h6>
				<app-feedback
					[componentMode]="true"
					[eventId]="eventId"
					[moduleId]="feedback.moduleId"
					[feedbackId]="feedback.uid"
					[sessionId]="data?.uid"
					[showBackBtn]="false"
				>
				</app-feedback>
				<hr />
				}
			</div>
			}
			<!-- Delete account -->
			<div class="f-row-c-c">
				@if (event.settings.allowDeleteUserAccount && data && user && data.uid === user.uid) {
				<ion-button shape="round" (click)="deleteAccount()" color="danger">
					{{ "buttons.delete_account" | translate }}
				</ion-button>
				}
			</div>
		</div>
	</div>
	@if (loader) {
	<div class="loader-container">
		<div class="loader-main" [style.width]="'60px'" [style.height]="'60px'"></div>
	</div>
	}
</ion-content>
}
