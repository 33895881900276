import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IRegisterFormState } from "../interfaces/register-form.interfaces";

export const getRegisterFormState = createFeatureSelector<IRegisterFormState>("registerForms");

export const checkSameEvent = (uid: string) =>
	createSelector(getRegisterFormState, (state: IRegisterFormState) => {
		return state.eventId === uid ? true : false;
	});

export const getRegisterForms = () =>
	createSelector(getRegisterFormState, (state: IRegisterFormState) => _.cloneDeep(state.registerForms));

export const getCurrentRegisterForm = (eventId: string, moduleId: string) =>
	createSelector(getRegisterFormState, (state: IRegisterFormState) => {
		return _.cloneDeep(state.registerForms).find(
			(registerForm) => registerForm.eventId == eventId && registerForm.moduleId === moduleId
		);
	});
