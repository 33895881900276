import { createAction, props } from "@ngrx/store";
import { ISheet } from "../interfaces/sheets.interfaces";

export enum SheetsActionsTypes {
	GetAllSheets = "[Sheets Service] Get all sheets"
}

export const GetAllSheets = createAction(
	SheetsActionsTypes.GetAllSheets,
	props<{ payload: ISheet[]; eventId: string }>()
);
