import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { saveAs } from "file-saver";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { UtilityService } from "./utility.service";

@Injectable({
	providedIn: "root"
})
export class FileService {
	constructor(private http: HttpClient, private platform: Platform, private SUtility: UtilityService) {}

	/**
	 * Download a file from url
	 * @param url
	 * @returns
	 */
	downloadFileFromUrl(url: string, name: string) {
		return this.http
			.get(url, {
				responseType: "blob",
				reportProgress: true,
				observe: "body"
			})
			.pipe(
				switchMap((blob) => {
					if (this.platform.is("mobile") && window.innerWidth < 768) {
						// if (this.platform.is("ios")) {
						return new Promise((resolve) => {
							const reader = new FileReader();
							reader.readAsDataURL(blob);
							reader.onloadend = async () => {
								resolve(reader.result);
								const fileWriteResult = await Filesystem.writeFile({
									path: name,
									data: reader.result as string,
									directory: Directory.Cache,
									recursive: true
								});
								if (fileWriteResult && fileWriteResult.uri)
									this.SUtility.shareImage(fileWriteResult.uri);
							};
						});
						// } else {
						// 	return new Promise((resolve, reject) => {
						// 		const reader = new FileReader();
						// 		reader.readAsDataURL(blob);
						// 		reader.onloadend = async () => {
						// 			resolve(reader.result);
						// 			await Filesystem.writeFile({
						// 				path: `Download/${name}`,
						// 				data: reader.result as string,
						// 				directory: Directory.External,
						// 				recursive: true
						// 			});
						// 		};
						// 	});
						// }
					} else {
						saveAs(blob, name);
						return of(url);
					}
				}),
				catchError((error, caught) => {
					return caught;
				})
			);
	}
}
