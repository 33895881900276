<ion-header>
	<ion-toolbar class="default-toolbar">
		<ion-buttons slot="start">
			<ion-button ion-button clear (click)="close()">
				<ion-icon src="assets/icon/Close.svg"></ion-icon>
			</ion-button>
		</ion-buttons>

		<ion-title>
			<span>{{ "texts.privacy" | translate }}</span>
		</ion-title>
	</ion-toolbar>
</ion-header>

<div class="background terms">
	<p [innerHTML]="privacy | safeHtml : 'html'" *ngIf="privacy !== null && privacy !== undefined"></p>
</div>
