<div class="background">
	<ion-searchbar
		[style.--background]="'rgb(234, 239, 242)'"
		searchIcon="assets/icon/Search.svg"
		mode="md"
		placeholder="{{ 'texts.search_plc' | translate }}"
		(ionClear)="resetFilter()"
		(keyup)="searchBar($event)"
	>
	</ion-searchbar>
	<ion-content *ngIf="event && module && ranking" class="content-container">
		<ion-list *ngIf="!ranking.rankingByGroups">
			<div *ngIf="eventUser" class="my-ranking-container">
				<ion-label [style.font-weight]="'bold'" [style.color]="event.styling.menuColor">{{
					"rankings.my_ranking" | translate
				}}</ion-label>
				<ion-item
					[style.--background]="event.styling.menuColor"
					[style.background]="event.styling.menuColor"
					[style.--color]="event.styling.menuTextColor"
					lines="none"
					class="attendee-item"
				>
					<ion-note slot="start" style="--color: event.styling.menuTextColor" class="position-note">
						{{ getEventUserPosition(eventUser) ? getEventUserPosition(eventUser) : "Not ranked" }}
					</ion-note>
					<ion-avatar slot="start">
						<img [src]="eventUser.photoUrl" *ngIf="eventUser.photoUrl" />
						<div class="profile-picture" *ngIf="!eventUser.photoUrl">
							<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
						</div>
					</ion-avatar>
					<ion-label>{{ eventUser.name }}</ion-label>
					<ion-note slot="end" style="--color: event.styling.menuTextColor" class="point-note">
						<span class="number">{{ getEventUserPoints(eventUser) }}</span>
						<span class="pts">pts</span>
					</ion-note>
				</ion-item>
			</div>
			<br />
			<div class="global-ranking-container">
				<ion-label [style.font-weight]="'bold'" [style.color]="event.styling.menuColor"
					>{{ "rankings.global_ranking" | translate }}
				</ion-label>
				<ion-item
					*ngFor="let user of getXFirstPlacesEventUser(null)"
					lines="none"
					class="attendee-item"
					[style.color]="event.styling.titleColor"
				>
					<ion-note slot="start" class="position-note" [style.color]="event.styling.titleColor"
						>{{ getEventUserPosition(user) }}
					</ion-note>
					<ion-avatar slot="start">
						<img [src]="user.photoUrl" *ngIf="user.photoUrl" />
						<div class="profile-picture" *ngIf="!user.photoUrl">
							<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
						</div>
					</ion-avatar>
					<ion-label>{{ user.name }}</ion-label>
					<ion-note slot="end" class="point-note" [style.color]="event.styling.titleColor">
						<span class="number">{{ getEventUserPoints(user) }}</span>
						<span class="pts">pts</span>
					</ion-note>
				</ion-item>
			</div>
		</ion-list>

		<ion-list *ngIf="ranking.rankingByGroups">
			<ion-item
				*ngFor="let group of getXFirstPlacesGroup(null)"
				lines="none"
				class="attendee-item"
				[style.color]="event.styling.titleColor"
			>
				<ion-note slot="start" class="position-note" [style.color]="event.styling.titleColor">
					{{ getGroupPosition(group) }}
				</ion-note>
				<ion-avatar slot="start">
					<div class="profile-picture">
						<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
					</div>
				</ion-avatar>
				<ion-label>{{ group.name }}</ion-label>
				<ion-note slot="end" class="point-note" [style.color]="event.styling.titleColor">
					<span class="number">{{ getGroupPoints(group) }}</span>
					<span class="pts">pts</span>
				</ion-note>
			</ion-item>
		</ion-list>

		<ion-infinite-scroll
			*ngIf="event && module && ranking && eventUsersSaved.length > 0 && !ranking.rankingByGroups"
			[disabled]="infiniteScrollDisabled"
			threshold=" 100px"
			(ionInfinite)="!ranking.rankingByGroups ? getXFirstPlacesEventUser($event) : getXFirstPlacesGroup($event)"
		>
			<ion-infinite-scroll-content loadingSpinner="dots"> </ion-infinite-scroll-content>
		</ion-infinite-scroll>

		<div *ngIf="!event || !module || !ranking">
			<ion-item lines="none" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
				<ion-text>
					<ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
				</ion-text>
				<ion-avatar slot="start">
					<img src="../../../assets/images/grey.jpg" />
				</ion-avatar>
				<ion-label>
					<h3>
						<ion-skeleton-text animated style="width: 70%"></ion-skeleton-text> &nbsp;&nbsp;&nbsp;&nbsp;
						<ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
					</h3>
				</ion-label>
			</ion-item>
		</div>
	</ion-content>
</div>
