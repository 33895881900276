<div class="background" *ngIf="event && module && eventUser && !scanningWeb">
	<div class="main_content">
		<div class="infos-box">
			<ion-avatar>
				<ion-img [src]="eventUser.photoUrl" *ngIf="eventUser.photoUrl"> </ion-img>
				<ion-img class="cus-picture" *ngIf="!eventUser.photoUrl" src="assets/icon/User2.svg"></ion-img>
			</ion-avatar>
			<h3 class="user-name">{{ eventUser.name }}</h3>
		</div>

		<div class="last-checkins">
			<h5 class="last-checkins-title">{{ "checkin.last_checkins" | translate }}</h5>
			<div class="last-checkins-content">
				<ion-item *ngFor="let checked of validCheckeds">
					<label class="ion-text-wrap"
						>{{ getCheckinData(checked) ? getCheckinData(checked).name : "" }} -
						{{ getDate(checked.creationDate) }}</label
					>
				</ion-item>
			</div>
		</div>

		<div class="btn-box">
			<ion-button
				shape="round"
				class="btn-default"
				[style.--background]="event.styling.btnBgColor"
				[style.--color]="event.styling.btnTextColor"
				(click)="scanQr()"
			>
				<ion-icon src="assets/icon/Flash.svg"></ion-icon>
				<span [style.color]="event.styling.btnTextColor">{{ "texts.read_qrcode" | translate }}</span>
			</ion-button>
		</div>
	</div>
</div>

<div *ngIf="scanningWeb" style="position: relative">
	<ion-icon
		(click)="scanning = false; scanningWeb = false; enableScanWeb = false"
		style="position: absolute; top: 20px; right: 20px; font-size: 30px; cursor: pointer; z-index: 99999999"
		color="danger"
		name="close"
	>
	</ion-icon>
	<zxing-scanner
		[enable]="enableScanWeb"
		(camerasFound)="camerasFoundHandler()"
		(tryHarder)="(true)"
		(camerasNotFound)="camerasNotFoundHandler()"
		(scanSuccess)="scanSuccessHandler($event)"
		(scanError)="scanErrorHandler()"
	>
	</zxing-scanner>
</div>
