import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { auditTime, map, skipWhile, switchMap, take } from "rxjs/operators";
import { IEvent } from "src/app/shared/interfaces/events.interfaces";
import { GetEvent } from "../actions/events.actions";
import { GetCustomFont, InitSpecificEventDatasPart } from "../actions/utility.actions";
import { TypeLogin } from "../enums/type-login";
import { checkSameEventId } from "../selectors/events.selectors";
import { getInitContainers, getInitSpecificEventDatasPart, getInitUser } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import { getCurrentUser } from "../selectors/auth.selectors";
import { getContainersState } from "../selectors/containers.selectors";
import { environment } from "src/environments/environment";
import { MongodbService } from "./mongodb.service";

@Injectable({
	providedIn: "root"
})
export class EventsService {
	getCurrentEventSub: Subscription;

	logo: string = "";
	loaderColor: string = "";
	loaderBackgroundColor: string = "";
	openAdsModalOpened = false;

	constructor(
		private SFirestore: FirestoreService,
		private SMongo: MongodbService,
		private store: Store,
		private sanitizer: DomSanitizer
	) {}

	unsubscribeAll() {
		if (this.getCurrentEventSub && !this.getCurrentEventSub.closed) {
			this.getCurrentEventSub.unsubscribe();
		}
	}

	getEventsQuery(preQuery: any[], page: number, limit: number) {
		return combineLatest([
			this.store.select(getInitUser).pipe(skipWhile((init) => init === false)),
			this.store.select(getInitContainers).pipe(skipWhile((init) => init === false))
		]).pipe(
			take(1),
			switchMap(() =>
				combineLatest([this.store.select(getCurrentUser), this.store.select(getContainersState)]).pipe(take(1))
			),
			switchMap((results) => {
				const user = results[0];
				const containersState = results[1];
				const container =
					!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
						? containersState.defaultContainer
						: containersState.currentContainer;
				const containerType: "default" | "client" =
					!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
						? "default"
						: "client";

				const endPipeline: any[] = [
					{
						$addFields: {
							queryTitle: {
								$toLower: "$title"
							}
						}
					},
					{ $sort: { queryTitle: 1 } },
					{
						$facet: {
							docs: [{ $skip: page * limit }, { $limit: limit }],
							meta: [{ $count: "total" }]
						}
					}
				];

				if (
					container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM ||
					container.loginSettings.type === TypeLogin.WITHOUT_EMAIL_CONFIRM
				) {
					// For container with or without email confirm
					if (user) {
						let pipeline: any[] = [
							{
								$lookup: {
									from: "event-users",
									localField: "uid",
									foreignField: "eventId",
									let: { eventUserId: "$uid" },
									pipeline: [
										{
											$match: {
												uid: user.uid
											}
										}
									],
									as: "eventUser"
								}
							}
						];

						pipeline.push(
							containerType === "client" && container.type === "independant"
								? {
										$match: {
											$and: [
												{ clientId: { $eq: container.client.clientId } },
												{ eventUser: { $size: 1 } },
												{ archived: { $eq: false } }
											]
										}
								  }
								: {
										$match: {
											$and: [{ eventUser: { $size: 1 } }, { archived: { $eq: false } }]
										}
								  }
						);

						pipeline = preQuery.concat(pipeline).concat(endPipeline);

						return this.SMongo.queryDatasMongodb(pipeline, "events", {}, "aggregation");
					} else {
						if (
							container.loginSettings.showAccessPublicEventsBtn ||
							container.loginSettings.showShortCodeBtn
						) {
							/**
							 * If not user take only public events if container allow it
							 */
							const pipeline = preQuery
								.concat([
									containerType === "client" && container.type === "independant"
										? {
												$match: {
													$and: [
														{ "settings.visibility": { $eq: true } },
														{ clientId: { $eq: container.client.clientId } },
														{ archived: { $eq: false } }
													]
												}
										  }
										: {
												$match: {
													$and: [
														{ "settings.visibility": { $eq: true } },
														{ archived: { $eq: false } }
													]
												}
										  }
								])
								.concat(endPipeline);
							return this.SMongo.queryDatasMongodb(pipeline, "events", {}, "aggregation");
						} else {
							/**
							 * If no user and not allowed return empty
							 */
							return of({
								docs: [],
								totalDocuments: 0
							});
						}
					}
				} else if (container.loginSettings.type === TypeLogin.PUBLIC_EVENT_HOME) {
					/**
					 * Case multiple public events home page get all these events
					 */
					const pipeline = preQuery
						.concat([{ uid: { $in: container.loginSettings.publicEventsIds } }])
						.concat(endPipeline);
					return this.SMongo.queryDatasMongodb(pipeline, "events", {}, "aggregation");
				} else if (container.loginSettings.type === TypeLogin.WITH_SHORTCODE) {
					/**
					 * If shortcode login type don't take event (connection to one event with shortcode)
					 */
					return of({
						docs: [],
						totalDocuments: 0
					});
				}
			})
		);
	}

	/**
	 * Get event on store
	 * @param eventId
	 */
	getCurrentEventOnStore(eventId: string) {
		this.store
			.select(checkSameEventId(eventId))
			.pipe(auditTime(200), take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.getCurrentEventSub && !this.getCurrentEventSub.closed) {
					return;
				} else if (!sameEvent && this.getCurrentEventSub && !this.getCurrentEventSub.closed) {
					this.getCurrentEventSub.unsubscribe();
				}

				this.getCurrentEventSub = this.SFirestore.valueChangesDocument(`events/${eventId}`).subscribe(
					(event: IEvent) => {
						// Init custom event poperties styling
						this.logo = event.identity ? event.identity.logo : "";
						if (event.styling) {
							this.loaderColor = event.styling.menuColor;
							this.loaderBackgroundColor = event.styling.menuTextColor;
							document.body.style.setProperty("--bg-content-color", event.styling.bgContentColor);
							document.body.style.setProperty("--bg-general-color", event.styling.bgGeneralColor);
							document.body.style.setProperty("--btn-bg-color", event.styling.btnBgColor);
							document.body.style.setProperty("--btn-text-color", event.styling.btnTextColor);
							document.body.style.setProperty("--content-text-color", event.styling.contentTextColor);
							document.body.style.setProperty("--default-color", event.styling.defaultColor);
							document.body.style.setProperty(
								"--default-color-contrast",
								event.styling.defaultColorContrast
							);
							document.body.style.setProperty("--default-color-shade", event.styling.defaultColorShade);
							document.body.style.setProperty("--default-color-tint", event.styling.defaultColorTint);
							document.body.style.setProperty("--link-color", event.styling.linkColor);
							document.body.style.setProperty("--menu-active-color", event.styling.menuActiveColor);
							document.body.style.setProperty(
								"--menu-color",
								event.styling.allowGradient
									? `linear-gradient(to right,
										${event.styling.menuColor}, ${event.styling.menuColorGradient})`
									: event.styling.menuColor
							);
							document.body.style.setProperty("--menu-color-gradient", event.styling.menuColorGradient);
							document.body.style.setProperty("--menu-text-color", event.styling.menuTextColor);
							document.body.style.setProperty(
								"--menu-text-color-gradient",
								event.styling.menuTextColorGradient
							);
							document.body.style.setProperty("--title-color", event.styling.titleColor);
							document.body.style.setProperty(
								"--bg-header",
								event.styling.allowGradient
									? `linear-gradient(to right,
										${event.styling.menuColor}, ${event.styling.menuColorGradient})`
									: event.styling.menuColor
							);
							document.body.style.setProperty(`--btn-validate-primary-color`, event.styling.btnTextColor);
							document.body.style.setProperty(
								`--btn-validate-primary-bg-color`,
								event.styling.btnBgColor
							);
							if (event.styling.font) {
								const customFont = this.sanitizer.bypassSecurityTrustResourceUrl(
									`https://fonts.googleapis.com/css?family=${event.styling.font}:300,400,600,700`
								);
								document.body.style.setProperty(
									"--font",
									`'${event.styling.font.split("+").join(" ")}'`
								);
								this.store.dispatch(GetCustomFont({ payload: customFont }));
							} else {
								this.store.dispatch(GetCustomFont({ payload: null }));
							}
						} else {
							this.store.dispatch(GetCustomFont({ payload: null }));
						}
						this.store.dispatch(GetEvent({ payload: event ? event : null }));
						this.store
							.select(getInitSpecificEventDatasPart("initEvent"))
							.pipe(take(1))
							.subscribe((init) => {
								if (!init) {
									this.store.dispatch(
										InitSpecificEventDatasPart({ part: "initEvent", payload: true })
									);
								}
							});
					}
				);
			});
	}

	getSpecificEvent(eventId: string) {
		return this.SFirestore.getDocumentObs(`events/${eventId}`).pipe(map((snapshot) => snapshot.data() as IEvent));
	}

	/**
	 * Get event by shortcode
	 * @param shortcode
	 * @returns
	 */
	getEventByShortcode(shortcode: string): Observable<IEvent | null> {
		return this.SFirestore.getDocumentsObs(`events`, [where("shortcode", "==", shortcode)]).pipe(
			switchMap((results) => {
				if (results.size > 0) {
					const event = results.docs[0].data();
					return of(event);
				} else {
					return of(null);
				}
			})
		);
	}
}
