<ion-header>
	<ion-toolbar class="default-toolbar">
		<ion-buttons slot="start">
			<ion-button (click)="logOut()">
				<ion-icon slot="icon-only" class="icons-25 contrast-color" src="assets/icon/Power.svg"> </ion-icon>
			</ion-button>
		</ion-buttons>

		<ion-title class="contrast-color" *ngIf="!searchOpen">{{ "events_list.events" | translate }}</ion-title>
		<ion-searchbar
			*ngIf="searchOpen"
			[style.--background]="'white'"
			placeholder="{{'labels.search' | translate}}..."
			showCancelButton="true"
			cancelButtonText="{{'buttons.cancel' | translate}}"
			[(ngModel)]="terms"
			(ngModelChange)="searchValueUpdated.next($event)"
			(ionCancel)="searchOpen = false"
			animated
		>
		</ion-searchbar>
		<ion-buttons slot="end">
			<ion-button *ngIf="!searchOpen" (click)="searchOpen = true">
				<ion-icon slot="icon-only" class="icons-25 contrast-color" name="search"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<div class="background">
		<div *ngIf="events.length > 0">
			<ion-list *ngFor="let event of events; let i = index">
				<ion-card class="basic-card back-white cursor-pointer" (click)="openEvent(event)">
					<ion-card-header>
						<span *ngIf="!event.identity.banner"><br /></span>
						<ion-img
							style="margin-bottom: 10px"
							class="full-width-banner"
							[src]="event.identity.banner"
							*ngIf="event.identity.banner"
						>
						</ion-img>
					</ion-card-header>

					<ion-card-content class="p-t-5 bg-white">
						<strong>{{ event.title }}</strong>
						<br />
						<span class="float-right"
							>{{ getDate(event, event.startDate) }} - {{ getDate(event, event.endDate) }}</span
						>
						<br />
					</ion-card-content>
				</ion-card>
			</ion-list>
			<ion-infinite-scroll
				*ngIf="totalDocuments > 10"
				(ionInfinite)="moreDatas()"
				[disabled]="events.length >= totalDocuments"
			>
				<ion-infinite-scroll-content></ion-infinite-scroll-content>
			</ion-infinite-scroll>
		</div>

		<div *ngIf="eventsGetted && events.length === 0" class="f-row-c-c">
			<span class="wait-content default-color">{{ "events_list.no-events" | translate }}</span>
		</div>
	</div>

	<!-- <div *ngIf="loader" class="loader-container">
        <div class="loader-default" [style.width]="'60px'" [style.height]="'60px'"
            [style.border-color]="container.ionicColors.defaultColor"></div>
    </div> -->
</ion-content>
