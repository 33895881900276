import { DOCUMENT } from "@angular/common";
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router } from "@angular/router";
import { Browser } from "@capacitor/browser";
import { ModalController, NavController, Platform, PopoverController } from "@ionic/angular";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { where } from "firebase/firestore";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, combineLatest, filter, firstValueFrom, take } from "rxjs";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, IModule, IUser } from "src/app/shared/interfaces";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { IRegisterForm } from "src/app/shared/interfaces/register-form.interfaces";
import { IHeaderState } from "src/app/shared/interfaces/states.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentUser, getMyEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getChatsModule,
	getModulesByType,
	getNoteModule,
	getNoteTakingIconStatus,
	getSpecificModule
} from "src/app/shared/selectors/modules.selectors";
import { getRegisterForms } from "src/app/shared/selectors/register-form.selectors";
import { selectQueryParams } from "src/app/shared/selectors/router.selectors";
import { getChatsBadge, getHeaderState, getNotificationsBadge } from "src/app/shared/selectors/utility.selectors";
import {
	CardExchangeService,
	ChatsService,
	CheckinsService,
	EventUsersService,
	FavoritesService,
	FirestoreService,
	NotificationsService,
	UtilityService,
	VisiosService
} from "src/app/shared/services";
import { RegisterFormService } from "src/app/shared/services/register-form.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild("header", { read: ElementRef }) header: ElementRef;
	subscriptions: Subscription[] = [];

	event: IEvent;
	eventHomePage: string;
	user: IUser;

	@Input() currentUrl: string;
	baseUrl: string;
	routingHistory: string[] = [];

	hamburgerActivated: boolean = false;
	@Output() hamburgerClickedEvent: EventEmitter<boolean> = new EventEmitter();
	@Output() elementHeight: EventEmitter<any> = new EventEmitter();
	eventUser: IEventUser;

	typeModule = TypeModule;

	headerState: IHeaderState;
	types: typeof TypeHeader = TypeHeader;

	notificationsBadge: number = 0;
	chatsBadge: number = 0;

	isMobile: boolean = false;
	eventUserModule: IModule;
	defaultEventUserModule: IModule;
	currentLanguage: string = environment.platform.defaultLanguage;

	updating: boolean = false;

	noteIconStatus: boolean = false;

	queryParams: any;
	favoriteModule: IModule;
	cardExchangeModule: IModule;
	registerForm: IRegisterForm;
	totalAccompanyingUsers: number;

	constructor(
		private platform: Platform,
		private router: Router,
		private store: Store,
		private navCtrl: NavController,
		private popoverCtrl: PopoverController,
		public SChats: ChatsService,
		private SCheckins: CheckinsService,
		private snackbar: MatSnackBar,
		private SEventUsers: EventUsersService,
		private SVisios: VisiosService,
		private SNotifications: NotificationsService,
		private modalCtrl: ModalController,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private SFavorite: FavoritesService,
		private SCardExchange: CardExchangeService,
		private SFirestore: FirestoreService,
		@Inject(DOCUMENT) private _document: Document,
		private SRegisterForm: RegisterFormService
	) {
		this.isMobile =
			this.platform.is("ios") || this.platform.is("android") || this.platform.is("mobileweb") ? true : false;

		this.subscriptions.push(
			this.SUtility.goBackSubject.subscribe((goBack) => {
				if (goBack) {
					this.goBack();
				}
			})
		);

		this.subscriptions.push(
			this.store.select(selectQueryParams).subscribe((queryParams) => {
				this.queryParams = queryParams;
			})
		);
	}

	ngOnInit() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		this.subscriptions.push(
			combineLatest([
				this.store.select(getCurrentEvent),
				this.store.select(getCurrentUser),
				this.store.select(getMyEventUser)
			]).subscribe((results) => {
				if (
					!_.isEqual(results[0], this.event) ||
					!_.isEqual(results[1], this.user) ||
					!_.isEqual(results[2], this.eventUser)
				) {
					this.event = results[0];
					if (this.event) {
						this.baseUrl = this.event.homePage;
						this.eventHomePage = this.event.homePage;

						this.subscriptions.push(
							this.store
								.select(getSpecificModule(this.event.settings.defaultAttendeesModule))
								.subscribe((module) => {
									this.defaultEventUserModule = module;
								})
						);
					}
					this.user = results[1];
					this.eventUser = results[2];

					if (this.eventUser) {
						this.subscriptions.push(
							this.store.select(getSpecificModule(this.eventUser.moduleId)).subscribe((module) => {
								this.eventUserModule = module;
							})
						);

						this.getRegisterForm();

						// Favorites module
						this.subscriptions.push(
							this.store.select(getModulesByType(TypeModule.FAVORITES)).subscribe((modules) => {
								if (modules && modules.length > 0) {
									if (!_.isEqual(modules[0], this.favoriteModule)) {
										this.favoriteModule = modules[0];
									}
								}
							})
						);

						// Card exchange module
						this.subscriptions.push(
							this.store.select(getModulesByType(TypeModule.CARD_EXCHANGE)).subscribe((modules) => {
								if (modules && modules.length > 0) {
									this.cardExchangeModule = modules[0];
								}
							})
						);
					}
				}
			})
		);

		this.subscriptions.push(
			this.store.select(getHeaderState).subscribe((headerState) => {
				if (!_.isEqual(this.headerState, headerState)) {
					this.headerState = headerState;
				}
			})
		);

		this.subscriptions.push(
			this.router.events
				.pipe(filter((evt: any) => evt instanceof NavigationEnd))
				.subscribe((event: NavigationEnd) => {
					if (this.routingHistory.length > 0) {
						// If go back
						if (this.routingHistory[this.routingHistory.length - 1] !== event.urlAfterRedirects) {
							// Go forward
							this.routingHistory.push(event.urlAfterRedirects);
						}
					} else {
						this.routingHistory.push(event.urlAfterRedirects);
					}
				})
		);

		this.subscriptions.push(
			this.store.select(getChatsBadge).subscribe((count) => {
				this.chatsBadge = count;
			})
		);

		this.subscriptions.push(
			this.store.select(getNotificationsBadge).subscribe((count) => {
				this.notificationsBadge = count;
			})
		);

		this.subscriptions.push(
			this.store.select(getNoteTakingIconStatus).subscribe((noteIconStatus) => {
				this.noteIconStatus = noteIconStatus;
			})
		);

		this.routingHistory.push(this.currentUrl);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngAfterViewInit(): void {
		const checkExist = setInterval(() => {
			if (this.header && this.header.nativeElement && this.header.nativeElement.clientHeight > 0) {
				this.elementHeight.emit(this.header.nativeElement.clientHeight);
				clearInterval(checkExist);
			}
		}, 100);
	}

	/**
	 * Clicked hamburger event
	 */
	hamburgerClicked() {
		this.hamburgerActivated = !this.hamburgerActivated;
		this.hamburgerClickedEvent.emit(this.hamburgerActivated);
	}

	/**
	 * Going back
	 */
	goBack() {
		if (this.routingHistory.length === 1) {
			this.routingHistory.pop();
			this.navCtrl.navigateRoot(this.baseUrl);
		} else {
			this.routingHistory.pop();
			this.navCtrl.back();
		}
	}

	getRegisterForm() {
		this.subscriptions.push(
			this.store
				.select(getRegisterForms())
				.pipe(take(1))
				.subscribe((registerForms) => {
					this.registerForm = registerForms.find(
						(registerForm) =>
							registerForm.eventId === this.event?.uid &&
							registerForm.moduleId === this.eventUser.moduleId
					);
				})
		);
	}

	openNoteModule() {
		this.store
			.select(getNoteModule)
			.pipe(take(1))
			.subscribe((module) => {
				if (module) {
					this.navCtrl.navigateForward(`event/${this.event.uid}/note-taking/${module.uid}`);
				}
			});
	}

	openAppointmentsSettings() {
		this.navCtrl.navigateForward(`event/${this.event.uid}/appointments/${this.headerState.module.uid}/settings`);
	}

	/**
	 * Chat detail
	 */

	/**
	 * Present groups chats settings popover
	 * @param ev
	 * @returns
	 */
	async presentPopover(ev: any) {
		if (this.headerState && this.headerState.item) {
			const popover = await this.popoverCtrl.create({
				component: PathComponents.groupChatsSettings,
				componentProps: {
					params: {
						eventId: this.headerState.item.eventId,
						moduleId: this.headerState.item.moduleId,
						chatId: this.headerState.item.uid,
						eventUser: this.eventUser
					}
				},
				event: ev,
				animated: true,
				showBackdrop: true
			});

			return await popover.present();
		}
	}

	/**
	 * Navigate forward
	 * @param path
	 */
	navigateTo(path: string) {
		this.navCtrl.navigateForward(path);
	}

	async navigateToProfile() {
		if (
			this.headerState &&
			this.headerState.module &&
			this.headerState.module.options &&
			this.headerState.module.options.modifProfilButtonLinkType &&
			this.headerState.module.options.modifProfilButtonLinkType === "external" &&
			this.headerState.module.options.profilButtonLink &&
			this.headerState.module.options.profilButtonLink.baseUrl
		) {
			await Browser.open({
				url: `${this.headerState.module.options.profilButtonLink.baseUrl}${
					this.headerState.module.options.profilButtonLink.params.length > 0 ? "?" : ""
				}${this.headerState.module.options.profilButtonLink.params.length > 0 ? this.buildParamsUrl() : ""}`
			});
		} else {
			this.navigateTo(`/event/${this.event.uid}/profile-edit/${this.eventUser.moduleId}/${this.eventUser.uid}`);
		}
	}

	buildParamsUrl() {
		return this.headerState.module.options.profilButtonLink.params
			.map((param) => {
				let paramsFull: string = "";

				if (!param.paramKey) {
					return null;
				}

				if (param.paramKey) {
					paramsFull += param.paramKey + "=";
				}

				if (param.paramData.type === "value") {
					if (!param.paramData.value) {
						return null;
					} else {
						paramsFull += param.paramData.value;
					}
				} else {
					if (
						!param.paramData.fieldKey ||
						!this.headerState.item ||
						!this.headerState.item[param.paramData.fieldKey]
					) {
						return null;
					} else {
						paramsFull += this.headerState.item[param.paramData.fieldKey];
					}
				}
				return paramsFull;
			})
			.filter((param) => param)
			.join("&");
	}

	/**
	 * Launch an event for create a visio
	 */
	createVisioChat() {
		this.SVisios.createVisioSubject.next(true);
	}

	/**
	 * Scan qr
	 */
	scanQr() {
		this.SCheckins.scanQrSubject.next(true);
	}

	/**
	 * favoriteScanQr
	 */
	favoriteScanQr() {
		this.SFavorite.favoriteFolderScanQrSubject.next(true);
	}

	/**
	 * cardExchangeSCanQr
	 */
	cardExchangeSCanQr() {
		this.SCardExchange.cardExchangeScanQrSubject.next(true);
	}

	async openChatsModule() {
		const chatModule = await firstValueFrom(this.store.pipe(select(getChatsModule), take(1)));
		if (chatModule) {
			this.navCtrl.navigateForward([chatModule.urlApp]);
		}
	}

	/**
	 * Open notification modal
	 * @returns
	 */
	async openNotifications() {
		const notifications = this.SNotifications.getNotifications();
		const modal = await this.modalCtrl.create({
			component: PathComponents.notifications,
			componentProps: {
				eventId: this.event.uid,
				// currentUser: this.eventUser,
				openTime: DateTime.now().valueOf(),
				notifications: notifications.filter((notif) => notif.deliveryDate <= DateTime.now().valueOf()),
				displayNotifID: ""
			},
			cssClass: "full-sized-modal"
		});
		return await modal.present();
	}

	/**
	 * Update notif for user
	 * @param key
	 * @param valueKey
	 * @param value
	 */
	async updateNotifsEventUser(key: string, valueKey: string) {
		try {
			if (this.eventUser && this.eventUser.options && !this.updating) {
				this.updating = true;
				if (!this.eventUser.options[key]) {
					this.eventUser.options[key] = {};
				}

				if (this.eventUser.options[key][valueKey] === undefined) {
					this.eventUser.options[key][valueKey] = false;
				} else {
					this.eventUser.options[key][valueKey] = !this.eventUser.options[key][valueKey];
				}

				await this.SEventUsers.updatePartOfEventUser(
					this.event.uid,
					this.eventUser.moduleId,
					this.eventUser.uid,
					{
						options: {
							feedNewsNotifs: this.eventUser.options.feedNewsNotifs
						}
					}
				);
				this.updating = false;
			}
		} catch (error) {
			this.updating = false;
		}
	}

	/**
	 * Check if allow notifs
	 * @param module
	 * @returns
	 */
	checkAllowNotifs(module: IModule) {
		if (
			module &&
			module.options &&
			module.options.activateNotifs &&
			(!this.eventUser.options ||
				!this.eventUser.options.feedNewsNotifs ||
				this.eventUser.options.feedNewsNotifs[module.uid] === undefined ||
				this.eventUser.options.feedNewsNotifs[module.uid])
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Open modal for creating a chat
	 * @returns
	 */
	async showCreateChat() {
		const modal = await this.modalCtrl.create({
			component: PathComponents.createChat,
			cssClass: "full-sized-modal"
		});
		return await modal.present();
	}

	/**
	 * Leave chat
	 * @param chat
	 */
	async leaveChat(chat: IChat) {
		try {
			if (this.eventUser && chat && !this.updating) {
				this.updating = true;
				const updatedChat = _.cloneDeep(chat);
				updatedChat.options.specificMembersLink.specifics.splice(
					updatedChat.options.specificMembersLink.specifics.indexOf(
						updatedChat.options.specificMembersLink.specifics.find((uid) => uid === this.eventUser.uid)
					),
					1
				);
				updatedChat.members.splice(
					updatedChat.members.indexOf(updatedChat.members.find((uid) => uid === this.eventUser.uid)),
					1
				);

				await this.SChats.updateFullChat(updatedChat.eventId, updatedChat.moduleId, updatedChat);
				this.navCtrl.navigateBack(`/event/${updatedChat.eventId}/chats/${updatedChat.moduleId}`);
				this.updating = false;
			}
		} catch (error) {
			this.updating = false;
		}
	}

	/**
	 * Delete a chat if creator
	 * @param chat
	 */
	async deleteChat(chat: IChat) {
		try {
			if (this.eventUser && chat && !this.updating) {
				this.updating = true;
				await this.SChats.deleteChat(chat);
				this.navCtrl.navigateBack(`/event/${chat.eventId}/chats/${chat.moduleId}`);
				this.updating = false;
			}
		} catch (error) {
			this.updating = false;
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegisterForm() {
		try {
			const totalEventUsers = await firstValueFrom(
				this.SFirestore.getCountOfQueryObs("event-users", "group", [where("eventId", "==", this.event.uid)])
			);

			if (totalEventUsers >= this.event?.limitations?.usersLimit) {
				this.snackbar.open(this.STranslate.instant("register.event_users_limit_reached"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			if (!this.defaultEventUserModule || !this.eventUserModule?.uid) {
				this.snackbar.open(this.STranslate.instant("snackbar.no_module_found"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			const modal = await this.modalCtrl.create({
				id: this.headerState.type === this.types.CHECKIN ? "checkin" : "register-modal",
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event.uid,
					mode: "checkin",
					moduleId: this.defaultEventUserModule ? this.eventUserModule?.uid : null,
					manualOpen: true
				},
				cssClass: "full-sized-modal" //: "confirm-register-modal"
			});
			await modal.present();

			<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
				((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
					"hidden");
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	async openAccompanyingForm() {
		try {
			await this.SRegisterForm.openAccompanyingForm(this.event, this.eventUser, this.registerForm);
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	openFavoriteModule() {
		this.navCtrl.navigateForward(`/event/${this.event.uid}/favorites/${this.favoriteModule.uid}`);
	}
}
