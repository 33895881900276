import { Component, OnDestroy, OnInit } from "@angular/core";
import { FirebaseApp } from "@angular/fire/app";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, skipWhile } from "rxjs";
import { Loading } from "src/app/shared/actions/utility.actions";
import { IContainer } from "src/app/shared/interfaces";
import { getContainersState } from "src/app/shared/selectors/containers.selectors";
import { AuthService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-reset-password",
	templateUrl: "./reset-password.component.html",
	styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	isMobile: boolean;
	loader: boolean = false;
	activeLoader: boolean = false;
	showPwd: boolean = false;
	subscriptions: Subscription[] = [];
	interval: Subscription;
	container: IContainer;
	environment = environment;
	loginForm: FormGroup;
	firebaseApp: FirebaseApp;
	mode: string;
	oobCode: string;
	continueUrl: string;
	lang: string;
	passwordView: boolean = true;
	successView: boolean = false;
	showLoginButon: boolean = false;
	errorView: boolean = false;
	errorMsg: string;

	constructor(
		private fb: FormBuilder,
		private platform: Platform,
		private store: Store,
		private activatedRoute: ActivatedRoute,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private SAuth: AuthService
	) {
		this.isMobile = this.platform.is("ios") || this.platform.is("android");
		this.loginForm = this.fb.group({
			// email: "",
			password: ["", [Validators.required, Validators.minLength(6)]],
			confirmPassword: ["", [Validators.required, Validators.minLength(6)]]
		});
	}

	ngOnInit() {
		this.subscriptions.push(
			this.store
				.select(getContainersState)
				.pipe(
					skipWhile((state) => (state && state.currentContainer === null) || state.defaultContainer === null)
				)
				.subscribe({
					next: (containersState) => {
						this.container =
							!environment.platform.containerId ||
							environment.platform.containerId === "defaultContainer" ||
							!containersState.currentContainer
								? containersState.defaultContainer
								: containersState.currentContainer;

						this.loader = false;
						this.store.dispatch(Loading({ payload: false }));
					}
				})
		);

		this.subscriptions.push(
			this.activatedRoute.queryParams.subscribe(async (params) => {
				params?.mode && (this.mode = params.mode);
				params?.oobCode && (this.oobCode = params.oobCode);
				params?.continueUrl && (this.continueUrl = params.continueUrl);
				params?.lang && (this.lang = params.lang);
				try {
					await this.SAuth.verifyPasswordResetCode(this.oobCode);
				} catch (error) {
					this.showErrorView(error.message);
					this.snackbar.open(this.STranslate.instant("snackbar.invalid_reset_password_code"), "OK", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	showSuccessView() {
		this.successView = true;
		this.passwordView = false;
		this.errorView = false;
		this.errorMsg = null;
	}

	showErrorView(errorMsg: string) {
		this.errorView = true;
		this.passwordView = false;
		this.successView = false;
		this.errorMsg = errorMsg;
	}

	/**
	 * updatePassword
	 */
	async updatePassword() {
		try {
			const newPassword = this.loginForm.getRawValue().password;
			await this.SAuth.updateUserPassword(this.oobCode, newPassword);
			this.showSuccessView();
			this.snackbar.open(this.STranslate.instant("snackbar.confirm_reset_password_success"), "OK", {
				duration: 3000,
				panelClass: "success-snackbar"
			});
		} catch (error) {
			console.error("🚀 ~ ResetPasswordComponent ~ updatePassword ~ error:", error);
			this.snackbar.open(this.STranslate.instant("snackbar.confirm_reset_password_error"), "OK", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * goToLogin
	 * INFO: redirect to login page, will be implemented in the future
	 * (probably when we will have same email domain for emails and apps)
	 */
	goToLogin() {
		window.location.href =
			window.location.href.split(".net/reset-password")[0]?.split("recovery.")[1] + ".web.app/login";
	}
}
