import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "video"
})
export class VideoPipe implements PipeTransform {
	/**
	 * Vimeo autoplay param accepts 1 or 0 not true/false values
	 * @param value
	 */
	private booleanToString(value: boolean): string {
		return value ? "1" : "0";
	}

	/**
	 * add start and autoplay params to the url if
	 * Youtube or Vimeo
	 * @param url
	 * @param start
	 */
	transform(url: string, start: number): string {
		url = url || "";

		if (start) {
			if (url.includes("youtube")) {
				return `${url}?start=${start}&autoplay=${!!start}`;
			} else if (url.includes("vimeo")) {
				return `${url}?autoplay=${this.booleanToString(!!start)}#t=${start}s`;
			}
		}
		return url;
	}
}
