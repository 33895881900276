<ion-header>
	<ion-toolbar
		class="ion-color"
		[style.background]="event.styling.menuColor"
		[style.color]="event.styling.menuTextColor"
	>
		<ion-buttons slot="start">
			<ion-button slot="icon-only" (click)="close()">
				<ion-icon src="assets/icon/Close.svg"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>{{ location ? location.name : ("labels.locations" | translate) }}</ion-title>
	</ion-toolbar>
</ion-header>
<ion-content class="hide-scrollbar" style="--background: var(--bg-color-content)">
	<div class="background" style="overflow-y: scroll">
		<div *ngIf="event && location">
			<h2 class="location-name">{{ location.name }}</h2>

			<div *ngIf="location.address" class="f-row-c-fs" style="width: 100%">
				<span class="b3-icons b3-icon-43 b3app-icons section-icon" style="margin-right: 20px"></span>
				<span class="section-name">{{ "labels.address" | translate }}</span>
			</div>
			<div *ngIf="location.address" class="f-col-fs-c section-text-container">
				<span *ngFor="let part of location.address.split(',')" class="section-text">{{
					removeSpace(part)
				}}</span>
			</div>

			<img *ngIf="location.image" class="local-img" [src]="location.image" />

			<div *ngIf="location.description" class="f-row-c-fs" style="width: 100%">
				<span class="b3-icons b3-icon-43 b3app-icons section-icon" style="margin-right: 20px"></span>
				<span class="section-name">{{ "labels.information" | translate }}</span>
			</div>
			<div *ngIf="location.description" class="f-row-c-fs section-text-container">
				<span class="section-text" [innerHTML]="location.description | safeHtml"></span>
			</div>

			<div *ngIf="location.address" class="f-row-c-fs" style="width: 100%; margin-bottom: 20px">
				<span class="b3-icons b3-icon-42 b3app-icons section-icon"></span>
				<span class="section-name">{{ "labels.plan" | translate }}</span>
			</div>
			<div
				*ngIf="location.address"
				#planContainer
				class="f-row-c-c section-text-container"
				[style.padding]="isMobile ? '30px 0' : '30px'"
			>
				<img
					[style.width]="isMobile && planContainer ? planContainer.clientWidth - 60 + 'px' : '600px'"
					(click)="openGooglePlan(location.address)"
					[src]="
						getGooglePlan(
							location.address,
							isMobile && planContainer ? planContainer.clientWidth - 60 : 600
						)
					"
					class="section-plan"
				/>
			</div>
		</div>
	</div>
</ion-content>
