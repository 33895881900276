import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { TypeModule } from "../enums/type-module";
import { TypeUser } from "../enums/type-user";
import { TypeVisionModule } from "../enums/type-vision-module";
import { IEvent, IEventUser, IUser } from "../interfaces";
import { IModule, IModulesState } from "../interfaces/modules.interfaces";
import { getCurrentUser, getMyEventUser } from "./auth.selectors";
import { getCurrentEvent } from "./events.selectors";

export const getModulesState = createFeatureSelector<IModulesState>("modules");

export const checkSameEvent = (uid: string) =>
	createSelector(getModulesState, (state: IModulesState) => {
		return state.eventId === uid ? true : false;
	});

export const getAllModules = createSelector(getModulesState, (state: IModulesState) => _.cloneDeep(state.modules));

export const getGenericModules = createSelector(
	getModulesState,
	getCurrentEvent,
	(state: IModulesState, event: IEvent) => {
		const modules = _.cloneDeep(state.modules);
		if (event && event.availableSingleModules) {
			return modules
				.filter((module) => {
					if (
						module.type === TypeModule.MANAGER_MODULES ||
						(module.type === TypeModule.CHAT &&
							event.availableSingleModules.chats &&
							module.options &&
							!module.options.redirectUrl) ||
						(module.type === TypeModule.CUSTOM_FIELDS && event.availableSingleModules.customFields) ||
						(module.type === TypeModule.FEEDBACKS && event.availableSingleModules.feedbacks) ||
						(module.type === TypeModule.MANAGER_GROUP && event.availableSingleModules.groups) ||
						(module.type === TypeModule.LOCATION &&
							event.availableSingleModules.location &&
							module.options &&
							!module.options.redirectUrl) ||
						(module.type === TypeModule.NOTIFICATION && event.availableSingleModules.notifications) ||
						(module.type === TypeModule.TRACKS && event.availableSingleModules.tracks)
					) {
						return true;
					} else {
						return false;
					}
				})
				.sort((a, b) => (a.moduleOrder < b.moduleOrder ? 1 : a.moduleOrder > b.moduleOrder ? -1 : 0));
		} else {
			return modules
				.filter((module) => module.habilitedBackOffice && module.moduleOrder <= -1)
				.sort((a, b) => (a.moduleOrder < b.moduleOrder ? 1 : a.moduleOrder > b.moduleOrder ? -1 : 0));
		}
	}
);

export const getNotGenericModules = createSelector(
	getModulesState,
	getCurrentEvent,
	getCurrentUser,
	getMyEventUser,
	(state: IModulesState, event: IEvent, user: IUser, eventUser: IEventUser) => {
		const modules = _.cloneDeep(state.modules);
		return modules
			.filter((module) => {
				if (module.visionType === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
					if (user && user.type <= TypeUser.EMPLOYEE) {
						return true;
					} else {
						if (eventUser && eventUser.groups.some((id) => module.groupsAccess.includes(id))) {
							return true;
						} else {
							return false;
						}
					}
				} else {
					return true;
				}
			})
			.filter((module) => {
				if (
					(module.type === TypeModule.NOTE_TAKING &&
						event.availableSingleModules.noteTaking &&
						module.habilitedApp &&
						eventUser) ||
					(module.type === TypeModule.SHEETS &&
						event.availableModules.sheets &&
						module.habilitedApp &&
						eventUser) ||
					(module.type === TypeModule.CARD_EXCHANGE &&
						event.availableModules.cardExchange &&
						module.options.enablecardExchangeForm &&
						module.options.activatecardExchangeOn.length > 0 &&
						module.habilitedApp &&
						eventUser) ||
					(module.habilitedBackOffice &&
						module.type === TypeModule.ASK_QUESTION &&
						event.availableModules.askQuestion &&
						module.options &&
						module.options.redirectUrl) ||
					(module.type === TypeModule.APPOINTMENTS && event.availableModules.appointments) ||
					(module.type === TypeModule.ATTENDEE && event.availableModules.attendees) ||
					(module.type === TypeModule.CHAT &&
						(!event.availableSingleModules || event.availableSingleModules.chats) &&
						module.options &&
						module.options.redirectUrl &&
						module.moduleOrder >= 0) ||
					(module.type === TypeModule.CHECKIN && event.availableModules.checkin) ||
					(module.type === TypeModule.SELF_CHECKIN && event.availableModules.checkin) ||
					(module.type === TypeModule.CUSTOM_PAGE && event.availableModules.customPage) ||
					(module.type === TypeModule.DOCUMENT && event.availableModules.documents) ||
					(module.type === TypeModule.EVENT && event.availableModules.event) ||
					(module.type === TypeModule.EXTERNAL_LINK && event.availableModules.externalLink) ||
					(module.type === TypeModule.EXTERNAL_INTERACTIVITY &&
						event.availableModules.externalInteractivity &&
						module.options &&
						module.options.redirectUrl) ||
					(module.type === TypeModule.NEWS_FEED && event.availableModules.feedNews) ||
					(module.type === TypeModule.GALLERY && event.availableModules.gallery) ||
					(module.type === TypeModule.QUIZ &&
						event.availableModules.quiz &&
						module.options &&
						module.options.redirectUrl) ||
					(module.type === TypeModule.RANKINGS && event.availableModules.rankings) ||
					(module.type === TypeModule.SCHEDULE && event.availableModules.schedules) ||
					(module.type === TypeModule.PERSONALSCHEDULE && event.availableModules.schedules) ||
					(module.type === TypeModule.SPEAKER && event.availableModules.speakers) ||
					(module.type === TypeModule.SURVEY &&
						event.availableModules.survey &&
						module.options &&
						module.options.redirectUrl) ||
					(module.type === TypeModule.TRAINING && event.availableModules.training) ||
					(module.type === TypeModule.TREASURE_HUNTS &&
						event.availableModules.treasureHunts &&
						module.options &&
						module.options.redirectUrl) ||
					(module.type === TypeModule.WIDGETS && event.availableModules.widget) ||
					(module.type === TypeModule.LOCATION &&
						(!event.availableSingleModules || event.availableSingleModules.location) &&
						module.options &&
						module.options.redirectUrl &&
						module.moduleOrder >= 0) ||
					(module.type === TypeModule.WORDCLOUD &&
						event.availableModules.wordCloud &&
						module.options &&
						module.options.redirectUrl) ||
					module.type === TypeModule.INTERACTIVITY ||
					module.type === TypeModule.GAMIFICATION ||
					module.type === TypeModule.DIVIDER
				) {
					return true;
				} else {
					return false;
				}
			})
			.sort((a, b) =>
				a.type === TypeModule.NOTE_TAKING
					? 1
					: b.type === TypeModule.NOTE_TAKING
					? -1
					: a.moduleOrder > b.moduleOrder
					? 1
					: a.moduleOrder < b.moduleOrder
					? -1
					: 0
			);
	}
);

export const getGenericModuleByType = (type: number) =>
	createSelector(getGenericModules, (genericModules: IModule[]) => {
		const modules = _.cloneDeep(genericModules);
		return modules.find((module) => module.type === type);
	});

export const getSpecificModule = (uid: string) =>
	createSelector(getModulesState, (state: IModulesState) => {
		const module = state.modules.find((module) => module.uid === uid);
		return module ? _.cloneDeep(module) : null;
	});

export const getModulesByType = (type: number) =>
	createSelector(getModulesState, (state: IModulesState) => {
		const modules = _.cloneDeep(state.modules);
		return modules.filter((module) => module.type === type);
	});

export const getModulesByTypes = (types: number[]) =>
	createSelector(getModulesState, (state: IModulesState) => {
		const modules = _.cloneDeep(state.modules);
		return modules.filter((module) => types.includes(module.type) && !module.options.redirectUrl);
	});

export const getInteractivityModule = createSelector(getModulesState, (state: IModulesState) => {
	const module = state.modules.find((module) => module.type === TypeModule.INTERACTIVITY);
	return module ? _.cloneDeep(module) : null;
});

export const getInteractivityModules = createSelector(getModulesState, (state: IModulesState) => {
	const modules = _.cloneDeep(state.modules);
	return modules.filter(
		(module) =>
			[
				TypeModule.SURVEY,
				TypeModule.QUIZ,
				TypeModule.ASK_QUESTION,
				TypeModule.TRAINING,
				TypeModule.WORDCLOUD,
				TypeModule.EXTERNAL_INTERACTIVITY
			].includes(module.type) &&
			(!module.options || !module.options.redirectUrl)
	);
});

export const getModulesTypes = (types: number[]) =>
	createSelector(getModulesState, (state: IModulesState) => {
		const modules = _.cloneDeep(state.modules);
		return modules.filter((module) => types.includes(module.type));
	});

export const getGamificationModule = createSelector(getModulesState, (state: IModulesState) => {
	const module = state.modules.find((module) => module.type === TypeModule.GAMIFICATION);
	return module ? _.cloneDeep(module) : null;
});

export const getGamificationModules = createSelector(getModulesState, (state: IModulesState) => {
	const modules = _.cloneDeep(state.modules);
	return modules.filter(
		(module) =>
			[TypeModule.TREASURE_HUNTS].includes(module.type) && (!module.options || !module.options.redirectUrl)
	);
	// return modules
	// 	.filter((module) => [33].includes(module.type) && !module.remove)
	// 	.sort((a, b) =>
	// 		a.options.gamificationOrder > b.options.gamificationOrder
	// 			? 1
	// 			: a.options.gamificationOrder < b.options.gamificationOrder
	// 			? -1
	// 			: 0
	// 	);
});

export const getChatsModule = createSelector(getModulesState, (state: IModulesState) => {
	return state.modules.find(
		(module) => module.type === TypeModule.CHAT && module.urlBackOffice && !module.options.redirectUrl
	);
});

export const getFavoriteModule = createSelector(getModulesState, (state: IModulesState) => {
	return state.modules.find((module) => module.type === TypeModule.FAVORITES);
});

export const getNoteTakingIconStatus = createSelector(getModulesState, (state: IModulesState) => {
	const noteTakingModule = state.modules.find((module) => module.type === TypeModule.NOTE_TAKING);
	return noteTakingModule && noteTakingModule.options && noteTakingModule.options.showIconHeader ? true : false;
});

export const getNoteModule = createSelector(getModulesState, (state: IModulesState) => {
	return state.modules.find((module) => module.type === TypeModule.NOTE_TAKING);
});
