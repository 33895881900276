<div *ngIf="event && module && eventUser" class="background">
	<ng-container *ngIf="wordsClouds.length > 0; else noContent">
		<div *ngFor="let cloud of wordsClouds">
			<ion-item
				detail
				class="card-content cursor-pointer"
				[style.background]="event.styling.bgContentColor"
				*ngIf="cloud.visibility"
				(click)="openWordsCloud(cloud)"
			>
				<span [style.color]="event.styling.titleColor">{{ cloud.name[currentLanguage] }}</span>
			</ion-item>
		</div>
	</ng-container>

	<ng-template #noContent>
		<div class="container-no-content">
			<span class="wait-content" [style.color]="event.styling.titleColor">{{
				"texts.waiting_for_content" | translate
			}}</span>
			<ion-icon
				src="assets/images/emptycontent/AUCUNRESULTAT.svg"
				[style.color]="
					event.styling.menuColor.includes('linear')
						? event.styling.menuColor.split(',')[1]
						: event.styling.menuColor
				"
			>
			</ion-icon>
			<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
				<span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
				<span>{{ "interactivity.default_empty_content_text" | translate }}</span>
			</div>
		</div>
	</ng-template>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
