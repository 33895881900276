import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule } from "@angular/core";
import { CustomDatePipe } from "./custom.datepipe";
import { FilterChatsPipe } from "./filter-chats.pipe";
import { SafeHtmlPipe } from "./safe-html";
import { SearchFilterPipe } from "./search-filter";
import { SortListPipe } from "./sort-list.pipe";
import { splitFirstPipe } from "./split-first-pipe";
import { SplitPipe } from "./split-string-pipe";
import { VideoPipe } from "./video.pipe";

registerLocaleData(localeFr);

@NgModule({
	declarations: [
		FilterChatsPipe,
		SafeHtmlPipe,
		SearchFilterPipe,
		CustomDatePipe,
		SortListPipe,
		SplitPipe,
		splitFirstPipe,
		VideoPipe
	],

	exports: [
		FilterChatsPipe,
		SafeHtmlPipe,
		SearchFilterPipe,
		CustomDatePipe,
		SortListPipe,
		SplitPipe,
		splitFirstPipe,
		VideoPipe
	],
	providers: [{ provide: LOCALE_ID, useValue: "fr-FR" }]
})
export class PipesModule {}
