<div class="container">
	<!-- <div [ngClass]="device === 'ios' ? 'ios-close-btn' : 'close-btn'" class="p-t-10">
		<ion-button fill=" clear" color="dark" (click)="closeModal()">
			<ion-icon color="danger" src="assets/icon/Close.svg"></ion-icon>
		</ion-button>
	</div> -->

	<!-- Schedule -->
	<!-- Single Session -->
	<div
		*ngIf="
			(module.type === typeModule.SCHEDULE || module.type === typeModule.PERSONALSCHEDULE) && mode === 'session'
		"
		class="calendar-bloc"
	>
		<div class="calendar-text">
			<h5 class="calendar-text">
				{{
					session.name[language ? language : eventUser ? eventUser.updatedSettings.language : event.language]
				}}
			</h5>
		</div>
		<div style="display: flex; justify-content: center">
			<div class="agenda-logos">
				<!-- Google agenda logo -->
				<div style="display: flex">
					<div class="google-logo img-logo" (click)="addSessionToCalendar('google')">
						<img src="assets/images/google-calendar.png" alt="Google Calendar Logo" />
					</div>
				</div>
				<p>{{ "texts.ics_link_google" | translate }}</p>
			</div>

			<div class="agenda-logos">
				<!-- ICS agenda logo -->
				<div style="display: block">
					<div class="ics-logo img-logo" (click)="addSessionToCalendar('ics')">
						<img src="assets/images/ics.png" alt="ICS Calendar Logo" />
					</div>
				</div>
				<p>{{ "texts.ics_download" | translate }}</p>
			</div>
		</div>
	</div>

	<!-- Multiple sessions -->
	<div
		*ngIf="
			(module.type === typeModule.SCHEDULE || module.type === typeModule.PERSONALSCHEDULE) && mode === 'schedule'
		"
		class="calendar-bloc"
	>
		<div class="calendar-text" style="margin: 0 !important; padding: 0 !important">
			<h5 class="calendar-text">
				{{ module.name[language] }}
			</h5>

			<div class="agenda-logos" style="margin: 0 !important; padding: 0 !important">
				<!-- ICS agenda logo -->
				<div style="display: block; margin-top: 0">
					<div
						class="ics-logo img-logo"
						style="width: 65px !important; height: 55px !important"
						(click)="downloadSchedule()"
					>
						<img
							src="assets/images/ics.png"
							style="width: 65px !important; height: 55px !important"
							alt="ICS Calendar Logo"
						/>
					</div>
				</div>

				<p style="max-width: none; margin-top: 15px">{{ "texts.ics_schedule_download" | translate }}</p>
			</div>
		</div>
	</div>

	<!-- Appointements -->
	<div *ngIf="module.type === typeModule.APPOINTMENTS" class="calendar-bloc">
		<div class="calendar-text">
			<h5 class="calendar-text">
				{{ "appointments.save-on-personal-schedule" | translate }}
			</h5>
		</div>
		<div style="display: flex; justify-content: center">
			<div class="agenda-logos">
				<!-- Google agenda logo -->
				<div style="display: block">
					<div class="google-logo img-logo" (click)="linkTimeSlotToCalendar(appointment)">
						<img src="assets/images/google-calendar.png" alt="Google Calendar Logo" />
					</div>
				</div>
				<p>{{ "texts.ics_link_google" | translate }}</p>
			</div>

			<div class="agenda-logos">
				<!-- ICS agenda logo -->
				<div style="display: block">
					<div class="ics-logo img-logo" (click)="generateTimeSlotIcsFile(appointment)">
						<img src="assets/images/ics.png" alt="ICS Calendar Logo" />
					</div>
				</div>
				<p>{{ "texts.ics_download" | translate }}</p>
			</div>
		</div>
	</div>
</div>
