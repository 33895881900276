/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from "@angular/core";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { where } from "firebase/firestore";
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom, take } from "rxjs";
import { GetAllFavoritesFolders } from "../actions/generics-modules-data.actions";
import { GetScanning, InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IEvent, IEventUser, IFullCustomField, IModule } from "../interfaces";
import { checkSameEvent } from "../selectors/generics-modules-data.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { CustomFieldsService } from "./custom-fields.service";
import { DocumentsService } from "./documents.service";
import { EventUsersService } from "./event-users.service";
import { FirestoreService } from "./firestore.service";
import { UtilityService } from "./utility.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
	providedIn: "root"
})
export class FavoritesService {
	foldersSub: Subscription;
	favoriteFolderScanQrSubject: Subject<boolean> = new Subject();

	constructor(
		private SFirestore: FirestoreService,
		private store: Store,
		private SUtility: UtilityService,
		private STranslate: TranslateService,
		private SEventUser: EventUsersService,
		private SCustomFields: CustomFieldsService,
		private SDocuments: DocumentsService,
		private platform: Platform,
		private snackbar: MatSnackBar
	) {}

	unsubscribeAll() {
		if (this.foldersSub && !this.foldersSub.closed) {
			this.foldersSub.unsubscribe();
		}
	}

	/**
	 * Get all folders of event
	 * @param eventId
	 * @returns
	 */
	getAllFavoriteFoldersOfEvent(eventId: string) {
		this.store.select(checkSameEvent({ key: "favoritesFolders", uid: eventId })).subscribe((sameEvent) => {
			if (sameEvent && this.foldersSub && !this.foldersSub.closed) {
				return;
			} else if (!sameEvent && this.foldersSub && !this.foldersSub.closed) {
				this.foldersSub.unsubscribe();
			}

			this.foldersSub = this.SFirestore.collectionGroupValueChangesDocuments("favorite-folders", [
				where("eventId", "==", eventId)
			])
				.pipe(debounceTime(200), distinctUntilChanged())
				.subscribe((folders) => {
					this.store.dispatch(GetAllFavoritesFolders({ payload: folders, eventId: eventId }));
					this.store
						.select(getInitSpecificEventDatasPart("initFavorites"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initFavorites", payload: true })
								);
							}
						});
				});
		});
	}

	async perfomScanQr(event: IEvent, module: IModule, eventUser: IEventUser, customFields: IFullCustomField[]) {
		try {
			const allowed = await this.checkPermission();
			if (allowed) {
				BarcodeScanner.hideBackground(); // make background of WebView transparent
				this.store.dispatch(GetScanning({ payload: true }));
				const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

				// if the result has content
				if (result.hasContent) {
					await this.addToFavorite(
						result.content.split("+") && result.content.split("+").length > 0
							? result.content.split("+")[0]
							: null,
						result.content.split("+") && result.content.split("+").length > 0
							? result.content.split("+")[1]
							: null,
						event,
						module,
						eventUser,
						customFields
					);
				}

				this.store.dispatch(GetScanning({ payload: false }));
			}
		} catch (error) {
			this.store.dispatch(GetScanning({ payload: false }));

			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.error_scanning"),
				this.STranslate.instant("alerts.no_camera"),
				[
					{
						text: this.STranslate.instant("buttons.yes"),
						handler: () => {
							this.perfomScanQr(event, module, eventUser, customFields);
						}
					},
					{
						text: this.STranslate.instant("buttons.no"),
						role: "cancel"
					}
				]
			);
			alert.present();
		}
	}

	/**
	 * checkPermission
	 * @returns
	 */
	async checkPermission() {
		const status = await BarcodeScanner.checkPermission({ force: true });
		if (status.granted) {
			return true;
		} else if (status.denied) {
			BarcodeScanner.openAppSettings();
			return false;
		}
	}

	/**
	 * Create checkin result
	 * @param qrEventId
	 * @param qrEventUserId
	 */
	async addToFavorite(
		qrEventId: string,
		qrEventUserId: string,
		event: IEvent,
		module: IModule,
		eventUser: IEventUser,
		customFields: IFullCustomField[]
	) {
		try {
			const eventUserToScan = await firstValueFrom(
				this.SEventUser.getSpecificEventUser(qrEventId, qrEventUserId)
			);

			if (qrEventId && qrEventUserId && eventUserToScan) {
				const isFavorite = eventUser.favorites.includes(qrEventUserId);

				if (isFavorite) {
					// already favorite
					this.presentAlertConfirm("alerts.not_success", "alerts.contact_already_in_your_fav", false, null);
					return;
				} else {
					eventUser.favorites?.push(qrEventUserId);
					await this.SEventUser.updatePartOfEventUser(eventUser.eventId, eventUser.moduleId, eventUser.uid, {
						favorites: eventUser.favorites
					});
				}

				this.presentAlertConfirm("alerts.success", "alerts.contact_has_been_add_to_your_fav", true, {
					event: event,
					module: module,
					eventUser: eventUserToScan,
					customFields: customFields,
					platform: this.platform,
					domEvent: null
				});
			} else {
				const alert = await this.SUtility.presentAlert(
					this.STranslate.instant("alerts.error_scanning"),
					this.STranslate.instant("alerts.invalid_qrcode"),
					[
						{
							text: this.STranslate.instant("buttons.yes"),
							handler: () => {
								this.perfomScanQr(event, module, eventUser, customFields);
							}
						},
						{
							text: this.STranslate.instant("buttons.no"),
							role: "cancel",
							handler: () => {}
						}
					]
				);
				alert.present();
			}
		} catch (error) {
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.error_scanning"),
				this.STranslate.instant("alerts.invalid_qrcode"),
				[
					{
						text: this.STranslate.instant("buttons.yes"),
						handler: () => {
							this.perfomScanQr(event, module, eventUser, customFields);
						}
					},
					{
						text: this.STranslate.instant("buttons.no"),
						role: "cancel",
						handler: () => {}
					}
				]
			);
			alert.present();
		}
	}

	/**
	 * Present a confirmation alert
	 */
	async presentAlertConfirm(
		title: string,
		msg: string,
		downloadVcf: boolean,
		vcfDatas: {
			event: IEvent;
			module: IModule;
			eventUser: IEventUser;
			customFields: IFullCustomField[];
			platform: Platform;
			domEvent?: any;
		}
	) {
		try {
			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant(title),
				this.STranslate.instant(msg),
				downloadVcf
					? [
							{
								text: this.STranslate.instant("buttons.ok"),
								handler: () => {}
							},
							{
								text: this.STranslate.instant("buttons.download_vcf"),
								handler: async () => {
									await this.downloadVcard(
										vcfDatas.event,
										vcfDatas.module,
										vcfDatas.eventUser,
										vcfDatas.customFields,
										vcfDatas.domEvent
									);
								}
							}
					  ]
					: [
							{
								text: this.STranslate.instant("buttons.ok"),
								handler: () => {}
							}
					  ],
				[]
			);

			await alert.present();
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * downloadVCard
	 * @param eventUser
	 */
	async downloadVcard(
		event: IEvent,
		module: IModule,
		eventUser: IEventUser,
		customFields: IFullCustomField[],
		domEvent
	) {
		try {
			domEvent?.stopPropagation();

			const eventUserUpdatedSettings = await firstValueFrom(
				this.SEventUser.getSpecificEventUserUpdatedSettings(
					eventUser.eventId,
					eventUser.moduleId,
					eventUser.uid
				)
			);

			if (!eventUserUpdatedSettings) throw new Error("No updated settings found for this user");

			eventUser.updatedSettings = eventUserUpdatedSettings;

			const { baseInformations, otherInformations } = this.SCustomFields.getCustomFieldsCardDatas(
				eventUser,
				module,
				customFields,
				event.language
			);

			const { eventUserJob, eventUserCompany, eventUserPhoneNumber, eventUserAddress } = baseInformations;

			const eventUserPhotoData =
				eventUser.photoUrl !== ""
					? await firstValueFrom(this.SDocuments.convertFileUrlToBase64(eventUser.photoUrl))
					: null;

			let vCardData = `BEGIN:VCARD
				VERSION:3.0
				N:${
					eventUser.name.split(" ").length > 1
						? eventUser.name.split(" ")[0] + ";" + eventUser.name.split(" ").slice(1).join(" ")
						: eventUser.name
				};;;
				FN:${eventUser.name};
				X-SOCIALPROFILE;type=Facebook:${
					(module.options.enableUserFieldsHideAbility &&
						(eventUser?.updatedSettings?.fieldsVisibility?.["facebook"] === false ||
							(!eventUser?.updatedSettings?.fieldsVisibility &&
								module.options.requiredFields?.["facebook"]?.hiding?.default === false))) ||
					!module.options.enableUserFieldsHideAbility
						? eventUser?.socialMedias.facebook
						: ""
				}
				X-SOCIALPROFILE;type=Linkedin:${
					(module.options.enableUserFieldsHideAbility &&
						(eventUser?.updatedSettings?.fieldsVisibility?.["linkedin"] === false ||
							(!eventUser?.updatedSettings?.fieldsVisibility &&
								module.options.requiredFields?.["linkedin"]?.hiding?.default === false))) ||
					!module.options.enableUserFieldsHideAbility
						? eventUser?.socialMedias.linkedin
						: ""
				}
				X-SOCIALPROFILE;type=Twitter:${
					(module.options.enableUserFieldsHideAbility &&
						(eventUser?.updatedSettings?.fieldsVisibility?.["twitter"] === false ||
							(!eventUser?.updatedSettings?.fieldsVisibility &&
								module.options.requiredFields?.["twitter"]?.hiding?.default === false))) ||
					!module.options.enableUserFieldsHideAbility
						? eventUser?.socialMedias.twitter
						: ""
				}
				X-SOCIALPROFILE;type=Instagram:${
					(module.options.enableUserFieldsHideAbility &&
						(eventUser?.updatedSettings?.fieldsVisibility?.["instagram"] === false ||
							(!eventUser?.updatedSettings?.fieldsVisibility &&
								module.options.requiredFields?.["instagram"]?.hiding?.default === false))) ||
					!module.options.enableUserFieldsHideAbility
						? eventUser?.socialMedias.instagram
						: ""
				}
				X-SOCIALPROFILE;type=Website:${
					(module.options.enableUserFieldsHideAbility &&
						(eventUser?.updatedSettings?.fieldsVisibility?.["website"] === false ||
							(!eventUser?.updatedSettings?.fieldsVisibility &&
								module.options.requiredFields?.["website"]?.hiding?.default === false))) ||
					!module.options.enableUserFieldsHideAbility
						? eventUser?.socialMedias.website
						: ""
				}
				ORG:${
					eventUser?.updatedSettings && eventUserCompany
						? eventUserCompany[eventUser?.updatedSettings?.language] &&
						  eventUserCompany[eventUser?.updatedSettings?.language] !== ""
							? eventUserCompany[eventUser?.updatedSettings?.language]
							: eventUserCompany?.[event.language] ?? ""
						: eventUserCompany?.[event.language] ?? ""
				};
				TITLE:${
					eventUser?.updatedSettings && eventUserJob
						? eventUserJob[eventUser?.updatedSettings?.language] &&
						  eventUserJob[eventUser?.updatedSettings?.language] !== ""
							? eventUserJob[eventUser?.updatedSettings?.language]
							: eventUserJob?.[event.language] ?? ""
						: eventUserJob?.[event.language] ?? ""
				}
				JOB:${
					eventUser?.updatedSettings && eventUserJob
						? eventUserJob[eventUser?.updatedSettings?.language] &&
						  eventUserJob[eventUser?.updatedSettings?.language] !== ""
							? eventUserJob[eventUser?.updatedSettings?.language]
							: eventUserJob?.[event.language] ?? ""
						: eventUserJob?.[event.language] ?? ""
				}
				X-JOB:${
					eventUser?.updatedSettings && eventUserJob
						? eventUserJob[eventUser?.updatedSettings?.language] &&
						  eventUserJob[eventUser?.updatedSettings?.language] !== ""
							? eventUserJob[eventUser?.updatedSettings?.language]
							: eventUserJob?.[event.language] ?? ""
						: eventUserJob?.[event.language] ?? ""
				}
				TEL:${eventUserPhoneNumber ? eventUserPhoneNumber : ""}
				EMAIL:${
					(module.options.enableUserFieldsHideAbility &&
						(eventUser?.updatedSettings?.fieldsVisibility?.["email"] === false ||
							(!eventUser?.updatedSettings?.fieldsVisibility &&
								module.options.requiredFields?.["email"]?.hiding?.default === false))) ||
					!module.options.enableUserFieldsHideAbility
						? eventUser.email
						: ""
				}
				ADR: ${eventUserAddress ? eventUserAddress : ""}
				NOTE:${Object.entries(otherInformations?.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {}))
					.filter(([_, value]) => value !== "" && value !== null && value !== undefined)
					.map(([key, value]) => `${key}: ${value}`)
					.join(" | ")}
				${eventUserPhotoData ? `PHOTO;ENCODING=b;TYPE=JPEG:${eventUserPhotoData}` : null}
				END:VCARD`;

			// Remove all tabulations
			vCardData = vCardData.replace(/\t/g, "");

			if (!(this.platform.is("mobile") && window.innerWidth < 768)) {
				const element = document.createElement("a");
				element.setAttribute("href", "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardData));
				element.setAttribute("download", `${eventUser.name}.vcf`);
				element.setAttribute("target", "_blank");
				element.style.display = "none";
				element.click();
			} else {
				this.SDocuments.downloadFile(`${eventUser.name}.vcf`, vCardData);
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}
}
