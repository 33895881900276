<app-visio
	*ngIf="event && module && eventUser && chat && chatVisio && showVisioWebApp && !componentMode"
	[type]="'chat'"
	[event]="event"
	[eventUser]="eventUser"
	[joined]="true"
	[visio]="chatVisio"
	(closeVisioEvent)="closeVisio()"
>
</app-visio>

<ng-container *ngIf="event && module && chat">
	<div
		class="background-mode-component"
		[class.full-w]="componentMode"
		[style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'"
	>
		<ion-row *ngIf="chat && componentMode && canGoBack">
			<ion-icon
				class="icons-25"
				name="arrow-back"
				[style.color]="event.styling.titleColor"
				(click)="goBackToList()"
			>
			</ion-icon>
		</ion-row>
		<div
			#msgsComp
			id="msgsComp"
			[style.height]="
				isMobile && componentMode
					? '300px'
					: footer !== undefined
					? 'calc(100% - ' + footer.clientHeight + 'px)'
					: '90%'
			"
			class="message-wrap msg-wrap"
			*ngIf="messages.length >= 1"
		>
			<div
				abindex="1"
				*ngFor="let msg of messages; let i = index"
				class="msg-item"
				[ngClass]="eventUser && msg.sender.uid === eventUser.uid ? 'connected-usr-msg' : 'other-usr-msg'"
				[ngStyle]="{ 'grid-template-rows': chatId && 'repeat(3, auto)' }"
			>
				<ion-avatar
					*ngIf="eventUser && msg.sender && msg.sender.uid !== eventUser.uid"
					[style.background]="event.styling.menuColor"
					(click)="goToProfile(msg.sender)"
					class="sender-img"
					[ngStyle]="{ 'grid-row': '2 / 3' }"
				>
					<img [src]="msg.sender.photoUrl" *ngIf="msg.sender.photoUrl" />
					<div class="profile-picture" *ngIf="!msg.sender.photoUrl">
						<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
					</div>
				</ion-avatar>

				<div
					class="msg-sender"
					style="grid-column: 2"
					*ngIf="chatId && eventUser && msg.sender.uid !== eventUser.uid"
				>
					{{ msg.sender.name }}
					<ion-icon
						*ngIf="
							msg &&
							msg.sender &&
							msg.sender.uid &&
							msg.sender.identifier &&
							msg.sender.email &&
							module.options.activateReport
						"
						slot="end"
						name="alert-circle"
						color="danger"
						class="cursor-pointer"
						(click)="openReportAlert(msg)"
					>
					</ion-icon>
				</div>

				<div *ngIf="!msg.visioUrl && !msg.appointment" class="msg-text">
					<div [style.color]="event.styling.contentTextColor" [innerHTML]="msg.content | linky"></div>
					<img
						class="msg-picture cursor-pointer"
						[src]="msg.imageUrl"
						style="max-height: 420px"
						*ngIf="msg.imageUrl"
						(click)="openImage(msg.imageUrl)"
					/>
				</div>

				<div class="visio-message-container msg-text" *ngIf="msg.visioUrl">
					<ion-avatar class="call-icon">
						<ion-icon
							*ngIf="msg.visioUrl !== 'leaved'"
							class="call-received-icon"
							[style.color]="event.styling.menuColor"
							src="assets/icon/Camera2.svg"
						>
						</ion-icon>
						<ion-icon
							*ngIf="msg.visioUrl === 'leaved'"
							class="call-exit-icon"
							[style.color]="event.styling.menuColor"
							name="exit"
						>
						</ion-icon>
					</ion-avatar>
					<div *ngIf="msg.visioUrl !== 'leaved'" class="message-container">
						<span
							[style.color]="event.styling.contentTextColor"
							class="line-breaker"
							style="margin-bottom: 10px"
						>
							{{ "texts.visio-launched" | translate }}
						</span>
						<span
							(click)="openVisio(msg)"
							class="btn-join-visio"
							[style.background]="event.styling.btnBgColor"
							[style.color]="event.styling.btnTextColor"
						>
							{{ "texts.join-visio" | translate }}
						</span>
					</div>
					<span *ngIf="msg.visioUrl === 'leaved'" class="line-breaker">
						{{ "texts.visio-leaved" | translate }}
					</span>
				</div>
				<!-- <app-chat-message-appointment *ngIf="msg.appointment" [event]="event" [eventUser]="eventUser"
                    [interlocutor]="interlocutor" [message]="msg"
                    [normalBorder]="msg.sender.uid !== eventUser.uid ? 'left' : 'right'"
                    [style.grid-column]="msg.sender.uid !== eventUser.uid ? 'auto' : 2"
                    [style.width]="msg.sender.uid !== eventUser.uid ? (isMobile ? 'auto' : '70%') : (isMobile ? 'auto': '50%')"
                    [style.margin-left]="msg.sender.uid !== eventUser.uid ? '0' : (isMobile ? '0%' : '50%')">
                </app-chat-message-appointment> -->
				<p [style.color]="event.styling.contentTextColor" class="msg-time">{{ getDate(msg.sendAt) }}</p>
			</div>
		</div>

		<div class="no-msgs" *ngIf="messages.length <= 0">
			<span>{{ "chat.no_messages" | translate }}</span>
		</div>

		<div
			#footer
			[style.margin-bottom]="android ? '45px' : !isMobile ? '0' : 'unset'"
			[style.max-height]="uploadedFile ? '330px' : '90px'"
			[style.height]="uploadedFile ? '330px' : '90px'"
			[class.chat-footer-sticky-component-mode]="componentMode"
			[class.footer-chat]="!componentMode"
		>
			<div class="pre-send-img-container" [class.img-component]="componentMode" *ngIf="uploadedFile">
				<img class="pre-send-img-pic" [src]="uploadedFile" />
				<br /><br />
			</div>
			<div class="align-loader" *ngIf="loaderUploadPic">
				<div class="loader-default"></div>
			</div>
			<div class="input-wrap" style="width: 100%">
				<ion-textarea
					autocapitalize="sentences"
					[style.color]="event.styling.contentTextColor"
					#chatInput
					placeholder="{{ 'chat.type_msg' | translate }}"
					[(ngModel)]="message"
					(keyup.enter)="createMessage()"
				></ion-textarea>
				<ion-buttons slot="end">
					<ion-button
						*ngIf="!isMobile"
						(click)="showEmojiPicker = !showEmojiPicker"
						class="basic-transparent-icon-button"
					>
						<ion-label class="emoji-label">😀</ion-label>
					</ion-button>
					<emoji-mart
						*ngIf="showEmojiPicker && !isMobile"
						class="emoji-mart"
						[style]="{ position: 'absolute', bottom: '75px', right: '20px' }"
						[set]="set"
						(emojiSelect)="addEmoji($event)"
						[isNative]="true"
						[exclude]="emojisExcluded"
						[virtualize]="true"
					>
					</emoji-mart>
					<ion-button slot="icon-only" (click)="getImageToSend()">
						<ion-icon
							src="assets/icon/Image.svg"
							class="icon-not-send"
							[style.color]="event.styling.btnBgColor"
						>
						</ion-icon>
					</ion-button>
					<div class="divider"></div>
					<ion-button
						slot="icon-only"
						class="send-btn"
						(click)="createMessage()"
						[style.background]="event.styling.btnBgColor"
						[style.color]="event.styling.btnTextColor"
					>
						<ion-icon src="assets/icon/Send.svg" class="send-icon"></ion-icon>
					</ion-button>
				</ion-buttons>
			</div>
		</div>
	</div>
</ng-container>
