<ion-content class="page">
	<div *ngIf="event" style="position: relative" class="background">
		<!-- <div *ngIf="module && folder" class="m-t-5"> -->
		<div *ngIf="module && folder">
			<ion-searchbar
				[style.--background]="'rgb(234, 239, 242)'"
				[placeholder]="'texts.search_plc' | translate"
				(ionClear)="resetFilter()"
				(ionChange)="filterDocs($event)"
			>
			</ion-searchbar>

			<div *ngIf="showTitle" [style.color]="event.styling.titleColor" class="title-documents">
				<ion-icon src="assets/icon/Clip.svg"></ion-icon>
				<span>{{ "documents.default_title" | translate }}</span>
			</div>
			<ion-list *ngIf="documents.length > 0">
				<ion-item
					*ngFor="let document of documents"
					detail="false"
					lines="full"
					class="align-flex clickable"
					(click)="openDocument(document)"
				>
					<ion-thumbnail
						slot="start"
						class="no-margins"
						[ngStyle]="{ 'width.px': widthImg, 'height.px': widthImg }"
					>
						<img
							[ngStyle]="{ 'width.px': widthImg, 'height.px': widthImg, color: event.styling.menuColor }"
							[src]="
								document.icon === 'jpg_ico.png' || document.icon === 'png_ico.png'
									? 'assets/icon/Files/photo_ico.svg'
									: 'assets/icon/Files/' + document.icon.substr(0, document.icon.length - 3) + 'svg'
							"
						/>
					</ion-thumbnail>
					<ion-label [style.color]="event.styling.titleColor" style="margin-left: 10px">
						<h2>{{ document?.name[currentLanguage] }}</h2>
						<p>{{ SDocuments.checkType(document.type) }}</p>
					</ion-label>
					<ion-icon
						slot="end"
						[style.color]="event.styling.btnBgColor"
						style="cursor: pointer"
						name="copy-outline"
						(click)="copyToClipboard(document); $event.stopPropagation()"
					></ion-icon>
				</ion-item>
			</ion-list>

			<div *ngIf="documents.length === 0" class="container">
				<ion-icon
					[src]="'assets/images/emptycontent/AUCUNRESULTAT.svg'"
					[style.color]="
						event.styling.menuColor.includes('linear')
							? event.styling.menuColor.split(',')[1]
							: event.styling.menuColor
					"
				>
				</ion-icon>
				<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
					<span class="title"> {{ "texts.no_result_title" | translate }}</span>
					<span>{{ "texts.no_result_text" | translate }}</span>
				</div>
			</div>
		</div>
	</div>
</ion-content>
