<ion-content class="page">
	<div *ngIf="event && eventUser" class="background">
		<ion-list>
			<ng-container *ngFor="let mod of modulesShowed">
				<ion-item
					lines="none"
					*ngIf="moduleSurveys && mod.type === moduleSurveys.type"
					[style.border-bottom-color]="event.styling.titleColor + '1c'"
					(click)="navigateTo(moduleSurveys.urlApp, 'surveys')"
					class="cursor-pointer"
				>
					<ion-avatar
						item-start
						[style.background]="event.styling.menuColor"
						[style.color]="event.styling.menuTextColor"
					>
						<ion-icon src="assets/icon/Star.svg"></ion-icon>
					</ion-avatar>
					<ion-label class="spaced ion-text-wrap">
						<h2 class="module-title" [style.color]="event.styling.titleColor">
							{{ moduleSurveys.name[currentLanguage] }}
						</h2>

						<p [style.color]="event.styling.contentTextColor">
							{{ "interactivity.survey_description" | translate }}
						</p>
					</ion-label>
				</ion-item>

				<ion-item
					lines="none"
					*ngIf="moduleQuizs && mod.type === moduleQuizs.type"
					(click)="navigateTo(moduleQuizs.urlApp, 'quizs')"
					class="cursor-pointer"
					[style.border-bottom-color]="event.styling.titleColor + '1c'"
				>
					<ion-avatar
						item-start
						[style.background]="event.styling.menuColor"
						[style.color]="event.styling.menuTextColor"
					>
						<ion-icon src="assets/icon/Message.svg"></ion-icon>
					</ion-avatar>
					<ion-label class="spaced ion-text-wrap">
						<h2 class="module-title" [style.color]="event.styling.titleColor">
							{{ moduleQuizs.name[currentLanguage] }}
						</h2>
						<p [style.color]="event.styling.contentTextColor">
							{{ "interactivity.quiz_description" | translate }}
						</p>
					</ion-label>
				</ion-item>

				<ion-item
					lines="none"
					*ngIf="moduleTrainings && mod.type === moduleTrainings.type"
					[style.border-bottom-color]="event.styling.titleColor + '1c'"
					(click)="navigateTo(moduleTrainings.urlApp, 'training')"
					class="cursor-pointer"
				>
					<ion-avatar item-start>
						<span
							class="b3-icons icon-space {{ moduleTrainings.iconFamily }}"
							*ngIf="moduleTrainings.iconFamily !== 'material-icons'"
							[style.color]="event.styling.menuColor"
						></span>
						<i
							class="{{ moduleTrainings.iconFamily }} icon-space"
							*ngIf="moduleTrainings.iconFamily === 'material-icons'"
							[style.color]="event.styling.menuColor"
							>{{ moduleTrainings.icon }}</i
						>
					</ion-avatar>
					<ion-label class="spaced ion-text-wrap">
						<h2 class="module-title" [style.color]="event.styling.titleColor">
							{{ moduleTrainings.name[currentLanguage] }}
						</h2>
						<p [style.color]="event.styling.contentTextColor">
							{{ "interactivity.training_description" | translate }}
						</p>
					</ion-label>
				</ion-item>

				<ion-item
					lines="none"
					*ngIf="moduleAskQuestions && mod.type === moduleAskQuestions.type"
					[style.border-bottom-color]="event.styling.titleColor + '1c'"
					(click)="navigateTo(moduleAskQuestions.urlApp, 'asksQuestions')"
					class="cursor-pointer"
				>
					<ion-avatar
						item-start
						[style.background]="event.styling.menuColor"
						[style.color]="event.styling.menuTextColor"
					>
						<ion-icon src="assets/icon/Check.svg"></ion-icon>
					</ion-avatar>
					<ion-label class="spaced ion-text-wrap">
						<h2 class="module-title" [style.color]="event.styling.titleColor">
							{{ moduleAskQuestions.name[currentLanguage] }}
						</h2>

						<p [style.color]="event.styling.contentTextColor">
							{{ "interactivity.ask_question_description" | translate }}
						</p>
					</ion-label>
				</ion-item>

				<ion-item
					lines="none"
					*ngIf="moduleExternalsInteractivity && mod.type === moduleExternalsInteractivity.type"
					[style.border-bottom-color]="event.styling.titleColor + '1c'"
					(click)="navigateTo(moduleExternalsInteractivity.urlApp, 'externalsInteractivity')"
					class="cursor-pointer"
				>
					<ion-avatar
						item-start
						[style.background]="event.styling.menuColor"
						[style.color]="event.styling.menuTextColor"
					>
						<ion-icon src="assets/icon/Check.svg"></ion-icon>
					</ion-avatar>
					<ion-label class="spaced ion-text-wrap">
						<h2 class="module-title" [style.color]="event.styling.titleColor">
							{{ moduleExternalsInteractivity.name[currentLanguage] }}
						</h2>

						<p [style.color]="event.styling.contentTextColor">
							{{ "interactivity.externals_interactivity_description" | translate }}
						</p>
					</ion-label>
				</ion-item>

				<ion-item
					lines="none"
					*ngIf="moduleWordClouds && mod.type === moduleWordClouds.type"
					[style.border-bottom-color]="event.styling.titleColor + '1c'"
					(click)="navigateTo(moduleWordClouds.urlApp, 'wordsClouds')"
					class="cursor-pointer"
				>
					<ion-avatar item-start>
						<span
							class="b3-icons icon-space {{ moduleWordClouds.iconFamily }}"
							*ngIf="moduleWordClouds.iconFamily !== 'material-icons'"
							[style.color]="event.styling.menuColor"
						></span>
						<i
							class="{{ moduleWordClouds.iconFamily }} icon-space"
							*ngIf="moduleWordClouds.iconFamily === 'material-icons'"
							[style.color]="event.styling.menuColor"
							>{{ moduleWordClouds.icon }}</i
						>
					</ion-avatar>
					<ion-label class="spaced ion-text-wrap">
						<h2 class="module-title" [style.color]="event.styling.titleColor">
							{{ moduleWordClouds.name[currentLanguage] }}
						</h2>
						<p [style.color]="event.styling.contentTextColor">
							{{ "interactivity.ask_question_description" | translate }}
						</p>
					</ion-label>
				</ion-item>
			</ng-container>
		</ion-list>
	</div>

	<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
</ion-content>
