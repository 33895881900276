/* eslint-disable max-len */
import { Component } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { IEvent, IEventUser } from "src/app/shared/interfaces";
import { IICS } from "src/app/shared/interfaces/ics.interfaces";
import { SchedulesService } from "src/app/shared/services";

@Component({
	selector: "app-confirm-register",
	templateUrl: "./confirm-register.component.html",
	styleUrls: ["./confirm-register.component.scss"]
})
export class ConfirmRegisterComponent {
	event: IEvent;
	eventUser: IEventUser;
	message: string;
	isMobile: boolean;
	device: string;
	enableAddToCalendar: boolean;
	calendarEvent: IICS;

	constructor(private modalCtrl: ModalController, private platform: Platform, private SSchedule: SchedulesService) {
		if ((this.platform.is("mobile") && window.innerWidth < 768) || window.innerWidth < 768) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}

		this.device = this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "desktop";
	}

	addToCalendar(type: string) {
		this.SSchedule.addEventToCalendar(this.event, type, this.calendarEvent);
	}

	closeModal() {
		this.modalCtrl.dismiss(null, null, "confirm-register-modal");
	}
}
